import * as React from "react";
const SvgInformation = (props) => (
  <svg
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16 0C7.2 0 0 7.2 0 16s7.2 16 16 16 16-7.2 16-16S24.8 0 16 0Zm0 29C8.8 29 3 23.2 3 16S8.8 3 16 3s13 5.8 13 13-5.8 13-13 13Z"
      fill="#EF8517"
    />
    <path
      d="M14 14h4v10h-4V14ZM18 10a2 2 0 1 1-3.999.001A2 2 0 0 1 18 10Z"
      fill="#EF8517"
    />
  </svg>
);
export default SvgInformation;
