import React from "react";
import PropTypes from "prop-types";
import { getYear, endOfYear, subYears, eachMonthOfInterval } from "date-fns";
import { capitalize } from "/src/utilities";
import { useApolloClient } from "@apollo/client";

import Chart, {
  LoadingChart,
  PlaceholderChart,
  PlaceholderChartText,
  NoDataChart,
} from "/src/components/chart";
import { useTranslation } from "src/translations/translationProvider";
import { Translate } from "src/translations/translate";
import { mapTypeToColor, hexToRGBA } from "/src/components/chart/utils";
import { useOrganizationQuery } from "/src/services/apollo/hooks/useOrganizationQuery";
import {
  GET_ORGANIZATION_TWO_YEAR_MONTHLY_SUMMARIES,
  getMonthlyFragmentsForYear,
} from "/src/services/apollo/queries";
import { useGetCurrentUser } from "src/modules/userProvider";

const YearAggregateChart = ({ className, year }) => {
  const { translate, formatDate } = useTranslation();
  const currentUser = useGetCurrentUser();
  const apolloClient = useApolloClient();
  const organizationId =
    currentUser.selectedOrganizationConnection.organization.id;

  const previousYear = Number(year) - 1;
  let cachedDataForCurrentYear = null;
  let cachedDataForPreviousYear = null;
  const currentYear = getYear(new Date());
  const isLookingForCurrentAndPreviousYear = year === currentYear;
  if (isLookingForCurrentAndPreviousYear) {
    const endDateMoment = subYears(endOfYear(new Date()), 1);
    cachedDataForCurrentYear = getMonthlyFragmentsForYear({
      organizationId,
      year: currentYear,
      apolloClient,
    });
    cachedDataForPreviousYear = getMonthlyFragmentsForYear({
      organizationId,
      year: previousYear,
      endDateMoment,
      apolloClient,
    });
  }
  const { loading, error, data } = useOrganizationQuery(
    GET_ORGANIZATION_TWO_YEAR_MONTHLY_SUMMARIES,
    {
      variables: {
        year,
        previousYear,
        canSkipCurrentYear: isLookingForCurrentAndPreviousYear,
        canSkipPreviousYear: isLookingForCurrentAndPreviousYear,
      },
    }
  );

  if (loading) {
    return <LoadingChart className={className} key="year agg chart loading" />;
  }

  if (error) {
    return (
      <PlaceholderChart className={className} key="year agg chart error">
        <PlaceholderChartText>
          <Translate>UnexpectedError</Translate>
        </PlaceholderChartText>
      </PlaceholderChart>
    );
  }

  let { selectedYearSummaries, previousYearSummaries } =
    data.organization.energy;

  if (isLookingForCurrentAndPreviousYear) {
    selectedYearSummaries = cachedDataForCurrentYear || [];
    previousYearSummaries = cachedDataForPreviousYear || [];
  }

  if (
    !selectedYearSummaries ||
    !previousYearSummaries ||
    (!selectedYearSummaries.length && !previousYearSummaries.length)
  ) {
    return <NoDataChart className={className} key="year agg chart empty" />;
  }

  const opacity = 0.5;
  const productionColor = mapTypeToColor("Production");
  const previousProductionColor = hexToRGBA(productionColor, opacity);
  const consumptionColor = mapTypeToColor("Consumption");
  const previousConsumptionColor = hexToRGBA(consumptionColor, opacity);

  const datasetOptions = {
    fill: false,
    borderWidth: 0,
    pointRadius: 0,
    pointHitRadius: 16,
    borderJoinStyle: "bevel",
  };

  const toMonthString = (monthIndex) =>
    capitalize(formatDate(new Date(2020, monthIndex), "MMMM"));

  const productionDataset = {
    ...datasetOptions,
    label: `${translate("Production")} ${year}`,
    backgroundColor: productionColor,
    data: selectedYearSummaries.map((summary) => ({
      x: toMonthString(summary.monthIndex),
      y: summary.production,
    })),
  };
  const previousProductionDataset = previousYearSummaries.length && {
    ...datasetOptions,
    label: `${translate("Production")} ${previousYear}`,
    backgroundColor: previousProductionColor,
    data: previousYearSummaries.map((summary) => ({
      x: toMonthString(summary.monthIndex),
      y: summary.production,
    })),
  };
  const consumptionDataset = {
    ...datasetOptions,
    label: `${translate("Consumption")} ${year}`,
    backgroundColor: consumptionColor,
    data: selectedYearSummaries.map((summary) => ({
      x: toMonthString(summary.monthIndex),
      y: summary.consumption,
    })),
  };
  const previousConsumptionDataset = previousYearSummaries.length && {
    ...datasetOptions,
    label: `${translate("Consumption")} ${previousYear}`,
    backgroundColor: previousConsumptionColor,
    data: previousYearSummaries.map((summary) => ({
      x: toMonthString(summary.monthIndex),
      y: summary.consumption,
    })),
  };

  const allMonthNames = eachMonthOfInterval({
    start: new Date(2020, 0),
    end: new Date(2020, 11),
  }).map((month) => capitalize(formatDate(month, "MMMM")));

  return (
    <Chart
      type="Bar"
      className={className}
      data={{
        labels: allMonthNames,
        datasets: [
          productionDataset,
          consumptionDataset,
          previousProductionDataset,
          previousConsumptionDataset,
        ].filter((hasValue) => hasValue),
      }}
      options={{
        yLabel: selectedYearSummaries[0].unit,
        xAxisType: "category",
      }}
    />
  );
};

YearAggregateChart.propTypes = {
  className: PropTypes.string,
  year: PropTypes.number.isRequired,
};

export default YearAggregateChart;
