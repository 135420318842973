import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { ButtonStateIndicator } from "./buttonStateIndicator";
import BUTTON_STATE from "./buttonState";
import styles from "./button.module.scss";

const FEEDBACK_DURATION = 1500;

const Button = (props) => {
  const {
    className,
    iconClassName,
    children,
    type,
    small,
    outline,
    icon: Icon,
    state,
    setState,
    statusText,
    ...restProps
  } = props;
  const shouldResetState =
    state === BUTTON_STATE.SUCCESS || state === BUTTON_STATE.FAIL;
  if (shouldResetState) {
    setTimeout(() => {
      setState && setState(BUTTON_STATE.DEFAULT);
    }, FEEDBACK_DURATION);
  }
  return (
    <button
      data-test-id={`button-${type}`}
      className={classNames(styles.button, className, styles[type], {
        [styles.small]: small,
        [styles.outline]: outline,
        [styles.isLoading]: state === BUTTON_STATE.LOADING,
        [styles.isSuccess]: state === BUTTON_STATE.SUCCESS,
        [styles.isFail]: state === BUTTON_STATE.FAIL,
      })}
      {...restProps}
    >
      {Icon && <Icon className={classNames(styles.icon, iconClassName)} />}
      <ButtonStateIndicator state={state} statusText={statusText} type={type} />
      <div className={styles.buttonContent}>{children}</div>
    </button>
  );
};

Button.propTypes = {
  className: PropTypes.string,
  iconClassName: PropTypes.string,
  children: PropTypes.node,
  small: PropTypes.bool,
  isLoading: PropTypes.bool,
  isSuccess: PropTypes.bool,
  isFail: PropTypes.bool,
  type: PropTypes.oneOf(["primary", "secondary", "danger"]),
  state: PropTypes.oneOf([
    BUTTON_STATE.DEFAULT,
    BUTTON_STATE.LOADING,
    BUTTON_STATE.SUCCESS,
    BUTTON_STATE.FAIL,
  ]),
  outline: PropTypes.bool,
  icon: PropTypes.func,
  setState: PropTypes.func,
  statusText: PropTypes.string,
  testIdSuffix: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

Button.defaultProps = {
  small: false,
  type: "primary",
  outline: false,
};

export default Button;
