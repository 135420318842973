import PropTypes from "prop-types";
import React from "react";

import { useTranslation } from "src/translations/translationProvider";
import FormInput from "./formInput";
import {
  preliminarySaveButtonDisabled,
  preliminaryFieldDisabled,
  isPositiveNumber,
  calculateTotalVolume,
  calculateVariableVolume,
} from "./utils";
import Button from "/src/components/button";
import BUTTON_STATE from "/src/components/button/buttonState";

import styles from "./goConsumptionForm.module.scss";

const PreliminaryVolume = ({
  goData,
  goConsumptionData,
  elcQuotaExemptions,
  setGoData,
  setElcQuotaExemptions,
  setOpenDialog,
  unit,
}) => {
  const { translate } = useTranslation();

  const isPreliminaryFieldDisabled = preliminaryFieldDisabled([
    ...goConsumptionData,
    ...elcQuotaExemptions,
  ]);

  const onGoChanged = ({ clientId, fixedVolume, totalVolume }) => {
    setGoData(
      goData.map((client) => {
        if (client.clientId === clientId) {
          return {
            ...client,
            preliminaryFixedVolume: isPositiveNumber(fixedVolume)
              ? parseFloat(fixedVolume)
              : null,
            preliminaryVariableVolume: parseFloat(
              calculateVariableVolume({
                fixedVolume,
                totalVolume,
              }),
            ),
          };
        }
        return client;
      }),
    );
  };

  const onElcChanged = ({ fixedVolume, totalVolume, typeId }) => {
    setElcQuotaExemptions(
      elcQuotaExemptions.map((exemption) => {
        if (exemption.typeId === typeId) {
          return {
            ...exemption,
            preliminaryFixedVolume: isPositiveNumber(fixedVolume)
              ? parseFloat(fixedVolume)
              : null,
            preliminaryVariableVolume: exemption.hasFixedVolume
              ? calculateVariableVolume({
                  fixedVolume: parseFloat(fixedVolume),
                  totalVolume,
                })
              : parseFloat(totalVolume),
          };
        }
        return exemption;
      }),
    );
  };

  const onPreliminaryPaste = (e) => {
    e.preventDefault();
    if (isPreliminaryFieldDisabled) return null;

    const rawData = e.clipboardData.getData("text");
    const rows = rawData.split(/\r?\n/).filter((row) => !!row);

    const columns = rows
      .map((row) => row.split("\t"))
      .filter((row) => !(row[0] === "" && row[1] === ""));

    const createMappingFunction = (indexOffset) => (data, i) => {
      const rowIndex = indexOffset + i;

      if (rowIndex >= columns.length) {
        return data;
      }

      return {
        ...data,
        preliminaryFixedVolume: isPositiveNumber(columns[i][1])
          ? parseFloat(columns[rowIndex][1])
          : null,
        preliminaryVariableVolume: parseFloat(
          calculateVariableVolume({
            totalVolume: columns[rowIndex][0],
            fixedVolume: columns[rowIndex][1],
          }),
        ),
      };
    };

    setGoData(goData.map(createMappingFunction(0)));
    setElcQuotaExemptions(
      elcQuotaExemptions.map(createMappingFunction(goData.length)),
    );
  };

  return (
    <section onPaste={onPreliminaryPaste}>
      <div className={styles.info}>
        <h2 className={styles.infoHeader}>
          {translate("Preliminary consumption volume")}
        </h2>
        {translate("preliminaryVolumeInformation")}
      </div>
      <div className={styles.inputs}>
        <div className={styles.divider}>{translate("Guarantee of origin")}</div>
        {goData.map(
          ({
            clientId,
            clientName,
            productionType,
            preliminaryFixedVolume,
            preliminaryVariableVolume,
            hasFixedVolume,
          }) => (
            <FormInput
              key={clientId}
              className={styles.card}
              title={clientName}
              description={translate(productionType)}
              disabled={isPreliminaryFieldDisabled}
              value={{
                fixedVolume: preliminaryFixedVolume,
                totalVolume: isPositiveNumber(preliminaryVariableVolume)
                  ? calculateTotalVolume({
                      variableVolume: preliminaryVariableVolume,
                      fixedVolume: preliminaryFixedVolume,
                    })
                  : null,
              }}
              type={"number"}
              unit={unit}
              hasFixedVolume={hasFixedVolume}
              onChange={(value) => onGoChanged({ ...value, clientId })}
              onWheel={(e) => e.target.blur()}
              min={0}
              validate={{
                validationFunction: isPositiveNumber,
                errorMessage: translate("This field is required"),
              }}
            />
          ),
        )}
        <div className={styles.divider}>
          {translate("Electricity Certificates")}
        </div>
        {elcQuotaExemptions.map(
          ({
            typeId,
            typeName,
            preliminaryFixedVolume,
            preliminaryVariableVolume,
            hasFixedVolume,
          }) => (
            <FormInput
              key={typeId}
              className={styles.card}
              title={typeName}
              hasFixedVolume={hasFixedVolume}
              disabled={isPreliminaryFieldDisabled}
              value={{
                fixedVolume: preliminaryFixedVolume,
                totalVolume: calculateTotalVolume({
                  variableVolume: isPositiveNumber(preliminaryVariableVolume)
                    ? parseFloat(preliminaryVariableVolume)
                    : null,
                  fixedVolume: preliminaryFixedVolume,
                }),
              }}
              type={"number"}
              unit={unit}
              onChange={(value) => onElcChanged({ ...value, typeId })}
              onWheel={(e) => e.target.blur()}
              min={0}
              validate={{
                validationFunction: isPositiveNumber,
                errorMessage: translate("This field is required"),
              }}
            />
          ),
        )}
        <div className={styles.saveButton}>
          <Button
            small
            state={
              isPreliminaryFieldDisabled
                ? BUTTON_STATE.SUCCESS
                : BUTTON_STATE.DEFAULT
            }
            disabled={preliminarySaveButtonDisabled([
              ...goData,
              ...elcQuotaExemptions,
            ])}
            onClick={(event) => {
              event.stopPropagation();
              setOpenDialog(true);
            }}
          >
            {translate("Save")}
          </Button>
        </div>
      </div>
    </section>
  );
};

PreliminaryVolume.propTypes = {
  elcQuotaExemptions: PropTypes.array,
  goConsumptionData: PropTypes.array,
  goData: PropTypes.array,
  setElcQuotaExemptions: PropTypes.func,
  setGoData: PropTypes.func,
  setOpenDialog: PropTypes.func,
  unit: PropTypes.string,
};

export default PreliminaryVolume;
