import { gql } from "@apollo/client";

export const GET_LATEST_SPOT_PRICES = gql`
  query getLatestSpotPrices($organizationId: String!) {
    organization(id: $organizationId) {
      id
      spotPriceAreas {
        area
        currency
        areaDescription
        spotPrices {
          time
          price
        }
      }
    }
    priceAreasWeatherToday {
      code
      temperature {
        readings {
          time
          value
        }
      }
      wind {
        readings {
          time
          value
        }
      }
    }
  }
`;

export const GET_PRICE_TRENDS = gql`
  query getPriceTrends($organizationId: String!) {
    organization(id: $organizationId) {
      id
      spotPriceTrends {
        area
        currency
        areaDescription
        spotPrices {
          time
          price
        }
      }
      forwards {
        name
        values {
          year
          price
        }
      }
    }
  }
`;
