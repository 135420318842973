import { useEffect } from "react";

export const useClick = (handler, deps) => {
  useEffect(() => {
    document.addEventListener("click", handler);
    return () => {
      document.removeEventListener("click", handler);
    };
  }, deps); // eslint-disable-line react-hooks/exhaustive-deps
};
