import { gql } from "@apollo/client";

export const UPDATE_USER = gql`
  mutation updateUser(
    $userId: String!
    $organizationId: String!
    $roleId: Int!
  ) {
    updateUser(
      organizationId: $organizationId
      userId: $userId
      roleId: $roleId
    )
  }
`;
