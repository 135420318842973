import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import Select from "/src/components/select";
import { useTranslation } from "src/translations/translationProvider";

import styles from "./languageSwitcher.module.scss";

const defaultLanguage = "se";

// dropdown options
const languages = [
  { name: "Svenska", value: defaultLanguage },
  { name: "English", value: "en" },
];

const languageSettings = {
  se: {
    apiLocale: "sv-SE",
    language: "se",
    locale: "sv-SE",
  },
  en: {
    apiLocale: "en",
    language: "en",
    locale: "en-GB",
  },
};

const LanguageSwitcher = ({ className }) => {
  const { language, selectLanguage } = useTranslation();
  const onChangeLanguage = (language) => {
    selectLanguage({
      apiLocale: languageSettings[language].apiLocale,
      language: languageSettings[language].language,
      locale: languageSettings[language].locale,
    });
  };

  const getLanguageListElement = (lang) => {
    return {
      label: (
        <span data-test-id={`language-${lang.name}`} className={styles.label}>
          <img
            className={styles.flag}
            src={`/images/locale-${languageSettings[lang.value].locale}.svg`}
            alt={"country flag"}
          />
          <p>{lang.name}</p>
        </span>
      ),
      value: lang.value,
    };
  };

  return (
    <Select
      isBottomToTop={true}
      data-test-id="user-menu-languageSwitcher"
      wrapperClassName={styles.languageSwitcher}
      triggerClassName={classNames(styles.trigger, className)}
      options={languages.map((l) => getLanguageListElement(l))}
      onChange={({ value }) => onChangeLanguage(value)}
      value={getLanguageListElement(
        languages.find((l) => l.value === language)
      )}
    />
  );
};

LanguageSwitcher.propTypes = {
  className: PropTypes.string,
};

export default LanguageSwitcher;
