import React, { useState } from "react";

import { useTranslation } from "src/translations/translationProvider";
import { Translate } from "src/translations/translate";
import {
  useOrganizationQuery,
  useMutation,
} from "/src/services/apollo/hooks/useOrganizationQuery";
import { GET_GO_CONSUMPTION } from "/src/services/apollo/queries";
import { UPDATE_GO_CONSUMPTION } from "/src/services/apollo/mutations";

import GoConsumptionForm from "./goConsumptionForm";
import LoadingSpinner from "/src/components/loadingSpinner";
import { useNotifications } from "src/modules/notifications/notificationsProvider";
import { useGetCurrentUser } from "src/modules/userProvider";

const GoConsumptionFormContainer = () => {
  const { translate, formatDate } = useTranslation();
  const { showNotification } = useNotifications();
  const currentUser = useGetCurrentUser();
  const { id } = currentUser.selectedOrganizationConnection.organization;

  const now = formatDate(new Date(), "yyyy-MM");
  const [month, setMonth] = useState(now);

  const { loading, error, data } = useOrganizationQuery(GET_GO_CONSUMPTION, {
    variables: {
      month: month,
    },
  });

  const [goConsumptionMutation] = useMutation(UPDATE_GO_CONSUMPTION);

  const stripTypeName = (items) =>
    // eslint-disable-next-line no-unused-vars
    items.map(({ __typename, ...keepAttrs }) => keepAttrs);

  const updateConsumption = ({ goConsumption, elcQuotaExemptions }) => {
    if (elcQuotaExemptions.__typename) {
      delete elcQuotaExemptions.__typename;
    }
    return goConsumptionMutation({
      refetchQueries: [
        {
          query: GET_GO_CONSUMPTION,
          variables: {
            month: month,
            organizationId: id,
          },
        },
      ],
      variables: {
        organizationId: id,
        month: month,
        goConsumption: stripTypeName(goConsumption),
        elcQuotaExemptions: stripTypeName(elcQuotaExemptions),
      },
    })
      .then(({ errors }) => {
        if (errors && errors.length) {
          throw errors[0];
        }
        showNotification(`${translate("Consumption updated")}`);
        return true;
      })
      .catch((error) => {
        console.error("Update consumption failed", error); // eslint-disable-line no-console
        showNotification(translate("Update consumption failed"));
      });
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return (
      <div>
        <Translate>UnexpectedError</Translate>
      </div>
    );
  }

  if (!data || data.organization.goConsumption.length < 1) {
    return <Translate>No data found</Translate>;
  }

  const { goConsumption, elcQuotaExemptions } = data.organization;

  return (
    <GoConsumptionForm
      organizationId={id}
      updateConsumption={updateConsumption}
      goConsumptionData={goConsumption}
      elcQuotaExemptionData={elcQuotaExemptions}
      month={month}
      setMonth={setMonth}
    />
  );
};

export default GoConsumptionFormContainer;
