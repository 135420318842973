import React, { useState } from "react";
import PropTypes from "prop-types";
import { useOrganizationQuery } from "/src/services/apollo/hooks/useOrganizationQuery";
import { Link } from "react-router-dom";

import ArticlePreview from "/src/components/articlePreview";
import { GET_ARTICLES } from "/src/services/apollo/queries";
import { useTranslation } from "src/translations/translationProvider";
import { Translate } from "src/translations/translate";
import ArticleListLoader from "./articleListLoader";
import Button from "/src/components/button";

import styles from "./knowledge.module.scss";
import loadMoreStyles from "./loadMore.module.scss";

const ArticleList = ({ searchInput, typeFilter }) => {
  const { apiLocale } = useTranslation();
  const fetchSize = 10;
  const [allFetched, setAllFetched] = useState({});

  const { loading, data, queryObservable } = useOrganizationQuery(
    GET_ARTICLES,
    {
      query: GET_ARTICLES,
      variables: {
        searchInput,
        typeFilter,
        locale: apiLocale,
        startCount: 0,
        maxResult: fetchSize,
      },
    }
  );

  const cacheKey = `${typeFilter}-${searchInput}`;

  if (
    allFetched[cacheKey] &&
    data &&
    data.organization.articles.length === fetchSize
  ) {
    setAllFetched({
      ...allFetched,
      [cacheKey]: false,
    });
  }

  const loadMore = async () => {
    const startCount = data.organization.articles.length;

    try {
      queryObservable.fetchMore({
        variables: {
          startCount,
        },
        updateQuery: (previous, { fetchMoreResult }) => {
          if (!fetchMoreResult) {
            return previous;
          }
          if (fetchMoreResult.organization.articles.length < fetchSize) {
            setAllFetched({
              ...allFetched,
              [cacheKey]: true,
            });
          }
          return {
            ...previous,
            organization: {
              ...previous.organization,
              articles: [
                ...previous.organization.articles,
                ...fetchMoreResult.organization.articles,
              ],
            },
          };
        },
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  if (!data) {
    return <></>;
  }

  return (
    <div className={loadMoreStyles.loadMoreContainer}>
      <div className={loadMoreStyles.content}>
        {data.organization.articles.map(({ id, ...rest }) => (
          <Link className={styles.link} key={id} to={`/article/${id}`}>
            <ArticlePreview key={id} {...rest} />
          </Link>
        ))}
        {loading && <ArticleListLoader fetchSize={fetchSize} />}
      </div>
      <div>
        <Button
          className={loadMoreStyles.button}
          onClick={loadMore}
          small
          disabled={allFetched[cacheKey]}
        >
          <Translate>Load more</Translate>
        </Button>
      </div>
    </div>
  );
};

ArticleList.propTypes = {
  searchInput: PropTypes.string,
  typeFilter: PropTypes.string,
};

export default ArticleList;
