import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import ValueUnit from "/src/components/valueUnit";

import styles from "./resultCard.module.scss";

const CardRow = ({ title, value, usePrimaryColor, isStandard, unit }) => {
  if (!value) {
    return null;
  }
  return (
    <div className={classNames({ [styles.standard]: isStandard }, styles.row)}>
      <div
        className={classNames(styles.title, { [styles.standard]: isStandard })}
      >
        {title}
      </div>
      <ValueUnit
        valueClassName={classNames(
          styles.value,
          { [styles.standard]: isStandard },
          { [styles.primaryColor]: usePrimaryColor }
        )}
        unitClassName={styles.unit}
        value={value}
        unit={unit}
        vertical
      />
    </div>
  );
};

CardRow.propTypes = {
  title: PropTypes.node,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.bool,
  ]),
  unit: PropTypes.string,
  usePrimaryColor: PropTypes.bool,
  isStandard: PropTypes.bool,
};

export default CardRow;
