import React from "react";
import sjson from 'secure-json-parse';

const NotIframe = ({ children }) => {
  let isIframe = sjson.parse(window.sessionStorage.getItem("isIframe"));

  return !isIframe && <>{children}</>;
};

export default NotIframe;
