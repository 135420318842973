import { useQuery } from "@apollo/client";
import ControlHeader from "src/components/controlHeader";
import { EmptyChartAndTable } from "src/modules/portfolio/financial/hedgeReport/EmptyChartAndTable";
import PortfolioConfigurationSelect from "src/modules/portfolio/financial/portfolioConfigurationSelect";
import { useTranslation } from "src/translations/translationProvider";
import financialStyles from "../financial.module.scss";
import { HedgeReport } from "./hedgeReport";
import LoadingSpinner from "src/components/loadingSpinner";
import { ELC_TYPE, GO_TYPE } from "src/constants/portfolioConfigurationTypes";
import { useStickyState } from "src/utilities/hooks";
import { GetProductsDocument, GetProductsQuery } from "src/graphql/graphql";
import { ElementArrayType } from "src/utilities/typescriptHelpers";

const HedgeReportContainer = ({
  organizationId,
  portfolioReportConfigurations,
}: {
  organizationId: string;
  portfolioReportConfigurations: { id: string; name: string; type: string }[]; // replace this with proper type from gql
}) => {
  const { translate } = useTranslation();
  const STORAGE_PREFIX = `hedge-report-${organizationId}-`;
  const excludedTypes = [ELC_TYPE, GO_TYPE];
  const validConfigurations = portfolioReportConfigurations.filter(
    (c) => !excludedTypes.includes(c.type),
  );

  const [selectedConfigurationIds, setSelectedConfigurationIds] =
    useStickyState(
      validConfigurations.map((config) => config.id),
      `${STORAGE_PREFIX}selectedConfigurationIds`,
    );
  const [selectedProduct, setSelectedProduct] = useStickyState<
    ElementArrayType<GetProductsQuery["organization"]["products"]>
  >(null, `${STORAGE_PREFIX}selectedProduct`);

  const { loading, error, data } = useQuery(GetProductsDocument, {
    variables: {
      portfolioConfigurationIds: selectedConfigurationIds,
      organizationId,
    },

    onCompleted: (data) => {
      const { products } = data.organization;
      const isValidSelectedProduct = products?.some(
        (product) => product?.name === selectedProduct?.name,
      );
      const hasProducts = products?.length !== undefined && products.length > 0;
      if (!isValidSelectedProduct && hasProducts && products[0]) {
        setSelectedProduct(products[0]);
      }
    },
  });

  if (loading) {
    return <LoadingSpinner />;
  }

  if (error || !data) {
    return <div>{translate("UnexpectedError")}</div>;
  }

  const { products } = data.organization;
  const hasNoProducts = products?.length !== undefined && products.length === 0;

  if (hasNoProducts || !selectedProduct) {
    return (
      <div className={financialStyles.tabContent}>
        <ControlHeader>
          <div className={financialStyles.dropdown}>
            {translate("Portfolios")}:
            <PortfolioConfigurationSelect
              availableConfigurations={portfolioReportConfigurations}
              selectedConfigurationIds={selectedConfigurationIds}
              setSelectedConfigurationIds={setSelectedConfigurationIds}
              showGroups
            />
          </div>
        </ControlHeader>
        <EmptyChartAndTable />
      </div>
    );
  }

  return (
    <HedgeReport
      organizationId={organizationId}
      products={products}
      portfolioReportConfigurations={validConfigurations}
      selectedConfigurationIds={selectedConfigurationIds}
      setSelectedConfigurationIds={setSelectedConfigurationIds}
      setSelectedProduct={setSelectedProduct}
      selectedProduct={selectedProduct}
    />
  );
};

export default HedgeReportContainer;
