import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { Translate } from "src/translations/translate";
import { appInsights } from "/src/analytics.js";

import styles from "./error.module.scss";
import { NotFoundIcon } from "/src/icons";

const logError = (error, errorInfo, path) => {
  appInsights.trackException({
    exception: error,
    properties: { errorInfo, path },
  });
};

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super();

    this.state = {
      hasError: false,
      path: props.path,
    };
  }

  componentDidCatch(error, errorInfo) {
    // In case of ChunkLoadError when releasing (https://modity.atlassian.net/browse/MSD-4960)
    if (/ChunkLoadError/i.test(error.message)) {
      window.location.reload();
    } else if (/Loading.*chunk.*failed./i.test(error.message)) {
      window.location.reload();
    }

    const { path } = this.state;
    logError(error, errorInfo, path);
  }

  static getDerivedStateFromError(error) {
    return {
      error,
      hasError: true,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.hasError && nextProps.path !== prevState.path) {
      return {
        hasError: false,
      };
    }

    return null;
  }

  render() {
    const { hasError } = this.state;
    const { children, className } = this.props;

    if (hasError) {
      return (
        <div className={classNames(styles.defaultError, className)}>
          <div className={styles.defaultErrorMessage}>
            <NotFoundIcon className={styles.defaultErrorIcon} />
            <div className={styles.defaultErrorText}>
              <Translate>UnexpectedError</Translate>
            </div>
          </div>
        </div>
      );
    }

    return children;
  }
}

ErrorBoundary.propTypes = {
  path: PropTypes.string,
  children: PropTypes.any,
  className: PropTypes.string,
};
