import React from "react";

import { useTranslation } from "src/translations/translationProvider";
import Chart from "src/components/chart";
import { modityColors } from "src/components/chart/utils";

import styles from "./priceTrends.module.scss";
import { GetPriceTrendsQuery } from "src/graphql/graphql";

const colors = Object.values(modityColors);

export function PriceTrendsChart({
  spotPrices,
  forwards,
}: {
  spotPrices: GetPriceTrendsQuery["organization"]["spotPriceTrends"];
  forwards: GetPriceTrendsQuery["organization"]["forwards"];
}) {
  const { translate } = useTranslation();

  const sharedOptions = {
    borderWidth: "2",
    pointRadius: 0,
    pointHitRadius: 16,
    borderJoinStyle: "bevel",
  };

  // We expect an extra 'DE' set in forwards, so we need to skip that one in the indexing
  const spotPriceSets = spotPrices
    .slice()
    .sort((a, b) => a.area.localeCompare(b.area))
    .map((area, i) => ({
      ...sharedOptions,
      label: area.area,
      borderColor: colors[i + 1],
      backgroundColor: colors[i + 1],
      data: area.spotPrices.map((spotPrice) => ({
        x: new Date(spotPrice.time),
        y: spotPrice.price,
      })),
    }));

  const maxSpotPriceDate = new Date(
    spotPrices[0].spotPrices[spotPrices[0].spotPrices.length - 1].time,
  );

  const forwardsSets = forwards
    ?.slice()
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((area, i) => ({
      ...sharedOptions,
      label: `FW ${area.name}`,
      borderColor: colors[i],
      backgroundColor: colors[i],
      borderDash: [10, 5],
      data: area.values.slice(0, 5).flatMap((value, i) => {
        const startMonth =
          maxSpotPriceDate.getFullYear() === value.year
            ? maxSpotPriceDate.getMonth()
            : 0;

        const months = [];
        for (let i = startMonth; i < 12; i++) {
          months.push({
            x: new Date(value.year, i, 2),
            y: value.price,
          });
        }

        // To make the graph square
        months.push({
          x: new Date(value.year + 1, 0, 2),
          y: value.price,
        });

        return months;
      }),
    }));

  return (
    <Chart
      enableCopy={true}
      data={{
        datasets: forwardsSets
          ? spotPriceSets.concat(forwardsSets)
          : spotPriceSets,
      }}
      maxNumberOfVisibleDatasets={15}
      options={{
        title: translate("Spot and forward prices"),
        unit: "month",
        yLabel: "SEK",
        classNameForChartCanvas: styles.chartCanvas,
        tooltipMode: "nearest",
      }}
    />
  );
}
