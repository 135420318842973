import React from "react";
import PropTypes from "prop-types";

import Hero from "/src/components/hero";
import Author from "/src/components/author";
import VideoPlayer from "/src/components/videoPlayer";
import SplitLayout from "/src/components/splitLayout";
import { isMobile } from "/src/components/responsive";

import globalStyles from "/src/styles/global.module.scss";
import styles from "./article.module.scss";
import config from "/src/config/config";

import { Remarkable } from "remarkable";
import RemarkableReactRenderer from "remarkable-react";
const md = new Remarkable();
md.renderer = new RemarkableReactRenderer();

const getImageObjectOrDefault = (img) => {
  const defaultImg = { fileUrl: config.defaultHeroImage };
  return img
    ? {
        desktop: (img.desktop || defaultImg).fileUrl,
        tablet: (img.tablet || defaultImg).fileUrl,
        mobile: (img.mobile || defaultImg).fileUrl,
      }
    : {
        desktop: defaultImg.fileUrl,
        tablet: defaultImg.fileUrl,
        mobile: defaultImg.fileUrl,
      };
};

const Article = ({ article }) => {
  const heroImage = getImageObjectOrDefault(article.heroImage);
  const hasVideo =
    article?.contentType === "videoArticle" ||
    article?.contentType === "webinarArticle";

  return (
    <SplitLayout
      mainContent={
        <article className={globalStyles.pageContentWrapper}>
          <div className={styles.header}>
            {hasVideo ? (
              <VideoPlayer videoId={article.videoId} />
            ) : (
              <Hero
                className={styles.hero}
                title={article.title}
                responsiveImage={heroImage}
              />
            )}
          </div>
          <div className={styles.contentHeader}>
            <Author
              className={styles.author}
              avatarClassName={styles.authorAvatar}
              avatar={article.author.avatar}
              firstName={article.author.firstName}
              lastName={article.author.lastName}
              publishedDate={article.publishedDate}
              large
            />
          </div>
          <div className={styles.main}>
            <div className={styles.content}>
              {(hasVideo || isMobile()) && (
                <h1 className={styles.title}>{article.title}</h1>
              )}
              {md.render(article.content)}
            </div>
          </div>
        </article>
      }
    />
  );
};

Article.propTypes = {
  article: PropTypes.object,
};

export default Article;
