import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import styles from "./knowledge.module.scss";

const ArticleListLoader = ({ fetchSize, itemClassName }) => (
  <div className={styles.loadMoreContainer}>
    {Array(fetchSize)
      .fill()
      .map((_, index) => (
        <div
          key={index}
          className={classNames(styles.loadingArticleWrapper, itemClassName)}
        >
          <div className={styles.loadingImage} />
          <div className={styles.loadingText}>
            <div className={styles.loadingTextLine} />
            <div
              className={classNames(styles.loadingTextLine, styles.double)}
            />
            <div className={styles.loadingTextLine} />
          </div>
        </div>
      ))}
  </div>
);

ArticleListLoader.propTypes = {
  fetchSize: PropTypes.number.isRequired,
  itemClassName: PropTypes.string,
};

export default ArticleListLoader;
