import React, { useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

import { isBefore } from "date-fns";
import { useTranslation } from "src/translations/translationProvider";
import Table from "/src/components/table";
import ToggleColumns from "/src/components/toggleColumns";
import { FormatOrEmpty, RawValue } from "/src/components/tableCellFormats";
import {
  loadVisibleColumns,
  persistVisibleColumns,
} from "./visibleColumnsStore";

import portfolioStyles from "/src/modules/portfolio/portfolio.module.scss";
import financialStyles from "../financial.module.scss";
import styles from "./hedgeReport.module.scss";

const DEFAULT_CURRENCY = "SEK";
const AMOUNT = "Amount";

const HedgeReportTable = ({
  className,
  unit,
  data,
  header,
  dataResolution,
}) => {
  const { translate, formatDate } = useTranslation();
  const [visibleColumns, setVisibleColumns] = useState(
    getColumnsData({
      formatDate,
      translate,
      unit,
      dataResolution,
    }),
  );

  const decoratedSetVisibleColumns = (columns) => {
    const storeMe = columns.reduce(
      (prev, { accessor, show }) => ({ ...prev, [accessor]: show }),
      {},
    );
    persistVisibleColumns(storeMe);
    setVisibleColumns(columns);
  };

  const columns = getColumnsData({
    formatDate,
    translate,
    unit,
    dataResolution,
  }).map((column) => {
    return {
      ...column,
      show: visibleColumns.find((c) => column.accessor === c.accessor).show,
    };
  });

  return (
    <div className={classNames(className, portfolioStyles.table)}>
      <div
        data-test-id={"hedge-report-table-header"}
        className={financialStyles.titleRow}
      >
        <div
          className={classNames(
            portfolioStyles.title,
            styles.title,
            financialStyles.title,
          )}
        >
          {header}
        </div>
        <ToggleColumns
          key={unit}
          columns={columns}
          setColumns={decoratedSetVisibleColumns}
        />
      </div>
      <Table
        headerClassName={classNames(
          portfolioStyles.cell,
          portfolioStyles.header,
          styles.hedgeReportTableHeader,
        )}
        cellClassName={classNames(portfolioStyles.cell, styles.cell)}
        data={data}
        columns={columns.filter((column) => column.show)}
        sortable
        tableOptions={{
          initialState: {
            sortBy: [
              {
                id: "period",
                desc: false,
              },
            ],
          },
          disableSortRemove: true,
        }}
      />
    </div>
  );
};

HedgeReportTable.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  unit: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.object),
  header: PropTypes.string,
  dataResolution: PropTypes.string,
};

export default HedgeReportTable;

function getColumnsData({ formatDate, translate, unit, dataResolution }) {
  const DEFAULT_VISIBLE_COLUMNS = {
    period: true,
    forecast: true,
    hedgeVolume: true,
    targetVolume: true,
    utilizedVolume: true,
    agreementPrice: true,
    openVolume: true,
    marketPrice: true,
    outcome: true,
    pricePerUnit: true,
    portfolioPrice: true,
    hedgePercentage: true,
    mandateMinPercentage: false,
    mandateMaxPercentage: false,
    mandateMinVolume: false,
    mandateMaxVolume: false,
    goVolume: false,
    goAgreementPrice: false,
    elcVolume: false,
    elcAgreementPrice: false,
    totalAgreeMentPrice: false,
  };

  const dateFormatMap = {
    Monthly: "MMM - yy",
    Quarterly: "QQQ - yyyy",
    Yearly: "yyyy",
  };

  const savedColumnState = loadVisibleColumns();

  const visibleColumns = {
    ...DEFAULT_VISIBLE_COLUMNS,
    ...savedColumnState,
  };

  const LabelAndUnit = (
    { label, unit }, // eslint-disable-line react/prop-types
  ) => (
    <div className={styles.columnHeader}>
      <div className={styles.columnHeaderLabel}>{label}</div>
      {unit && <div className={styles.columnHeaderUnit}>{unit}</div>}
    </div>
  );

  const financialOutcomeClass = ({ row }) => {
    const { period } = row.values;
    const now = new Date();

    return isBefore(now, new Date(period))
      ? styles.highlightFinancialOutcome
      : "";
  };

  /* eslint-disable react/prop-types */
  return [
    {
      Header: () => (
        <div className={styles.columnHeaderLabel}>{translate("Period")}</div>
      ),
      accessor: "period",
      Cell: ({ value }) => (
        <div className={styles.monthColumnHeader}>
          {formatDate(new Date(value), dateFormatMap[dataResolution]).replace(
            ".",
            "",
          )}
        </div>
      ),
      name: translate("Period"),
      show: visibleColumns["period"],
    },
    {
      Header: () => LabelAndUnit({ label: translate("Forecast"), unit }),
      accessor: "forecast",
      Cell: ({ value }) => <FormatOrEmpty value={value} />,
      name: `${translate("Forecast")} ${unit}`,
      show: visibleColumns["forecast"],
    },
    {
      Header: () => LabelAndUnit({ label: translate("Hedged volume"), unit }),
      accessor: "hedgeVolume",
      Cell: ({ value }) => <FormatOrEmpty value={value} />,
      name: `${translate("Hedged volume")} ${unit}`,
      show: visibleColumns["hedgeVolume"],
    },
    {
      Header: () => LabelAndUnit({ label: translate("Target volume"), unit }),
      accessor: "targetVolume",
      Cell: ({ value }) => <FormatOrEmpty value={value} />,
      name: `${translate("Target volume")} ${unit}`,
      show: visibleColumns["targetVolume"],
    },
    {
      Header: () =>
        LabelAndUnit({
          label: translate("Heading_ProductionConsumption"),
          unit,
        }),
      accessor: "utilizedVolume",
      Cell: ({ value }) => {
        return <FormatOrEmpty value={value} />;
      },
      name: translate("Heading_ProductionConsumption"),
      show: visibleColumns["utilizedVolume"],
    },
    {
      Header: () =>
        LabelAndUnit({
          label: translate("Hedged price"),
          unit: `${DEFAULT_CURRENCY}/MWh`,
        }),
      accessor: "agreementPrice",
      Cell: ({ value }) => <FormatOrEmpty value={value} />,
      name: `${translate("Hedged price")} ${DEFAULT_CURRENCY}/MWh`,
      show: visibleColumns["agreementPrice"],
    },
    {
      Header: () => LabelAndUnit({ label: translate("Open volume"), unit }),
      accessor: "openVolume",
      Cell: ({ value }) => <FormatOrEmpty value={value} />,
      name: `${translate("Open volume")} ${unit}`,
      show: visibleColumns["openVolume"],
    },
    {
      Header: () =>
        LabelAndUnit({
          label: translate("Market price"),
          unit: `${DEFAULT_CURRENCY}/${unit}`,
        }),
      accessor: "marketPrice",
      Cell: ({
        value,
        row: {
          original: { preliminary },
        },
      }) => (
        <FormatOrEmpty
          value={value}
          className={classNames({ [styles.preliminary]: preliminary })}
        />
      ),
      name: `${translate("Market price")} ${DEFAULT_CURRENCY}/MWh}`,
      show: visibleColumns["marketPrice"],
    },
    {
      Header: () =>
        LabelAndUnit({
          label: translate("Settlement"),
          unit: DEFAULT_CURRENCY,
        }),
      accessor: "outcome",
      Cell: ({ value, row }) => (
        <FormatOrEmpty
          value={value}
          className={classNames(financialOutcomeClass({ row }), {
            [styles.preliminary]: row?.original?.preliminary,
          })}
        />
      ),
      name: `${translate("Settlement")} ${DEFAULT_CURRENCY}`,
      show: visibleColumns["outcome"],
    },
    {
      Header: () =>
        LabelAndUnit({
          label: translate("Financial Price per unit"),
          unit: `${DEFAULT_CURRENCY}/MWh`,
        }),
      accessor: "pricePerUnit",
      Cell: ({ value }) => <FormatOrEmpty value={value} />,
      name: `${translate(
        "Financial Price per unit",
      )} ${DEFAULT_CURRENCY}/${unit}`,
      show: visibleColumns["pricePerUnit"],
    },
    {
      Header: () =>
        LabelAndUnit({
          label: translate("Forecast price"),
          unit: `${DEFAULT_CURRENCY}/${unit}`,
        }),
      accessor: "portfolioPrice",
      Cell: ({ value }) => <FormatOrEmpty value={value} />,
      name: `${translate("Forecast price")} ${DEFAULT_CURRENCY}/MWh`,
      show: visibleColumns["portfolioPrice"],
    },
    {
      Header: () =>
        LabelAndUnit({
          label: translate("% Hedged"),
        }),
      accessor: "hedgePercentage",
      Cell: ({ value }) => <RawValue value={value} />,
      name: translate("% Hedged"),
      show: visibleColumns["hedgePercentage"],
    },
    {
      Header: () =>
        LabelAndUnit({
          label: translate("% Min"),
        }),
      accessor: "mandateMinPercentage",
      Cell: ({ value }) => <RawValue value={value} />,
      name: "% Min",
      show: visibleColumns["mandateMinPercentage"],
    },
    {
      Header: () =>
        LabelAndUnit({
          label: translate("% Max"),
        }),
      accessor: "mandateMaxPercentage",
      Cell: ({ value }) => <RawValue value={value} />,
      name: "% Max",
      show: visibleColumns["mandateMaxPercentage"],
    },
    {
      Header: () =>
        LabelAndUnit({
          label: "Min",
          unit: unit,
        }),
      accessor: "mandateMinVolume",
      Cell: ({ value }) => <FormatOrEmpty value={value} />,
      name: "Min MWh",
      show: visibleColumns["mandateMinVolume"],
    },
    {
      Header: () =>
        LabelAndUnit({
          label: "Max",
          unit: unit,
        }),
      accessor: "mandateMaxVolume",
      Cell: ({ value }) => <FormatOrEmpty value={value} />,
      name: "Max MWh",
      show: visibleColumns["mandateMaxVolume"],
    },
    {
      Header: () =>
        LabelAndUnit({
          label: translate("Go Volume"),
          unit: translate(AMOUNT),
        }),
      accessor: "goVolume",
      Cell: ({ value }) => <RawValue value={value} />,
      name: `${translate("Go Volume")} ${translate(AMOUNT)}`,
      show: visibleColumns["goVolume"],
    },
    {
      Header: () =>
        LabelAndUnit({
          label: translate("Go Agreement Price"),
        }),
      accessor: "goAgreementPrice",
      Cell: ({ value }) => <RawValue value={value} />,
      name: translate("Go Agreement Price"),
      show: visibleColumns["goAgreementPrice"],
    },
    {
      Header: () =>
        LabelAndUnit({
          label: translate("Elc Volume"),
          unit: translate(AMOUNT),
        }),
      accessor: "elcVolume",
      Cell: ({ value }) => <RawValue value={value} />,
      name: `${translate("Elc Volume")} ${translate(AMOUNT)}`,
      show: visibleColumns["elcVolume"],
    },
    {
      Header: () =>
        LabelAndUnit({
          label: translate("Elc Agreement Price"),
        }),
      accessor: "elcAgreementPrice",
      Cell: ({ value }) => <RawValue value={value} />,
      name: translate("Elc Agreement Price"),
      show: visibleColumns["elcAgreementPrice"],
    },
    {
      Header: () =>
        LabelAndUnit({
          label: translate("Total Agreement Price"),
        }),
      accessor: "totalAgreementPrice",
      Cell: ({ value }) => <RawValue value={value} />,
      name: translate("Total Agreement Price"),
      show: visibleColumns["totalAgreementPrice"],
    },
  ];
  /* eslint-enable react/prop-types */
}
