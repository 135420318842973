import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import styles from "./radioButtonList.module.scss";

const isSpace = ({ key, keyCode }) => {
  return key === " " || keyCode === 32; // eslint-disable-line no-magic-numbers
};

const RadioButtonList = ({
  className,
  titleClassName,
  optionClassName,
  activeOptionClassName,
  onChange,
  title,
  options,
  selectedValue,
}) => {
  const objectMode = selectedValue && !!selectedValue.id;

  return (
    <div className={classNames(styles.radioButtonList, className)}>
      {title && <span className={titleClassName}>{title}</span>}
      {options.map(({ value, text }, i) => {
        const isActiveOption = objectMode
          ? selectedValue.id === value.id
          : selectedValue === value || (!selectedValue && i === 0);

        const selectOnSpace = (event) => {
          if (isSpace(event)) {
            event.preventDefault();
            onChange(value);
          }
        };

        return (
          <label
            data-test-id={`radio-button-${text}`}
            tabIndex={0}
            key={objectMode ? value.id : value}
            className={classNames(styles.option, optionClassName, {
              [activeOptionClassName]: isActiveOption,
            })}
            onKeyDown={selectOnSpace}
          >
            <input
              type="radio"
              name="options"
              data-test-id={`radio-button-${objectMode ? value.id : value}`}
              value={objectMode ? value.id : value}
              onChange={() => onChange(value)}
              checked={isActiveOption}
            />
            <span>{text}</span>
          </label>
        );
      })}
    </div>
  );
};

const entryType = PropTypes.oneOfType([
  PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  PropTypes.string,
  PropTypes.number,
]);

RadioButtonList.propTypes = {
  className: PropTypes.string,
  titleClassName: PropTypes.string,
  optionClassName: PropTypes.string,
  activeOptionClassName: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  title: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      value: entryType.isRequired,
    })
  ).isRequired,
  selectedValue: entryType,
};

export default RadioButtonList;
