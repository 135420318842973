import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { useOrganizationQuery } from "/src/services/apollo/hooks/useOrganizationQuery";

import { Translate } from "src/translations/translate";
import { useTranslation } from "src/translations/translationProvider";
import InvoiceList from "./invoiceList";
import LoadingSpinner from "/src/components/loadingSpinner";
import { Mobile, NotMobile } from "/src/components/responsive";
import Search from "/src/components/search";
import { GET_INVOICES } from "/src/services/apollo/queries";

import styles from "./invoices.module.scss";
import adminStyles from "/src/styles/admin.module.scss";
import globalStyles from "/src/styles/global.module.scss";

const Invoices = ({ searchInput }) => {
  const { loading, error, data } = useOrganizationQuery(GET_INVOICES, {
    variables: {
      startDate: "2000-01-01",
      endDate: new Date().toISOString().split("T")[0],
      startCount: 0,
      maxResultCount: 100000,
    },
  });

  if (loading) {
    return (
      <div>
        <LoadingSpinner />
      </div>
    );
  }

  if (error) {
    return (
      <div>
        <Translate>UnexpectedError</Translate>
      </div>
    );
  }

  const {
    organization: { invoices },
  } = data;

  if (!invoices.length) {
    return (
      <div data-test-id="invoices">
        <Translate>No invoices found</Translate>
      </div>
    );
  }

  const searchInputOnlyWithNumbers = searchInput.replace("#", "");
  const filteredInvoices = invoices.filter((invoice) =>
    invoice.invoiceNumber
      .toLowerCase()
      .includes(searchInputOnlyWithNumbers.toLowerCase())
  );

  return (
    <InvoiceList className={styles.invoices} invoices={filteredInvoices} />
  );
};

const InvoicesWrapper = ({ onSearch, searchInput }) => {
  const { translate } = useTranslation();

  return (
    <div>
      <header className={globalStyles.subTitle}>
        <Translate>All invoices in one place</Translate>
      </header>
      <NotMobile>
        <Search
          className={styles.search}
          label={translate("Search by invoice number")}
          defaultValue={searchInput}
          autoFocus
          onSubmit={onSearch}
        />
      </NotMobile>
      <Mobile>
        <Search
          className={classNames(adminStyles.search, styles.search)}
          buttonClassName={adminStyles.searchButton}
          label={translate("Search by invoice number")}
          defaultValue={searchInput}
          onSubmit={onSearch}
        />
      </Mobile>
      <Invoices searchInput={searchInput} />
    </div>
  );
};

Invoices.propTypes = {
  searchInput: PropTypes.string,
};

InvoicesWrapper.propTypes = {
  onSearch: PropTypes.func,
  searchInput: PropTypes.string,
};

InvoicesWrapper.defaultProps = {
  onSearch: () => {},
};

export default InvoicesWrapper;
