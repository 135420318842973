import { gql } from "@apollo/client";

export const GET_CURRENT_CONTACTS = gql`
  query getCurrentContacts {
    currentContacts {
      id
      firstName
      lastName
      email
      phoneNumber
      fileUrl
      functionTitle
      organizationConnections {
        id
        name
      }
    }
  }
`;
