import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { Translate } from "src/translations/translate";

import styles from "./dailyNotification.module.scss";

const DailyNotification = ({
  className,
  notification,
  onDismiss,
  onDisable,
}) => {
  const { title, content, url } = notification;

  const decoratedOnDismiss = () => {
    onDismiss(notification);
  };

  return (
    <div className={classNames(styles.dailyNotification, className)}>
      <p className={styles.title}>{title}</p>
      <p className={styles.content}>{content}</p>
      <div className={styles.buttons}>
        {url && (
          <a
            href={url.includes("http") ? url : `//${url}`}
            className={styles.url}
            title={url}
          >
            <Translate>Read more</Translate>
          </a>
        )}
        {onDisable && (
          <span id="btn-disable" onClick={onDisable} className={styles.button}>
            <Translate>Dont show again</Translate>
          </span>
        )}
        <span
          id="btn-dismiss"
          onClick={decoratedOnDismiss}
          className={styles.button}
        >
          <Translate>Dismiss</Translate>
        </span>
      </div>
    </div>
  );
};

DailyNotification.propTypes = {
  className: PropTypes.string,
  notification: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    url: PropTypes.string,
  }).isRequired,
  onDismiss: PropTypes.func,
  onDisable: PropTypes.func,
};

export default DailyNotification;
