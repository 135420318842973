import { InMemoryCache } from "@apollo/client";

const cache = new InMemoryCache({
  typePolicies: {
    ClientAdministration: {
      merge: false,
    },
    Claim: {
      keyFields: ["type", "value"],
    },
    EnergyOrganization: {
      merge: false,
    },
    EnergyPlant: {
      merge: false,
    },
    Measurand: {
      keyFields: ["name", "type"],
    },
    OrganizationMonthlySummaries: {
      keyFields: ["month", "consumption", "production"],
    },
    PlantMeasurandSeries: {
      keyFields: ["name", "type"],
    },
    PlantMonthlySummaries: {
      keyFields: ["month", "consumption", "production"],
    },
    Readings: {
      keyFields: ["time", "value"],
    },
    UserOrganization: {
      keyFields: ["organization", ["id"], "user", ["id"]],
    },
  },
  possibleTypes: {
    Article: ["TextArticle", "VideoArticle", "WebinarArticle"],
  },
});

export default cache;
