import React from "react";
import { useTranslation } from "src/translations/translationProvider";
import { Helmet } from "react-helmet";

import Portfolio from "./portfolio";

const PortfolioContainer = () => {
  const { translate } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{`Modity - ${translate("Menu_Portfolio")}`}</title>
      </Helmet>
      <Portfolio />
    </>
  );
};

export default PortfolioContainer;
