import { gql } from "@apollo/client";

export const GET_PHYSICAL_DATA = gql`
  query getPortfolioPhysical(
    $organizationId: String!
    $year: Int!
    $month: Int!
  ) {
    organization(id: $organizationId) {
      id
      portfolio {
        id
        physical(year: $year, month: $month) {
          id
          month
          currency
          productionTotalAmount
          consumptionTotalAmount
          productionRows {
            volume
            price
            amount
            rowLabel
            sortOrder
            shouldBeBold
          }
          consumptionRows {
            volume
            price
            amount
            rowLabel
            sortOrder
            shouldBeBold
          }
        }
      }
    }
  }
`;
