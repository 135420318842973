import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import styles from "./valueUnit.module.scss";

const ValueUnit = ({
  className,
  valueClassName,
  unitClassName,
  value,
  unit,
  vertical,
}) => (
  <div
    className={classNames(styles.valueUnit, className, {
      [styles.vertical]: vertical,
    })}
  >
    <span className={classNames(styles.value, valueClassName)} title={value}>
      {value}
    </span>
    {unit && (
      <span className={classNames(styles.unit, unitClassName)}>{unit}</span>
    )}
  </div>
);

ValueUnit.propTypes = {
  className: PropTypes.string,
  valueClassName: PropTypes.string,
  unitClassName: PropTypes.string,
  unit: PropTypes.string,
  value: PropTypes.string,
  vertical: PropTypes.bool,
};

export default ValueUnit;
