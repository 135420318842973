import { gql } from "@apollo/client";

export const CREATE_API_TOKEN = gql`
  mutation createApiToken($organizationId: String!, $name: String) {
    issueApiToken(organizationId: $organizationId, name: $name) {
      token
      jti
      expires
    }
  }
`;
