import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { endOfMonth, isAfter, isBefore, subMonths } from "date-fns";
import { Translate } from "src/translations/translate";
import { NotMobile, Mobile } from "/src/components/responsive";
import { RangePicker, RangePickerMobile } from "/src/components/calendar";
import { useOrganizationQuery } from "/src/services/apollo/hooks/useOrganizationQuery";
import { GET_PLANT_MONTHLY_SUMMARIES } from "/src/services/apollo/queries";
import WeatherChart from "/src/components/weatherChart";
import {
  PlaceholderChart,
  PlaceholderChartText,
} from "/src/components/chart/placeholderChart";

import PlantAggregateChart from "./plantAggregateChart";
import SpotPriceChart from "./spotPriceChart";
import PlantMeasurandChart from "./plantMeasurandChart";
import MonthlySummaryCards from "/src/components/monthlySummaryCards";
import PlantMeasurandTableMobile from "./plantMeasurandTableMobile";
import MonthlySummaryCardsMobile from "../overview/monthlySummaryCardsMobile";

import styles from "./plant.module.scss";

function datesHaveWeatherData(from, to) {
  const now = new Date();
  const dateBackInTimeWhereNoDataExists = subMonths(now, 4);

  if (isAfter(from, now)) {
    return false;
  }
  if (isBefore(to, dateBackInTimeWhereNoDataExists)) {
    return false;
  }

  return true;
}

const PlantDetails = ({ from, setFrom, to, setTo, plantId, plants }) => {
  const year = new Date().getFullYear();
  const getSelectedPlantCoordinates = () => {
    const plant = plants.find((p) => p.id === plantId);
    if (plant && plant.coordinates.latitude && plant.coordinates.longitude)
      return plant.coordinates;

    return null;
  };

  const monthlySummaryCardsQuery = useOrganizationQuery(
    GET_PLANT_MONTHLY_SUMMARIES,
    {
      variables: {
        plantId,
        year,
        previousYear: year - 1,
      },
    }
  );

  return (
    <>
      <NotMobile>
        <MonthlySummaryCards
          className={classNames(styles.results, styles.section)}
          cardClassName={styles.result}
          plantId={plantId}
          year={year}
          queryResult={monthlySummaryCardsQuery}
        />
        <div className={styles.content}>
          <div className={classNames(styles.heading, styles.section)}>
            <Translate>PERIOD</Translate>{" "}
            <RangePicker
              from={from}
              to={to}
              onChange={([from, to]) => {
                setFrom(from);
                setTo(to);
              }}
            />
          </div>

          <div className={styles.section}>
            <div className={classNames(styles.heading, styles.chartHeading)}>
              <Translate>Overview</Translate>
            </div>
            <PlantAggregateChart
              className={styles.chart}
              from={from}
              to={to}
              plantId={plantId}
            />
          </div>

          <div className={styles.section}>
            <div className={classNames(styles.heading, styles.chartHeading)}>
              <Translate>Heading_SpotPrice</Translate>
            </div>
            <SpotPriceChart
              className={styles.chart}
              from={from}
              to={to}
              plantId={plantId}
            />
          </div>

          {getSelectedPlantCoordinates() && (
            <div className={styles.section}>
              <div className={classNames(styles.heading, styles.chartHeading)}>
                <Translate>Weather information</Translate>
              </div>
              {datesHaveWeatherData(from, to) ? (
                <WeatherChart
                  className={styles.chart}
                  from={from}
                  to={to}
                  latitude={getSelectedPlantCoordinates().latitude}
                  longitude={getSelectedPlantCoordinates().longitude}
                  plantId={plantId}
                />
              ) : (
                <PlaceholderChart>
                  <PlaceholderChartText>
                    <Translate>
                      No data available for the selected period.
                    </Translate>
                  </PlaceholderChartText>
                </PlaceholderChart>
              )}
            </div>
          )}

          <div className={classNames(styles.heading, styles.chartHeading)}>
            <Translate>Meter points</Translate>
          </div>
          <PlantMeasurandChart
            className={styles.chart}
            from={from}
            to={to}
            plantId={plantId}
          />
        </div>
      </NotMobile>
      <Mobile>
        <MonthlySummaryCardsMobile
          plantId={plantId}
          year={year}
          queryResult={monthlySummaryCardsQuery}
        />
        <div className={styles.heading}>
          <Translate>ALL MEASURE POINTS</Translate>
          <RangePickerMobile
            from={from}
            to={to}
            onChangeFrom={(from) => {
              setFrom(new Date(from));
              setTo(endOfMonth(from));
            }}
            onChangeTo={(to) => setTo(new Date(to))}
          />
        </div>
        <PlantMeasurandTableMobile from={from} to={to} plantId={plantId} />
      </Mobile>
    </>
  );
};

PlantDetails.propTypes = {
  from: PropTypes.instanceOf(Date),
  setFrom: PropTypes.func,
  to: PropTypes.instanceOf(Date),
  setTo: PropTypes.func,
  plantId: PropTypes.string,
  plants: PropTypes.array,
};

export default PlantDetails;
