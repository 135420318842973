import { gql } from "@apollo/client";

export const GET_WEATHER_FOR_PLANT = gql`
  query getWeatherForPlant(
    $organizationId: String!
    $plantId: String!
    $fromDate: String!
    $toDate: String!
  ) {
    organization(id: $organizationId) {
      id
      plant(id: $plantId) {
        id
        weather(fromDate: $fromDate, toDate: $toDate) {
          temperature {
            name
            unit
            distance
            readings {
              time
              value
            }
          }
          wind {
            name
            unit
            distance
            readings {
              time
              value
            }
          }
        }
      }
    }
  }
`;

export const GET_PLANTS = gql`
  query getPlants($organizationId: String!) {
    organization(id: $organizationId) {
      id
      plants {
        id
        name
        coordinates {
          id
          latitude
          longitude
        }
        maxPower
      }
    }
  }
`;

export const GET_PLANTS_WITH_ENERGY = gql`
  query getPlantsWithEnergy(
    $organizationId: String!
    $fromDate: String!
    $toDate: String!
  ) {
    organization(id: $organizationId) {
      id
      plants {
        id
        name
        coordinates {
          id
          latitude
          longitude
        }
        maxPower

        energy {
          production(fromDate: $fromDate, toDate: $toDate)
          consumption(fromDate: $fromDate, toDate: $toDate)
        }
      }
    }
  }
`;
