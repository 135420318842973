import React, { useState, useRef } from "react";
import PropTypes from "prop-types";

import Checkbox from "/src/components/checkbox";
import Button from "/src/components/button";
import { Translate } from "src/translations/translate";
import { useOutsideClick } from "/src/utilities/hooks";

import { EyeIcon } from "/src/icons";
import styles from "./toggleColumns.module.scss";

const ToggleColumns = ({ columns, setColumns }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [newColumns, setNewColumns] = useState(columns);

  const toggleColumn = (column) => {
    const { show, name } = column;
    setNewColumns(
      newColumns.map((newColumn) =>
        newColumn.name === name ? { ...newColumn, show: !show } : newColumn
      )
    );
  };

  const toggleColumnsRef = useRef();

  useOutsideClick({
    ref: toggleColumnsRef,
    callback: () => setIsExpanded(false),
  });

  const save = (newColumns) => {
    setColumns(newColumns);
    setIsExpanded(false);
  };

  return (
    <div className={styles.toggleColumns} ref={toggleColumnsRef}>
      <div
        className={styles.trigger}
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <div className={styles.triggerLabel}>
          <Translate>Show/Hide columns</Translate>
        </div>
        <EyeIcon className={styles.eyeIcon} />
      </div>

      {isExpanded && (
        <div className={styles.columnList}>
          <div className={styles.columnListTitle}>
            <Translate>Visible columns</Translate>
          </div>
          <div className={styles.checkboxes}>
            {newColumns.map((column, i) => (
              <Checkbox
                key={i}
                onClick={() => toggleColumn(column)}
                text={column.Header({ shouldHideUnit: true })}
                checked={column.show}
                className={styles.column}
              />
            ))}
          </div>
          <Button
            className={styles.saveButton}
            small
            onClick={() => save(newColumns)}
          >
            <Translate>Save</Translate>
          </Button>
        </div>
      )}
    </div>
  );
};

ToggleColumns.propTypes = {
  columns: PropTypes.array,
  setColumns: PropTypes.func,
};

export default ToggleColumns;
