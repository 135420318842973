import { gql } from "@apollo/client";

export const UPDATE_GO_CONSUMPTION = gql`
  mutation updateGoConsumption(
    $organizationId: String!
    $month: String!
    $goConsumption: [GoConsumptionInput]
    $elcQuotaExemptions: [ElcQuotaExemptionInput]
  ) {
    updateGoConsumption(
      id: $organizationId
      month: $month
      goConsumption: $goConsumption
      elcQuotaExemptions: $elcQuotaExemptions
    )
  }
`;
