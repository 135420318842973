import { ParsedExcelError } from "src/modules/energy/availability/validateAvailability";
import { useTranslation } from "src/translations/translationProvider";
import styles from "./availability.module.scss";

export function ValidationErrors({ errors }: { errors: ParsedExcelError[] }) {
  const { translate } = useTranslation();

  if (errors.length <= 0) {
    return null;
  }

  return (
    <div>
      <h3>{translate("AvailabilityPlanCriticalErrorMessage")}</h3>
      {errors.map((error, i) => (
        <div className={styles.error} key={i}>
          {translate("row")}: {error.row + 1},&nbsp;
          {translate("column")}: {error.column},&nbsp;
          {translate("value")}: {error.value},&nbsp;
          {translate("error message")}: {translate(error.message)}
        </div>
      ))}
    </div>
  );
}
