import React, { useState } from "react";
import { useMutation } from "/src/services/apollo/hooks/useOrganizationQuery";
import { Translate } from "src/translations/translate";
import { DELETE_IMPERSONATION } from "/src/services/apollo/mutations";
import styles from "./impersonatedWarning.module.scss";
import Button from "components/button";
import { useGetCurrentUser } from "src/modules/userProvider";

const ImpersonatedWarning = () => {
  const { id, impersonatedRole } = useGetCurrentUser();
  const [hasClicked, setHasClicked] = useState(false);

  const [deleteImpersonationMutation] = useMutation(DELETE_IMPERSONATION);

  const deleteImpersonation = (id) => {
    return deleteImpersonationMutation({
      variables: {
        userId: id,
      },
    });
  };
  if (impersonatedRole == null) {
    return null;
  }
  return (
    <div className={styles.impersonatedWarning}>
      <div className={styles.content}>
        <p>
          <Translate>ImpersonatedRole</Translate>: {impersonatedRole.roleName}
        </p>
        <p>
          <Translate>Organization</Translate>:{" "}
          {impersonatedRole.organizationName}
        </p>
        <Button
          small
          disabled={hasClicked}
          onClick={() => {
            setHasClicked(true);
            deleteImpersonation(id).then(() => {
              window.location.reload();
            });
          }}
        >
          <Translate>EndImpersonation</Translate>
        </Button>
      </div>
    </div>
  );
};

export default ImpersonatedWarning;
