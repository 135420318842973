import React from "react";
import sjson from "secure-json-parse";

import { MsalProvider, MsalAuthenticationTemplate } from "@azure/msal-react";
import {
  InteractionType,
  PublicClientApplication,
  InteractionRequiredAuthError,
} from "@azure/msal-browser";

import config from "/src/config/config";
import { ApolloWithCustomAuth, ApolloWithMsal } from "/src/services/apollo";
import CustomAuthenticationRequired from "./customAuthenticationRequired";
import { MsalErrorBoundary } from "src/components/error/msalErrorBoundary";

const isCustomAuth = () => {
  const isCustom = sjson.parse(window.sessionStorage.getItem("isCustomAuth"));
  return isCustom;
};

const pca = new PublicClientApplication({
  auth: {
    ...config.auth,
    navigateToLoginRequestUrl: true,
    redirectUri: `${window.location.origin}`,
    authority: `${config.auth.authority}${config.auth.authPolicy}/`,
  },
  system: {
    iframeHashTimeout: 10000,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true,
    secureCookies: true,
  },
});

const AuthenticationProvider = ({ children }) => {
  return isCustomAuth() ? (
    <>{children}</>
  ) : (
    <MsalProvider instance={pca}>{children}</MsalProvider>
  );
};

const AuthenticationRequired = ({ children }) => {
  return isCustomAuth() ? (
    <CustomAuthenticationRequired>{children}</CustomAuthenticationRequired>
  ) : (
    <MsalErrorBoundary>
      <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
        {children}
      </MsalAuthenticationTemplate>
    </MsalErrorBoundary>
  );
};

const ApolloWithAuth = ({ children }) => {
  return isCustomAuth() ? (
    <ApolloWithCustomAuth>{children}</ApolloWithCustomAuth>
  ) : (
    <ApolloWithMsal>{children}</ApolloWithMsal>
  );
};

const getToken = async (account) => {
  if (isCustomAuth()) {
    return sjson.parse(localStorage.getItem("token")).access_token;
  }

  if (!account) {
    account = pca.getAllAccounts()[0];
  }

  try {
    const result = await pca.acquireTokenSilent({
      scopes: config.auth.scopes,
      account,
      redirectUri: `${window.location.origin}/blank.html`,
    });
    return result.accessToken;
  } catch (err) {
    if (err instanceof InteractionRequiredAuthError) {
      return await pca.acquireTokenRedirect({ scopes: config.auth.scopes });
    } else {
      throw err;
    }
  }
};

export {
  isCustomAuth,
  AuthenticationProvider,
  AuthenticationRequired,
  ApolloWithAuth,
  getToken,
};
