import { gql } from "@apollo/client";

export const ADD_PHYSICAL_FEE_PERIOD = gql`
  mutation addPhysicalFeePeriod($organizationId: String!, $fromDate: String!) {
    addPhysicalFeePeriod(organizationId: $organizationId, fromDate: $fromDate)
  }
`;

export const DELETE_PHYSICAL_FEE_PERIOD = gql`
  mutation deletePhysicalFeePeriod($organizationId: String!) {
    deletePhysicalFeePeriod(organizationId: $organizationId)
  }
`;

export const UPDATE_PHYSICAL_FEES = gql`
  mutation updatePhysicalFees($organizationId: String!, $fees: [FeeInput]!) {
    updatePhysicalFees(organizationId: $organizationId, fees: $fees)
  }
`;
