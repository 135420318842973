import React, {  useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { OrganizationAppQuery } from "/src/components/appQuery";
import { useTranslation } from "src/translations/translationProvider";
import { Translate } from "src/translations/translate";
import TableMobile, { LoadingTableMobile } from "/src/components/tableMobile";

import { GET_PLANT_AGGREGATES } from "/src/services/apollo/queries";

import styles from "./plantMeasurandTableMobile.module.scss";

const DO_NOT_EXPAND_ANY_DATES_BY_DEFAULT = -1;

const PlantMeasurandTableMobile = ({ className, from, to, plantId }) => {
  const { translate, formatDate } = useTranslation();
  const [activeIndex, setActiveIndex] = useState(
    DO_NOT_EXPAND_ANY_DATES_BY_DEFAULT
  );
  const canMakeQuery = plantId;
  if (!canMakeQuery) {
    return null;
  }

  const toggleActiveIndex = (index) => {
    const clickedTheSameDate = index === activeIndex;
    setActiveIndex(
      !clickedTheSameDate ? index : DO_NOT_EXPAND_ANY_DATES_BY_DEFAULT
    );
  };

  return (
    <OrganizationAppQuery
      query={GET_PLANT_AGGREGATES}
      variables={{
        plantId,
        fromDate: formatDate(new Date(from), "yyyy-MM-dd"),
        toDate: formatDate(new Date(to), "yyyy-MM-dd"),
      }}
      renderLoading={() => (
        <LoadingTableMobile
          className={className}
          key="plant measurand table mobile loading"
        />
      )}
      renderError={() => (
        <div className={styles.chartText}>
          <span>
            <Translate>UnexpectedError</Translate>
          </span>
        </div>
      )}
      renderNoContent={() => (
        <div className={styles.chartText}>
          <span>
            <Translate>No data found</Translate>
          </span>
        </div>
      )}
    >
      {({ organization: { plant } }) => {
        const { aggregate } = plant.energy;
        const data = getAggregateData(aggregate, formatDate);
        const unit = getUnit(aggregate);

        if (!data) {
          return (
            <div>
              <Translate>No data available for the selected period.</Translate>
            </div>
          );
        }
        return Object.values(data).map((day, index) => {
          const columns = [
            {
              Header: translate("Time"),
              accessor: "time",
            },
            {
              Header: `${translate("Production")} (${unit})`,
              accessor: "production",
            },
            {
              Header: `${translate("Consumption")} (${unit})`,
              accessor: "consumption",
            },
          ];

          const isSelected = index === activeIndex;

          return (
            <div
              key={index}
              className={classNames(styles.listItem, {
                [styles.expanded]: isSelected,
              })}
            >
              <div
                className={classNames(
                  styles.listHeader,
                  styles.expandable,
                  styles.clickable
                )}
                onClick={() => toggleActiveIndex(index)}
              >
                <span className={styles.listItemCircle}></span>
                <span className={styles.listItemValue}>
                  {Object.keys(data)[index]}
                </span>
                <span className={styles.listItemCaret}></span>
              </div>
              <div className={styles.longVerticalLine}></div>
              <TableMobile
                data={day}
                columns={columns}
                className={classNames(
                  className,
                  {
                    [isSelected ? styles.expanded : styles.collapsed]: true,
                  },
                  styles.table
                )}
              />
              <div
                className={styles.collapseHelper}
                onClick={() => toggleActiveIndex(index)}
              ></div>
            </div>
          );
        });
      }}
    </OrganizationAppQuery>
  );
};

PlantMeasurandTableMobile.propTypes = {
  className: PropTypes.string,
  from: PropTypes.object.isRequired,
  to: PropTypes.object.isRequired,
  plantId: PropTypes.string,
};

export default PlantMeasurandTableMobile;

export function getAggregateData(aggregates, formatDate) {
  if (!aggregates || !aggregates.length) {
    return null;
  }
  const consumptionAggregate = aggregates.find(
    (aggregate) => aggregate.type === "Consumption"
  );
  const productionAggregate = aggregates.find(
    (aggregate) => aggregate.type === "Production"
  );
  const data = {};

  consumptionAggregate.readings.forEach((consumptionReading, index) => {
    const { time, value: consumptionValue } = consumptionReading;
    const dateOnly = formatDate(new Date(time), "yyyy-MM-dd");
    const dataToAdd = {};
    if (consumptionValue || consumptionValue === 0) {
      dataToAdd.consumption = consumptionValue;
    }
    const productionReading = productionAggregate.readings[index];
    if (productionReading || productionReading === 0) {
      dataToAdd.production = productionReading.value;
    }
    if (!Object.keys(dataToAdd).length) {
      return;
    }
    if (!data[dateOnly]) {
      data[dateOnly] = [];
    }
    dataToAdd.time = formatDate(new Date(time), "HH:mm");
    data[dateOnly].push(dataToAdd);
  });

  return isEmptyObject(data) ? null : getReversedSortedObjectKeys(data);
}

export function getUnit(aggregates) {
  if (!aggregates || !aggregates.length) {
    return "";
  }
  return aggregates[0].unit;
}

export function isEmptyObject(input) {
  return input.constructor === Object && Object.entries(input).length === 0;
}

export function getReversedSortedObjectKeys(data) {
  const reverse = {};
  Object.keys(data)
    .reverse()
    .forEach((key) => {
      reverse[key] = data[key];
    });

  return reverse;
}
