import { gql } from "@apollo/client";

export const GET_AVAILABLE_CONTACTS = gql`
  query getAvailableContacts($filter: String = "") {
    availableUsers(filter: $filter) {
      id
      firstName
      lastName
      role
      fileUrl
      email
    }
  }
`;
