import PropTypes from "prop-types";
import classNames from "classnames";

import styles from "./tableCellFormats.module.scss";

const RawValue = ({ value, className, unit }) => {
  return value === undefined || value === null ? (
    ""
  ) : (
    <div className={classNames(styles.formatOrEmpty, className)}>
      {`${value} ${unit ? unit : ""}`}
    </div>
  );
};

RawValue.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  unit: PropTypes.string,
};

export default RawValue;
