import React from "react";
import PropTypes from "prop-types";

import { useTranslation } from "src/translations/translationProvider";
import Chart from "/src/components/chart";
import { modityColors } from "/src/components/chart/utils";
import { convertResolutionToMonth } from "/src/modules/portfolio/financial/hedgeReport/hedgeUtils";
import { capitalize } from "/src/utilities";

const GRAPH_HIT_RADIUS = 12;
const DEFAULT_UNIT = "MWh";

// The order in the array decides the order in list above diagram and the order-property decides the order in tooltip
const GRAPH_LINES_BARS = {
  forecast: {
    type: "bar",
    order: 1,
    color: modityColors.black,
  },
  targetVolume: {
    type: "bar",
    order: 2,
    color: modityColors.darkGrey,
  },
  hedgeVolume: {
    type: "bar",
    order: 3,
    color: modityColors.orange,
  },
};

const IndexPoolHedgeChart = ({ data, unit, dataResolution }) => {
  const { translate } = useTranslation();

  const sortByDate = (dateA, dateB) =>
    new Date(dateA.date) - new Date(dateB.date);

  const datasets = Object.keys(GRAPH_LINES_BARS).map((key) => {
    const chartData = data
      .map((monthData) => {
        return {
          date: monthData.period,
          value: monthData[key],
        };
      })
      .sort(sortByDate);

    return {
      key,
      label: capitalize(translate(key)),
      fill: false,
      borderColor: GRAPH_LINES_BARS[key].color,
      borderWidth: "2",
      backgroundColor: GRAPH_LINES_BARS[key].color,
      pointRadius: 0,
      pointHitRadius: GRAPH_HIT_RADIUS,
      borderJoinStyle: "bevel",
      borderDash: GRAPH_LINES_BARS[key].dash,
      type: GRAPH_LINES_BARS[key].type,
      order: GRAPH_LINES_BARS[key].order,
      data: chartData.slice().map(({ date, value }) => ({
        x: new Date(date),
        y: parseFloat(value),
      })),
    };
  });

  return (
    <Chart
      key="HedgeRapport chart"
      data={{ datasets }}
      options={{
        yLabel: unit || DEFAULT_UNIT,
        unit: convertResolutionToMonth(dataResolution),
      }}
    />
  );
};

IndexPoolHedgeChart.propTypes = {
  data: PropTypes.array,
  unit: PropTypes.string,
  loading: PropTypes.any,
  error: PropTypes.any,
};

export default IndexPoolHedgeChart;
