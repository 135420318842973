import React, { useState } from "react";
import { useParams } from "react-router-dom";

import { useTranslation } from "src/translations/translationProvider";
import { Translate } from "src/translations/translate";
import { hasAccess } from "/src/components/accessControl/accessCheck";
import { TABS, tabRestrictionLookup } from "./tabs";
import TabBar from "/src/components/tabBar";
import PageLayout from "/src/components/pageLayout";
import Documents from "/src/modules/files/documents";
import Invoices from "/src/modules/files/invoices";
import InvoiceMaterials from "/src/modules/files/invoiceMaterials";
import ErrorPage from "/src/modules/error-page";

import styles from "./files.module.scss";
import { useGetCurrentUser } from "src/modules/userProvider";

const Files = () => {
  const { translate } = useTranslation();
  const { selectedOrganizationConnection, isModityAdmin } = useGetCurrentUser();
  const userClaims = selectedOrganizationConnection.role.claims;
  const params = useParams();

  const tabs = Object.keys(TABS)
    .filter((tab) => {
      const restrictions = tabRestrictionLookup[tab];
      if (Array.isArray(restrictions)) {
        return restrictions.every((r) => {
          return !r.type || hasAccess(r, userClaims);
        });
      }
      return !restrictions.type || hasAccess(restrictions, userClaims);
    })
    .map((tab) => ({
      text: translate(`TABS_${tab}`),
      value: tab,
    }));

  const [activeTab, setActiveTab] = useState(
    (
      tabs.find((tab) => tab.value === params.tab) ||
      tabs[0].value ||
      ""
    ).toUpperCase(),
  );

  const onTabChange = (tab) => {
    setActiveTab(tab);
  };

  const renderSubPage = () => {
    switch (activeTab) {
      case TABS.DOCUMENTS:
        return (
          <Documents
            organizationId={selectedOrganizationConnection.organization.id}
            isModityAdmin={isModityAdmin}
          />
        );
      case TABS.INVOICES:
        return <Invoices />;
      case TABS.INVOICE_MATERIALS:
        return <InvoiceMaterials />;

      default:
        return <ErrorPage />;
    }
  };

  return (
    <PageLayout title={<Translate>Files</Translate>}>
      <TabBar
        className={styles.tabs}
        optionClassName={styles.tab}
        activeOptionClassName={styles.activeTab}
        options={tabs}
        selectedValue={activeTab}
        onChange={onTabChange}
      />

      <main>{renderSubPage()}</main>
    </PageLayout>
  );
};

export default Files;
