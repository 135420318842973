const emailReg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const isValidEmail = (value) => emailReg.test(value);

export const isNotEmpty = (value) => !!value;

// (integer) OR (integer.integer) OR (.integer)
const numberReg = /(^-?\d+$)|(^-?\d+\.\d+$)|(^-?\.\d+$)/;
export const isNumber = (value) => numberReg.test(value);

// (integer) OR (integer.integer) OR (integer,integer) OR (.integer) OR (,integer)
const numberWithCommaOrDotRegex = /(^-?\d+$)|(^-?\d+(\.|,)?\d+$)|(^-?(\.|,)?\d+$)/;
export function isValidNumberString(string = "") {
  return numberWithCommaOrDotRegex.test(string);
}

const phoneNumber = /(^\d+\s?-?\s?\d+?\s?\d+?\s?\d+?$)/;
export function isPhoneNumber(string = "") {
  return phoneNumber.test(string);
}

export function replaceCommaWithDot(string) {
  return isValidNumberString(string) ? string.replace(",", ".") : string;
}
