import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import {
  startOfDay,
  getYear,
  eachYearOfInterval,
  isBefore,
  endOfMonth,
} from "date-fns";
import { useOrganizationQuery } from "/src/services/apollo/hooks/useOrganizationQuery";
import { Translate } from "src/translations/translate";
import {
  GET_ORGANIZATION_MONTHLY_SUMMARIES_FOR_BALANCE_CONTROLLER,
  GET_ORGANIZATION_MONTHLY_SUMMARIES_FOR_SUPPLIER,
} from "/src/services/apollo/queries";
import { NotMobile, Mobile } from "/src/components/responsive";
import { RangePicker, RangePickerMobile } from "/src/components/calendar";
import Select from "/src/components/select";
import { ORGANIZATION_CATEGORY } from "/src/constants/organizationCategories";

import MonthlySummaryCards from "/src/components/monthlySummaryCards";
import PlantsListingTableMobile from "./plantsListingTableMobile";
import EnergyAggregateCharts from "./energyAggregateCharts";
import PlantsListingTable from "./plantsListingTable";
import YearAggregateChart from "./yearAggregateChart";
import MonthlySummaryCardsMobile from "./monthlySummaryCardsMobile";
import RangePickerText from "./rangePickerText";

import styles from "./overview.module.scss";

const yearToSelectObject = (year) => ({ label: year, value: String(year) });

const Overview = ({ from, setFrom, to, setTo, organizationCategory }) => {
  const now = new Date();
  const today = startOfDay(now);
  const currentYear = getYear(now);
  const [startYearForTwoYearGraph, setStartYearForTwoYearGraph] =
    useState(currentYear);

  const availableYears = eachYearOfInterval({
    start: new Date(2016, 1, 1),
    end: now,
  })
    .map((date) => getYear(date))
    .reverse();

  const organizationIsSupplier =
    organizationCategory === ORGANIZATION_CATEGORY.SUPPLIER;
  const organizationIsBalanceController =
    organizationCategory === ORGANIZATION_CATEGORY.BALANCE_CONTROLLER;

  const monthlySummaryCardsQuery = useOrganizationQuery(
    organizationIsBalanceController
      ? GET_ORGANIZATION_MONTHLY_SUMMARIES_FOR_BALANCE_CONTROLLER
      : GET_ORGANIZATION_MONTHLY_SUMMARIES_FOR_SUPPLIER,
    {
      variables: {
        year: currentYear,
        previousYear: currentYear - 1,
      },
    }
  );

  return (
    <>
      <NotMobile>
        <MonthlySummaryCards
          className={classNames(styles.results, styles.section)}
          cardClassName={styles.result}
          year={currentYear}
          queryResult={monthlySummaryCardsQuery}
        />
        <div className={styles.content}>
          <div className={styles.section}>
            <div className={styles.heading}>
              {organizationIsSupplier ? (
                <Translate>ALL PLANTS DURING PERIOD</Translate>
              ) : (
                <Translate>ALL REGIONS DURING PERIOD</Translate>
              )}{" "}
              <RangePicker
                from={from}
                to={to}
                onChange={([from, to]) => {
                  setFrom(from);
                  setTo(to);
                }}
              />
            </div>

            <EnergyAggregateCharts
              className={styles.chart}
              from={from}
              to={to}
            />

            {organizationIsBalanceController && (
              <EnergyAggregateCharts
                className={styles.chart}
                from={from}
                to={to}
                dataOptions={{
                  dataKey: "standard",
                  productionKey: "HourlyStandardsProduction",
                  consumptionKey: "HourlyStandardsConsumption",
                  shouldGetStandards: true,
                  filterReadings: (reading) =>
                    isBefore(new Date(reading.time), today),
                }}
              />
            )}

            {organizationIsSupplier && (
              <div className={styles.section}>
                <PlantsListingTable from={from} to={to} />
              </div>
            )}

            <div className={styles.heading}>
              <Translate>YEAR OVERVIEW FOR</Translate>{" "}
              <Select
                value={yearToSelectObject(startYearForTwoYearGraph)}
                options={availableYears.map(yearToSelectObject)}
                onChange={({ value }) =>
                  setStartYearForTwoYearGraph(Number(value))
                }
                isWithCaret={true}
              />
            </div>
            <YearAggregateChart
              className={styles.chart}
              year={startYearForTwoYearGraph}
            />
          </div>
        </div>
      </NotMobile>

      <Mobile>
        <div className={styles.contentMobile}>
          <MonthlySummaryCardsMobile
            year={currentYear}
            queryResult={monthlySummaryCardsQuery}
          />
          <div className={styles.heading}>
            <RangePickerText organizationCategory={organizationCategory} />
            <RangePickerMobile
              from={from}
              to={to}
              onChangeFrom={(from) => {
                setFrom(new Date(from));
                setTo(endOfMonth(from));
              }}
              onChangeTo={(to) => setTo(new Date(to))}
            />
          </div>
          {organizationIsSupplier && (
            <PlantsListingTableMobile from={from} to={to} />
          )}
        </div>
      </Mobile>
    </>
  );
};

Overview.propTypes = {
  from: PropTypes.instanceOf(Date),
  setFrom: PropTypes.func,
  to: PropTypes.instanceOf(Date),
  setTo: PropTypes.func,
  organizationCategory: PropTypes.string.isRequired,
};

export default Overview;
