import { gql } from "@apollo/client";

export const GET_COOKIE_CONSENT = gql`
  query getCookieConsent($locale: String) {
    cookieConsent(locale: $locale) {
      fileName
      contentType
      url
    }
  }
`;
