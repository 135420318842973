import React from "react";
import classNames from "classnames";
import { isValid, parseISO } from "date-fns";
import PropTypes from "prop-types";
import { useRowSelect, useSortBy, useTable } from "react-table";
import XLSX from "xlsx";
import { useExportData } from "./useExportData";

import { Translate } from "src/translations/translate";
import { useTranslation } from "src/translations/translationProvider";
import Button from "/src/components/button";

import styles from "./table.module.scss";

const FILE_NAME = "ModityExcelExport";

const NoData = () => (
  <tr>
    <td data-test-id="no-data-in-table">
      <Translate>No data found</Translate>
    </td>
  </tr>
);

const Table = ({
  highlightChangedRow,
  clickable,
  className,
  headerClassName,
  cellClassName,
  data,
  columns,
  useFooter,
  tableOptions,
  sortable,
  exportable,
  selectable,
  selectedButtonText,
  handleSelected,
  ...props
}) => {
  const { formatDate } = useTranslation();

  const formatCell = (cellData) => {
    const parsedDate = parseISO(cellData, "yyyy-MM-ddTHH:mm:ss");
    if (isValid(parsedDate)) {
      return formatDate(parsedDate, "yyyy-MM");
    }
    return cellData;
  };

  const getExportFileBlob = ({ columns, data, fileType }) => {
    if (fileType === "xlsx") {
      const header = columns.map((c) => c.name);
      const compatibleData = data.map((row) => {
        const obj = {};
        header.forEach((col, index) => {
          obj[col] = formatCell(row[index]);
        });
        return obj;
      });
      let wb = XLSX.utils.book_new();
      let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
        header,
      });
      XLSX.utils.book_append_sheet(wb, ws1, FILE_NAME);
      XLSX.writeFile(wb, `${FILE_NAME}.xlsx`);
      return false;
    }
    return false;
  };

  const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
      const defaultRef = React.useRef();
      const resolvedRef = ref || defaultRef;

      React.useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate;
      }, [resolvedRef, indeterminate]);

      return (
        <>
          <input type="checkbox" ref={resolvedRef} {...rest} />
        </>
      );
    }
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    footerGroups,
    exportData,
    selectedFlatRows,
  } = useTable(
    {
      columns,
      data,
      ...tableOptions,
      getExportFileBlob,
    },
    useSortBy,
    useExportData,
    useRowSelect,
    (hooks) => {
      if (!selectable) {
        return;
      }
      hooks.visibleColumns.push((columns) => [
        {
          id: "selection",
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div className={styles.checkbox}>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          Cell: ({ row }) => (
            <div className={styles.checkbox}>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ]);
    }
  );

  const shouldHighlightChangedRow = (row) => {
    if (
      !(highlightChangedRow && row.original[highlightChangedRow.propToCheck])
    ) {
      return;
    }
    return highlightChangedRow.class;
  };

  const isClickable = (enabled) => {
    return enabled ? styles.clickable : null;
  };

  return (
    <>
      {exportable && (
        <Button
          className={styles.export}
          small
          onClick={() => {
            exportData("xlsx", false);
          }}
        >
          <Translate>Export</Translate>
        </Button>
      )}
      {selectable && (
        <Button
          className={styles.removeButton}
          disabled={selectedFlatRows.map((d) => d.original).length < 1}
          onClick={(e) => {
            e.stopPropagation();
            handleSelected(selectedFlatRows.map((d) => d.original));
          }}
        >
          {selectedButtonText}
        </Button>
      )}
      <table
        className={classNames(className, styles.table)}
        {...getTableProps()}
        {...props}
      >
        <thead>
          {headerGroups.map((headerGroup, i) => (
            <tr key={i} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, i) => (
                <th
                  data-test-id={`portfolio-header-${i}`}
                  className={classNames(
                    { [headerClassName]: !column.headerClassName },
                    column.headerClassName,
                    { [styles.sortable]: sortable },
                    {
                      [styles.sortDesc]: column.isSorted && column.isSortedDesc,
                    },
                    {
                      [styles.sortAsc]: column.isSorted && !column.isSortedDesc,
                    }
                  )}
                  key={i}
                  {...column.getSortByToggleProps()}
                  {...column.getHeaderProps()}
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr
                key={i}
                {...row.getRowProps()}
                className={classNames([
                  shouldHighlightChangedRow(row),
                  isClickable(clickable.enabled),
                ])}
                onClick={() => clickable.onClick(row.original)}
              >
                {row.cells.map((cell, i) => {
                  return (
                    <td
                      data-test-id={`portfolio-cell-${i}`}
                      className={cellClassName}
                      key={i}
                      {...cell.getCellProps()}
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
          {rows.length < 1 && <NoData />}
        </tbody>
        {useFooter && data.length > 0 && (
          <tfoot>
            {footerGroups.map((group, i) => (
              <tr key={i} {...group.getFooterGroupProps()}>
                {group.headers.map((column, i) => (
                  <td
                    data-test-id={`table-cell-${i}`}
                    className={column.footerClassName}
                    key={i}
                    {...column.getFooterProps()}
                  >
                    {column.render("Footer")}
                  </td>
                ))}
              </tr>
            ))}
          </tfoot>
        )}
      </table>
    </>
  );
};

Table.propTypes = {
  highlightChangedRow: PropTypes.shape({
    propToCheck: PropTypes.string.isRequired,
    class: PropTypes.string,
  }),
  clickable: PropTypes.shape({
    onClick: PropTypes.func,
    enabled: PropTypes.bool,
  }),
  className: PropTypes.string,
  headerClassName: PropTypes.string,
  cellClassName: PropTypes.string,
  data: PropTypes.array,
  columns: PropTypes.array,
  tableOptions: PropTypes.object,
  sortable: PropTypes.bool,
  useFooter: PropTypes.bool,
  selectable: PropTypes.bool,
  selectedButtonText: PropTypes.string,
  handleSelected: PropTypes.func,
};

Table.defaultProps = {
  clickable: {
    onClick: () => {},
    enabled: false,
  },
  sortable: false,
  tableOptions: { disableSortBy: true },
  useFooter: false,
};

export default Table;
