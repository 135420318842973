import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useTranslation } from "src/translations/translationProvider";
import Panel from "/src/components/panel";
import UserProfile from "/src/components/userProfile";
import { useMutation } from "/src/services/apollo/hooks/useOrganizationQuery";
import { UPDATE_CURRENT_USER } from "/src/services/apollo/mutations";
import { GET_CURRENT_USER } from "/src/services/apollo/queries";
import { useNotifications } from "src/modules/notifications/notificationsProvider";
import { useGetCurrentUser } from "src/modules/userProvider";

const EditProfile = () => {
  const currentUser = useGetCurrentUser();
  const { translate } = useTranslation();
  const { showNotification } = useNotifications();
  const [isUploading, setIsUploading] = useState(false);
  const [updateCurrentUser] = useMutation(UPDATE_CURRENT_USER);
  const navigate = useNavigate();
  const location = useLocation();

  function closeUserProfile() {
    location.hash = location.hash.replace(",editprofile", "");
    navigate(location, { replace: true });
  }

  const modifyUser = (newUser, { preventClosingSidePanel } = {}) => {
    const updatedUser = { ...currentUser, ...newUser };
    const serverUser = {
      firstName: updatedUser.firstName,
      lastName: updatedUser.lastName,
      showDailyComment: updatedUser.showDailyComment,
      phoneNumber: updatedUser.phoneNumber,
    };

    const IMAGE_MIME_TYPE = "data:image/";
    const avatarPictureIsValid =
      updatedUser.avatar &&
      updatedUser.avatar.fileUrl &&
      updatedUser.avatar.fileUrl.includes("base64") &&
      updatedUser.avatar.fileUrl.startsWith(IMAGE_MIME_TYPE);

    if (avatarPictureIsValid) {
      const resultSize = new Blob([updatedUser.avatar.fileUrl]).size;
      const stringLimitInBytes = 260175;
      const avatarPictureExceedsFileLimit = resultSize > stringLimitInBytes;
      if (avatarPictureExceedsFileLimit) {
        showNotification(
          translate("File is too large, has to be maximum 195kB")
        );
        return;
      }
      serverUser.avatar = updatedUser.avatar.fileUrl.substr(
        updatedUser.avatar.fileUrl.indexOf(",") + 1
      );

      let mimeType = updatedUser.avatar.fileUrl.substr(IMAGE_MIME_TYPE.length);
      mimeType = mimeType.substr(0, mimeType.indexOf(";"));
      serverUser.imageMimeType = mimeType;
    }

    setIsUploading(true);

    updateCurrentUser({
      refetchQueries: [
        {
          query: GET_CURRENT_USER,
        },
      ],
      variables: {
        userId: currentUser.id,
        user: serverUser,
      },
    })
      .then(() => {
        setIsUploading(false);
        if (preventClosingSidePanel) {
          return false;
        }
        closeUserProfile();
        return true;
      })
      .catch((error) => {
        setIsUploading(false);
        // eslint-disable-next-line no-console
        console.log("Error updating user profile", error);

        showNotification(translate("Updating your profile failed"));
      });
  };

  return (
    <Panel
      isOpen={window.location.hash.includes(",editprofile")}
      close={closeUserProfile}
    >
      <UserProfile
        user={currentUser}
        onChange={modifyUser}
        onCancel={closeUserProfile}
        shouldPreventUpdate={isUploading}
      />
    </Panel>
  );
};

export default EditProfile;
