import React from "react";
import PropTypes from "prop-types";

import Chart from "/src/components/chart";

const GoSummaryChart = ({ className, chartData, yLabel }) => {
  const datasetOptions = {
    fill: false,
    borderWidth: 0,
    pointRadius: 0,
    pointHitRadius: 16,
    borderJoinStyle: "bevel",
  };

  const { data, barColors, datasetLabel, barLabels } = chartData;

  const datasets = [
    {
      ...datasetOptions,
      data,
      backgroundColor: barColors,
      label: datasetLabel,
    },
  ];

  return (
    <Chart
      type="Bar"
      className={className}
      data={{
        labels: barLabels,
        datasets,
      }}
      options={{
        yLabel,
        xAxisType: "category",
      }}
    />
  );
};

GoSummaryChart.propTypes = {
  className: PropTypes.string,
};

export default GoSummaryChart;
