import React from "react";
import classNames from "classnames" ;
import PropTypes from "prop-types";

import { Translate } from "src/translations/translate";
import { useTranslation } from "src/translations/translationProvider";
import { OrganizationAppQuery } from "/src/components/appQuery";
import {
  LoadingChart,
  NoDataChart,
  PlaceholderChart,
  PlaceholderChartText,
} from "/src/components/chart";

import EnergyAggregateChart from "/src/components/chart/energyAggregateChart";
import {
  GET_ORGANIZATION_AGGREGATES,
  GET_ORGANIZATION_STANDARDS,
} from "/src/services/apollo/queries";

const EnergyAggregateCharts = ({
  className,
  loadingClassName,
  from,
  to,
  options,
  dataOptions,
}) => {
  const { formatDate } = useTranslation();
  const {
    shouldGetStandards,
    dataKey,
    productionKey,
    consumptionKey,
    filterReadings,
  } = dataOptions;

  return (
    <OrganizationAppQuery
      query={
        shouldGetStandards
          ? GET_ORGANIZATION_STANDARDS
          : GET_ORGANIZATION_AGGREGATES
      }
      variables={{
        fromDate: formatDate(new Date(from), "yyyy-MM-dd"),
        toDate: formatDate(new Date(to), "yyyy-MM-dd"),
      }}
      renderLoading={() => (
        <>
          <LoadingChart
            className={classNames(className, loadingClassName)}
            key="org agg chart loading 1"
            options={options}
          />
          <LoadingChart
            className={classNames(className, loadingClassName)}
            key="org agg chart loading 2"
            options={options}
          />
        </>
      )}
      renderError={() => (
        <PlaceholderChart
          className={classNames(className, loadingClassName)}
          key="org agg chart error"
          options={options}
        >
          <PlaceholderChartText>
            <Translate>UnexpectedError</Translate>
          </PlaceholderChartText>
        </PlaceholderChart>
      )}
      renderNoContent={() => (
        <NoDataChart
          className={classNames(className, loadingClassName)}
          key="org agg chart empty"
        />
      )}
    >
      {({ organization: { energy } }) => {
        const production = energy[dataKey].find(
          (a) => a.type === productionKey
        );
        const consumption = energy[dataKey].find(
          (a) => a.type === consumptionKey
        );
        const sortedAggregates = [production, consumption];

        return sortedAggregates.map((aggregate) => {
          if (!aggregate || aggregate.readings.length === 0) {
            return null;
          }

          const chartProps = {
            className,
            type: aggregate.type,
            readings: aggregate.readings,
            filterReadings,
            from,
            to,
            unit: aggregate.unit,
            options: {
              xMin: from,
              xMax: to,
              yLabel: `${aggregate.unit}`,
              ...options,
            },
          };

          return <EnergyAggregateChart key={aggregate.type} {...chartProps} />;
        });
      }}
    </OrganizationAppQuery>
  );
};

EnergyAggregateCharts.propTypes = {
  className: PropTypes.string,
  loadingClassName: PropTypes.string,
  from: PropTypes.object,
  to: PropTypes.object,
  options: PropTypes.object,
  dataOptions: PropTypes.shape({
    dataKey: PropTypes.string,
    productionKey: PropTypes.string,
    consumptionKey: PropTypes.string,
    shouldGetStandards: PropTypes.bool,
    filterReadings: PropTypes.func,
  }),
};

EnergyAggregateCharts.defaultProps = {
  dataOptions: {
    dataKey: "aggregate",
    productionKey: "Production",
    consumptionKey: "Consumption",
    shouldGetStandards: false,
    filterReadings: (reading) => reading,
  },
};

export default EnergyAggregateCharts;
