import { gql } from "@apollo/client";

export const CREATE_MEASURAND = gql`
  mutation createMeasurand(
    $organizationId: String!
    $plantId: String!
    $measurand: MeasurandInput!
  ) {
    createMeasurand(
      organizationId: $organizationId
      plantId: $plantId
      measurand: $measurand
    )
  }
`;

export const UPDATE_MEASURAND = gql`
  mutation updateMeasurand(
    $organizationId: String!
    $plantId: String!
    $measurandId: String!
    $measurand: MeasurandInput!
  ) {
    updateMeasurand(
      organizationId: $organizationId
      plantId: $plantId
      measurandId: $measurandId
      measurand: $measurand
    )
  }
`;

export const DELETE_MEASURAND = gql`
  mutation deleteMeasurand(
    $organizationId: String!
    $plantId: String!
    $measurandId: String!
  ) {
    deleteMeasurand(
      organizationId: $organizationId
      plantId: $plantId
      measurandId: $measurandId
    )
  }
`;
