import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

import RadioButtonList from "/src/components/radioButtonList";

import styles from "./tabBar.module.scss";

const TabBar = ({
  className,
  optionClassName,
  activeOptionClassName,
  onChange,
  options,
  selectedValue,
  shouldSkipTabsClassName,
}) => {
  return (
    <RadioButtonList
      className={classNames(className, {
        [styles.tabs]: !shouldSkipTabsClassName,
      })}
      optionClassName={classNames(styles.tabsOption, optionClassName)}
      activeOptionClassName={classNames(
        styles.activeTabOption,
        activeOptionClassName
      )}
      options={options}
      selectedValue={selectedValue}
      onChange={onChange}
    />
  );
};

const entryType = PropTypes.oneOfType([
  PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  PropTypes.string,
]);

TabBar.propTypes = {
  className: PropTypes.string,
  optionClassName: PropTypes.string,
  activeOptionClassName: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      value: entryType.isRequired,
    })
  ).isRequired,
  selectedValue: entryType,
  shouldSkipTabsClassName: PropTypes.bool,
};

export default TabBar;
