// in [invoices]
// out [ [year, [ [month, [invoices] ] ]] ]
export default (invoices, locale = "en") => {
  if (!invoices) {
    return [];
  }
  return invoices.reduce((list, invoice) => {
    const date = new Date(invoice.date);
    const year = date.getFullYear();
    const month = date.toLocaleDateString(locale, {
      month: "long",
    });

    const years = list.find((y) => y[0] === year);
    if (!years) {
      list.push([year, [[month, [invoice]]]]);
    } else {
      const months = years[1].find((m) => m[0] === month);
      if (!months) {
        years[1].push([month, [invoice]]);
      } else {
        months[1].push(invoice);
      }
    }

    return list;
  }, []);
};
