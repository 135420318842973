import React, { Dispatch, SetStateAction, useState } from "react";
import {
  isWithinInterval,
  isAfter,
  startOfYear,
  endOfYear,
  isSameYear,
  parseJSON,
} from "date-fns";

import { useTranslation } from "src/translations/translationProvider";
import { Translate } from "src/translations/translate";
import { GET_HEDGES } from "src/services/apollo/queries";
import { AppQuery } from "src/components/appQuery";
import HedgeReportTable from "./hedgeReportTable";
import HedgeChart from "./hedgeChart";
import {
  LoadingChart,
  PlaceholderChart,
  PlaceholderChartText,
} from "src/components/chart";
import { useStickyState } from "src/utilities/hooks";

import financialStyles from "../financial.module.scss";
import styles from "./hedgeReport.module.scss";
import { EmptyChartAndTable } from "src/modules/portfolio/financial/hedgeReport/EmptyChartAndTable";
import { GetHedgesQuery, GetProductsQuery } from "src/graphql/graphql";
import { ElementArrayType } from "src/utilities/typescriptHelpers";
import {
  RESOLUTION,
  RESOLUTION_LIST,
  UNIT,
  UNIT_LIST,
  convertHedgeToMW,
  flagDataInFuture,
  getImportedPositionOptions,
} from "src/modules/portfolio/financial/hedgeReport/hedgeUtils";
import { HedgeReportFilter } from "src/modules/portfolio/financial/hedgeReport/hedgeReportFilter";

export function HedgeReport({
  organizationId,
  products,
  portfolioReportConfigurations,
  selectedConfigurationIds,
  setSelectedConfigurationIds,
  setSelectedProduct,
  selectedProduct,
}: {
  organizationId: string;
  products: GetProductsQuery["organization"]["products"];
  portfolioReportConfigurations: { id: string; name: string; type: string }[];
  selectedConfigurationIds: string[];
  setSelectedConfigurationIds: Dispatch<SetStateAction<string[]>>;
  setSelectedProduct: Dispatch<
    SetStateAction<
      ElementArrayType<GetProductsQuery["organization"]["products"]>
    >
  >;
  selectedProduct: ElementArrayType<
    GetProductsQuery["organization"]["products"]
  >;
}) {
  const currentDate = new Date();
  const STORAGE_PREFIX = `hedge-report-${organizationId}-`;

  const { translate, formatDate } = useTranslation();
  const importedPositionOptions = getImportedPositionOptions(translate);

  const [pointInTime, setPointInTime] = useStickyState<Date | null>(
    null,
    `${STORAGE_PREFIX}pointInTime`,
  );

  const [range, setRange] = useStickyState<{ to?: Date; from: Date }>(
    {
      from: new Date(),
    },
    `${STORAGE_PREFIX}range`,
  );

  const [selectedUnit, setSelectedUnit] = useStickyState<UNIT>(
    UNIT_LIST[0],
    `${STORAGE_PREFIX}selectedUnit`,
  );

  const [selectedResolution, setSelectedResolution] =
    useStickyState<RESOLUTION>(
      RESOLUTION_LIST.MONTHLY,
      `${STORAGE_PREFIX}selectedResolution`,
    );

  const [selectedCurrencies, setSelectedCurrencies] = useStickyState(
    selectedProduct?.currencies ?? [],
    `${STORAGE_PREFIX}selectedCurrencies`,
  );

  const [selectedImportedPosition, setSelectedImportedPosition] =
    useStickyState(
      importedPositionOptions[0],
      `${STORAGE_PREFIX}selectedImportedPosition`,
    );

  const [comment1, setComment1] = useState<{ value: string }[]>([]);
  const [comment2, setComment2] = useState<{ value: string }[]>([]);

  const resetToDefaultState = () => {
    setPointInTime(null);
    setRange({ from: new Date() });
    setSelectedUnit(UNIT_LIST[0]);
    setSelectedResolution(RESOLUTION_LIST.MONTHLY);
    setSelectedCurrencies(selectedProduct?.currencies ?? []);
    setSelectedImportedPosition(importedPositionOptions[0]);
    setComment1([]);
    setComment2([]);
    setSelectedConfigurationIds(portfolioReportConfigurations.map((p) => p.id));
  };

  const getCurrentDate = () =>
    pointInTime ? parseJSON(pointInTime) : currentDate;

  const getSelectedImportedPositionValue = () =>
    parseInt(selectedImportedPosition?.value || "") || 1;

  const handleProductChange = (selection: { label: string; value: string }) => {
    const product = products.find(
      (product) => product.name === selection.value,
    );
    if (product && product !== selectedProduct) {
      setSelectedProduct(product);
      setSelectedCurrencies(product?.currencies ?? []);
    }
  };

  const handleCurrenciesChange = (
    selection: {
      label: string;
      value: string;
    }[],
  ) => {
    const newSelection = selection.map((currency) => currency?.value);
    if (JSON.stringify(newSelection) !== JSON.stringify(selectedCurrencies)) {
      setSelectedCurrencies(newSelection);
    }
  };

  const handleConfigurationChange = (selection: string[]) => {
    setSelectedConfigurationIds(selection);
  };

  const renderErrorChartAndTable = () => (
    <div className={styles.hedgeChart}>
      <PlaceholderChart key="HedgeReport chart error">
        <PlaceholderChartText>
          <Translate>UnexpectedError</Translate>
        </PlaceholderChartText>
      </PlaceholderChart>
      <HedgeReportTable data={[]} />
    </div>
  );

  const renderLoadingChartAndTable = () => (
    <div className={styles.hedgeChart}>
      <LoadingChart key="HedgeReport chart loading" />
    </div>
  );

  const getHedgesInInterval = (
    hedges: GetHedgesQuery["organization"]["hedgeReport"][number]["hedges"],
  ) => {
    const convertedHedges =
      selectedUnit === UNIT_LIST[1] ? convertHedgeToMW(hedges) : hedges;

    const { from, to } = range;
    if (from && to) {
      return convertedHedges.filter((h) =>
        isWithinInterval(new Date(h.period), {
          start: startOfYear(parseJSON(from)),
          end: endOfYear(parseJSON(to)),
        }),
      );
    }

    return convertedHedges.filter((h) => {
      const hedgeDate = new Date(h.period);
      const thisYear = startOfYear(getCurrentDate());
      return isAfter(hedgeDate, thisYear) || isSameYear(hedgeDate, thisYear);
    });
  };

  const applyFilters = (
    data: NonNullable<GetHedgesQuery["organization"]["hedgeReport"][number]>,
  ) => {
    const { hedges, products: visibleProducts } = data;

    const hedgesInRange = getHedgesInInterval(hedges);

    const hedgesWithFutureFlag = flagDataInFuture({
      currentDate: getCurrentDate(),
      hedges: hedgesInRange,
      resolution: selectedResolution,
    });

    return {
      hedges: hedgesWithFutureFlag,
      graphHedges: hedgesInRange,
      visibleProducts,
    };
  };

  const renderHedgeReport = ({ data }: { data: GetHedgesQuery }) => {
    const dataInCorrectResolution = data.organization.hedgeReport.find(
      (res) => res.resolution === selectedResolution,
    );
    if (!dataInCorrectResolution) {
      return <EmptyChartAndTable />;
    }

    const { hedges, graphHedges, visibleProducts } = applyFilters(
      dataInCorrectResolution,
    );

    return (
      <div className={styles.hedgeChart}>
        <HedgeChart
          data={graphHedges}
          unit={selectedUnit}
          dataResolution={selectedResolution}
        />
        <HedgeReportTable
          unit={selectedUnit}
          data={hedges}
          header={visibleProducts ? visibleProducts.join(", ") : ""}
          className={styles.hedgeReportTable}
          dataResolution={selectedResolution}
        />
      </div>
    );
  };

  const filterProps = {
    portfolioReportConfigurations,
    selectedConfigurationIds,
    handleConfigurationChange,
    pointInTime: getCurrentDate(),
    setPointInTime,
    range: {
      from: parseJSON(range.from),
      to: range.to ? parseJSON(range.to) : undefined,
    },
    setRange,
    setSelectedUnit,
    setSelectedResolution,
    setSelectedImportedPosition,
    comment1,
    setComment1,
    comment2,
    setComment2,
    handleProductChange,
    products,
    selectedProduct,
    handleCurrenciesChange,
    importedPositionOptions,
    selectedImportedPosition,
    selectedUnit,
    selectedResolution,
    organizationId,
    getSelectedImportedPositionValue,
    selectedCurrencies,
    resetToDefaultState,
  };
  return (
    <div className={financialStyles.tabContent}>
      <HedgeReportFilter {...filterProps} />
      <AppQuery
        query={GET_HEDGES}
        variables={{
          organizationId,
          pointInTime: formatDate(getCurrentDate(), "yyyy-MM-dd"),
          portfolioConfigurationIds: selectedConfigurationIds,
          productName: selectedProduct?.name,
          currencies: selectedCurrencies,
          importedPosition: getSelectedImportedPositionValue(),
          comment1: comment1.map((c) => c.value),
          comment2: comment2.map((c) => c.value),
        }}
        renderNoContent={EmptyChartAndTable}
        renderError={renderErrorChartAndTable}
        renderLoading={renderLoadingChartAndTable}
      >
        {(data: GetHedgesQuery) => renderHedgeReport({ data })}
      </AppQuery>
    </div>
  );
}
