import React from "react";
import PropTypes from "prop-types";

import styles from "./slideInFromRight.module.scss";

import Transition from "./transition";

const SlideInFromRight = ({ children, ...props }) => (
  <Transition classNames={{ ...styles }} {...props}>
    {children}
  </Transition>
);

SlideInFromRight.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default SlideInFromRight;
