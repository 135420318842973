import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import auth from "/src/services/customAuth";

const AuthCallback = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const urlParams = new URLSearchParams(location.hash.replace("#", "?"));

  const previousLocation = urlParams.get("state");

  useEffect(() => {
    if (urlParams.get("error")) {
      navigate(`/error${location.hash}`, { replace: true });
    } else {
      auth.extractAndStoreToken(location.hash);
      navigate(previousLocation || "/", { replace: true });
    }
  });

  return null;
};

export default AuthCallback;
