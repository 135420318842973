import React from "react";
import { useNavigate, useMatch } from "react-router-dom";

import { GET_ARTICLE } from "/src/services/apollo/queries";

import { OrganizationAppQuery } from "/src/components/appQuery";
import ErrorPage from "/src/modules/error-page";
import Article from "./article";

const ArticleContainer = () => {
  const navigate = useNavigate();
  const { params, pathname } = useMatch("/article/:id");
  let filterType = "Article";

  if (pathname.includes("video")) {
    filterType = "VideoArticle";
  } else if (pathname.includes("webinar")) {
    filterType = "WebinarArticle";
  }

  return (
    <OrganizationAppQuery
      query={GET_ARTICLE}
      variables={{
        id: params.id,
        filterType,
      }}
      renderNoContent={ErrorPage}
    >
      {({ organization: { article = {} } }) => {
        return <Article article={article} onBackClick={() => navigate(-1)} />;
      }}
    </OrganizationAppQuery>
  );
};

export default ArticleContainer;
