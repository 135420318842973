import React from "react";
import PropTypes from "prop-types";

import { hasAccess } from "./accessCheck";
import { useGetCurrentUser } from "src/modules/userProvider";

const evaluateRestrictions = (restrictions, operator, currentUser) => {
  if (restrictions.some((r) => r.type === "ModityAdmin")) {
    return currentUser.isModityAdmin;
  }

  const userClaims = currentUser.selectedOrganizationConnection.role.claims;
  if (operator === "and") {
    if (restrictions.every((r) => hasAccess(r, userClaims))) {
      return true;
    }
  } else if (operator === "or") {
    if (restrictions.some((r) => hasAccess(r, userClaims))) {
      return true;
    }
  }

  return false;
};

const AccessControl = ({ children, restriction, operator, fallback }) => {
  const currentUser = useGetCurrentUser();

  const restrictions = Array.isArray(restriction) ? restriction : [restriction];

  return evaluateRestrictions(restrictions, operator, currentUser)
    ? children
    : fallback;
};

AccessControl.defaultProps = {
  operator: "and",
  fallback: null,
};

AccessControl.propTypes = {
  children: PropTypes.node,
  restriction: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string.isRequired,
        permission: PropTypes.string.isRequired,
      })
    ).isRequired,
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      permission: PropTypes.string.isRequired,
    }).isRequired,
  ]),
  operator: PropTypes.oneOf(["and", "or"]),
};

export default AccessControl;
