import { gql } from "@apollo/client";

export const Total = gql`
  fragment Total on PortfolioMonthlyTotal {
    id
    month
    volume
    amount
    fee
    totalAmount
    unitPrice
    financialOutcome
    financialVolume
  }
`;
