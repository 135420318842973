import { Dispatch, SetStateAction, useState } from "react";
import sjson from "secure-json-parse";

function getValue<T>(
  stickyValue: string | null,
  defaultValue: T,
  validator?: (value: T) => boolean,
) {
  if (
    stickyValue === null ||
    stickyValue === undefined ||
    stickyValue === "undefined"
  ) {
    return defaultValue;
  }
  const item: T = sjson.parse(stickyValue);
  if (!validator) {
    return item;
  }

  const returnValue = validator(item) ? item : defaultValue;
  return returnValue;
}

export function useStickyState<T>(
  defaultValue: T,
  key: string,
  validator?: (value: T) => boolean,
): [T, Dispatch<SetStateAction<T>>] {
  const [value, setValue] = useState<T>(() => {
    const stickyValue = localStorage.getItem(key);

    const returnValue = getValue(stickyValue, defaultValue, validator);
    if (returnValue != null) {
      localStorage.setItem(key, JSON.stringify(returnValue));
    }
    return returnValue;
  });

  const decoratedSetValue = (value: SetStateAction<T>) => {
    localStorage.setItem(key, JSON.stringify(value));
    setValue(value);
  };

  return [value, decoratedSetValue];
}
