import * as React from "react";
const SvgHistory = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={24}
    height={24}
    {...props}
  >
    <defs>
      <path
        id="history_svg__a"
        d="M12.59 6.852h1.728v4.928l4.004 2.464-.85 1.4-4.882-2.996V6.852zM13.714 1c1.865 0 3.593.476 5.184 1.428a10.378 10.378 0 0 1 3.703 3.78A10.445 10.445 0 0 1 24 11.5a10.44 10.44 0 0 1-1.399 5.292 10.378 10.378 0 0 1-3.703 3.78A9.914 9.914 0 0 1 13.714 22a9.97 9.97 0 0 1-3.977-.812 10.46 10.46 0 0 1-3.264-2.24l1.618-1.708a8.063 8.063 0 0 0 2.524 1.764c.987.43 2.02.644 3.1.644a7.748 7.748 0 0 0 4.031-1.092 8.067 8.067 0 0 0 2.908-2.94c.731-1.25 1.097-2.623 1.097-4.116 0-1.493-.366-2.865-1.097-4.116a8.067 8.067 0 0 0-2.908-2.94 7.748 7.748 0 0 0-4.032-1.092 7.748 7.748 0 0 0-4.032 1.092 8.136 8.136 0 0 0-2.88 2.94c-.713 1.25-1.07 2.623-1.07 4.116h3.43l-4.609 4.704-.11-.168L0 11.5h3.429c0-1.904.466-3.668 1.398-5.292a10.378 10.378 0 0 1 3.703-3.78A9.914 9.914 0 0 1 13.714 1z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="history_svg__b" fill="#fff">
        <use xlinkHref="#history_svg__a" />
      </mask>
      <use fill="#000" fillRule="nonzero" xlinkHref="#history_svg__a" />
      <g fill="#F08517" fillRule="nonzero" mask="url(#history_svg__b)">
        <path d="M0 0h24v24H0z" />
      </g>
    </g>
  </svg>
);
export default SvgHistory;
