import React from "react";
import PropTypes from "prop-types";
import { eachMonthOfInterval, subMonths, getYear } from "date-fns";

import { useTranslation } from "src/translations/translationProvider";
import ResultCard from "/src/components/resultCard";

const LoadingSummaryCards = ({ className, cardClassName }) => {
  const { formatDate } = useTranslation();
  const now = new Date();
  const twelveMonthsStartingWithCurrent = eachMonthOfInterval({
    start: subMonths(now, 11),
    end: now,
  })
    .map((month) => ({
      month: formatDate(month, "MMMM"),
      year: getYear(month),
    }))
    .reverse();

  return (
    <div className={className}>
      {twelveMonthsStartingWithCurrent.map(({ month, year }) => (
        <ResultCard
          key={month}
          className={cardClassName}
          year={year}
          month={month}
          loading={true}
        />
      ))}
    </div>
  );
};

LoadingSummaryCards.propTypes = {
  className: PropTypes.string,
  cardClassName: PropTypes.string,
};

export default LoadingSummaryCards;
