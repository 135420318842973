import classNames from "classnames";
import { format } from "date-fns";

import { useQuery } from "src/services/apollo/hooks/useOrganizationQuery";
import { useTranslation } from "src/translations/translationProvider";

import ControlHeader from "src/components/controlHeader";
import Button from "src/components/button";
import Select, { MultiSelect } from "src/components/select";
import { MonthPicker } from "src/components/calendar";
import RadioButtonList from "src/components/radioButtonList";
import HedgeReportExcelExport from "./hedgeReportExcelExport.jsx";
import HedgeRangePicker from "./hedgeRangePicker";
import PortfolioConfigurationSelect from "../portfolioConfigurationSelect";
import LoadingSpinner from "src/components/loadingSpinner";
import { loadVisibleColumns } from "./visibleColumnsStore";

import financialStyles from "../financial.module.scss";
import adminStyles from "src/styles/admin.module.scss";
import styles from "./hedgeReport.module.scss";
import {
  RESOLUTION,
  RESOLUTION_LIST,
  UNIT,
  UNIT_LIST,
} from "src/modules/portfolio/financial/hedgeReport/hedgeUtils";
import { GetCommentsDocument, GetProductsQuery } from "src/graphql/graphql";
import { ElementArrayType } from "src/utilities/typescriptHelpers";
import { LanguageKey } from "src/config/translations/translationsTypes";

interface filterProps {
  portfolioReportConfigurations: {
    id: string;
    name: string;
    type: string;
  }[];
  selectedConfigurationIds: string[];
  handleConfigurationChange: (selection: string[]) => void;
  pointInTime: Date;
  setPointInTime: React.Dispatch<React.SetStateAction<Date | null>>;
  range: {
    from: Date;
    to: Date | undefined;
  };
  setRange: React.Dispatch<
    React.SetStateAction<{
      to?: Date;
      from: Date;
    }>
  >;
  setSelectedUnit: React.Dispatch<React.SetStateAction<UNIT>>;
  setSelectedResolution: React.Dispatch<React.SetStateAction<RESOLUTION>>;
  setSelectedImportedPosition: React.Dispatch<
    React.SetStateAction<
      | {
          value: string;
          label: string;
        }
      | undefined
    >
  >;
  comment1: {
    value: string;
  }[];
  setComment1: React.Dispatch<
    React.SetStateAction<
      {
        value: string;
      }[]
    >
  >;
  comment2: {
    value: string;
  }[];
  setComment2: React.Dispatch<
    React.SetStateAction<
      {
        value: string;
      }[]
    >
  >;
  handleProductChange: (selection: { label: string; value: string }) => void;
  products: GetProductsQuery["organization"]["products"];
  selectedProduct: ElementArrayType<
    GetProductsQuery["organization"]["products"]
  >;
  handleCurrenciesChange: (
    selection: {
      label: string;
      value: string;
    }[],
  ) => void;
  importedPositionOptions: {
    value: string;
    label: string;
  }[];
  selectedImportedPosition:
    | {
        value: string;
        label: string;
      }
    | undefined;
  selectedUnit: UNIT;
  selectedResolution: RESOLUTION;
  organizationId: string;
  getSelectedImportedPositionValue: () => number;
  selectedCurrencies: string[];
  resetToDefaultState: () => void;
}

export function HedgeReportFilter({
  portfolioReportConfigurations,
  selectedConfigurationIds,
  handleConfigurationChange,
  pointInTime,
  setPointInTime,
  range,
  setRange,
  setSelectedUnit,
  setSelectedResolution,
  setSelectedImportedPosition,
  comment1,
  setComment1,
  comment2,
  setComment2,
  handleProductChange,
  products,
  selectedProduct,
  handleCurrenciesChange,
  importedPositionOptions,
  selectedImportedPosition,
  selectedUnit,
  selectedResolution,
  organizationId,
  getSelectedImportedPositionValue,
  selectedCurrencies,
  resetToDefaultState,
}: filterProps) {
  const { translate } = useTranslation();
  const STORAGE_PREFIX = `hedge-report-${organizationId}-`;

  const productName = selectedProduct?.name;
  const currencies = selectedProduct?.currencies;

  const makeDropdownOptionItems = ({
    options,
    shouldTranslate,
  }: {
    options: string[];
    shouldTranslate: boolean;
  }) => {
    if (!options) {
      return [];
    }
    return options.map((item) => ({
      label: shouldTranslate ? translate(item as LanguageKey) : item,
      value: item,
    }));
  };

  const resetFilters = () => {
    Object.keys({ ...sessionStorage })
      .filter((key) => key.startsWith(STORAGE_PREFIX))
      .forEach((key) => sessionStorage.removeItem(key));
    resetToDefaultState();
  };

  const { loading, error, data } = useQuery(GetCommentsDocument, {
    variables: {
      organizationId,
      currencies: selectedCurrencies,
      portfolioConfigurationIds: selectedConfigurationIds,
      productName: productName,
      importedPosition: getSelectedImportedPositionValue(),
    },
  });

  if (loading) {
    return <LoadingSpinner />;
  }

  if (error || !data) {
    return <div>{translate("UnexpectedError")}</div>;
  }

  const {
    comments: { comment1Values, comment2Values },
  } = data.organization;

  const comment1List = comment1Values.map((c) => ({ label: c, value: c }));
  const comment2List = comment2Values.map((c) => ({ label: c, value: c }));

  const shouldDisplayCommentFilters =
    comment1List.length > 0 || comment2List.length > 0;

  const getColumnFilter = () => {
    const visibleColumns = loadVisibleColumns();
    return (
      visibleColumns &&
      Object.keys(visibleColumns).filter((k) => visibleColumns[k])
    );
  };

  return (
    <ControlHeader>
      <div className={styles.filterContent}>
        <div className={styles.filterCategory}>
          <h3>{translate("Filter")}</h3>
          <div className={styles.filters}>
            <div className={financialStyles.dropdown}>
              {translate("Portfolios")}:
              <PortfolioConfigurationSelect
                availableConfigurations={portfolioReportConfigurations}
                selectedConfigurationIds={selectedConfigurationIds}
                setSelectedConfigurationIds={handleConfigurationChange}
                showGroups
              />
            </div>
            <div className={financialStyles.dropdown}>
              {translate("Until")}:
              <MonthPicker
                className={"hello"}
                dataTestId={"hjello"}
                month={pointInTime}
                maxDate={new Date()}
                key={JSON.stringify(selectedConfigurationIds)}
                triggerClassName={classNames(
                  adminStyles.trigger,
                  financialStyles.trigger,
                )}
                onChange={(value) => setPointInTime(new Date(value))}
              />
            </div>
            <div className={financialStyles.dropdown}>
              {translate("Range")}:
              <HedgeRangePicker
                from={range.from || pointInTime}
                to={range?.to ? range?.to : undefined}
                minDetail={"decade"}
                maxDetail={"decade"}
                triggerClassName={classNames(
                  adminStyles.trigger,
                  financialStyles.trigger,
                )}
                onChange={(values) => {
                  setRange({
                    from: values[0],
                    to: values[1] || values[0],
                  });
                }}
              />
            </div>
            <div className={financialStyles.dropdown}>
              {translate("Product")}:
              <Select
                key={JSON.stringify(products)}
                isWithCaret
                triggerClassName={classNames(
                  adminStyles.trigger,
                  financialStyles.trigger,
                )}
                wrapperClassName={financialStyles.selectWrapper}
                options={makeDropdownOptionItems({
                  options: products.map((product) => product.name),
                  shouldTranslate: false,
                })}
                value={{
                  label: productName,
                  value: productName,
                }}
                onChange={handleProductChange}
              />
            </div>
            <div
              data-test-id={`currency-item-${currencies}`}
              className={financialStyles.dropdown}
            >
              {translate("Currency")}:
              <MultiSelect
                key={productName}
                isWithCaret
                showSelectedCount
                triggerClassName={classNames(
                  adminStyles.trigger,
                  financialStyles.trigger,
                )}
                wrapperClassName={financialStyles.selectWrapper}
                options={makeDropdownOptionItems({
                  options: currencies,
                  shouldTranslate: false,
                })}
                value={makeDropdownOptionItems({
                  options: selectedCurrencies,
                  shouldTranslate: false,
                })}
                onChange={handleCurrenciesChange}
              />
            </div>
            <div data-test-id="position" className={financialStyles.dropdown}>
              <Select
                isWithCaret
                triggerClassName={classNames(
                  adminStyles.trigger,
                  financialStyles.trigger,
                )}
                wrapperClassName={financialStyles.selectWrapper}
                options={importedPositionOptions}
                value={selectedImportedPosition}
                onChange={(value) => setSelectedImportedPosition(value)}
              />
            </div>
            {shouldDisplayCommentFilters && (
              <>
                <div className={financialStyles.dropdown}>
                  {`${translate("Comment")} 1:`}
                  <MultiSelect
                    showSelectAll
                    key={comment1List.length}
                    searchable
                    isWithCaret
                    showSelectedCount={comment1.length > 0}
                    triggerClassName={classNames(
                      adminStyles.trigger,
                      financialStyles.trigger,
                    )}
                    options={comment1List}
                    value={comment1}
                    onChange={(value) => setComment1(value)}
                  />
                </div>
                <div className={financialStyles.dropdown}>
                  {`${translate("Comment")} 2:`}
                  <MultiSelect
                    key={JSON.stringify(comment2List)}
                    showSelectAll
                    searchable
                    isWithCaret
                    showSelectedCount={comment2.length > 0}
                    triggerClassName={classNames(
                      adminStyles.trigger,
                      financialStyles.trigger,
                    )}
                    options={comment2List}
                    value={comment2}
                    onChange={(value) => setComment2(value)}
                  />
                </div>
              </>
            )}
          </div>
        </div>
        <div className={styles.filterCategory}>
          <h3>{translate("Visual")}</h3>
          <div className={styles.filters}>
            <div className={styles.radioButtons}>
              <RadioButtonList
                className={financialStyles.radioGroup}
                titleClassName={styles.unitTitle}
                optionClassName={styles.unitOption}
                activeOptionClassName={styles.activeUnit}
                options={UNIT_LIST.map((unit) => ({
                  text: unit,
                  value: unit,
                }))}
                selectedValue={selectedUnit}
                onChange={(unit) => setSelectedUnit(unit)}
              />
            </div>
            <div data-test-id="resolution" className={financialStyles.dropdown}>
              {translate("Resolution")}:
              <Select
                isWithCaret
                triggerClassName={classNames(
                  adminStyles.trigger,
                  financialStyles.trigger,
                )}
                wrapperClassName={financialStyles.selectWrapper}
                options={makeDropdownOptionItems({
                  options: Object.values(RESOLUTION_LIST),
                  shouldTranslate: true,
                })}
                value={{
                  label: translate(selectedResolution),
                  value: selectedResolution,
                }}
                onChange={(selection) => setSelectedResolution(selection.value)}
              />
            </div>
          </div>
        </div>
        <div className={styles.filterButtons}>
          <HedgeReportExcelExport
            className={styles.exportButton}
            organizationId={organizationId}
            args={{
              portfolioConfigurationIds: selectedConfigurationIds,
              importedPositionState: getSelectedImportedPositionValue(),
              pointInTime: format(pointInTime, "yyyy-MM-dd"),
              resolution: selectedResolution,
              commentFilter: {
                comment1: comment1.map((c) => c.value),
                comment2: comment2.map((c) => c.value),
              },
              fromDate: format(range.from, "yyyy-MM-dd"),
              toDate: range?.to && format(range.to, "yyyy-MM-dd"),
              unit: selectedUnit,
            }}
            getColumnFilter={getColumnFilter}
          />
          <Button className={styles.resetButton} small onClick={resetFilters}>
            {translate("Reset filter")}
          </Button>
        </div>
      </div>
    </ControlHeader>
  );
}
