import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import config from "/src/config/config";
import { useTranslation } from "src/translations/translationProvider";
import { Translate } from "src/translations/translate";
import VideoThumbnail from "/src/components/videoThumbnail";
import Image from "/src/components/image";

import { PinIcon , ArrowIcon } from "/src/icons";

import styles from "./articlePreview.module.scss";

const getThumbnailOrDefault = (thumbnail) => {
  const defaultImgUrl = config.defaultHeroThumbnail;
  return thumbnail && thumbnail.fileUrl
    ? {
        desktop: thumbnail.fileUrl,
        tablet: thumbnail.fileUrl,
        mobile: thumbnail.fileUrl,
      }
    : {
        desktop: defaultImgUrl,
        tablet: defaultImgUrl,
        mobile: defaultImgUrl,
      };
};

const ArticlePreview = ({
  author,
  className,
  videoId,
  heroThumbnail,
  preamble,
  publishedDate,
  title,
  compact,
  isFeatured,
}) => {
  const { formatDate } = useTranslation();
  const thumbnail = getThumbnailOrDefault(heroThumbnail);
  return (
    <div
      data-test-id="article-preview-landingpage"
      className={classNames(styles.articleWrapper, className, {
        [styles.desktopView]: compact,
      })}
    >
      <div className={styles.image} data-test-id="article-icon-landingpage">
        {videoId ? (
          <VideoThumbnail videoId={videoId} />
        ) : (
          <Image responsiveImage={thumbnail}>
            {isFeatured && (
              <div className={styles.iconWrapper}>
                <PinIcon className="icon" />
              </div>
            )}
          </Image>
        )}
      </div>
      <article className={styles.articlePreview}>
        <header className={styles.header}>
          <p className={styles.title}>{title}</p>
        </header>
        <div className={styles.dateAndAuthor}>
          {publishedDate && (
            <span className={styles.date}>
              {formatDate(new Date(publishedDate), "d MMMM yyyy")}
            </span>
          )}
          <span className={styles.authorAndDateSeparator} />
          <span className={styles.author}>
            {author && author.firstName} {author && author.lastName}
          </span>
        </div>
        <div className={styles.preamble}>
          <p>{preamble}</p>
        </div>
        <footer className={styles.footer}>
          <div className={styles.sectionLink}>
            <Translate>Read entry</Translate>
            <ArrowIcon className={styles.arrowIcon} />
          </div>
        </footer>
      </article>
    </div>
  );
};

ArticlePreview.propTypes = {
  author: PropTypes.object,
  className: PropTypes.string,
  date: PropTypes.string,
  heroThumbnail: PropTypes.object,
  onBookmarkClick: PropTypes.func,
  preamble: PropTypes.string,
  publishedDate: PropTypes.string,
  title: PropTypes.string,
  videoId: PropTypes.string,
  showBookmark: PropTypes.bool,
  compact: PropTypes.bool,
  isFeatured: PropTypes.bool,
};

ArticlePreview.defaultProps = {
  onBookmarkClick: () => {},
  showBookmark: true,
  compact: false,
  isFeatured: false,
};

export default ArticlePreview;
