import React from "react";
import { Helmet } from "react-helmet";

import { useTranslation } from "src/translations/translationProvider";
import SplitLayout from "/src/components/splitLayout";

import Files from "./files";

const FilesContainer = () => {
  const { translate } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{`Modity - ${translate("Menu_Files")}`}</title>
      </Helmet>
      <SplitLayout mainContent={<Files />} />
    </>
  );
};

export default FilesContainer;
