import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import classNames from "classnames";

import styles from "./snackbar.module.scss";

const snackbarHeight = 74;
const spacingBetweenSnackbars = 8;

const Snackbar = ({ className, open, message, action, index }) =>
  ReactDOM.createPortal(
    <div
      data-test-id="snackbar"
      style={{
        bottom: `${index * (snackbarHeight + spacingBetweenSnackbars)}px`,
      }}
      className={classNames(
        styles.snackbar,
        { [styles.open]: open },
        className
      )}
    >
      <div className={styles.message}>{message}</div>
      <div className={styles.action}>{action}</div>
    </div>,
    document.body
  );

Snackbar.propTypes = {
  className: PropTypes.string,
  open: PropTypes.bool,
  message: PropTypes.string.isRequired,
  action: PropTypes.node,
  index: PropTypes.number,
};

export default Snackbar;
