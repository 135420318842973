import * as React from "react";
const SvgPin = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" {...props}>
    <path
      fill="#FFF"
      d="m4.048 17.22 3.549-3.549-3.278-3.294a.514.514 0 0 1-.16-.414.549.549 0 0 1 .207-.398A5.623 5.623 0 0 1 6.96 8.387a5.605 5.605 0 0 1 2.785.191l3.422-2.705a2.99 2.99 0 0 1 .477-1.75.52.52 0 0 1 .398-.231.511.511 0 0 1 .43.15l3.485 3.486a.51.51 0 0 1 .151.43.475.475 0 0 1-.23.382 2.933 2.933 0 0 1-1.751.493l-2.705 3.422a5.57 5.57 0 0 1 .183 2.793 5.694 5.694 0 0 1-1.186 2.602.548.548 0 0 1-.398.206.514.514 0 0 1-.414-.159L8.33 14.403l-3.55 3.549a.512.512 0 0 1-.374.16.499.499 0 0 1-.366-.152.499.499 0 0 1-.151-.366c0-.143.053-.268.159-.374z"
    />
  </svg>
);
export default SvgPin;
