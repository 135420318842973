import { gql } from "@apollo/client";

export const GET_FINANCIAL_DATA = gql`
  query getFinancialData(
    $organizationId: String!
    $startDate: String = "2018-01-01"
  ) {
    organization(id: $organizationId) {
      id
      elcStartDate
      goStartDate
      portfolioReportConfigurations {
        id
        name
        measurandIds
        type
      }
      indexOrders(startDate: $startDate) {
        portfolioId
        portfolioName
        positionType
        product
        currency
        hedgingStartDate
        hedgingEndDate
        tradingStartDate
        tradingEndDate
        status
        forecastVolume
        targetVolume
        hedgedVolume
        price
        createdDate
        hedgedPercentageOfTarget
        hedgedPercentageOfForecast
      }
    }
  }
`;
