import { gql } from "@apollo/client";

export const BalanceControllerStandards = gql`
  fragment BalanceControllerStandards on EnergyMonthlyStandardsSummary {
    id
    consumptionHourlyPriceArea1
    consumptionHourlyPriceArea2
    consumptionHourlyPriceArea3
    consumptionHourlyPriceArea4
    consumptionMonthly
    productionHourlyPriceArea1
    productionHourlyPriceArea2
    productionHourlyPriceArea3
    productionHourlyPriceArea4
  }
`;
