import React from "react";
import classNames from "classnames";

import styles from "./dailyNotification.module.scss";

import DailyNotification from "./dailyNotification";

const ExtraNotification = ({ className, ...props }) => {
  return (
    <DailyNotification
      className={classNames(styles.extraNotification, className)}
      {...props}
    />
  );
};

ExtraNotification.propTypes = DailyNotification.propTypes;

export default ExtraNotification;
