import React, { useState, useEffect, forwardRef } from "react";
import classNames from "classnames";

import Input from "../input";
import { WarningIcon } from "/src/icons";

import styles from "./validatedInput.module.scss";

const ValidatedInput = forwardRef(
  ({ value, label, inputClassName, className, validate, ...props }, ref) => {
    const [isValid, setIsValid] = useState(true);
    const [errorMsg, setErrorMsg] = useState([]);
    const validationFunctions = Array.isArray(validate) ? validate : [validate];

    // reset validation when value changes
    useEffect(() => {
      setIsValid(true);
      setErrorMsg([]);
    }, [value]);

    const validateInput = () => {
      const errors = validationFunctions
        .map(({ validationFunction, errorMessage }) => {
          if (!validationFunction(value)) {
            return errorMessage;
          }
          return null;
        })
        .filter((e) => e !== null);
      setIsValid(errors.length === 0);
      setErrorMsg(errors);
    };

    return (
      <div className={className}>
        <Input
          inputClassName={classNames(inputClassName, styles.input, {
            [styles.invalid]: !isValid,
          })}
          value={value}
          label={label}
          {...props}
          onBlur={validateInput}
          ref={ref}
        />
        {!isValid &&
          errorMsg.length > 0 &&
          errorMsg.map((e, i) => (
            <div key={i} className={styles.error}>
              <WarningIcon className={classNames("icon", styles.icon)} />
              <span className={styles.message}>{e}</span>
            </div>
          ))}
      </div>
    );
  }
);

ValidatedInput.displayName = "ValidatedInput";

ValidatedInput.propTypes = {
  ...Input.propTypes,
};

export default ValidatedInput;
