import { GetHedgesQuery } from "src/graphql/graphql";
import { endOfMonth, endOfQuarter, endOfYear } from "date-fns";
import { LanguageKey } from "src/config/translations/translationsTypes";

export type UNIT = (typeof UNIT_LIST)[number];
export type RESOLUTION = (typeof RESOLUTION_LIST)[keyof typeof RESOLUTION_LIST];
export const UNIT_LIST = ["MWh", "MW"] as const;
export const RESOLUTION_LIST = {
  MONTHLY: "Monthly",
  QUARTERLY: "Quarterly",
  YEARLY: "Yearly",
} as const;

export function convertHedgeToMW(
  hedge: GetHedgesQuery["organization"]["hedgeReport"][number]["hedges"],
) {
  return hedge.map((row) => {
    const {
      settlementUnits,
      forecast,
      hedgeVolume,
      mandateMaxVolume,
      mandateMinVolume,
      openVolume,
      targetVolume,
      utilizedVolume,
    } = row;
    return {
      ...row,
      forecast: convertVolumeToMW(settlementUnits, forecast),
      hedgeVolume: convertVolumeToMW(settlementUnits, hedgeVolume),
      mandateMaxVolume: convertVolumeToMW(settlementUnits, mandateMaxVolume),
      mandateMinVolume: convertVolumeToMW(settlementUnits, mandateMinVolume),
      openVolume: convertVolumeToMW(settlementUnits, openVolume),
      targetVolume: convertVolumeToMW(settlementUnits, targetVolume),
      utilizedVolume: convertVolumeToMW(settlementUnits, utilizedVolume),
    };
  });
}

function convertVolumeToMW(hoursThisMonth: number, volume: number): number;
function convertVolumeToMW(
  hoursThisMonth: number,
  volume: number | null,
): number | null;

function convertVolumeToMW(
  hoursThisMonth: number,
  volume: number | null,
): number | null {
  if (volume === null) {
    return null;
  }
  return volume / hoursThisMonth;
}

export const flagDataInFuture = ({
  currentDate,
  hedges,
  resolution,
}: {
  currentDate: Date;
  hedges: GetHedgesQuery["organization"]["hedgeReport"][number]["hedges"];
  resolution: (typeof RESOLUTION_LIST)[keyof typeof RESOLUTION_LIST];
}) => {
  const endOfPeriod = (date: Date) => {
    switch (resolution) {
      case RESOLUTION_LIST.MONTHLY:
        return endOfMonth(date);

      case RESOLUTION_LIST.QUARTERLY:
        return endOfQuarter(date);

      case RESOLUTION_LIST.YEARLY:
        return endOfYear(date);
    }
  };

  return hedges.map((h) => ({
    ...h,
    preliminary: endOfPeriod(new Date(h.period)) > new Date(currentDate),
  }));
};

export function getImportedPositionOptions(
  translate: (value: LanguageKey) => string,
) {
  return [
    { value: "1", label: translate("Without imported position") },
    { value: "2", label: translate("With imported position") },
    { value: "3", label: translate("Only imported position") },
  ];
}

export function convertResolutionToMonth(
  resolution: (typeof RESOLUTION_LIST)[keyof typeof RESOLUTION_LIST],
) {
  return resolution.slice(0, -2).toLowerCase();
}
