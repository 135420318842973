import { PlaceholderChart, PlaceholderChartText } from "src/components/chart";
import { useTranslation } from "src/translations/translationProvider";
import styles from "./hedgeReport.module.scss";
import HedgeReportTable from "src/modules/portfolio/financial/hedgeReport/hedgeReportTable";

export function EmptyChartAndTable() {
  const { translate } = useTranslation();
  return (
    <div className={styles.hedgeChart}>
      <PlaceholderChart className={styles.hedgeChart}>
        <PlaceholderChartText>
          {translate("There is nothing to show")}
        </PlaceholderChartText>
      </PlaceholderChart>
      <HedgeReportTable data={[]} />
    </div>
  );
}
