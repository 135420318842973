import React, { useState } from "react";

import GoWithoutService from "./goWithoutService";
import { useOrganizationQuery } from "services/apollo/hooks/useOrganizationQuery";
import { GET_GO_WITHOUT_SERVICE } from "services/apollo/queries";
import LoadingSpinner from "components/loadingSpinner";
import { useTranslation } from "src/translations/translationProvider";
import { Translate } from "src/translations/translate";
import goProductionTypes from "constants/goProductionTypes";
import ControlHeader, { ControlHeaderItem } from "components/controlHeader";
import { MultiSelect } from "components/select";

import greenProductStyles from "../greenProducts.module.scss";

const GoWithoutServiceContainer = () => {
  const { locale } = useTranslation();
  const MAX_SELECTIONS = goProductionTypes.length;

  const productionTypesItems = goProductionTypes
    .sort((a, b) => a.text.localeCompare(b.text, locale))
    .map(({ text, value }, i) => {
      return {
        label: <span key={i}>{text}</span>,
        value,
      };
    });

  const [selectedProductionTypes, setSelectedProductionTypes] = useState(
    goProductionTypes.map((type) => type.value)
  );

  const { data, loading, error } = useOrganizationQuery(
    GET_GO_WITHOUT_SERVICE,
    {
      variables: {
        productionTypes: selectedProductionTypes,
      },
    }
  );

  if (loading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return (
      <div>
        <Translate>UnexpectedError</Translate>
      </div>
    );
  }

  const { goWithoutService } = data.organization;

  return (
    <>
      <ControlHeader>
        <ControlHeaderItem className={greenProductStyles.headerItem}>
          <Translate>Production Types</Translate>:
          <MultiSelect
            showSelectAll
            value={productionTypesItems.filter((item) =>
              selectedProductionTypes.find((type) => type === item.value)
            )}
            showSelectedCount
            isWithCaret
            options={productionTypesItems}
            onChange={(values) =>
              setSelectedProductionTypes(values.map((v) => v.value))
            }
            maxSelections={MAX_SELECTIONS}
          />
        </ControlHeaderItem>
      </ControlHeader>
      <GoWithoutService tableData={goWithoutService} />
    </>
  );
};

export default GoWithoutServiceContainer;
