import { gql } from "@apollo/client";

export const GET_USER = gql`
  query getUser($organizationId: String!, $userId: String!) {
    organization(id: $organizationId) {
      id
      userConnection(userId: $userId) {
        user {
          id
          firstName
          lastName
          email
          showDailyComment
          phoneNumber
          avatar {
            fileUrl
          }
          active
          organizationCount
        }
        role {
          id
          name
        }
      }
    }
  }
`;
