import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import styles from "./button.module.scss";
import { CrossIcon } from "/src/icons";
import { CheckIcon } from "/src/icons";
import BUTTON_STATE from "./buttonState";
import { FadeIn } from "/src/components/transitions";

export const ButtonStateIndicator = ({ state, statusText, type }) => {
  return (
    <div className={styles.buttonStateIndicator}>
      <FadeIn mountOnEnter unmountOnExit in={state === BUTTON_STATE.LOADING}>
        <Spinner type={type} />
      </FadeIn>
      <FadeIn mountOnEnter unmountOnExit in={state === BUTTON_STATE.SUCCESS}>
        <SuccessIndicator statusText={statusText} />
      </FadeIn>
      <FadeIn mountOnEnter unmountOnExit in={state === BUTTON_STATE.FAIL}>
        <FailIndicator statusText={statusText} />
      </FadeIn>
    </div>
  );
};
ButtonStateIndicator.propTypes = {
  state: PropTypes.oneOf([
    BUTTON_STATE.DEFAULT,
    BUTTON_STATE.LOADING,
    BUTTON_STATE.SUCCESS,
    BUTTON_STATE.FAIL,
  ]),
  statusText: PropTypes.string,
  type: PropTypes.oneOf(["primary", "secondary", "danger"]),
};

const Spinner = ({ type }) => {
  return (
    <div className={classNames(styles.spinnerContainer, styles[type])}>
      <div className={classNames(styles.spinnerBorder, styles[type])}>
        <div className={classNames(styles.spinnerWhiteSpace, styles[type])} />
      </div>
    </div>
  );
};
Spinner.propTypes = {
  className: PropTypes.string,
};

const SuccessIndicator = ({ statusText }) => {
  return (
    <div className={classNames(styles.successIndicator)}>
      {<CheckIcon />} {statusText}
    </div>
  );
};
SuccessIndicator.propTypes = {
  statusText: PropTypes.string,
  className: PropTypes.string,
};

const FailIndicator = ({ statusText }) => {
  return (
    <div className={classNames(styles.failIndicator)}>
      {<CrossIcon />} {statusText}
    </div>
  );
};
FailIndicator.propTypes = {
  statusText: PropTypes.string,
  className: PropTypes.string,
};
