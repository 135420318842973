import { gql } from "@apollo/client";

import { Total } from "/src/services/apollo/fragments";

export const GET_PORTFOLIO_TOTAL_SUMMARIES = gql`
  query getTotalSummaries($organizationId: String!) {
    organization(id: $organizationId) {
      id
      portfolio {
        id
        summaries {
          id
          year
          currency
          consumption {
            ...Total
          }
          production {
            ...Total
          }
        }
      }
    }
  }
  ${Total}
`;
