import React from "react";
import PropTypes from "prop-types"  ;

import { useOrganizationQuery } from "/src/services/apollo/hooks/useOrganizationQuery";
import { GET_SPOT_PRICES } from "/src/services/apollo/queries";

import Chart, {
  LoadingChart,
  PlaceholderChart,
  PlaceholderChartText,
} from "/src/components/chart";

import { Translate } from "src/translations/translate";
import { useTranslation } from "src/translations/translationProvider";
import styles from "./plant.module.scss";

const SpotPriceChart = ({ className, from, to, plantId }) => {
  const { translate, formatDate } = useTranslation();
  const { loading, error, data } = useOrganizationQuery(GET_SPOT_PRICES, {
    variables: {
      plantId,
      currency: "Eur",
      fromDate: formatDate(new Date(from), "yyyy-MM-dd"),
      toDate: formatDate(new Date(to), "yyyy-MM-dd"),
    },
  });

  if (loading) {
    return (
      <>
        <LoadingChart className={className} key="spot price chart loading" />
      </>
    );
  }

  if (error) {
    return (
      <PlaceholderChart className={className} key="spot price chart error">
        <PlaceholderChartText>
          <Translate>No data found</Translate>
        </PlaceholderChartText>
      </PlaceholderChart>
    );
  }

  const {
    organization: {
      plant: { spotPrices },
    },
  } = data;

  if (!spotPrices) {
    return (
      <PlaceholderChart className={className} key="spot price chart error">
        <PlaceholderChartText>
          <Translate>No data found</Translate>
        </PlaceholderChartText>
      </PlaceholderChart>
    );
  }

  const chartData = {
    label: spotPrices.area,
    fill: false,
    borderColor: "#2589f1",
    borderWidth: "2",
    backgroundColor: "#2589f1",
    pointRadius: 0,
    pointHitRadius: 16,
    borderJoinStyle: "bevel",
    data: spotPrices.hourlyPrices.map((hour) => ({
      x: new Date(hour.date),
      y: parseFloat(hour.price),
    })),
  };

  return (
    <>
      <Chart
        className={className}
        data={{ datasets: [chartData] }}
        options={{
          xMin: from,
          xMax: to,
          yLabel: String(spotPrices.currency).toUpperCase(),
        }}
      />
      <div className={styles.chartInfo}>
        <span>{`${translate("Source")}: Nord Pool`}</span>
      </div>
    </>
  );
};

SpotPriceChart.propTypes = {
  className: PropTypes.string,
  from: PropTypes.object,
  to: PropTypes.object,
  plantId: PropTypes.string,
};

export default SpotPriceChart;
