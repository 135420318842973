import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

import { useTranslation } from "src/translations/translationProvider";
import Card from "/src/components/card";
import { isMobile } from "/src/components/responsive";
import CardRow from "/src/components/resultCard/cardRow";
import { formatNumber } from "/src/utilities";

import styles from "./spotPriceCard.module.scss";
import globalStyles from "/src/styles/global.module.scss";

const SpotPriceCard = ({
  className,
  titleClass,
  area,
  unit,
  spotPrices,
  testDataId,
  weather,
  isLoading,
}) => {
  const { locale, formatDateRelative } = useTranslation();

  const twoDigitsNumberOrLoading = (number) => {
    return formatNumber(number, locale) || "...";
  };

  const renderWeather = (weather) => {
    if (!weather || !weather.temperature || !weather.wind) return;

    const { readings: temperatureReadings } = weather.temperature;
    const { readings: windReadings } = weather.wind;
    const temperature =
      temperatureReadings[temperatureReadings.length - 1].value ||
      temperatureReadings[temperatureReadings.length - 1].value === 0
        ? temperatureReadings[temperatureReadings.length - 1].value
        : "...";
    const wind =
      windReadings[windReadings.length - 1].value ||
      windReadings[windReadings.length - 1].value === 0
        ? windReadings[windReadings.length - 1].value
        : "...";

    return (
      <div className={styles.weather}>
        <div className={styles.temperature}>{`${temperature} °C`}</div>
        <div>{`${wind} m/s`}</div>
      </div>
    );
  };

  return (
    <Card
      className={classnames(className, styles.card, {
        [styles.loading]: isLoading,
      })}
      title={
        <div
          data-test-id={testDataId}
          className={classnames(
            titleClass,
            styles.header,
            styles.primaryColor,
            {
              [globalStyles.sectionTitleLarge]: isMobile(),
            }
          )}
        >
          <div>{area}</div>
          {weather && renderWeather(weather)}
        </div>
      }
      slotOne={
        spotPrices.length > 0 && (
          <CardRow
            title={
              <div className={styles.calendarDay}>
                {
                  formatDateRelative(
                    new Date(spotPrices[0].time),
                    new Date()
                  ).split(" ")[0]
                }
              </div>
            }
            value={twoDigitsNumberOrLoading(spotPrices[0].price)}
            unit={`${unit}/MWh`}
          />
        )
      }
      slotTwo={
        spotPrices.length > 1 && (
          <CardRow
            title={
              <div className={styles.calendarDay}>
                {
                  formatDateRelative(
                    new Date(spotPrices[1].time),
                    new Date()
                  ).split(" ")[0]
                }
              </div>
            }
            value={twoDigitsNumberOrLoading(spotPrices[1].price)}
            unit={`${unit}/MWh`}
          />
        )
      }
    />
  );
};

SpotPriceCard.propTypes = {
  className: PropTypes.string,
  titleClass: PropTypes.string,
  area: PropTypes.string,
  price: PropTypes.number,
  unit: PropTypes.string,
  testDataId: PropTypes.string,
  isLoading: PropTypes.bool,
  spotPrices: PropTypes.arrayOf(
    PropTypes.shape({
      price: PropTypes.number,
      time: PropTypes.string,
    })
  ),
  weather: PropTypes.shape({
    code: PropTypes.string,
    temperature: PropTypes.shape({
      readings: PropTypes.arrayOf(
        PropTypes.shape({
          time: PropTypes.string.isRequired,
          value: PropTypes.number,
        }).isRequired
      ).isRequired,
    }),
    wind: PropTypes.shape({
      readings: PropTypes.arrayOf(
        PropTypes.shape({
          time: PropTypes.string.isRequired,
          value: PropTypes.number,
        }).isRequired
      ).isRequired,
    }),
  }),
};

export default SpotPriceCard;
