import React from "react";
import propTypes from "prop-types";
import { parseISO, addMonths, isFuture } from "date-fns";

import { useTranslation } from "src/translations/translationProvider";
import { invoiceMaterialFormatter } from "./invoiceMaterials.utils";
import InvoiceMaterialItem from "./invoiceMaterialItem";
import ListContainer from "/src/components/listContainer";

import styles from "./invoiceMaterials.module.scss";

const DATE_FORMAT = "yyyy-MM-dd";

const InvoiceMaterials = ({
  financialMaterials,
  physicalMaterials,
  organizationId,
}) => {
  const { formatDate, translate } = useTranslation();

  const invoiceMaterials = financialMaterials
    .map((m) => ({ ...m, insertionDate: parseISO(m.insertionDate) }))
    .concat(
      physicalMaterials.map((m) => ({
        ...m,
        isPhysical: true,
        insertionDate: addMonths(parseISO(m.month), 1), // Phys report months are really for the next month
        id: 0,
      })),
    )
    .filter((m) => !isFuture(m.insertionDate));

  const formattedInvoiceMaterials = invoiceMaterialFormatter(
    invoiceMaterials,
    formatDate,
  );

  return (
    <>
      {formattedInvoiceMaterials.map(([year, months]) => (
        <div className={styles.year} key={year}>
          <div className={styles.yearTitle}>{year}</div>
          {months.map(([month, materialsInMonth]) => (
            <ListContainer key={`${year}:${month}`} title={month}>
              {materialsInMonth
                .sort(
                  (a, b) => (b.isPhysical || false) - (a.isPhysical || false),
                )
                .map((material) => (
                  <InvoiceMaterialItem
                    key={material.id}
                    id={material.id}
                    organizationId={organizationId}
                    month={formatDate(material.insertionDate, DATE_FORMAT)}
                    name={
                      material.isPhysical
                        ? translate("Consumption and Production")
                        : material.name
                    }
                  />
                ))}
            </ListContainer>
          ))}
        </div>
      ))}
    </>
  );
};

InvoiceMaterials.propTypes = {
  organizationId: propTypes.string.isRequired,
  financialMaterials: propTypes.arrayOf(
    propTypes.shape({
      id: propTypes.number.isRequired,
      name: propTypes.string,
      month: propTypes.string,
    }),
  ),
  physicalMaterials: propTypes.arrayOf(
    propTypes.shape({
      month: propTypes.string,
    }),
  ),
};

export default InvoiceMaterials;
