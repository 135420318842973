import React, { useState } from "react";
import PropTypes from "prop-types";

import { getToken } from "/src/auth/authenticationStrategies";
import Button, { BUTTON_STATE } from "/src/components/button";
import { useTranslation } from "src/translations/translationProvider";
import styles from "./authLink.module.scss";
import { useNotifications } from "src/modules/notifications/notificationsProvider";

const utf8FileNameRegex = /filename\*=UTF-8''(.+?)($|;)/;
const fallbackFileNameRegex = /filename=["']?(.+?)["']?($|;)/;

const AuthLink = ({
  url,
  getPostBody,
  onDownloadingChanged,
  children,
  ...props
}) => {
  const [downloading, setDownloading] = useState(false);
  const { showNotification } = useNotifications();
  const { translate } = useTranslation();

  const handleDownload = async (event) => {
    event.preventDefault();
    if (onDownloadingChanged) onDownloadingChanged(true);
    setDownloading(true);

    try {
      const token = await getToken();
      const response = await fetch(url, {
        method: getPostBody ? "POST" : "GET",
        body: getPostBody && JSON.stringify(getPostBody()),
        mode: "cors",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      const contentDisposition = response.headers.get("content-disposition");
      const fileNameMatch =
        contentDisposition.match(utf8FileNameRegex) ||
        contentDisposition.match(fallbackFileNameRegex);

      const blob = await response.blob();
      const objectUrl = URL.createObjectURL(blob);

      var anchor = document.createElement("a");
      if (fileNameMatch) {
        anchor.download = decodeURI(fileNameMatch[1]);
      }
      anchor.href = objectUrl;
      anchor.click();

      URL.revokeObjectURL(objectUrl);
    } catch (error) {
      showNotification(translate("UnexpectedError"));
      throw error;
    } finally {
      setDownloading(false);
      if (onDownloadingChanged) onDownloadingChanged(false);
    }
  };

  const Link = () => (
    <a href={url} onClick={handleDownload} {...props}>
      {children || url}
    </a>
  );

  return (
    <>
      {downloading ? (
        <div className={styles.loaderContainer}>
          <Button
            className={styles.button}
            type="secondary"
            state={BUTTON_STATE.LOADING}
          />
        </div>
      ) : (
        <Link />
      )}
    </>
  );
};

AuthLink.propTypes = {
  url: PropTypes.string,
  onDownloadingChanged: PropTypes.func,
  children: PropTypes.node,
  getPostBody: PropTypes.func,
};

export default AuthLink;
