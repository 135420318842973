import * as React from "react";
const SvgLogo = (props) => (
  <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fillRule="nonzero" fill="none">
      <path
        d="M15.407 14.815H7.835c-2.078 0-4.072-.793-5.541-2.206C.824 11.196-.001 9.28 0 7.281V0h7.572c4.324-.002 7.831 3.365 7.835 7.522v7.293ZM2.79 2.682v4.599c-.002 2.676 2.25 4.847 5.033 4.851h4.783V7.534c.002-2.676-2.251-4.847-5.034-4.852H2.79Z"
        fill="#FFC20E"
      />
      <path
        d="M24.17 14.815h-7.577V7.534C16.59 3.377 20.095.004 24.422 0H32v7.28c.002 4.158-3.502 7.53-7.83 7.535Zm-4.785-2.683h4.786c2.785-.004 5.04-2.175 5.037-4.851V2.682h-4.786c-2.785-.002-5.045 2.165-5.05 4.84l.013 4.61Z"
        fill="#00C0F3"
      />
      <path
        d="M7.578 32H0v-7.28c0-4.157 3.503-7.528 7.83-7.535h7.577v7.281c0 4.156-3.503 7.528-7.829 7.534Zm-4.786-2.682h4.786c2.785.002 5.045-2.165 5.05-4.84v-4.599H7.84c-2.785-.002-5.045 2.164-5.05 4.84v4.599Z"
        fill="#0089CF"
      />
      <path
        d="M32 32h-7.572c-4.323 0-7.829-3.369-7.835-7.528v-7.287h7.572c4.322 0 7.828 3.369 7.835 7.528V32ZM19.382 19.858v4.602a4.76 4.76 0 0 0 1.477 3.435 5.144 5.144 0 0 0 3.569 1.42h4.782v-4.602c.002-2.677-2.25-4.85-5.033-4.855h-4.795Z"
        fill="#8DC63F"
      />
    </g>
  </svg>
);
export default SvgLogo;
