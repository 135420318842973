export const ROUTES = {
  agreement: {
    path: "/agreement",
    pageName: "User Agreement",
  },
  invoices: {
    path: "/files",
    pageName: "Files",
  },
  knowledgeCenter: {
    path: "/knowledge",
    pageName: "Knowledge Center",
  },
  article: {
    path: "/article",
    pageName: "Knowledge Center",
  },
  admin: {
    path: "/admin",
    pageName: "User administration",
  },
  energy: {
    path: "/energy",
    pageName: "Consumption and Production",
  },
  home: {
    path: "/",
    pageName: "Home",
  },
};
