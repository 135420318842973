import React from "react";

import WeatherMap from "./weatherMap";
import LoadingSpinner from "/src/components/loadingSpinner";
import { GET_PLANTS_WITH_ENERGY } from "/src/services/apollo/queries";
import { OrganizationAppQuery } from "/src/components/appQuery";

import styles from "./weatherMap.module.scss";
import { Translate } from "src/translations/translate";
import { useGetCurrentUser } from "src/modules/userProvider";

const createFromAndToDates = () => {
  const date = new Date();
  const fromDate = new Intl.DateTimeFormat("sv").format(
    new Date(date.getFullYear(), date.getMonth(), 1)
  );
  const toDate = new Intl.DateTimeFormat("sv").format(date);
  return { fromDate, toDate };
};

const WeatherMapContainer = () => {
  const { fromDate, toDate } = createFromAndToDates();
  const currentUser = useGetCurrentUser();
  const userClaims = currentUser.selectedOrganizationConnection.role.claims;
  const hasAccess = userClaims.some((claim) => claim.type === "Energy");

  const renderLoading = () => (
    <div className={styles.loadingSpinner}>
      <LoadingSpinner />
    </div>
  );

  return (
    <>
      {hasAccess && (
        <OrganizationAppQuery
          query={GET_PLANTS_WITH_ENERGY}
          renderLoading={renderLoading}
          renderError={() => {
            return (
              <>
                <h4>
                  <Translate>Could not fetch coordinates for plants</Translate>
                </h4>
                <WeatherMap />
              </>
            );
          }}
          variables={{
            fromDate,
            toDate,
          }}
        >
          {(data) => {
            const { plants } = data.organization;
            return <WeatherMap plants={plants} />;
          }}
        </OrganizationAppQuery>
      )}
      {!hasAccess && <WeatherMap />}
    </>
  );
};

export default WeatherMapContainer;
