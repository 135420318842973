import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { useTranslation } from "src/translations/translationProvider";
import { Translate } from "src/translations/translate";
import { GET_INDEXPOOL_HEDGEREPORT } from "/src/services/apollo/queries";
import {
  UNIT_LIST,
  RESOLUTION_LIST,
  convertHedgeToMW,
} from "../../hedgeReport/hedgeUtils";

import { AppQuery } from "/src/components/appQuery";
import ControlHeader from "/src/components/controlHeader";
import Select, { MultiSelect } from "/src/components/select";
import { MonthPicker } from "/src/components/calendar";
import RadioButtonList from "/src/components/radioButtonList";
import IndexPoolHedgeReportTable from "./indexPoolHedgeReportTable";
import IndexPoolHedgeChart from "./indexPoolHedgeChart";
import {
  LoadingChart,
  PlaceholderChart,
  PlaceholderChartText,
} from "/src/components/chart";
import IndexPoolExcelExport from "./indexPoolExcelExport.jsx";
import PortfolioConfigurationSelect from "../../portfolioConfigurationSelect";

import financialStyles from "/src/modules/portfolio/financial/financial.module.scss";
import adminStyles from "/src/styles/admin.module.scss";
import styles from "./indexPoolHedgeReport.module.scss";
import { useGetCurrentUser } from "src/modules/userProvider";

const IndexPoolHedgeReport = ({
  products,
  indexPools,
  portfolioReportConfigurations,
  selectedConfigurationIds,
  setSelectedConfigurationIds,
}) => {
  const { translate, formatDate } = useTranslation();
  const currentUser = useGetCurrentUser();
  const organizationId =
    currentUser.selectedOrganizationConnection.organization.id;

  const defaultState = {
    pointInTime: new Date(),
    selectedProduct: products.length > 0 && products[0],
    selectedUnit: UNIT_LIST[0],
    selectedResolution: RESOLUTION_LIST.MONTHLY,
    selectedCurrencies: products.length > 0 ? products[0].currencies : [],
    selectedIndexPools: indexPools,
  };

  const [filter, setFilter] = useState(defaultState);

  useEffect(() => {
    if (products.length === 0) return;

    setFilter({
      ...filter,
      selectedProduct: products[0],
      selectedCurrencies: products[0]?.currencies,
    });
  }, [products]);

  const makeDropdownOptionItems = ({ options, shouldTranslate }) => {
    if (!options) {
      return [];
    }
    return options.map((item) => ({
      label: shouldTranslate ? translate(item) : item,
      value: item,
    }));
  };

  const handleProductChange = (selection) => {
    const product = products.find(
      (product) => product.name === selection.value,
    );
    if (product !== filter.selectedProduct) {
      setFilter({
        ...filter,
        selectedProduct: product,
        selectedCurrencies: product?.currencies,
      });
    }
  };

  const handleIndexPoolChange = (selection) => {
    const newSelection = indexPools.filter((ip) =>
      selection.find((s) => s.value === ip.key),
    );
    setFilter({
      ...filter,
      selectedIndexPools: newSelection,
    });
  };

  const handleCurrenciesChange = (selection) => {
    const newSelection = selection.map((currency) => currency.value);
    if (
      JSON.stringify(newSelection) !== JSON.stringify(filter.selectedCurrencies)
    ) {
      setFilter({ ...filter, selectedCurrencies: newSelection });
    }
  };

  const handleResolutionChange = (selection) => {
    setFilter({ ...filter, selectedResolution: selection.value });
  };

  const renderNoContentChartAndTable = () => (
    <div className={styles.hedgeChart}>
      <PlaceholderChart
        key="HedgeReport chart error"
        className={styles.hedgeChart}
      >
        <PlaceholderChartText>
          <Translate>There is nothing to show</Translate>
        </PlaceholderChartText>
      </PlaceholderChart>
      <IndexPoolHedgeReportTable data={[]} />
    </div>
  );

  const renderErrorChartAndTable = () => (
    <div className={styles.hedgeChart}>
      <PlaceholderChart key="HedgeReport chart error">
        <PlaceholderChartText>
          <Translate>UnexpectedError</Translate>
        </PlaceholderChartText>
      </PlaceholderChart>
      <IndexPoolHedgeReportTable data={[]} />
    </div>
  );

  const renderLoadingChartAndTable = () => (
    <div className={styles.hedgeChart}>
      <LoadingChart key="HedgeReport chart loading" />
    </div>
  );

  if (products.length === 0 || filter.selectedIndexPools.length === 0) {
    return (
      <div className={financialStyles.tabContent}>
        <ControlHeader>
          <div className={financialStyles.dropdown}>
            <Translate>Portfolios</Translate>:
            <PortfolioConfigurationSelect
              availableConfigurations={portfolioReportConfigurations}
              selectedConfigurationIds={selectedConfigurationIds}
              setSelectedConfigurationIds={setSelectedConfigurationIds}
            />
          </div>
          {filter.selectedIndexPools.length === 0 && (
            <div className={financialStyles.dropdown}>
              <Translate>Index pools</Translate>:
              <MultiSelect
                key={filter.selectedIndexPools}
                isWithCaret
                showSelectAll
                showSelectedCount={filter.selectedIndexPools.length > 0}
                triggerClassName={classNames(
                  adminStyles.trigger,
                  financialStyles.trigger,
                )}
                wrapperClassName={financialStyles.selectWrapper}
                options={makeDropdownOptionItems({
                  options: indexPools.map((ip) => ip.key),
                  shouldTranslate: false,
                })}
                value={makeDropdownOptionItems({
                  options: filter.selectedIndexPools.map((ip) => ip.key),
                  shouldTranslate: false,
                })}
                onChange={handleIndexPoolChange}
              />
            </div>
          )}
        </ControlHeader>
        {renderNoContentChartAndTable()}
      </div>
    );
  }

  return (
    <div className={financialStyles.tabContent}>
      <ControlHeader>
        <div className={financialStyles.dropdown}>
          <Translate>Portfolios</Translate>:
          <PortfolioConfigurationSelect
            availableConfigurations={portfolioReportConfigurations}
            selectedConfigurationIds={selectedConfigurationIds}
            setSelectedConfigurationIds={setSelectedConfigurationIds}
          />
        </div>
        <div className={financialStyles.dropdown}>
          <Translate>Index pools</Translate>:
          <MultiSelect
            key={filter.selectedIndexPools}
            isWithCaret
            showSelectAll
            showSelectedCount={filter.selectedIndexPools.length > 0}
            triggerClassName={classNames(
              adminStyles.trigger,
              financialStyles.trigger,
            )}
            wrapperClassName={financialStyles.selectWrapper}
            options={makeDropdownOptionItems({
              options: indexPools.map((ip) => ip.key),
              shouldTranslate: false,
            })}
            value={makeDropdownOptionItems({
              options: filter.selectedIndexPools.map((ip) => ip.key),
              shouldTranslate: false,
            })}
            onChange={handleIndexPoolChange}
          />
        </div>
        <div className={financialStyles.dropdown}>
          <Translate>Until</Translate>:
          <MonthPicker
            month={filter.pointInTime}
            maxDate={new Date()}
            key={selectedConfigurationIds}
            triggerClassName={classNames(
              adminStyles.trigger,
              financialStyles.trigger,
            )}
            onChange={(value) => setFilter({ ...filter, pointInTime: value })}
          />
        </div>
        <div className={financialStyles.dropdown}>
          <Translate>Product</Translate>:
          <Select
            key={products}
            isWithCaret
            triggerClassName={classNames(
              adminStyles.trigger,
              financialStyles.trigger,
            )}
            wrapperClassName={financialStyles.selectWrapper}
            options={makeDropdownOptionItems({
              options: products.map((product) => product.name),
              shouldTranslate: false,
            })}
            value={{
              label: filter.selectedProduct.name,
              value: filter.selectedProduct.name,
            }}
            onChange={handleProductChange}
          />
        </div>
        <div
          data-test-id={`currency-item-${filter.selectedProduct.currencies}`}
          className={financialStyles.dropdown}
        >
          <Translate>Currency</Translate>:
          <MultiSelect
            key={filter.selectedProduct.name}
            isWithCaret
            showSelectedCount
            triggerClassName={classNames(
              adminStyles.trigger,
              financialStyles.trigger,
            )}
            wrapperClassName={financialStyles.selectWrapper}
            options={makeDropdownOptionItems({
              options: filter.selectedProduct?.currencies,
              shouldTranslate: false,
            })}
            value={makeDropdownOptionItems({
              options: filter.selectedCurrencies,
              shouldTranslate: false,
            })}
            onChange={handleCurrenciesChange}
          />
        </div>
        <div data-test-id="resolution" className={financialStyles.dropdown}>
          <Translate>Resolution</Translate>:
          <Select
            isWithCaret
            triggerClassName={classNames(
              adminStyles.trigger,
              financialStyles.trigger,
            )}
            wrapperClassName={financialStyles.selectWrapper}
            options={makeDropdownOptionItems({
              options: Object.values(RESOLUTION_LIST),
              shouldTranslate: true,
            })}
            value={{
              label: translate(filter.selectedResolution),
              value: filter.selectedResolution,
            }}
            onChange={handleResolutionChange}
          />
        </div>
        <div>
          <RadioButtonList
            className={financialStyles.radioGroup}
            titleClassName={styles.unitTitle}
            optionClassName={styles.unitOption}
            activeOptionClassName={styles.activeUnit}
            options={UNIT_LIST.map((unit) => ({ text: unit, value: unit }))}
            selectedValue={filter.selectedUnit}
            onChange={(unit) => setFilter({ ...filter, selectedUnit: unit })}
          />
        </div>
        <IndexPoolExcelExport
          className={styles.exportButton}
          organizationId={organizationId}
          portfolioConfigurationIds={portfolioReportConfigurations.map(
            (ip) => ip.id,
          )}
          indexPoolIds={filter.selectedIndexPools.map((ip) => ip.id)}
          pointInTime={filter.pointInTime}
        />
      </ControlHeader>
      <AppQuery
        query={GET_INDEXPOOL_HEDGEREPORT}
        variables={{
          organizationId,
          pointInTime: formatDate(filter.pointInTime, "yyyy-MM-dd"),
          productName: filter.selectedProduct.name,
          currencies: filter.selectedCurrencies,
          indexPoolIds: filter.selectedIndexPools.map((ip) => ip.id),
          portfolioConfigurationIds: portfolioReportConfigurations.map(
            (ip) => ip.id,
          ),
        }}
        renderNoContent={renderNoContentChartAndTable}
        renderError={renderErrorChartAndTable}
        renderLoading={renderLoadingChartAndTable}
      >
        {(data) => {
          const dataInCorrectResolution =
            data.organization.indexPoolHedgeReport.find(
              (res) => res.resolution === filter.selectedResolution,
            );

          if (!dataInCorrectResolution) return renderNoContentChartAndTable();

          let { hedges, products: visibleProducts } = dataInCorrectResolution;
          if (filter.selectedUnit === UNIT_LIST[1]) {
            hedges = convertHedgeToMW(hedges);
          }

          return (
            <div className={styles.hedgeChart}>
              <IndexPoolHedgeChart
                data={hedges}
                unit={filter.selectedUnit}
                dataResolution={filter.selectedResolution}
              />
              <IndexPoolHedgeReportTable
                unit={filter.selectedUnit}
                data={hedges}
                header={visibleProducts ? visibleProducts.join(", ") : ""}
                className={styles.hedgeReportTable}
                dataResolution={filter.selectedResolution}
              />
            </div>
          );
        }}
      </AppQuery>
    </div>
  );
};

IndexPoolHedgeReport.propTypes = {
  organizationId: PropTypes.string,
  products: PropTypes.array,
  indexPools: PropTypes.array,
  portfolioReportConfigurations: PropTypes.array,
  selectedConfigurationIds: PropTypes.array,
  setSelectedConfigurationIds: PropTypes.func,
};

export default IndexPoolHedgeReport;
