export const ORGANIZATION_CATEGORY = {
  SUPPLIER: "supplier",
  BALANCE_CONTROLLER: "balanceController",
};

export const organizationCategories = [
  ORGANIZATION_CATEGORY.SUPPLIER,
  ORGANIZATION_CATEGORY.BALANCE_CONTROLLER,
];

export const ORGANIZATION_TYPES = {
  NORMAL: "Normal",
  INTEGRATION: "Integration",
};
