import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { useTranslation } from "src/translations/translationProvider";
import MonthlySummaryCards from "/src/components/monthlySummaryCards";
import IconLabel from "/src/components/iconLabel";

import { HistoryIcon } from "/src/icons";
import styles from "./overview.module.scss";

const MonthlySummaryCardsMobile = ({ year, plantId, queryResult }) => {
  const { translate } = useTranslation();

  const [showAllMonthlyCards, setShowAllMonthlyCards] = useState(false);
  const [hasData, setHasData] = useState(false);

  return (
    <>
      <MonthlySummaryCards
        className={classNames(styles.results, styles.mobileResults, {
          [styles.showLimited]: !showAllMonthlyCards,
        })}
        cardClassName={classNames(styles.result, styles.mobileResultCard)}
        year={year}
        plantId={plantId}
        setHasData={setHasData}
        queryResult={queryResult}
      />
      {showAllMonthlyCards || !hasData ? null : (
        <IconLabel
          to="#"
          onClick={() => setShowAllMonthlyCards(!showAllMonthlyCards)}
          label={translate("Show previous months")}
          icon={HistoryIcon}
        />
      )}
    </>
  );
};

MonthlySummaryCardsMobile.propTypes = {
  year: PropTypes.any,
  plantId: PropTypes.string,
  queryResult: PropTypes.shape({
    data: PropTypes.object,
    loading: PropTypes.bool,
    error: PropTypes.object,
  }).isRequired,
};

export default MonthlySummaryCardsMobile;
