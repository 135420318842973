import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import InvoicePreview from "./invoicePreview";
import invoiceListFormatter from "./invoiceListFormatter";
import { useTranslation } from "src/translations/translationProvider";
import ListContainer from "/src/components/listContainer";

import styles from "./invoiceList.module.scss";

const InvoiceList = ({ className, invoices }) => {
  const [groupedInvoices, setGroupedInvoices] = useState([]);
  const { apiLocale } = useTranslation();

  useEffect(() => {
    setGroupedInvoices(invoiceListFormatter(invoices, apiLocale));
  }, [invoices, apiLocale]);

  return (
    <div className={className} data-test-id="invoices">
      {groupedInvoices.map(([year, months]) => (
        <div key={year} className={styles.year}>
          <div className={styles.yearTitle}>{year}</div>
          {months.map(([month, invoicesForMonth]) => (
            <ListContainer key={`${year}${month}`} title={month}>
              {invoicesForMonth.map((invoice) => (
                <InvoicePreview key={invoice.id} invoice={invoice} />
              ))}
            </ListContainer>
          ))}
        </div>
      ))}
    </div>
  );
};

InvoiceList.propTypes = {
  className: PropTypes.string,
  invoices: PropTypes.array,
};

export default InvoiceList;
