import { gql } from "@apollo/client";

export const GET_INVOICES = gql`
  query getInvoices(
    $organizationId: String!
    $startDate: String!
    $endDate: String!
    $startCount: Int = 0
    $maxResultCount: Int = 100000
  ) {
    organization(id: $organizationId) {
      id
      invoices(
        fromDate: $startDate
        toDate: $endDate
        skip: $startCount
        limit: $maxResultCount
      ) {
        invoiceNumber
        id
        date
        amount
        currencyCode
        type
      }
    }
  }
`;
