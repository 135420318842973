import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "src/translations/translationProvider";
import config from "/src/config/config";
import AuthLink from "/src/components/authLink";
import Button from "/src/components/button";
import Input from "/src/components/input";
import Dialog from "/src/components/dialog";

import styles from "./downloadTemplate.module.scss";

const MINIMUM_DAYS= 1;
const DEFAULT_DAYS = 1;
const MAXIMUM_DAYS= 365;

const DownloadTemplate = ({ organizationId, className, plantId }) => {
  const { translate } = useTranslation();
  const [amountOfDays, setAmountOfDays] = useState(DEFAULT_DAYS);
  const [openDialog, setOpenDialog] = useState(false);
  const downloadUrl =
  `${config.fileProxyURL}/availabilityplans/${organizationId}/template/${plantId}?days=${amountOfDays}`;

  const dayInputValid = (days) => days <= MAXIMUM_DAYS && days >= MINIMUM_DAYS;

    return (
    <div className={className}>
      <Button
        small
        onClick={(e) => {
          e.stopPropagation();
          setOpenDialog(true);
        }}
      >
        {translate("Download Template")}
      </Button>
      {openDialog && (
        <Dialog
          title={translate("downloadTemplateDialogTitle")}
          forceClose={() => setOpenDialog(false)}
          dialogSupport={
            <Input
              label={translate("Amount of days")}
              className={styles.daysInput}
              inputClassName={styles.innerDaysInput}
              value={amountOfDays.toString()}
              onChange={setAmountOfDays}
              type={"number"}
              min={1}
              max={365}
              validate={[
                {
                  validationFunction: dayInputValid,
                  errorMessage: translate("availabilityDayError"),
                },
              ]}
            />
          }
        >
          <button onClick={() => setOpenDialog(false)}>
          {translate("Cancel")}
          </button>
          <AuthLink className={styles.downloadButton} url={downloadUrl}>
            <Button
              small
              disabled={!dayInputValid(amountOfDays)}
              onClick={() => setOpenDialog(false)}
            >
              {translate("Download Template")}
            </Button>
          </AuthLink>
        </Dialog>
      )}
    </div>
);
};

DownloadTemplate.propTypes = {
  className: PropTypes.string,
  organizationId: PropTypes.string.isRequired,
  plantId: PropTypes.string
};

export default DownloadTemplate;
