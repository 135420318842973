import React, { forwardRef, useImperativeHandle } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useExpandable } from "/src/utilities/hooks";
import styles from "./dropdown.module.scss";

const Dropdown = forwardRef(
  (
    {
      children,
      onOutsideClickCallback,
      trigger,
      triggerDisabled,
      className,
      containerClassName,
      isInitiallyExpanded,
      isWithCaret,
      isBottomToTop,
      dataTestId,
    },
    mainRef
  ) => {
    const { isExpanded, setExpanded, toggleExpanded, ref } = useExpandable(
      isInitiallyExpanded,
      mainRef
    );

    useImperativeHandle(mainRef, () => ({
      forceClose() {
        setExpanded(false);
      },
      onOutsideClick() {
        if (isExpanded) {
          onOutsideClickCallback();
        }
      },
    }));

    const testPrefix = dataTestId || "dropdown";

    return (
      <div
        data-test-id={testPrefix}
        className={classNames(styles.dropdown, className, {
          [styles.expanded]: isExpanded,
          [styles.withCaret]: isWithCaret,
        })}
      >
        <div
          data-test-id={testPrefix + "-list"}
          onClick={(e) => {
            e.stopPropagation();
            if (!triggerDisabled) {
              toggleExpanded();
            }
          }}
          className={styles.trigger}
        >
          {trigger}
        </div>
        {children && (
          <div
            data-test-id={testPrefix + "-container"}
            ref={ref}
            className={classNames(containerClassName, styles.container, {
              [styles.reverse]: isBottomToTop,
            })}
          >
            {children}
          </div>
        )}
      </div>
    );
  }
);

Dropdown.displayName = "dropdown";

Dropdown.propTypes = {
  children: PropTypes.node,
  onOutsideClickCallback: PropTypes.func,
  trigger: PropTypes.node.isRequired,
  triggerDisabled: PropTypes.bool,
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  isInitiallyExpanded: PropTypes.bool,
  isWithCaret: PropTypes.bool,
  isBottomToTop: PropTypes.bool,
  dataTestId: PropTypes.string,
};

Dropdown.defaultProps = {
  onOutsideClickCallback: () => {},
  triggerDisabled: false,
  isInitiallyExpanded: false,
  isWithCaret: false,
};

export default Dropdown;
