import React from "react";
import PropTypes from "prop-types";

import ResultCard from "/src/components/resultCard";
import { ORGANIZATION_CATEGORY } from "/src/constants/organizationCategories";
import { useTranslation } from "src/translations/translationProvider";

import LoadingSummaryCards from "./loadingSummaryCards";
import getMaxTwelveMonthsBackwards from "./getMaxTwelveMonthsBackwards";
import { useGetCurrentUser } from "src/modules/userProvider";

const MonthlySummaryCards = ({
  className,
  cardClassName,
  plantId,
  year: currentYear,
  setHasData,
  queryResult,
}) => {
  const currentUser = useGetCurrentUser();
  const { translate } = useTranslation();
  const { category: organizationCategory } =
    currentUser.selectedOrganizationConnection.organization;
  const isBalanceController =
    organizationCategory === ORGANIZATION_CATEGORY.BALANCE_CONTROLLER;

  const shouldShowStandards = !plantId && isBalanceController;
  const shouldShowHourlyAndMonthly = !isBalanceController;

  const { loading, error, data } = queryResult;

  if (loading) {
    return (
      <LoadingSummaryCards
        className={className}
        cardClassName={cardClassName}
      />
    );
  }

  if (error) {
    console.error(error); // eslint-disable-line no-console
    return null;
  }

  if (!data) {
    return null;
  }

  const isPlantView = !!plantId;

  const monthlyReadingSummaries = isPlantView
    ? getMaxTwelveMonthsBackwards({
        data: data.organization.plant.energy,
        currentYear,
      })
    : getMaxTwelveMonthsBackwards({
        data: data.organization.energy,
        currentYear,
      });

  const hasData = monthlyReadingSummaries && monthlyReadingSummaries.length;

  if (setHasData) {
    setHasData(hasData);
  }

  if (!hasData) {
    return null;
  }
  return (
    <div className={className}>
      {monthlyReadingSummaries
        .reverse()
        .filter(
          ({ consumption, production }) =>
            production != null && consumption != null
        )
        .map(({ consumption, production, month, unit, standards, year }) => (
          <ResultCard
            key={`${year}:${month}`}
            className={cardClassName}
            month={translate(month)}
            production={production}
            consumption={consumption}
            year={year || currentYear}
            shouldShowStandards={shouldShowStandards}
            shouldShowHourlyAndMonthly={shouldShowHourlyAndMonthly}
            unit={unit}
            standards={standards}
            loading={loading}
          />
        ))}
    </div>
  );
};

MonthlySummaryCards.propTypes = {
  className: PropTypes.string,
  cardClassName: PropTypes.string,
  plantId: PropTypes.string,
  year: PropTypes.number,
  setHasData: PropTypes.func,
  queryResult: PropTypes.shape({
    data: PropTypes.object,
    loading: PropTypes.bool,
    error: PropTypes.object,
  }).isRequired,
};

export default MonthlySummaryCards;
