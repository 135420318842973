import React from "react";
import PropTypes from "prop-types";

import { useTranslation } from "src/translations/translationProvider";
import Input from "/src/components/input";

import styles from "./formInput.module.scss";

const FormInput = ({
  className,
  title,
  description,
  value,
  unit,
  onChange,
  hasFixedVolume,
  ...rest
}) => {
  const { translate } = useTranslation();

  return (
    <div className={styles.cardContent}>
      <div className={styles.textWrapper}>
        <div className={styles.title}>{title}</div>
        <div className={styles.description}>{description}</div>
      </div>
      <Input
        label={translate("Total volume")}
        className={styles.input}
        inputClassName={styles.innerInput}
        value={
          value.totalVolume === undefined || value.totalVolume === null
            ? ""
            : value.totalVolume.toString()
        }
        onChange={(newValue) => {
          onChange({ ...value, totalVolume: newValue });
        }}
        {...rest}
      />
      <span className={styles.unit}>{unit}</span>
      {hasFixedVolume && (
        <>
          <Input
            label={translate("Fixed volume")}
            className={styles.input}
            inputClassName={styles.innerInput}
            value={
              value.fixedVolume === undefined || value.fixedVolume === null
                ? ""
                : value.fixedVolume.toString()
            }
            onChange={(newValue) => {
              onChange({ ...value, fixedVolume: newValue });
            }}
            {...rest}
          />
          <span className={styles.unit}>{unit}</span>
        </>
      )}
    </div>
  );
};

FormInput.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  value: PropTypes.any,
  unit: PropTypes.string,
  onChange: PropTypes.func,
  type: PropTypes.oneOf(["text", "password", "datetime-local", "number"]),
};

export default FormInput;
