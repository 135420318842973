import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";

import styles from "./app.module.scss";

const ContentWrapper = ({ children }) => {
  const { pathname } = useLocation();
  const ref = useRef();

  useEffect(() => {
    ref.current.scrollTo(0, 0);
  }, [pathname]);

  return (
    <main ref={ref} className={styles.content}>
      {children}
    </main>
  );
};

export default ContentWrapper;

ContentWrapper.propTypes = {
  children: PropTypes.node,
};
