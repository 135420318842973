import { gql } from "@apollo/client";

export const GET_PLANT_MEASURAND_SERIES = gql`
  query getPlantMeasurandSeries(
    $organizationId: String!
    $plantId: String!
    $fromDate: String!
    $toDate: String!
  ) {
    organization(id: $organizationId) {
      id
      plant(id: $plantId) {
        id
        energy {
          measurands(fromDate: $fromDate, toDate: $toDate) {
            name
            series {
              type
              unit
              readings {
                time
                value
              }
            }
          }
        }
      }
    }
  }
`;
