import { gql } from "@apollo/client";

export const GET_CONTACTS = gql`
  query getContacts($organizationId: String!) {
    contacts(organizationId: $organizationId) {
      userId
      firstName
      lastName
      fileUrl
      email
      phoneNumber
      function
    }
  }
`;
