import React from "react";
import classNames from "classnames";
import { NavLink } from "react-router-dom";
import { animated, config, useSpring } from "@react-spring/web";

import { Boop, Confetti } from "src/components/animation";
import { ModityLiveLogoIcon, ModityNameIcon, EggIcon } from "src/icons";
import { useTranslation } from "src/translations/translationProvider";

import styles from "./logotype.module.scss";

const EASTER = false;

const Logotype = () => {
  const { translate } = useTranslation();

  const [props] = useSpring(
    () => ({
      from: { translateY: -200 },
      to: { translateY: 0 },
      config: config.wobbly,
    }),
    [],
  );

  if (!EASTER) {
    return (
      <NavLink end to="/" title={translate("Menu_Start")}>
        <ModityLiveLogoIcon className={styles.logo} data-test-id="logo" />
      </NavLink>
    );
  }

  return (
    <Confetti origin={{ y: 0.15, x: 0.9 }}>
      <div className={classNames(styles.easterLogo, styles.logo)}>
        <ModityNameIcon data-test-id="logo" />
        <Boop>
          <animated.div
            title={"Click me 🎉"}
            style={props}
            className={styles.egg}
          >
            <EggIcon />
          </animated.div>
        </Boop>
      </div>
    </Confetti>
  );
};

export default Logotype;
