import React, { useState } from "react";
import { useParams } from "react-router-dom";

import Invoices from "./invoices";

const InvoiceContainer = () => {
  const { searchInput = "" } = useParams();

  const [searchText, setSearchText] = useState(searchInput);
  const onSearch = (newSearchInput) => {
    setSearchText(newSearchInput);
  };

  return <Invoices onSearch={onSearch} searchInput={searchText} />;
};

export default InvoiceContainer;
