import { gql } from "@apollo/client";

export const CREATE_PLANT = gql`
  mutation createPlant($organizationId: String!, $plant: PlantInput!) {
    createPlant(organizationId: $organizationId, plant: $plant)
  }
`;

export const UPDATE_PLANT = gql`
  mutation updatePlant(
    $organizationId: String!
    $plantId: String!
    $plant: PlantInput!
  ) {
    updatePlant(
      organizationId: $organizationId
      plantId: $plantId
      plant: $plant
    )
  }
`;

export const DELETE_PLANT = gql`
  mutation deletePlant($organizationId: String!, $plantId: String!) {
    deletePlant(organizationId: $organizationId, plantId: $plantId)
  }
`;
