import { gql } from "@apollo/client";

export const GET_INVOICE_MATERIALS = gql`
  query getInvoiceMaterials($organizationId: String!) {
    organization(id: $organizationId) {
      id
      invoiceMaterials {
        financial {
          id
          name
          month
          insertionDate
        }
        physical {
          month
        }
      }
    }
  }
`;
