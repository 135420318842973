import { gql } from "@apollo/client";

export const GET_ADMIN_PLANTS = gql`
  query getPlants($organizationId: String!) {
    clientAdministration {
      organization(id: $organizationId) {
        id
        name
      }
      plants(organizationId: $organizationId) {
        id
        name
        priceArea
        maxPower
        facilityId
        coordinates {
          id
          latitude
          longitude
        }
        nrOfMeasurands
        isGenerated
        timeZone
      }
    }
  }
`;
