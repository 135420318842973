import React from "react";
import sjson from "secure-json-parse";

import { ApolloClient, ApolloLink, ApolloProvider } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import config from "/src/config/config";
import { errorLink, httpLink, retryLink } from "./links";
import cache from "./cache";
import defaultOptions from "./defaultOptions";

const ApolloWithCustomAuth = ({ children }) => {
  const withToken = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        Authorization: `Bearer ${
          sjson.parse(localStorage.getItem("token")).access_token
        }`,
        Accept: "application/json",
      },
    };
  });

  const client = new ApolloClient({
    link: ApolloLink.from([
      withToken,
      errorLink(),
      retryLink,
      httpLink(config.graphqlURL),
    ]),
    cache,
    defaultOptions,
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default ApolloWithCustomAuth;
