import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import Input from "/src/components/input";
import { SearchIcon } from "/src/icons";

import styles from "./search.module.scss";

function Search(props) {
  const [state, setState] = useState({ value: props.defaultValue || "" });

  const handleChange = (value) => {
    setState({ value });
    props.onChange(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    props.onSubmit(state.value);
  };

  const { className, buttonClassName, label, autoFocus } = props;
  const { value } = state;

  return (
    <form
      className={classNames(styles.search, className)}
      onSubmit={handleSubmit}
    >
      <Input
        className={styles.input}
        placeholder={label}
        value={value}
        maxLength="40"
        onChange={handleChange}
        autoFocus={autoFocus}
      />
      <button
        type="submit"
        className={classNames(styles.iconWrapper, buttonClassName)}
      >
        <SearchIcon />
      </button>
    </form>
  );
}

Search.propTypes = {
  autoFocus: PropTypes.bool,
  className: PropTypes.string,
  buttonClassName: PropTypes.string,
  defaultValue: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
};

Search.defaultProps = {
  onChange: () => {},
  onSubmit: () => {},
};

export default Search;
