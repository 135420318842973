import { gql } from "@apollo/client";

export const CREATE_CONTACT = gql`
  mutation createContact($organizationIds: [String]!, $contact: ContactInput!) {
    createContact(organizationIds: $organizationIds, contact: $contact)
  }
`;

export const UPDATE_CONTACT = gql`
  mutation updateContact($contact: UpdateContactInput!) {
    updateContact(contact: $contact)
  }
`;
