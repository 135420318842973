/* eslint-disable no-magic-numbers */

export const phoneNumberFormat = (input) => {
  if (!input) {
    return "";
  }

  const sanitizedInput = input.replace(/\D/g, "");
  const inputLength = sanitizedInput.length;

  if (inputLength < 5) {
    return sanitizedInput;
  }

  if (inputLength < 7) {
    return `${sanitizedInput.slice(0, 4)} - ${sanitizedInput.slice(4)}`;
  }

  if (inputLength < 9) {
    return `${sanitizedInput.slice(0, 4)} - ${sanitizedInput.slice(
      4,
      6
    )} ${sanitizedInput.slice(6)}`;
  }

  return `${sanitizedInput.slice(0, 4)} - ${sanitizedInput.slice(
    4,
    6
  )} ${sanitizedInput.slice(6, 8)} ${sanitizedInput.slice(8)}`;
};
