import { gql } from "@apollo/client";

import { BalanceControllerStandards } from "/src/services/apollo/fragments";

export const GET_ORGANIZATION_MONTHLY_SUMMARIES_STANDARDS_FOR_DASHBOARD = gql`
  query getOrganizationMonthlySummariesStandards(
    $organizationId: String!
    $year: Int!
    $fromDate: String!
    $toDate: String!
  ) {
    organization(id: $organizationId) {
      id
      energy {
        monthlySummary(year: $year) {
          id
          month
          monthIndex
          unit
          consumption
          production
        }
        standard(fromDate: $fromDate, toDate: $toDate) {
          type
          unit
          readings {
            time
            value
          }
        }
      }
    }
  }
`;

export const GET_ORGANIZATION_MONTHLY_SUMMARIES_AGGREGATES_FOR_DASHBOARD = gql`
  query getOrganizationMonthlySummariesAggregates(
    $organizationId: String!
    $year: Int!
    $fromDate: String!
    $toDate: String!
  ) {
    organization(id: $organizationId) {
      id
      energy {
        monthlySummary(year: $year) {
          id
          month
          monthIndex
          unit
          consumption
          production
        }
        aggregate(fromDate: $fromDate, toDate: $toDate) {
          type
          unit
          readings {
            time
            value
          }
        }
      }
    }
  }
`;

export const GET_ORGANIZATION_MONTHLY_SUMMARIES_FOR_BALANCE_CONTROLLER = gql`
  query getOrganizationTwoYearMonthlySummaries(
    $organizationId: String!
    $year: Int!
    $previousYear: Int!
  ) {
    organization(id: $organizationId) {
      id
      energy {
        currentYearSummaries: monthlySummary(year: $year) {
          id
          month
          monthIndex
          unit
          consumption
          production
          standards {
            ...BalanceControllerStandards
          }
        }
        previousYearSummaries: monthlySummary(year: $previousYear) {
          id
          month
          monthIndex
          unit
          consumption
          production
          standards {
            ...BalanceControllerStandards
          }
        }
      }
    }
  }
  ${BalanceControllerStandards}
`;

export const GET_ORGANIZATION_MONTHLY_SUMMARIES_FOR_SUPPLIER = gql`
  query getOrganizationTwoYearMonthlySummaries(
    $organizationId: String!
    $year: Int!
    $previousYear: Int!
  ) {
    organization(id: $organizationId) {
      id
      energy {
        currentYearSummaries: monthlySummary(year: $year) {
          id
          month
          monthIndex
          unit
          consumption
          production
          standards {
            id
            consumptionMonthly
          }
        }
        previousYearSummaries: monthlySummary(year: $previousYear) {
          id
          month
          monthIndex
          unit
          consumption
          production
          standards {
            id
            consumptionMonthly
          }
        }
      }
    }
  }
`;
