import { gql } from "@apollo/client";

export const CREATE_ORGANIZATION = gql`
  mutation createOrganization($organization: OrganizationInput!) {
    createOrganization(organization: $organization)
  }
`;

export const UPDATE_ORGANIZATION = gql`
  mutation updateOrganization(
    $organizationId: String!
    $organization: OrganizationInput!
  ) {
    updateOrganization(
      organizationId: $organizationId
      organization: $organization
    )
  }
`;

export const DELETE_ORGANIZATION = gql`
  mutation deleteOrganization($organizationId: String!) {
    deleteOrganization(organizationId: $organizationId)
  }
`;
