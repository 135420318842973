import { onError } from "@apollo/client/link/error";
import customAuth from "/src/services/customAuth";
import { appInsights } from "/src/analytics.js";

const redColor = "color:red;";

const STATUS_CODES = {
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
};

/* eslint-disable no-console */
export const errorLink = (msalInstance) =>
  onError(({ operation, response, graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      if (Array.isArray(graphQLErrors)) {
        console.group(
          `%c[${graphQLErrors.length} Apollo error${
            graphQLErrors.length > 1 ? "s" : ""
          }]`,
          redColor,
        );

        for (const { message, path, extensions } of graphQLErrors) {
          const details =
            !import.meta.env.NODE_ENV &&
            `\nDetails: ${JSON.stringify(extensions, null, 1)}`;
          const formattedPath = path && path.length ? path.join(" >> ") : path;
          console.log(
            `%cMessage: "${message}",\nPath: "${formattedPath}"${details}, \nOperation: ${
              operation.operationName
            }, \nVariables: ${JSON.stringify(operation.variables, null, 1)}`,
            redColor,
          );

          // We expect 409s to mean user tried creating something that already exists, which should be handled by the UI
          if (message === "409: Conflict") {
            continue;
          }

          appInsights.trackException({
            exception: new Error(message),
            properties: {
              details: JSON.stringify(extensions, null, 1),
              formattedPath: formattedPath,
              message: message,
              operation: operation.operationName,
              variables: operation.variables,
              query: operation.query,
              response: response,
            },
          });
        }

        console.groupEnd();
      } else {
        console.log("%cGraphQL errors:", redColor, graphQLErrors);
        const { message, path, extensions } = graphQLErrors;
        const formattedPath = path && path.length ? path.join(" >> ") : path;
        appInsights.trackException({
          exception: new Error(message),
          properties: {
            details: JSON.stringify(extensions, null, 1),
            formattedPath: formattedPath,
            message: message,
            operation: operation.operationName,
            variables: operation.variables,
            query: operation.query,
            response: response,
          },
        });
      }

      if (
        graphQLErrors.some(
          (e) => e.extensions.code === STATUS_CODES.UNAUTHORIZED,
        )
      ) {
        msalInstance
          ? msalInstance.logoutRedirect({
              account: msalInstance.getActiveAccount(),
            })
          : customAuth.logout();
      }
    }

    if (networkError) {
      let errorName = "Apollo Network Error";
      if (Object.keys(networkError).length === 0) {
        errorName = "Unknown " + errorName;
      }

      console.log(`%c[${errorName}]: ${networkError}`, redColor);

      appInsights.trackException({
        exception: new Error(errorName),
        properties: {
          originalError: networkError,
          type: errorName,
          operation: operation.operationName,
          variables: operation.variables,
          query: operation.query,
          response: response,
        },
      });
    }
  });
/* eslint-enable no-console */
