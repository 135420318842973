import * as React from "react";
const SvgNotFound = (props) => (
  <svg
    viewBox="0 0 480 320"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <defs>
      <path id="not-found_svg__a" d="M0 320h480V0H0z" />
      <path
        d="M261.068 76.831a7.903 7.903 0 0 1 7.898 7.899v7.898h12.377c5.567.001 10.086 4.52 10.086 10.086v140.369c0 5.566-4.519 10.085-10.086 10.085h-80.686c-5.567 0-10.086-4.519-10.086-10.085V102.714c0-5.566 4.519-10.085 10.086-10.086h12.377V84.73a7.903 7.903 0 0 1 7.898-7.899h40.136Z"
        id="not-found_svg__c"
      />
      <path
        d="M261.068 76.831a7.903 7.903 0 0 1 7.898 7.899v7.898h12.377c5.567.001 10.086 4.52 10.086 10.086v140.369c0 5.566-4.519 10.085-10.086 10.085h-80.686c-5.567 0-10.086-4.519-10.086-10.085V102.714c0-5.566 4.519-10.085 10.086-10.086h12.377V84.73a7.903 7.903 0 0 1 7.898-7.899h40.136Z"
        id="not-found_svg__d"
      />
      <path id="not-found_svg__e" d="M190.571 109.453H291.43v-.62H190.571z" />
      <path id="not-found_svg__f" d="M190.571 233.417H291.43v-.62H190.571z" />
      <path
        id="not-found_svg__g"
        d="M300.584 137.511 176.533 170.75l4.883 18.227 124.051-33.24z"
      />
      <path
        d="m337.037 137.511-192.074 51.466 192.074-51.466Z"
        id="not-found_svg__h"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="not-found_svg__b" fill="#fff">
        <use xlinkHref="#not-found_svg__a" />
      </mask>
      <use fill="#FFF" xlinkHref="#not-found_svg__a" />
      <ellipse
        fill="#FBFBFB"
        fillRule="nonzero"
        mask="url(#not-found_svg__b)"
        cx={240}
        cy={251.346}
        rx={200}
        ry={52.592}
      />
      <use
        stroke="#8C8E91"
        strokeWidth={6}
        fill="#FFF"
        strokeLinecap="round"
        strokeLinejoin="round"
        xlinkHref="#not-found_svg__c"
      />
      <use
        stroke="#8C8E91"
        strokeWidth={6}
        strokeLinecap="round"
        strokeLinejoin="round"
        xlinkHref="#not-found_svg__d"
      />
      <use
        stroke="#8C8E91"
        strokeWidth={6}
        strokeLinecap="round"
        strokeLinejoin="round"
        xlinkHref="#not-found_svg__e"
      />
      <use
        stroke="#8C8E91"
        strokeWidth={6}
        strokeLinecap="round"
        strokeLinejoin="round"
        xlinkHref="#not-found_svg__f"
      />
      <use fill="#FFF" xlinkHref="#not-found_svg__g" />
      <use
        stroke="#8C8E91"
        strokeWidth={6}
        strokeLinecap="round"
        strokeLinejoin="round"
        xlinkHref="#not-found_svg__h"
      />
    </g>
  </svg>
);
export default SvgNotFound;
