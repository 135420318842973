import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useTranslation } from "src/translations/translationProvider";
import { Translate } from "src/translations/translate";
import { formatNumber } from "/src/utilities";
import { YearPicker } from "/src/components/calendar";
import ControlHeader from "/src/components/controlHeader";
import PortfolioCard from "/src/modules/portfolio/portfolioCard";
import GoSummaryChart from "../goSummary/goSummaryChart";
import GoSummaryExcelExport from "../goSummary/goSummaryExcelExport";
import { distinctColors } from "/src/components/chart/utils";

import financialStyles from "../../financial.module.scss";
import adminStyles from "/src/styles/admin.module.scss";
import styles from "./goSummary.module.scss";
import { useGetCurrentUser } from "src/modules/userProvider";

const DEFAULT_UNIT = "SEK";

const CARDS = {
  TOTAL_QUANTITY: {
    name: "TOTAL_QUANTITY",
    valueAccessor: (data) => data.totalQuantity.toString(),
    unit: null,
  },
  TOTAL_UNIT_PRICE: {
    name: "TOTAL_UNIT_PRICE",
    valueAccessor: (data) => formatNumber(data.totalUnitPrice),
    unit: DEFAULT_UNIT,
  },
  TOTAL_PRICE: {
    name: "TOTAL_PRICE",
    valueAccessor: (data) => formatNumber(data.totalPrice),
    unit: DEFAULT_UNIT,
  },
};

const GoSummary = ({ year, setYear, goSummary }) => {
  const [activeCard, setActiveCard] = useState(CARDS.TOTAL_PRICE.name);
  const { translate } = useTranslation();

  const currentUser = useGetCurrentUser();
  const { id: organizationId } =
    currentUser.selectedOrganizationConnection.organization;

  const yearDate = new Date(year, 1, 1);

  const { marketPrices, marketQuantities } = goSummary;

  const renderChart = () => {
    switch (activeCard) {
      case CARDS.TOTAL_QUANTITY.name: {
        const quantityData = marketQuantities.map((quantity) => quantity.value);
        const quantityBarColors = marketQuantities.map(
          (_, i) => distinctColors[i].hex
        );
        const quantityBarLabels = marketQuantities.map((mq) =>
          translate(mq.productionType)
        );
        const quantityDatasetLabel = translate(CARDS.TOTAL_QUANTITY.name);
        const quantityChartData = {
          data: quantityData,
          barColors: quantityBarColors,
          datasetLabel: quantityDatasetLabel,
          barLabels: quantityBarLabels,
        };

        return (
          <GoSummaryChart
            className={styles.chart}
            chartData={quantityChartData}
            yLabel={translate("Amount")}
          />
        );
      }
      case CARDS.TOTAL_UNIT_PRICE.name: {
        const priceData = marketPrices.map((price) => price.value);
        const priceBarColors = marketPrices.map(
          (_, i) => distinctColors[i].hex
        );
        const priceDatasetLabel = translate(CARDS.TOTAL_UNIT_PRICE.name);
        const priceBarLabels = marketPrices.map((mp) =>
          translate(mp.productionType)
        );
        const priceChartData = {
          data: priceData,
          barColors: priceBarColors,
          datasetLabel: priceDatasetLabel,
          barLabels: priceBarLabels,
        };

        return (
          <GoSummaryChart
            className={styles.chart}
            chartData={priceChartData}
            yLabel={DEFAULT_UNIT}
          />
        );
      }

      case CARDS.TOTAL_PRICE.name: {
        const data = marketPrices.map(
          (price, index) => price.value * marketQuantities[index].value
        );
        const barColors = marketPrices.map((_, i) => distinctColors[i].hex);
        const datasetLabel = translate(CARDS.TOTAL_PRICE.name);
        const barLabels = marketPrices.map((mp) =>
          translate(mp.productionType)
        );
        const chartData = {
          data: data,
          barColors: barColors,
          datasetLabel: datasetLabel,
          barLabels: barLabels,
        };

        return (
          <GoSummaryChart
            className={styles.chart}
            chartData={chartData}
            yLabel={DEFAULT_UNIT}
          />
        );
      }
      default:
        return null;
    }
  };

  return (
    <div>
      <ControlHeader className={styles.controlHeader}>
        <div className={financialStyles.dropdown}>
          <Translate>Year</Translate>:
          <YearPicker
            year={yearDate}
            onChange={(date) => setYear(date.getFullYear())}
            triggerClassName={classNames(
              adminStyles.trigger,
              financialStyles.trigger
            )}
          />
        </div>
        <div className={styles.downloadButton}>
          <GoSummaryExcelExport organizationId={organizationId} year={year} />
        </div>
      </ControlHeader>
      <div className={financialStyles.cardRow}>
        {Object.keys(CARDS).map((key) => {
          const card = CARDS[key];
          return (
            <PortfolioCard
              key={card.name}
              active={activeCard === card.name}
              onClick={() => setActiveCard(card.name)}
              text={
                <span className={styles.cardText}>
                  <Translate>{card.name}</Translate>
                </span>
              }
              value={card.valueAccessor(goSummary)}
              valueClassName={styles.cardValue}
              unit={card.unit}
              vertical
            />
          );
        })}
      </div>
      {renderChart()}
    </div>
  );
};

GoSummary.propTypes = {
  year: PropTypes.number,
  setYear: PropTypes.func,
  goSummary: PropTypes.object,
};

export default GoSummary;
