import { gql } from "@apollo/client";

export const DELETE_USER_FROM_ORGANIZATION = gql`
  mutation deleteUserFromOrganization(
    $organizationId: String!
    $userId: String!
  ) {
    deleteUserFromOrganization(organizationId: $organizationId, userId: $userId)
  }
`;
