import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

import { useTranslation } from "src/translations/translationProvider";
import { formatNumber } from "/src/utilities";

import styles from "./tableCellFormats.module.scss";

const FormatOrEmpty = ({ value, className, unit }) => {
  const { locale } = useTranslation();
  return value === undefined || value === null ? (
    ""
  ) : (
    <div className={classNames(styles.formatOrEmpty, className)}>
      {`${formatNumber(value, locale)} ${unit ? unit : ""}`}
    </div>
  );
};

FormatOrEmpty.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  unit: PropTypes.string,
};

export default FormatOrEmpty;
