import React from "react";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import classNames from "classnames";

import styles from "./iconLabel.module.scss";

const IconLabel = ({ className, icon: Icon, label, ...restProps }) => (
  <NavLink
    data-test-id={"icon-label"}
    className={({ isActive }) =>
      classNames(styles.iconLabel, className, {
        [styles.active]: isActive,
      })
    }
    {...restProps}
  >
    {Icon && <Icon className={styles.icon} />}
    {label}
  </NavLink>
);

IconLabel.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.func,
  label: PropTypes.string.isRequired,
};

export default IconLabel;
