import React from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { parseISO, addMonths, format } from "date-fns";

import config from "/src/config/config";

import AuthLink from "/src/components/authLink";
import { DownloadIcon } from "/src/icons";

import styles from "./invoiceMaterialItem.module.scss";
import fileStyles from "/src/modules/files/files.module.scss";

const getLinkUrl = (id, organizationId, month) => {
  if (id) {
    return `${config.fileProxyURL}/invoicematerials/${organizationId}/${id}`;
  }

  // The phys report is always for the month preceeding the selected one
  const reportMonth = addMonths(parseISO(month), -1);

  return `${
    config.fileProxyURL
  }/invoicematerialsphysical/${organizationId}?month=${format(
    reportMonth,
    "yyyy-MM-dd",
  )}`;
};

const InvoiceMaterialItem = ({ organizationId, id, month, name }) => (
  <div className={classNames(fileStyles.link)}>
    <AuthLink url={getLinkUrl(id, organizationId, month)}>
      <div className={fileStyles.file}>
        <div className={fileStyles.half}>
          <div className={fileStyles.title}>{name}</div>
          <div className={fileStyles.date}>{month.toString()}</div>
        </div>
        <div className={fileStyles.half}>
          <DownloadIcon
            className={classNames(fileStyles.downloadIcon, styles.icon)}
          />
        </div>
      </div>
    </AuthLink>
  </div>
);

InvoiceMaterialItem.propTypes = {
  organizationId: propTypes.string.isRequired,
  id: propTypes.number.isRequired,
  month: propTypes.string,
  name: propTypes.string,
};

export default InvoiceMaterialItem;
