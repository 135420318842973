import { gql } from "@apollo/client";

export const GET_ROLES = gql`
  query getRoles($organizationId: String!) {
    organization(id: $organizationId) {
      id
      roles {
        id
        name
        claims {
          type
          value
        }
      }
    }
  }
`;
