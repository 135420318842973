import * as React from "react";
const SvgCopy = (props) => (
  <svg viewBox="0 0 21 22" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M14.5 0h-12C1.4 0 .5.9.5 2v14h2V2h12zm3 4h-11c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 16h-11V6h11z"
      fillRule="evenodd"
      style={{
        InkscapeStroke: "none",
      }}
    />
  </svg>
);
export default SvgCopy;
