import * as React from "react";
const SvgDownload = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20.022 24.064"
    {...props}
  >
    <path d="M10.011 17.297a.778.778 0 0 1-.545-.22l-3.078-3.008a.738.738 0 0 1 0-1.063.782.782 0 0 1 1.089 0l2.536 2.476 2.535-2.476a.782.782 0 0 1 1.089 0 .738.738 0 0 1 0 1.063l-3.08 3.008a.774.774 0 0 1-.544.22Z" />
    <path d="M10.011 17.297a.761.761 0 0 1-.77-.752v-7.52a.77.77 0 0 1 1.54 0v7.52a.761.761 0 0 1-.77.752ZM14.632 20.304H5.396a.752.752 0 1 1 0-1.5h9.241a.752.752 0 1 1 0 1.5Z" />
    <path d="M18.047 24.065H1.977A1.934 1.934 0 0 1 0 22.183V6.734a2.472 2.472 0 0 1 .745-1.756L5.097.728A2.555 2.555 0 0 1 6.895 0h11.151a1.933 1.933 0 0 1 1.979 1.881v20.3a1.932 1.932 0 0 1-1.975 1.881ZM6.895 1.5a1.009 1.009 0 0 0-.709.287l-4.352 4.25a.975.975 0 0 0-.294.693v15.452a.412.412 0 0 0 .437.379h16.069a.41.41 0 0 0 .436-.377V1.881a.41.41 0 0 0-.436-.377Z" />
    <path d="M5.776 7.52h-2.7a.752.752 0 1 1 0-1.5h2.7a.38.38 0 0 0 .385-.376V3.008a.77.77 0 0 1 1.54 0V5.64a1.906 1.906 0 0 1-1.925 1.88Z" />
  </svg>
);
export default SvgDownload;
