import React, { useState } from "react";

import { useOrganizationQuery } from "/src/services/apollo/hooks/useOrganizationQuery";
import { Translate } from "src/translations/translate";
import { GET_DOCUMENTS } from "/src/services/apollo/queries";

import LoadingSpinner from "/src/components/loadingSpinner";
import DocumentItem from "./documentItem";
import ControlHeader from "/src/components/controlHeader";
import DocumentUpload from "./documentUpload";
import DocumentDelete from "./documentDelete";

import styles from "./documents.module.scss";

const Documents = ({ organizationId, isModityAdmin }) => {
  const [fileToDelete, setFileToDelete] = useState();
  const { loading, error, data, apolloClient } =
    useOrganizationQuery(GET_DOCUMENTS);

  const showAdminControls = isModityAdmin;

  const refetch = () => {
    apolloClient.refetchQueries({ include: [GET_DOCUMENTS] });
  };

  const handleDeleteSelected = (event, file) => {
    event.stopPropagation();

    setFileToDelete(file);
  };

  const handleDeleteClosed = () => {
    setFileToDelete(null);
  };

  if (loading) {
    return (
      <div>
        <LoadingSpinner />
      </div>
    );
  }

  if (error) {
    return (
      <div>
        <Translate>UnexpectedError</Translate>
      </div>
    );
  }

  const {
    organization: { documents },
  } = data;

  const Upload = () =>
    showAdminControls && (
      <ControlHeader className={styles.upload}>
        <DocumentUpload organizationId={organizationId} onSuccess={refetch} />
      </ControlHeader>
    );

  if (!documents.length) {
    return (
      <>
        <Upload />
        <div data-test-id="documents">
          <Translate>No documents found</Translate>
        </div>
      </>
    );
  }

  const documentItems = documents.map((d) => ({
    id: d.id,
    fileName: d.fileName,
    date: new Date(d.modifiedDate || d.createdDate),
    forEveryone: isModityAdmin && !d.organization,
  }));
  documentItems.sort((a, b) => b.date.getTime() - a.date.getTime());

  return (
    <>
      <Upload />
      <div data-test-id="documents">
        {documentItems.map((item) => (
          <DocumentItem
            key={item.id}
            item={item}
            onDelete={handleDeleteSelected}
            showAdminControls={showAdminControls}
          />
        ))}
      </div>
      {fileToDelete && (
        <DocumentDelete
          file={fileToDelete}
          onClose={handleDeleteClosed}
          onSuccess={refetch}
        />
      )}
    </>
  );
};

export default Documents;
