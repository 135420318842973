import React, { useState, useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import ControlHeader from "/src/components/controlHeader";
import { RangePicker } from "/src/components/calendar";
import { MultiSelect } from "/src/components/select";
import IndexDetailsTable from "./indexDetailsTable";
import { useTranslation } from "src/translations/translationProvider";
import { Translate } from "src/translations/translate";
import PortfolioConfigurationSelect from "../portfolioConfigurationSelect";
import { INDEXPRODUCT_TYPE } from "/src/constants/portfolioConfigurationTypes";

import styles from "./indexDetails.module.scss";
import adminStyles from "/src/styles/admin.module.scss";
import financialStyles from "../financial.module.scss";

const DEFAULT_FROM_DATE = new Date("2018-01-01");
const DEFAULT_TO_DATE = new Date(Date.now());

const convertToListItem = (item) => {
  return {
    label: item,
    value: item,
  };
};

const IndexDetails = ({ indexOrders, portfolioReportConfigurations }) => {
  const { translate } = useTranslation();

  const validConfigurations = portfolioReportConfigurations.filter(
    (c) => c.type === INDEXPRODUCT_TYPE
  );
  const [selectedConfigurationIds, setSelectedConfigurationIds] = useState(
    validConfigurations.map((config) => config.id)
  );

  const [fromDate, setFromDate] = useState(DEFAULT_FROM_DATE);
  const [toDate, setToDate] = useState(DEFAULT_TO_DATE);

  const configurationOrders = useMemo(
    () =>
      indexOrders.filter((order) =>
        selectedConfigurationIds.includes(order.portfolioId)
      ),
    [indexOrders, selectedConfigurationIds]
  );

  const currencies = [...new Set(configurationOrders.map((io) => io.currency))];
  const [selectedCurrencies, setSelectedCurrencies] = useState(
    currencies.map((c) => convertToListItem(c))
  );

  const uniqueProducts = [
    ...new Set(configurationOrders.map((io) => io.product)),
  ];
  const [selectedProducts, setSelectedProducts] = useState(
    uniqueProducts.map((io) => convertToListItem(io))
  );

  useEffect(() => {
    setSelectedProducts(uniqueProducts.map((io) => convertToListItem(io)));
    setSelectedCurrencies(currencies.map((c) => convertToListItem(c)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configurationOrders]);

  const dateFilter = (order) => {
    const date = new Date(order.createdDate);
    return date >= fromDate && date <= toDate;
  };

  const productFilter = (order) =>
    selectedProducts.map((product) => product.label).includes(order.product);

  const currencyFilter = (currency) =>
    selectedCurrencies
      .map((currency) => currency.label)
      .includes(currency.currency);

  const renderHeader = () =>
    `${translate("Index Portfolios")}, ${selectedProducts
      .map((product) => product.label)
      .join(", ")}`;

  return (
    <div
      data-test-id="index-details-header"
      className={financialStyles.tabContent}
    >
      <ControlHeader>
        <div className={financialStyles.dropdown}>
          <Translate>Portfolios</Translate>:
          <PortfolioConfigurationSelect
            availableConfigurations={validConfigurations}
            selectedConfigurationIds={selectedConfigurationIds}
            setSelectedConfigurationIds={setSelectedConfigurationIds}
          />
        </div>
        <div className={financialStyles.dropdown}>
          <RangePicker
            from={fromDate}
            to={toDate}
            onChange={([from, to]) => {
              setFromDate(from);
              setToDate(to);
            }}
          />
        </div>
        <div className={financialStyles.dropdown}>
          <Translate>Product</Translate>:
          <MultiSelect
            key={selectedConfigurationIds}
            isWithCaret
            showSelectedCount={selectedProducts.length > 0}
            triggerClassName={classNames(
              adminStyles.trigger,
              financialStyles.trigger
            )}
            options={uniqueProducts.map(convertToListItem)}
            value={selectedProducts}
            onChange={setSelectedProducts}
          />
        </div>
        <div className={financialStyles.dropdown}>
          <Translate>Currency</Translate>:
          <MultiSelect
            key={selectedConfigurationIds}
            isWithCaret
            showSelectedCount={selectedCurrencies.length > 0}
            triggerClassName={classNames(
              adminStyles.trigger,
              financialStyles.trigger
            )}
            options={currencies.map(convertToListItem)}
            value={selectedCurrencies}
            onChange={setSelectedCurrencies}
          />
        </div>
      </ControlHeader>
      <IndexDetailsTable
        className={styles.table}
        indexOrders={configurationOrders
          .filter(dateFilter)
          .filter(productFilter)
          .filter(currencyFilter)}
        heading={renderHeader()}
      />
    </div>
  );
};

IndexDetails.propTypes = {
  indexOrders: PropTypes.array,
  portfolioReportConfigurations: PropTypes.array.isRequired,
};

export default IndexDetails;
