const CONFIGURATION_TYPES = [
  { text: "Electricity certificate", value: "ElectricityCertificate" },
  { text: "NetMW", value: "NetMw" },
  { text: "Index product", value: "IndexProduct" },
  { text: "Index pool", value: "IndexPool" },
  { text: "Guarantee of origin", value: "GuaranteeOfOrigin" },
];

const lookup = CONFIGURATION_TYPES.reduce((acc, curr) => {
  acc[curr.value] = curr.text;
  return acc;
}, {});

export default CONFIGURATION_TYPES;
export const ELC_TYPE = CONFIGURATION_TYPES[0].value;
export const NETMW_TYPE = CONFIGURATION_TYPES[1].value;
export const INDEXPRODUCT_TYPE = CONFIGURATION_TYPES[2].value;
export const INDEXPOOL_TYPE = CONFIGURATION_TYPES[3].value;
export const GO_TYPE = CONFIGURATION_TYPES[4].value;
export const getText = (value) => lookup[value];
