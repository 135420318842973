import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { NotMobile, Mobile } from "/src/components/responsive";
import NotIframe from "/src/components/notIframe";

import styles from "./pageLayout.module.scss";
import globalStyles from "/src/styles/global.module.scss";
import { useGetCurrentUser } from "src/modules/userProvider";

const PageLayout = ({ className, children, title, subtitle }) => {
  const currentUser = useGetCurrentUser();
  const activeOrganization =
    currentUser.selectedOrganizationConnection.organization.name;
  return (
    <div
      className={classNames(
        globalStyles.pageContentWrapper,
        styles.pageLayout,
        className
      )}
    >
      <header>
        <NotIframe>
          <NotMobile>
            <h1
              data-test-id="welcome-text"
              className={classNames(globalStyles.pageTitle, styles.pageTitle)}
            >
              {title}
            </h1>
            <div className={styles.organizationName}>{activeOrganization}</div>
            <div className={globalStyles.subTitle}>{subtitle}</div>
          </NotMobile>
        </NotIframe>
        <Mobile>
          <div className={styles.pageTitle}>
            <h1 className={globalStyles.pageTitle}>{title}</h1>
          </div>
        </Mobile>
      </header>
      {children}
    </div>
  );
};

PageLayout.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

export default PageLayout;
