import React from "react";

import Button from "/src/components/button";
import { NotFoundIcon } from "/src/icons";
import { Mobile, NotMobile } from "/src/components/responsive";
import { Translate } from "src/translations/translate";

import styles from "./error-page.module.scss";

const refresh = (
  /* eslint-disable-next-line jsx-a11y/anchor-is-valid */
  <a href="#" className={styles.link} onClick={() => window.location.reload()}>
    <Translate>refresh</Translate>
  </a>
);

const goBack = (
  /* eslint-disable-next-line jsx-a11y/anchor-is-valid */
  <a href="#" className={styles.link} onClick={() => window.history.back()}>
    <Translate>go back</Translate>
  </a>
);

const ErrorPage = () => (
  <div className={styles.content}>
    <NotFoundIcon className={styles.icon} />
    <h1 className={styles.pageTitle}>
      <Translate>Sorry, couldn't find what you were looking for</Translate>
    </h1>

    <NotMobile>
      <span className={styles.subTitle}>
        <Translate>Please</Translate> {refresh} <Translate>or</Translate>{" "}
        {goBack}
      </span>
    </NotMobile>
    <Mobile>
      <Button
        className={styles.backButton}
        onClick={() => window.history.back()}
      >
        <Translate>go back</Translate>
      </Button>
    </Mobile>
  </div>
);

export default ErrorPage;
