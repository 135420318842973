import React from "react";
import PropTypes from "prop-types";

import { useTranslation } from "src/translations/translationProvider";
import { formatNumber } from "/src/utilities";
import CardRow from "./cardRow";

import styles from "./resultCard.module.scss";

const hasValue = (input) => input || input === 0;

const CardRows = ({
  title,
  total,
  consumptionStandards,
  productionStandards,
  usePrimaryColor,
  loading,
  monthlyConsumptionStandard,
  hourlyConsumptionStandard,
}) => {
  const { translate, locale } = useTranslation();

  const totalValue = hasValue(total) && formatNumber(total, locale);

  return (
    <div className={styles.fragment}>
      <CardRow
        title={title}
        value={loading ? "..." : totalValue}
        usePrimaryColor={usePrimaryColor}
        loading={loading}
      />

      {productionStandards &&
        productionStandards.map(({ key, value }) => (
          <CardRow
            key={key}
            title={translate(key)}
            value={hasValue(value) && formatNumber(value, locale)}
            usePrimaryColor={usePrimaryColor}
            isStandard={true}
          />
        ))}
      {consumptionStandards &&
        consumptionStandards.map(({ key, value }) => (
          <CardRow
            key={key}
            title={translate(key)}
            value={hasValue(value) && formatNumber(value, locale)}
            usePrimaryColor={usePrimaryColor}
            isStandard={true}
          />
        ))}
      {hasValue(hourlyConsumptionStandard) && (
        <CardRow
          title={translate("hourlyConsumptionStandard")}
          value={formatNumber(hourlyConsumptionStandard, locale)}
          usePrimaryColor={usePrimaryColor}
          isStandard={true}
        />
      )}
      {hasValue(monthlyConsumptionStandard) && (
        <CardRow
          title={translate("monthlyConsumptionStandard")}
          value={formatNumber(monthlyConsumptionStandard, locale)}
          usePrimaryColor={usePrimaryColor}
          isStandard={true}
        />
      )}
    </div>
  );
};

CardRows.propTypes = {
  title: PropTypes.node,
  titleStyle: PropTypes.string,
  total: PropTypes.any,
  usePrimaryColor: PropTypes.bool,
  consumptionStandards: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
  productionStandards: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
  loading: PropTypes.bool,
  monthlyConsumptionStandard: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.bool,
  ]),
  hourlyConsumptionStandard: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.bool,
  ]),
};

export default CardRows;
