import React from "react";

import { GET_USER_AGREEMENT } from "/src/services/apollo/queries";
import UserAgreement from "./userAgreement";
import { OrganizationAppQuery } from "/src/components/appQuery";
import ErrorPage from "/src/modules/error-page";
import { ErrorBoundary } from "/src/components/error";

const UserAgreementContainer = () => (
  <ErrorBoundary>
    <OrganizationAppQuery
      query={GET_USER_AGREEMENT}
      renderNoContent={ErrorPage}
      renderError={ErrorPage}
    >
      {(data) => <UserAgreement content={data.agreement.content} />}
    </OrganizationAppQuery>
  </ErrorBoundary>
);

export default UserAgreementContainer;
