import { gql } from "@apollo/client";

export const GET_GO_CONSUMPTION = gql`
  query getGoConsumption($organizationId: String!, $month: String!) {
    organization(id: $organizationId) {
      id
      goConsumption(month: $month) {
        clientId
        clientName
        productionType
        hasFixedVolume
        preliminaryFixedVolume
        preliminaryVariableVolume
        finalFixedVolume
        finalVariableVolume
      }
      elcQuotaExemptions(month: $month) {
        typeId
        typeName
        preliminaryFixedVolume
        preliminaryVariableVolume
        finalFixedVolume
        finalVariableVolume
      }
    }
  }
`;
