import React from "react";
import classNames from "classnames";

import styles from "./controlHeader.module.scss";

const ControlHeaderItem = ({ className, children }) => (
  <div className={classNames(styles.item, className)}>{children}</div>
);

export default ControlHeaderItem;
