import { gql } from "@apollo/client";

export const CREATE_PORTFOLIO_REPORT_CONFIGURATION = gql`
  mutation createPortfolioReportConfiguration(
    $organizationId: String!
    $portfolioReportConfiguration: PortfolioReportConfigurationInput!
  ) {
    createPortfolioReportConfiguration(
      organizationId: $organizationId
      portfolioReportConfiguration: $portfolioReportConfiguration
    )
  }
`;

export const DELETE_PORTFOLIO_REPORT_CONFIGURATION = gql`
  mutation ($organizationId: String!, $configurationId: String!) {
    deletePortfolioReportConfiguration(
      organizationId: $organizationId
      configurationId: $configurationId
    )
  }
`;

export const UPDATE_PORTFOLIO_REPORT_CONFIGURATION = gql`
  mutation (
    $organizationId: String!
    $configurationId: String!
    $portfolioReportConfiguration: PortfolioReportConfigurationInput!
  ) {
    updatePortfolioReportConfiguration(
      organizationId: $organizationId
      configurationId: $configurationId
      portfolioReportConfiguration: $portfolioReportConfiguration
    )
  }
`;

export const CREATE_FORECAST = gql`
  mutation createForecast(
    $organizationId: String!
    $configurationId: String!
    $indexPoolId: Int
    $forecasts: [ForecastInput]!
  ) {
    createForecast(
      organizationId: $organizationId
      configurationId: $configurationId
      indexPoolId: $indexPoolId
      forecasts: $forecasts
    )
  }
`;

export const CREATE_MANDATE = gql`
  mutation createMandate(
    $organizationId: String!
    $portfolioConfigurationId: String!
    $mandates: MandateInput!
  ) {
    createMandate(
      organizationId: $organizationId
      configurationId: $portfolioConfigurationId
      mandates: $mandates
    )
  }
`;

export const CREATE_OR_UPDATE_IMPORTED_POSITIONS = gql`
  mutation createOrUpdateImportedPositions(
    $organizationId: String!
    $portfolioConfigurationId: String!
    $positions: [ImportPositionInput!]!
  ) {
    createOrUpdateImportedPositions(
      organizationId: $organizationId
      configurationId: $portfolioConfigurationId
      positions: $positions
    )
  }
`;

export const DELETE_IMPORTED_POSITIONS = gql`
  mutation deleteImportedPositions(
    $organizationId: String!
    $portfolioConfigurationId: String!
    $positions: [ImportPositionInput!]!
  ) {
    deleteImportedPositions(
      organizationId: $organizationId
      configurationId: $portfolioConfigurationId
      positions: $positions
    )
  }
`;

export const CREATE_OR_UPDATE_IMPORTED_ELC_POSITIONS = gql`
  mutation createOrUpdateImportedElcPositions(
    $organizationId: String!
    $portfolioConfigurationId: String!
    $positions: [ImportElcPositionInput!]!
  ) {
    createOrUpdateImportedElcPositions(
      organizationId: $organizationId
      configurationId: $portfolioConfigurationId
      positions: $positions
    )
  }
`;

export const DELETE_IMPORTED_ELC_POSITIONS = gql`
  mutation deleteImportedElcPositions(
    $organizationId: String!
    $portfolioConfigurationId: String!
    $positions: [ImportElcPositionInput!]!
  ) {
    deleteImportedElcPositions(
      organizationId: $organizationId
      configurationId: $portfolioConfigurationId
      positions: $positions
    )
  }
`;

export const CREATE_OR_UPDATE_IMPORTED_GO_POSITIONS = gql`
  mutation createOrUpdateImportedGoPositions(
    $organizationId: String!
    $portfolioConfigurationId: String!
    $positions: [ImportGoPositionInput!]!
  ) {
    createOrUpdateImportedGoPositions(
      organizationId: $organizationId
      configurationId: $portfolioConfigurationId
      positions: $positions
    )
  }
`;

export const DELETE_IMPORTED_GO_POSITIONS = gql`
  mutation deleteImportedGoPositions(
    $organizationId: String!
    $portfolioConfigurationId: String!
    $positions: [ImportGoPositionInput!]!
  ) {
    deleteImportedGoPositions(
      organizationId: $organizationId
      configurationId: $portfolioConfigurationId
      positions: $positions
    )
  }
`;

export const DELETE_FORECAST = gql`
  mutation deleteForecast(
    $portfolioConfigurationId: String!
    $forecastId: String!
  ) {
    deleteForecast(
      configurationId: $portfolioConfigurationId
      forecastId: $forecastId
    )
  }
`;

export const DELETE_MANDATE = gql`
  mutation deleteMandate(
    $portfolioConfigurationId: String!
    $mandateId: String!
  ) {
    deleteMandate(
      configurationId: $portfolioConfigurationId
      mandateId: $mandateId
    )
  }
`;
