import { gql } from "@apollo/client";

export const GET_ELC_WITHOUT_SERVICE = gql`
  query getElcWithoutService($organizationId: String!) {
    organization(id: $organizationId) {
      id
      elcWithoutService {
        year
        buyQuantity
        buyPriceAvg
        sellQuantity
        sellPriceAvg
        totalQuantity
        totalPriceAvg
        totalPrice
        quotaPercent
        forecast
        quotaQuantity
        buyForecastPercent
        sellForecastPercent
        importedQuantity
        importedPriceAvg
        remainingQuantity
        remainingPercent
        importedBuyQuantity
        importedBuyPriceAvg
        importedSellQuantity
        importedSellPriceAvg
      }
    }
  }
`;

export const GET_ELC_WITH_SERVICE = gql`
  query getElcWithService($organizationId: String!) {
    organization(id: $organizationId) {
      id
      elcWithService {
        year
        month
        consumptionVolume
        quotaObligationExemptedVolume
        quotaObligationVolume
        buyQuantity
        buyPriceAvg
        quotaPercent
        quotaQuantity
      }
    }
  }
`;
