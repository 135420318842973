import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { useTranslation } from "src/translations/translationProvider";
import { Translate } from "src/translations/translate";
import ControlHeader from "/src/components/controlHeader";
import RadioButtonList from "/src/components/radioButtonList";
import TotalTable from "./totalTable";
import ListContainer from "/src/components/listContainer";

import styles from "./total.module.scss";
import adminStyles from "/src/styles/admin.module.scss";

import { GET_PORTFOLIO_TOTAL_SUMMARIES } from "/src/services/apollo/queries";
import { useOrganizationQuery } from "/src/services/apollo/hooks/useOrganizationQuery";
import LoadingSpinner from "/src/components/loadingSpinner";

const alternatives = ["Production", "Consumption"];

const Total = ({ className }) => {
  const [activeAlternative, setActiveAlternative] = useState(alternatives[0]);

  const { translate } = useTranslation();

  const alternativesTextAndValues = alternatives.map((alt) => ({
    text: translate(alt),
    value: alt,
  }));

  const { loading, error, data } = useOrganizationQuery(
    GET_PORTFOLIO_TOTAL_SUMMARIES
  );

  if (loading) {
    return (
      <div>
        <LoadingSpinner />
      </div>
    );
  }

  if (error) {
    return (
      <div>
        <Translate>UnexpectedError</Translate>
      </div>
    );
  }

  const { summaries = [] } = data.organization.portfolio;

  return (
    <div className={classNames(styles.total, className)}>
      <ControlHeader>
        <RadioButtonList
          className={classNames(adminStyles.roles, styles.radioGroup)}
          titleClassName={adminStyles.rolesTitle}
          optionClassName={classNames(
            adminStyles.role,
            styles.radioAlternatives
          )}
          activeOptionClassName={adminStyles.activeRole}
          onChange={(alt) => setActiveAlternative(alt)}
          options={alternativesTextAndValues}
          selectedValue={activeAlternative}
        />
      </ControlHeader>

      <div className={styles.yearList}>
        {summaries
          .slice()
          .map(({ year, currency, production, consumption }) => (
            <ListContainer
              key={year}
              title={year.toString()}
              headerClass={styles.listHeader}
              isPortfolio
            >
              <TotalTable
                data-test-id={`value${data}`}
                data={
                  activeAlternative === "Production" ? production : consumption
                }
                type={activeAlternative}
                currencyCode={currency}
              />
            </ListContainer>
          ))}
      </div>
    </div>
  );
};

Total.propTypes = {
  className: PropTypes.string,
};

export default Total;
