import React, { useState } from "react";
import PropTypes from "prop-types";
import { useNavigate, useParams } from "react-router-dom";
import classNames from "classnames";

import SUBPAGES from "./subPages";
import TabBar from "/src/components/tabBar";
import ElcWithService from "./elcWithService";
import ElcWithoutService from "./elcWithoutService";
import GoConsumptionForm from "./goConsumptionForm";
import GoSummary from "./goSummary";
import { useTranslation } from "src/translations/translationProvider";

import portfolioStyles from "../../portfolio.module.scss";
import styles from "./greenProducts.module.scss";
import GoWithoutService from "./goWithoutService";

const GreenProducts = ({
  elcServiceStartDate,
  goServiceStartDate,
  portfolioReportConfigurations,
}) => {
  const { translate } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();

  const filterSubpage = (page) => {
    if (page === SUBPAGES.ELC) return true;
    if (page === SUBPAGES.GO_SUMMARY) return !!goServiceStartDate;
    if (page === SUBPAGES.GO) return !!goServiceStartDate;
    if (page === SUBPAGES.GO_CONSUMPTION_FORM)
      return !!(goServiceStartDate || elcServiceStartDate);
    return false;
  };

  const subpages = Object.keys(SUBPAGES)
    .filter(filterSubpage)
    .map((subpage) => ({
      text: translate(`GREEN_PRODUCTS_${subpage}`),
      value: subpage,
    }));

  const [activePage, setActivePage] = useState(
    (params?.subpageLvl2 || subpages[0].value).toUpperCase()
  );

  const onPageChange = (page) => {
    setActivePage(page);
    navigate(`financial/green_products/${page.toLowerCase()}`);
  };

  const renderSubpage = () => {
    switch (activePage) {
      case SUBPAGES.ELC:
        return (
          <>
            {elcServiceStartDate && (
              <ElcWithService
                portfolioReportConfigurations={portfolioReportConfigurations}
              />
            )}
            {!elcServiceStartDate && (
              <ElcWithoutService
                portfolioReportConfigurations={portfolioReportConfigurations}
              />
            )}
          </>
        );
      case SUBPAGES.GO_CONSUMPTION_FORM:
        return <GoConsumptionForm />;
      case SUBPAGES.GO_SUMMARY:
        return (
          <GoSummary
            portfolioReportConfigurations={portfolioReportConfigurations}
          />
        );
      case SUBPAGES.GO:
        return (
          <GoWithoutService
            portfolioReportConfigurations={portfolioReportConfigurations}
          />
        );

      default:
        break;
    }
  };

  return (
    <>
      <TabBar
        className={classNames(portfolioStyles.tabs, styles.tabs)}
        optionClassName={classNames(portfolioStyles.tab, styles.tab)}
        activeOptionClassName={portfolioStyles.activeTab}
        options={subpages}
        selectedValue={activePage}
        onChange={(page) => onPageChange(page)}
      />
      {renderSubpage()}
    </>
  );
};

GreenProducts.propTypes = {
  elcServiceStartDate: PropTypes.string,
  goServiceStartDate: PropTypes.string,
  portfolioReportConfigurations: PropTypes.array,
};

export default GreenProducts;
