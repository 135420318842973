import React, { useRef } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { useTranslation } from "src/translations/translationProvider";
import Calendar from "./calendar";
import Dropdown from "/src/components/dropdown";

import styles from "./calendar.module.scss";

const DatePicker = ({ date, onChange, isWithCaret, className, ...props }) => {
  const { formatDate } = useTranslation();
  const dropdownRef = useRef(null);

  const closeDropdown = () => {
    dropdownRef.current.forceClose();
  };

  const saveDate = (newDate) => {
    onChange(newDate);
    closeDropdown();
  };

  const format = (date) => formatDate(date, "yyyy-MM-dd");

  return (
    <Dropdown
      ref={dropdownRef}
      onOutsideClickCallback={() => closeDropdown()}
      isWithCaret={isWithCaret}
      className={classNames(className, styles.dropdown, {
        [styles.withCaret]: isWithCaret,
      })}
      trigger={<span className={styles.selectedDates}>{format(date)}</span>}
    >
      <Calendar
        data-test-id={`calendar-${date}`}
        tileClassName={classNames({
          [styles.showHover]: true,
        })}
        value={date}
        onClickDay={(date) => saveDate(date)}
        {...props}
      />
    </Dropdown>
  );
};

DatePicker.propTypes = {
  date: PropTypes.instanceOf(Date),
  onChange: PropTypes.func.isRequired,
  isWithCaret: PropTypes.bool,
  className: PropTypes.string,
};

DatePicker.defaultProps = {
  date: new Date(),
  onChange: () => { },
  isWithCaret: true,
};

export default DatePicker;
