import * as React from "react";
const SvgDeveloper = (props) => (
  <svg viewBox="0 0 128 128" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="m85.973 16.949-1.92 3.51L38.491 103.7l-1.92 3.508 7.018 3.841 1.922-3.51L91.07 24.3l1.92-3.508-7.017-3.842zM34.034 32.877 5.739 61.172 2.909 64l2.83 2.828 28.295 28.295 5.656-5.656L14.223 64 39.69 38.533l-5.656-5.656zm59.934 0-5.656 5.656L113.779 64 88.312 89.467l5.656 5.656 28.295-28.295 2.83-2.828-2.83-2.828-28.295-28.295z"
      style={{
        InkscapeStroke: "none",
      }}
    />
  </svg>
);
export default SvgDeveloper;
