import { gql } from "@apollo/client";

export const GET_ORGANIZATIONS = gql`
  query getOrganizations {
    clientAdministration {
      organizations {
        id
        name
      }
    }
  }
`;
