import React from "react";
import PropTypes from "prop-types";

import { useOrganizationQuery } from "/src/services/apollo/hooks/useOrganizationQuery";
import { GET_PLANTS } from "/src/services/apollo/queries";

import { NotMobile, Mobile } from "/src/components/responsive";
import PlantSelector from "./plantSelector";
import PlantDetails from "./plantDetails";

import styles from "./plant.module.scss";

const Plant = ({
  from,
  setFrom,
  to,
  setTo,
  plantId: plantIdFromProp,
  setPlantId,
}) => {
  const { loading, error, data } = useOrganizationQuery(GET_PLANTS);

  const plants = loading ? [] : data.organization.plants;

  const plantId =
    plantIdFromProp || new URLSearchParams(window.location.search).get("id");

  return (
    <>
      <NotMobile>
        <PlantSelector
          plantId={plantId}
          setPlantId={setPlantId}
          plants={plants}
          loading={loading}
          error={error}
        />
        {plantId && plants && (
          <PlantDetails
            from={from}
            setFrom={setFrom}
            to={to}
            setTo={setTo}
            plantId={plantId}
            plants={plants}
          />
        )}
      </NotMobile>

      <Mobile>
        <div className={styles.contentMobile}>
          <PlantSelector
            plantId={plantId}
            setPlantId={setPlantId}
            plants={plants}
            loading={loading}
            error={error}
          />
          {plantId && plants && (
            <PlantDetails
              from={from}
              setFrom={setFrom}
              to={to}
              setTo={setTo}
              plantId={plantId}
              plants={plants}
            />
          )}
        </div>
      </Mobile>
    </>
  );
};

Plant.propTypes = {
  from: PropTypes.instanceOf(Date),
  setFrom: PropTypes.func,
  to: PropTypes.instanceOf(Date),
  setTo: PropTypes.func,
  plantId: PropTypes.string,
  setPlantId: PropTypes.func,
};

export default Plant;
