import React, { useState, useEffect, useMemo } from "react";
import { subMonths } from "date-fns/esm";
import classNames from "classnames";
import PropTypes from "prop-types";

import { useOrganizationQuery } from "/src/services/apollo/hooks/useOrganizationQuery";
import { GET_TRANSACTIONS_AND_PRODUCTS } from "/src/services/apollo/queries/portfolio";

import ControlHeader from "/src/components/controlHeader";
import Select, { MultiSelect } from "/src/components/select";
import { RangePicker } from "/src/components/calendar";
import { useTranslation } from "src/translations/translationProvider";
import { Translate } from "src/translations/translate";
import LoadingSpinner from "/src/components/loadingSpinner";
import TransactionListTable from "./transactionListTable";
import { filterTransactions } from "./utils.jsx";
import PortfolioConfigurationSelect from "../portfolioConfigurationSelect";
import { NETMW_TYPE } from "/src/constants/portfolioConfigurationTypes";

import adminStyles from "/src/styles/admin.module.scss";
import financialStyles from "../financial.module.scss";
import styles from "./transactionList.module.scss";

const TransactionList = ({ portfolioReportConfigurations }) => {
  const { translate, formatDate, locale } = useTranslation();

  const validConfigurations = portfolioReportConfigurations.filter(
    (c) => c.type === NETMW_TYPE
  );
  const [selectedConfigurationIds, setSelectedConfigurationIds] = useState(
    validConfigurations.map((config) => config.id)
  );
  const ALL_ALTERNATIVE = {
    value: "ALL",
    label: (
      <span data-test-id={"all-products"} key={"products_all"}>
        <Translate>All products</Translate>
      </span>
    ),
  };

  const addAllAlternative = (options) => {
    return [ALL_ALTERNATIVE, ...options];
  };

  const defaultStartDate = subMonths(new Date(), 1);
  const defaultEndDate = new Date();

  const DEFAULT_STATE = {
    startDate: defaultStartDate,
    endDate: defaultEndDate,
    product: ALL_ALTERNATIVE,
    comment1: [],
    comment2: [],
  };

  const [filter, setFilter] = useState(DEFAULT_STATE);

  const transformForSelect = ({ options, shouldHaveAllAlternative }) => {
    const result = options.map((option, i) => ({
      value: option,
      label: (
        <span data-test-id={`transaction-product-list-${option}`} key={i}>
          {option}
        </span>
      ),
    }));
    return shouldHaveAllAlternative ? addAllAlternative(result) : result;
  };

  useEffect(() => {
    setFilter({ ...filter, product: ALL_ALTERNATIVE });
  }, [selectedConfigurationIds]); // eslint-disable-line react-hooks/exhaustive-deps

  const selectedConfigurationNames = useMemo(
    () =>
      validConfigurations
        .filter((c) => selectedConfigurationIds.includes(c.id))
        .map((c) => c.name),
    [validConfigurations, selectedConfigurationIds]
  );

  const { loading, error, data } = useOrganizationQuery(
    GET_TRANSACTIONS_AND_PRODUCTS,
    {
      variables: {
        portfolioConfigurationIds: selectedConfigurationIds,
        fromDate: formatDate(filter.startDate, "yyyy-MM-dd"),
        toDate: formatDate(filter.endDate, "yyyy-MM-dd"),
      },
    }
  );

  if (loading) {
    return (
      <div>
        <LoadingSpinner />
      </div>
    );
  }

  if (error) {
    return (
      <div>
        <Translate>UnexpectedError</Translate>
      </div>
    );
  }

  const { products, transactions } = data.organization.transactions;

  const sortAndTransformProducts = (products) => {
    const sortedProducts = products
      .filter((product) => {
        return transactions.some(
          (transaction) => transaction.product === product
        );
      })
      .slice()
      .sort();
    return transformForSelect({
      options: sortedProducts,
      shouldHaveAllAlternative: true,
    });
  };

  const sortAndTransformComments = (transactionList, valueIdentifier) => {
    const uniqueFilteredAndSorted = [
      ...new Set(
        transactionList
          .filter((t) => t[valueIdentifier])
          .map((t) => t[valueIdentifier])
      ),
    ].sort((a, b) => a.localeCompare(b, locale));
    return transformForSelect({
      options: uniqueFilteredAndSorted,
      shouldHaveAllAlternative: false,
    });
  };

  const shouldShowCommentFilters = transactions.some(
    (t) => t.comment1 || t.comment2
  );

  const getFilters = () => ({
    ...filter,
    comment1: filter.comment1?.map((c) => c.value),
    comment2: filter.comment2?.map((c) => c.value),
  });

  return (
    <div className={financialStyles.tabContent}>
      <ControlHeader>
        <div className={financialStyles.dropdown}>
          <Translate>Portfolios</Translate>:
          <PortfolioConfigurationSelect
            availableConfigurations={validConfigurations}
            selectedConfigurationIds={selectedConfigurationIds}
            setSelectedConfigurationIds={setSelectedConfigurationIds}
          />
        </div>
        <div className={financialStyles.dropdown}>
          <RangePicker
            from={filter.startDate}
            to={filter.endDate}
            onChange={([from, to]) =>
              setFilter({ ...filter, startDate: from, endDate: to })
            }
          />
        </div>
        <div className={financialStyles.dropdown}>
          <Translate>Product</Translate>:
          <Select
            isWithCaret
            key={selectedConfigurationIds}
            triggerClassName={classNames(
              adminStyles.trigger,
              financialStyles.trigger
            )}
            options={sortAndTransformProducts(products)}
            value={filter.product}
            onChange={(value) => setFilter({ ...filter, product: value })}
          />
        </div>
        {shouldShowCommentFilters && (
          <div className={styles.filterWrapper}>
            {`${translate("Comment")} 1:`}
            <MultiSelect
              showSelectAll
              key={`${transactions.length}comment1`}
              searchable
              isWithCaret
              showSelectedCount={filter.comment1.length > 0}
              wrapperClassName={styles.filter}
              triggerClassName={classNames(
                adminStyles.trigger,
                financialStyles.trigger
              )}
              options={sortAndTransformComments(transactions, "comment1")}
              value={filter.comment1}
              onChange={(value) => {
                setFilter({ ...filter, comment1: value });
              }}
            />
            {`${translate("Comment")} 2:`}
            <MultiSelect
              key={`${transactions.length}comment2`}
              showSelectAll
              searchable
              isWithCaret
              showSelectedCount={filter.comment2.length > 0}
              wrapperClassName={styles.filter}
              triggerClassName={classNames(
                adminStyles.trigger,
                financialStyles.trigger
              )}
              options={sortAndTransformComments(transactions, "comment2")}
              value={filter.comment2}
              onChange={(value) => {
                setFilter({ ...filter, comment2: value });
              }}
            />
          </div>
        )}
      </ControlHeader>
      <TransactionListTable
        className={styles.hedgeReportTable}
        unit={filter.product.currency}
        selectedProduct={filter.product}
        selectedPortfolioReportConfigurationNames={selectedConfigurationNames}
        data={filterTransactions({
          transactions: transactions,
          filter: getFilters(),
        })}
      />
    </div>
  );
};

TransactionList.propTypes = {
  portfolioReportConfigurations: PropTypes.array.isRequired,
};

export default TransactionList;
