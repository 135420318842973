import { useState, useEffect, useRef } from "react";

export const useExpandable = (value, mainRef) => {
  const ref = useRef();
  const [isExpanded, setExpanded] = useState(value);
  const toggleExpanded = () => setExpanded(!isExpanded);
  const isOutOfBounds = (
    { clientX, clientY },
    { left, right, top, bottom }
  ) => {
    const xOutOfBounds = clientX > right || clientX < left;
    const yOutOfBounds = clientY > bottom || clientY < top;
    return xOutOfBounds || yOutOfBounds;
  };

  useEffect(() => {
    const listener = (e) => {
      if (
        isExpanded &&
        (!ref.current || isOutOfBounds(e, ref.current.getBoundingClientRect()))
      ) {
        if (mainRef && mainRef.current?.onOutsideClick) {
          mainRef.current.onOutsideClick();
        }
        setExpanded(false);
      }
    };

    document.addEventListener("click", listener);
    return () => {
      document.removeEventListener("click", listener);
    };
  });

  return {
    isExpanded,
    setExpanded,
    ref,
    toggleExpanded,
  };
};
