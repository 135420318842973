import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { useTranslation } from "src/translations/translationProvider";
import ControlHeader from "/src/components/controlHeader/controlHeader";
import { MonthPicker } from "/src/components/calendar";
import Dialog from "/src/components/dialog";

import GoConsumptionExcelExport from "./goConsumptionExcelExport";
import PreliminaryVolume from "./preliminaryVolume";
import FinalVolume from "./finalVolume";

import financialStyles from "../../financial.module.scss";
import adminStyles from "/src/styles/admin.module.scss";
import styles from "./goConsumptionForm.module.scss";

const UNIT = "MWh";

const GoConsumptionForm = ({
  organizationId,
  goConsumptionData,
  elcQuotaExemptionData,
  updateConsumption,
  month,
  setMonth,
}) => {
  const { translate, formatDate } = useTranslation();

  const [goData, setGoData] = useState(goConsumptionData);
  const [openDialog, setOpenDialog] = useState(false);
  const [elcQuotaExemptions, setElcQuotaExemptions] = useState(
    elcQuotaExemptionData,
  );

  useEffect(() => {
    setGoData(goConsumptionData);
    setElcQuotaExemptions(elcQuotaExemptionData);
  }, [goConsumptionData, elcQuotaExemptionData]);

  const volumeSectionProps = {
    goData,
    goConsumptionData,
    elcQuotaExemptions,
    setGoData,
    setElcQuotaExemptions,
    setOpenDialog,
    unit: UNIT,
  };

  return (
    <>
      <ControlHeader className={styles.portfolioHeader}>
        <div className={financialStyles.dropdown}>
          {translate("Month")}:
          <MonthPicker
            month={new Date(month)}
            onChange={(date) => setMonth(formatDate(date, "yyyy-MM-dd"))}
            triggerClassName={classNames(
              adminStyles.trigger,
              financialStyles.trigger,
            )}
          />
        </div>
        <div className={styles.downloadButton}>
          <GoConsumptionExcelExport organizationId={organizationId} />
        </div>
      </ControlHeader>
      <div className={styles.goConsumptionForm}>
        <div className={styles.formWrapper}>
          <PreliminaryVolume {...volumeSectionProps} />
          <FinalVolume {...volumeSectionProps} />
        </div>
        {openDialog && (
          <Dialog
            forceClose={() => setOpenDialog(false)}
            title={translate("Are you sure you want to save?")}
            dialogSupport={translate("You can't undo this choice")}
            dialogSupportclassName={styles.description}
          >
            <button onClick={() => setOpenDialog(false)}>
              {translate("Cancel")}
            </button>
            <button
              onClick={() => {
                updateConsumption({
                  goConsumption: goData,
                  elcQuotaExemptions: elcQuotaExemptions,
                });
                setOpenDialog(false);
              }}
            >
              {translate("Save")}
            </button>
          </Dialog>
        )}
      </div>
    </>
  );
};

GoConsumptionForm.propTypes = {
  organizationId: PropTypes.string,
  goConsumptionData: PropTypes.array,
  elcQuotaExemptionData: PropTypes.array,
  updateConsumption: PropTypes.func,
  month: PropTypes.string,
  setMonth: PropTypes.func,
};

export default GoConsumptionForm;
