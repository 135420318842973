import React, { useState } from "react";
import { Translate } from "src/translations/translate";
import { GET_GO_SUMMARY } from "/src/services/apollo/queries";
import { useOrganizationQuery } from "/src/services/apollo/hooks/useOrganizationQuery";
import LoadingSpinner from "/src/components/loadingSpinner";

import GoSummary from "./goSummary";

const GoSummaryContainer = () => {
  const now = new Date().getFullYear();

  const [year, setYear] = useState(now);

  const { loading, error, data } = useOrganizationQuery(GET_GO_SUMMARY, {
    variables: {
      year: year,
    },
  });

  if (loading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return (
      <div>
        <Translate>UnexpectedError</Translate>
      </div>
    );
  }

  if (!data || !data.organization.goSummary) {
    return <Translate>No data found</Translate>;
  }

  const { goSummary } = data.organization;

  const props = {
    year,
    setYear,
    goSummary,
  };

  return <GoSummary {...props} />;
};

export default GoSummaryContainer;
