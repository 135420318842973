import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import styles from "./error.module.scss";

class Error extends React.Component {
  render() {
    const { className, errorString = "" } = this.props;
    const params = new URLSearchParams(errorString.replace("#", "?"));
    const error = params.get("error") || "Unknown error";
    const description = params.get("error_description");

    return (
      <div className={classnames(styles.error, className)}>
        <h1>Something went wrong :(</h1>
        <p>Error: {error}</p>
        <p>{description}</p>
      </div>
    );
  }
}

Error.propTypes = {
  className: PropTypes.string,
  errorString: PropTypes.string,
};

export default Error;
