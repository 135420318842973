import React from "react";
import PropTypes from "prop-types";

import { Translate } from "src/translations/translate";
import { NotMobile, Mobile } from "src/components/responsive";

import TabBar from "src/components/tabBar";
import PageLayout from "src/components/pageLayout";

import styles from "./energy.module.scss";

const Energy = ({ children, tabs, activeTab, onTabChange }) => {
  return (
    <PageLayout title={<Translate>Heading_ProductionConsumption</Translate>}>
      <NotMobile>
        <TabBar
          className={styles.tabs}
          optionClassName={styles.tab}
          activeOptionClassName={styles.activeTab}
          options={tabs}
          selectedValue={activeTab}
          onChange={onTabChange}
        />
      </NotMobile>
      <Mobile>
        <TabBar
          options={tabs}
          selectedValue={activeTab}
          onChange={onTabChange}
        />
      </Mobile>
      <main className={styles.content}>{children}</main>
    </PageLayout>
  );
};

Energy.propTypes = {
  children: PropTypes.node,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  activeTab: PropTypes.string,
  onTabChange: PropTypes.func,
};

export default Energy;
