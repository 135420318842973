import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import styles from "./card.module.scss";

const Card = ({
  isPreliminary,
  title,
  headerStyle,
  unit,
  slotOne,
  slotTwo,
  className,
  active,
  onClick,
  updateDate,
}) => {
  return (
    <div
      className={classNames(
        styles.card,
        className,
        { [styles.clickable]: !!onClick },
        { [styles.active]: active }
      )}
      onClick={onClick}
    >
      <div className={classNames(headerStyle, styles.header)}>
        {isPreliminary && <div className={styles.preliminaryIndicator} />}
        {title}
        <div className={styles.cardUnit}>{unit}</div>
      </div>
      <div className={styles.slot}>{slotOne}</div>
      {slotTwo && <div className={styles.slot}>{slotTwo}</div>}
      {updateDate && (
        <div className={styles.updateDate}>
          <p>{updateDate}</p>
        </div>
      )}
    </div>
  );
};

Card.propTypes = {
  title: PropTypes.node,
  slotOne: PropTypes.node,
  slotTwo: PropTypes.node,
  isPreliminary: PropTypes.bool,
  className: PropTypes.string,
  headerStyle: PropTypes.string,
  unit: PropTypes.string,
  active: PropTypes.bool,
  onClick: PropTypes.func,
  updateDate: PropTypes.string,
};

export default Card;
