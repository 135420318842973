import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import Input from "/src/components/input";
import { useEscape } from "/src/utilities/hooks";

import { SearchIcon } from "/src/icons";

import styles from "./filterInput.module.scss";
import adminStyles from "/src/styles/admin.module.scss";

function FilterInput(props) {
  const {
    onSearch,
    placeholder,
    className,
    inputClassName,
    iconWrapperClassName,
  } = props;
  const [filterValue, setFilterValue] = useState(props.defaultValue || "");

  const onChange = (searchText) => {
    setFilterValue(searchText);
    onSearch(searchText);
  };

  useEscape(() => {
    setFilterValue("");
  }, []);

  return (
    <div className={classNames(styles.search, className)}>
      <Input
        value={filterValue}
        className={classNames(styles.input, inputClassName)}
        placeholder={placeholder}
        onChange={onChange}
      />
      <div
        className={classNames(
          styles.iconWrapper,
          adminStyles.searchButton,
          iconWrapperClassName
        )}
      >
        <SearchIcon />
      </div>
    </div>
  );
}

FilterInput.propTypes = {
  onSearch: PropTypes.func.isRequired,
  defaultValue: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  iconWrapperClassName: PropTypes.string,
};

FilterInput.defaultProps = {
  type: "text",
};

export default FilterInput;
