import { gql } from "@apollo/client";

export const GET_USERS = gql`
  query getUsers($organizationId: String!) {
    organization(id: $organizationId) {
      id
      userConnections {
        user {
          id
          firstName
          lastName
          email
          showDailyComment
          avatar {
            fileUrl
          }
          active
        }
        role {
          name
        }
      }
    }
  }
`;
