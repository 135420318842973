import React from "react";
import PropTypes from "prop-types";

import { useOrganizationQuery } from "/src/services/apollo/hooks/useOrganizationQuery";
import { Translate } from "src/translations/translate";
import { useTranslation } from "src/translations/translationProvider";
import { GET_FINANCIAL_DATA } from "/src/services/apollo/queries";

import Financial from "./financial";
import LoadingSpinner from "/src/components/loadingSpinner";

const FinancialContainer = (restProps) => {
  const { locale } = useTranslation();
  const { loading, error, data } = useOrganizationQuery(GET_FINANCIAL_DATA);

  if (loading) {
    return <LoadingSpinner />;
  }

  if (error) {
    console.log(error); // eslint-disable-line no-console
    return (
      <div>
        <Translate>UnexpectedError</Translate>
      </div>
    );
  }

  const {
    portfolioReportConfigurations = [],
    indexOrders = [],
    id: organizationId,
    elcStartDate,
    goStartDate,
  } = data.organization;

  const sortedPortfolioReportConfigurations = portfolioReportConfigurations
    .slice()
    .sort((a, b) => a.name.localeCompare(b.name, locale));

  return (
    <Financial
      portfolioReportConfigurations={sortedPortfolioReportConfigurations}
      indexOrders={indexOrders}
      organizationId={organizationId}
      elcServiceStartDate={elcStartDate}
      goServiceStartDate={goStartDate}
      {...restProps}
    />
  );
};

FinancialContainer.propTypes = {
  restProps: PropTypes.object,
};

export default FinancialContainer;
