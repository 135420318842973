import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

import { useTranslation } from "src/translations/translationProvider";
import Table from "/src/components/table";
import { FormatOrEmpty } from "/src/components/tableCellFormats";

import portfolioStyles from "/src/modules/portfolio/portfolio.module.scss";
import styles from "./outcome.module.scss";

const ProductResultTable = ({ className, title, data }) => {
  const { translate } = useTranslation();
  let outcome = [],
    summary = {};
  if (data) ({ outcome, summary } = data);
  const { totalVolume } = summary;
  const unit = "MWh";

  /* eslint-disable react/prop-types */
  const columns = [
    {
      Header: translate("Product"),
      name: translate("Product"),
      accessor: "product",
      Cell: ({ value }) => value,
      Footer: translate("Summary"),
      footerClassName: styles.footerTitle,
    },
    {
      Header: `${translate("Hedged price")}/${unit}`,
      name: `${translate("Hedged price")}/${unit}`,
      accessor: "agreementPrice",
      Cell: ({ value }) => <FormatOrEmpty value={value} />,
      Footer: "",
      footerClassName: styles.hideCell,
    },
    {
      Header: `${translate("Heading_SpotPrice")}/${unit}`,
      name: `${translate("Heading_SpotPrice")}/${unit}`,
      accessor: "spotPrice",
      Cell: ({ value }) => <FormatOrEmpty value={value} />,
    },
    {
      Header: `${translate("Settlement")}/${unit}`,
      name: `${translate("Settlement")}/${unit}`,
      accessor: "settlementPrice",
      Cell: ({ value }) => <FormatOrEmpty value={value} />,
    },
    {
      Header: `${translate("Hedged volume")}/${unit}`,
      name: `${translate("Hedged volume")}/${unit}`,
      accessor: "totalVolume",
      Cell: ({ value }) => <FormatOrEmpty value={value} />,
      Footer: <FormatOrEmpty value={totalVolume} />,
      footerClassName: styles.footerSummary,
    },
    {
      Header: translate("Settlement"),
      name: translate("Settlement"),
      accessor: "outcome",
      Cell: ({ value, row }) => {
        const { currency } = row.original;
        return (
          <FormatOrEmpty
            value={value}
            className={portfolioStyles.highlighted}
            unit={currency}
          />
        );
      },
      Footer: <FormatOrEmpty value={summary.outcome} />,
      footerClassName: styles.footerSummary,
    },
  ];
  /* eslint-enable react/prop-types */

  return (
    <div className={classNames(className, portfolioStyles.table)}>
      <div className={portfolioStyles.title}>{title}</div>
      <Table
        headerClassName={classNames(
          portfolioStyles.cell,
          portfolioStyles.header
        )}
        cellClassName={portfolioStyles.cell}
        data={outcome}
        columns={columns}
        useFooter={true}
        exportable
      />
    </div>
  );
};

ProductResultTable.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  currency: PropTypes.string,
  data: PropTypes.object,
};

export default ProductResultTable;
