import React from "react";
import PropTypes from "prop-types";

import { useTranslation } from "src/translations/translationProvider";
import { OrganizationAppQuery } from "/src/components/appQuery";
import Chart, { LoadingChart, NoDataChart } from "/src/components/chart";
import { mapTypeToColor } from "/src/components/chart/utils";
import { roundNumber } from "/src/utilities/formatNumber";

import { GET_PLANT_AGGREGATES } from "/src/services/apollo/queries";

const PlantAggregateChart = ({ className, from, to, plantId }) => {
  const { translate, formatDate } = useTranslation();
  return (
    <OrganizationAppQuery
      query={GET_PLANT_AGGREGATES}
      variables={{
        plantId,
        fromDate: formatDate(new Date(from), "yyyy-MM-dd"),
        toDate: formatDate(new Date(to), "yyyy-MM-dd"),
      }}
      renderLoading={() => (
        <LoadingChart className={className} key="plant agg chart loading" />
      )}
      renderError={() => (
        <NoDataChart className={className} key="plant agg chart error" />
      )}
      renderNoContent={() => (
        <NoDataChart className={className} key="plant agg chart empty" />
      )}
    >
      {({ organization: { plant } }) => {
        const { aggregate } = plant.energy;
        if (!aggregate || aggregate.every((a) => a.readings.length === 0)) {
          return (
            <NoDataChart
              className={className}
              key="plant agg chart empty dataset"
            />
          );
        }
        const production = aggregate.find((a) => a.type === "Production");
        const consumption = aggregate.find((a) => a.type === "Consumption");
        const sortedAggregates = [production, consumption];

        return sortedAggregates.map((aggregate) => {
          if (aggregate.readings.length > 0) {
            const color = mapTypeToColor(aggregate.type);
            const chartData = {
              label: translate(aggregate.type),
              fill: false,
              borderColor: color,
              borderWidth: "2",
              backgroundColor: color,
              pointRadius: 0,
              pointHitRadius: 16,
              borderJoinStyle: "bevel",
              data: aggregate.readings.map((reading) => ({
                x: new Date(reading.time),
                y: roundNumber({
                  value: parseFloat(reading.value),
                  maxDecimals: 4,
                }),
              })),
            };
            return (
              <Chart
                key={aggregate.type}
                className={className}
                data={{ datasets: [chartData] }}
                options={{
                  xMin: from,
                  xMax: to,
                  yLabel: `${aggregate.unit}`,
                }}
              />
            );
          }
          return null;
        });
      }}
    </OrganizationAppQuery>
  );
};

PlantAggregateChart.propTypes = {
  className: PropTypes.string,
  from: PropTypes.object,
  to: PropTypes.object,
  plantId: PropTypes.string,
};

export default PlantAggregateChart;
