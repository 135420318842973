import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import classNames from "classnames";

import TabBar from "/src/components/tabBar";
import ErrorPage from "/src/modules/error-page";
import SUBPAGES from "./subPages";
import { useTranslation } from "src/translations/translationProvider";
import Orders from "./orders";
import IndexPoolInvoiceMaterial from "./indexPoolInvoiceMaterial";
import IndexPoolHedgeReport from "./indexPoolHedgeReport";

import portfolioStyles from "../../portfolio.module.scss";
import styles from "./indexPool.module.scss";

const IndexPoolContainer = ({ portfolioReportConfigurations }) => {
  const { translate } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();

  const subpages = Object.keys(SUBPAGES).map((subpage) => ({
    text: translate(`INDEXPOOL_${subpage}`),
    value: subpage,
  }));

  const [activePage, setActivePage] = useState(
    (params?.subpageLvl2 || subpages[0].value).toUpperCase()
  );

  const onPageChange = (page) => {
    setActivePage(page);
    navigate(`financial/index_pool/${page.toLowerCase()}`);
  };

  const renderSubpage = () => {
    switch (activePage) {
      case SUBPAGES.OVERVIEW:
        return (
          <IndexPoolHedgeReport
            key={portfolioReportConfigurations}
            portfolioReportConfigurations={portfolioReportConfigurations}
          />
        );
      case SUBPAGES.ORDERS:
        return (
          <Orders
            portfolioReportConfigurations={portfolioReportConfigurations}
          />
        );
      case SUBPAGES.INVOICES:
        return (
          <IndexPoolInvoiceMaterial
            portfolioReportConfigurations={portfolioReportConfigurations}
          />
        );
      default:
        return <ErrorPage />;
    }
  };

  return (
    <>
      <TabBar
        className={classNames(portfolioStyles.tabs, styles.tabs)}
        optionClassName={classNames(portfolioStyles.tab, styles.tab)}
        activeOptionClassName={portfolioStyles.activeTab}
        options={subpages}
        selectedValue={activePage}
        onChange={(page) => onPageChange(page)}
      />
      {renderSubpage()}
    </>
  );
};

IndexPoolContainer.propTypes = {
  organizationId: PropTypes.string,
  portfolioReportConfigurations: PropTypes.array,
};

export default IndexPoolContainer;
