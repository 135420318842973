import React from "react";

import ContactPanel from "./contactPanel";
import { GET_CONTACTS } from "/src/services/apollo/queries";
import { useOrganizationQuery } from "/src/services/apollo/hooks/useOrganizationQuery";

const ContactPanelContainer = (props) => {
  const { loading, error, data } = useOrganizationQuery(GET_CONTACTS);

  if (loading) {
    return <></>;
  }

  if (error) {
    console.log(error); // eslint-disable-line no-console
    return (
      <div>
        <ContactPanel contacts={[]} {...props} />
      </div>
    );
  }

  const { contacts } = data;

  return <ContactPanel contacts={contacts} {...props} />;
};

export default ContactPanelContainer;
