import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import styles from "./contact.module.scss";

const Contact = ({
  className,
  avatar,
  firstName,
  lastName,
  title,
  email,
  phoneNumber,
}) => {
  return (
    <div className={classNames(styles.contact, className)}>
      {avatar}
      <div className={styles.information}>
        <div className={styles.row}>
          <p className={styles.name}>
            {`${firstName} ${lastName}`}
            {title ? "," : ""}
          </p>
          <p className={styles.title}>{title ? title : ""}</p>
        </div>
        <div className={styles.row}>
          <a
            href={`tel:${phoneNumber}`}
            className={classNames(styles.link, styles.phoneNumber)}
          >
            {phoneNumber}
          </a>
        </div>
        <div className={styles.row}>
          <a
            href={`mailto:${email}`}
            className={classNames(styles.link, styles.email)}
          >
            {email}
          </a>
        </div>
      </div>
    </div>
  );
};

Contact.propTypes = {
  className: PropTypes.string,
  avatar: PropTypes.object,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  title: PropTypes.string,
  email: PropTypes.string,
  phoneNumber: PropTypes.string,
};

Contact.defaultProps = {
  firstName: "",
  lastName: "",
  title: "",
  email: "",
  phoneNumber: "",
};

export default Contact;
