import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

import { useTranslation } from "src/translations/translationProvider";
import { Translate } from "src/translations/translate";
import { TABS, tabRestrictionLookup } from "./tabs";
import { NotMobile, Mobile } from "/src/components/responsive";
import { hasAccess } from "/src/components/accessControl/accessCheck";
import NotIframe from "/src/components/notIframe";
import TabBar from "/src/components/tabBar";
import PageLayout from "/src/components/pageLayout";
import ErrorPage from "/src/modules/error-page";

import Total from "./total";
import Physical from "./physical";
import Financial from "./financial";

import styles from "./portfolio.module.scss";
import { useGetCurrentUser } from "src/modules/userProvider";

const Portfolio = () => {
  const { translate } = useTranslation();
  const currentUser = useGetCurrentUser();
  const userClaims = currentUser.selectedOrganizationConnection.role.claims;
  const navigate = useNavigate();
  const params = useParams();

  const tabs = Object.keys(TABS)
    .filter((tab) => {
      const restrictions = tabRestrictionLookup[tab];
      if (Array.isArray(restrictions)) {
        return restrictions.every((r) => hasAccess(r, userClaims));
      }
      return hasAccess(restrictions, userClaims);
    })
    .map((tab) => ({
      text: translate(`TABS_${tab}`),
      value: tab,
    }));

  const [activeTab, setActiveTab] = useState(
    (params?.tab || tabs[0].value).toUpperCase()
  );

  const onTabChange = (tab) => {
    setActiveTab(tab);
    if (!document.startViewTransition) {
      return navigate(`/portfolio/${tab.toLowerCase()}`);
    } else {
      return document.startViewTransition(() => {
        navigate(`/portfolio/${tab.toLowerCase()}`);
      });
    }
  };

  const renderTab = () => {
    switch (activeTab) {
      case TABS.TOTAL:
        return <Total />;
      case TABS.PHYSICAL:
        return <Physical />;
      case TABS.FINANCIAL:
        return <Financial />;
      default:
        return <ErrorPage />;
    }
  };

  return (
    <PageLayout title={<Translate>Portfolio report</Translate>}>
      <NotIframe>
        <NotMobile>
          <TabBar
            className={styles.tabs}
            optionClassName={styles.tab}
            activeOptionClassName={styles.activeTab}
            options={tabs}
            selectedValue={activeTab}
            onChange={(tab) => onTabChange(tab)}
          />
        </NotMobile>
        <Mobile>
          <TabBar
            options={tabs}
            selectedValue={activeTab}
            onChange={(tab) => onTabChange(tab)}
          />
        </Mobile>
      </NotIframe>

      <main className={styles.content}>{renderTab()}</main>
    </PageLayout>
  );
};

export default Portfolio;
