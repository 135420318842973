import { ErrorBoundary } from "/src/components/error";
import LoadingSpinner from "/src/components/loadingSpinner";
import PropTypes from "prop-types";
import { useOrganizationQuery } from "/src/services/apollo/hooks/useOrganizationQuery";
import React from "react";
import { GET_WEATHER_FOR_PLANT } from "/src/services/apollo/queries";
import { useTranslation } from "src/translations/translationProvider";
import { Translate } from "src/translations/translate";

import WeatherChart from "./weatherChart";
import styles from "./weatherChart.module.scss";

const WeatherChartContainer = ({
  from,
  to,
  latitude,
  longitude,
  plantId,
  ...props
}) => {
  const { translate } = useTranslation();

  const { loading, data, error } = useOrganizationQuery(GET_WEATHER_FOR_PLANT, {
    variables: {
      plantId,
      fromDate: new Intl.DateTimeFormat("sv").format(from),
      toDate: new Intl.DateTimeFormat("sv").format(to),
    },
  });

  if (loading) {
    return (
      <div>
        <LoadingSpinner />
      </div>
    );
  }

  if (error) {
    console.log(error); // eslint-disable-line no-console
    return (
      <div>
        <Translate>UnexpectedError</Translate>
      </div>
    );
  }

  const { temperature, wind } = data.organization.plant.weather;

  const mapReadingsToData = (readings) =>
    readings.map((r) => ({ x: new Date(r.time), y: r.value }));

  return (
    <div>
      <ErrorBoundary>
        <WeatherChart
          from={from}
          to={to}
          temperature={mapReadingsToData(temperature.readings)}
          wind={mapReadingsToData(wind.readings)}
          {...props}
        />
        <div className={styles.info}>
          <span>{`${translate("Temperature data from")} ${temperature.name} (~${
            temperature.distance
          }km)`}</span>

          <span>{`${translate("Wind data from")} ${wind.name} (~${
            wind.distance
          }km)`}</span>
          <span>{`${translate("Source")}: SMHI`}</span>
        </div>
      </ErrorBoundary>
    </div>
  );
};

WeatherChartContainer.propTypes = {
  from: PropTypes.instanceOf(Date),
  to: PropTypes.instanceOf(Date),
  plantId: PropTypes.string,
};

WeatherChartContainer.defaultProps = {
  from: new Date(),
  to: new Date(),
};

export default WeatherChartContainer;
