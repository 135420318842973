import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { startOfMonth, endOfMonth } from "date-fns";
import { Helmet } from "react-helmet";

import { useTranslation } from "src/translations/translationProvider";

import Energy from "./energy";
import Overview from "./overview";
import Plant from "./plant";
import { ORGANIZATION_CATEGORY } from "src/constants/organizationCategories";
import { TABS, SUPPLIER_TABS, BALANCE_TABS } from "./tabs";

import { useGetCurrentUser } from "src/modules/userProvider";
import { Availability } from "src/modules/energy/availability/availability";

const EnergyContainer = () => {
  const {
    selectedOrganizationConnection: {
      organization: { id: organizationId, category: organizationCategory },
    },
  } = useGetCurrentUser();
  const { translate } = useTranslation();
  const now = new Date();
  const [from, setFrom] = useState(startOfMonth(now));
  const [to, setTo] = useState(endOfMonth(now));
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const urlParams = new URLSearchParams(location.search);
  const urlPlantId = urlParams.get("id");
  const [plantId, setPlantId] = useState("");

  useEffect(() => {
    if (urlPlantId) {
      setPlantId(urlPlantId);
    }
  }, [urlPlantId]);

  useEffect(() => {
    setPlantId(""); // unset plant id when changing organization
  }, [organizationId]); // eslint-disable-line

  const activeTab = (params?.tab || TABS.OVERVIEW).toUpperCase();

  const isSupplier = organizationCategory === ORGANIZATION_CATEGORY.SUPPLIER;

  const tabs = isSupplier ? SUPPLIER_TABS : BALANCE_TABS;

  const tabsForSelector = Object.values(tabs).map((tab) => ({
    text: translate(`Energy_${tab}`),
    value: tab,
  }));

  const onTabChange = (tab) => {
    if (!document.startViewTransition) {
      return navigate(`${tab.toLowerCase()}`);
    } else {
      return document.startViewTransition(() => {
        navigate(`${tab.toLowerCase()}`);
      });
    }
  };

  const sharedStateProps = {
    from,
    setFrom,
    to,
    setTo,
    plantId,
    setPlantId,
  };

  return (
    <div>
      <Helmet>
        <title>{`Modity - ${translate("Menu_Energy")}`}</title>
      </Helmet>
      <Energy
        key={organizationId}
        tabs={tabsForSelector}
        activeTab={activeTab}
        onTabChange={onTabChange}
      >
        {activeTab === TABS.OVERVIEW && (
          <Overview
            {...sharedStateProps}
            organizationCategory={organizationCategory}
          />
        )}
        {activeTab === TABS.PLANT && <Plant {...sharedStateProps} />}
        {activeTab === TABS.AVAILABILITY && (
          <Availability
            organizationId={organizationId}
            plantId={sharedStateProps.plantId}
            setPlantId={sharedStateProps.setPlantId}
          />
        )}
      </Energy>
    </div>
  );
};

export default EnergyContainer;
