export const TABS = {
  INVOICES: "INVOICES",
  INVOICE_MATERIALS: "INVOICE_MATERIALS",
  DOCUMENTS: "DOCUMENTS",
};

export const tabRestrictionLookup = {
  INVOICES: [
    {
      type: "Invoices",
      permission: "Read",
    },
  ],
  DOCUMENTS: [
    {
      type: null,
      permission: "Read",
    },
  ],
  INVOICE_MATERIALS: [
    {
      type: "InvoiceMaterials",
      permission: "Read",
    },
  ],
};
