import { sv, enGB } from "date-fns/locale";
import { LanguageKey } from "src/config/translations/translationsTypes";

export function createChartOptions({
  translate,
  locale,
}: {
  translate: (value: LanguageKey) => string;
  locale: string;
}) {
  const timeFormat = "MM/DD/YYYY HH:mm";

  return {
    x: {
      adapters: {
        date: {
          locale: locale === "sv-SE" ? sv : enGB,
        },
      },
      offset: true,
      type: "time",
      time: {
        parser: timeFormat,
        tooltipFormat: "yyyy-MM-dd HH:mm",
        unit: "hour",
        displayFormats: {
          hour: "yyyy-MM-dd HH:mm",
          month: "yyyy-MM",
        },
      },
      title: {
        display: false,
      },
      ticks: {
        color: "#8c8e91",
        font: {
          size: 14,
          family: "AcuminPro",
          padding: 8,
        },
      },
      grid: {
        tickLength: 0,
        color: "rgba(0, 0, 0, 0.05)",
      },
    },

    y: {
      type: "linear",
      position: "left",
      beginAtZero: true,
      suggestedMin: 0,
      title: {
        display: true,
        text: translate("Power") + "/" + translate("Curtailment") + "(MW)",
      },
    },
    y1: {
      type: "linear",
      position: "right",
      beginAtZero: true,
      suggestedMin: 0,
      grid: {
        drawOnChartArea: false,
      },
      title: {
        display: true,
        text: translate("Ice") + "(%)",
      },
    },
  };
}
