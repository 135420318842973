import React, { useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import Select from "/src/components/select";

import { useTranslation } from "src/translations/translationProvider";
import { Translate } from "src/translations/translate";

import styles from "./plant.module.scss";

const PlantSelector = ({ plantId, setPlantId, plants, loading, error }) => {
  const { locale } = useTranslation();

  const sortedPlants = sortPlantsAlphabeticallyAscending(plants, locale);
  const selectedPlant = plantId
    ? sortedPlants.find((p) => p.id === plantId)
    : sortedPlants[0];

  useEffect(() => {
    if (!plantId && sortedPlants.length > 0) {
      setPlantId(sortedPlants[0].id);
    }
  }, [plantId, sortedPlants, setPlantId]);

  const hasLessThanTwoPlants = !sortedPlants || sortedPlants.length <= 1;
  const hasTwoOrMorePlants = sortedPlants && sortedPlants.length > 1;

  const renderLoading = () => {
    return (
      <div className={classNames(styles.heading, styles.section)}>
        <Translate>Getting list of plants</Translate>
      </div>
    );
  };

  const renderNoPlants = () => {
    return (
      <div className={classNames(styles.heading, styles.section)}>
        <Translate>No plants found</Translate>
      </div>
    );
  };

  return (
    (loading && renderLoading()) ||
    ((error || !plants || !plants.length) && renderNoPlants()) || (
      <div className={classNames(styles.heading, styles.section)}>
        <Translate>PLANT</Translate>{" "}
        <Select
          triggerClassName={styles.uppercase}
          isDisabled={hasLessThanTwoPlants}
          isWithCaret={hasTwoOrMorePlants}
          options={sortedPlants.map((p) => ({ label: p.name, value: p.id }))}
          value={{ value: selectedPlant.id, label: selectedPlant.name }}
          onChange={({ value }) => {
            if (value) {
              setPlantId(value);
            }
          }}
        />
      </div>
    )
  );
};

PlantSelector.propTypes = {
  plantId: PropTypes.string,
  setPlantId: PropTypes.func.isRequired,
  plants: PropTypes.array,
  loading: PropTypes.bool,
  error: PropTypes.bool,
};

export default PlantSelector;

export function sortPlantsAlphabeticallyAscending(plants, locale) {
  if (!Array.isArray(plants) || plants.length < 1) {
    return plants;
  }
  const validObjects = plants.every((plant) => plant.hasOwnProperty("name"));
  if (!validObjects) {
    return plants;
  }
  return [...plants].sort((a, b) =>
    a.name
      .toUpperCase()
      .localeCompare(b.name.toUpperCase(), locale, { numeric: true })
  );
}
