import React from "react";
import { getYear, startOfDay, subDays, getMonth } from "date-fns";
import { Link } from "react-router-dom";

import {
  GET_ORGANIZATION_MONTHLY_SUMMARIES_STANDARDS_FOR_DASHBOARD,
  GET_ORGANIZATION_MONTHLY_SUMMARIES_AGGREGATES_FOR_DASHBOARD,
} from "/src/services/apollo/queries";
import { ORGANIZATION_CATEGORY } from "/src/constants/organizationCategories";
import { OrganizationAppQuery } from "/src/components/appQuery";
import { useTranslation } from "src/translations/translationProvider";
import { Translate } from "src/translations/translate";
import EnergySummaryCard from "./energySummaryCard";
import { ArrowIcon } from "/src/icons";
import { ErrorBoundary } from "/src/components/error";

import styles from "./energySummary.module.scss";
import dashboardStyles from "../dashboard.module.scss";
import { useGetCurrentUser } from "src/modules/userProvider";

const EnergySummary = () => {
  const currentUser = useGetCurrentUser();
  const {
    selectedOrganizationConnection: {
      organization: { category: organizationCategory },
    },
  } = currentUser;
  const { formatDate } = useTranslation();

  const now = new Date();
  const chartFromDate = subDays(startOfDay(now), 6);
  const chartToDate = startOfDay(now);
  const currentYear = getYear(now);

  const usesStandards =
    organizationCategory === ORGANIZATION_CATEGORY.BALANCE_CONTROLLER;

  const query = usesStandards
    ? GET_ORGANIZATION_MONTHLY_SUMMARIES_STANDARDS_FOR_DASHBOARD
    : GET_ORGANIZATION_MONTHLY_SUMMARIES_AGGREGATES_FOR_DASHBOARD;

  const Container = ({ children }) => {
    return (
      <div className={styles.energySummary}>
        <ErrorBoundary>
          <div className={styles.cardContainer}>{children}</div>
        </ErrorBoundary>
        <div className={dashboardStyles.sectionLink}>
          <Link to="/energy" data-test-id="energy-link">
            <Translate>Consumption and Production</Translate>
            <ArrowIcon className={dashboardStyles.arrowIcon} />
          </Link>
        </div>
      </div>
    );
  };

  return (
    <OrganizationAppQuery
      query={query}
      variables={{
        year: currentYear,
        fromDate: formatDate(new Date(chartFromDate), "yyyy-MM-dd"),
        toDate: formatDate(new Date(chartToDate), "yyyy-MM-dd"),
      }}
      renderLoading={() => {
        return (
          <Container>
            <EnergySummaryCard isLoading />
            <EnergySummaryCard isLoading />
          </Container>
        );
      }}
      renderError={() => {
        return (
          <Container>
            <EnergySummaryCard error />
            <EnergySummaryCard error />
          </Container>
        );
      }}
      renderNoContent={() => {
        return (
          <Container>
            <EnergySummaryCard />
            <EnergySummaryCard />
          </Container>
        );
      }}
    >
      {(data) => {
        const { monthlySummary, standard, aggregate } =
          data.organization.energy;

        const currentMonthsTotal = monthlySummary.find(
          (month) => month.monthIndex === getMonth(now)
        );

        const consumptionProps = {
          chartFromDate,
          chartToDate,
          date: formatDate(now, "MMMM yyyy"),
          dataType: "Consumption",
          monthTotal: currentMonthsTotal.consumption,
          unitForTotal: currentMonthsTotal.unit,
          chartData: usesStandards
            ? standard.find(
                (dataset) => dataset.type === "HourlyStandardsConsumption"
              )
            : aggregate.find((dataset) => dataset.type === "Consumption"),
        };

        const productionProps = {
          ...consumptionProps,
          dataType: "Production",
          monthTotal: monthlySummary.find(
            (month) => month.monthIndex === getMonth(now)
          ).production,
          chartData: usesStandards
            ? standard.find(
                (dataset) => dataset.type === "HourlyStandardsProduction"
              )
            : aggregate.find((dataset) => dataset.type === "Production"),
        };

        return (
          <Container>
            <EnergySummaryCard {...productionProps} />
            <EnergySummaryCard {...consumptionProps} />
          </Container>
        );
      }}
    </OrganizationAppQuery>
  );
};

export default EnergySummary;
