export const filterTransactions = ({ transactions, filter }) => {
  const productFilter = (transaction) =>
    filter.product.value === "ALL" ||
    transaction.product === filter.product.value;

  const commentFilterIsOff = () =>
    filter.comment1.length === 0 && filter.comment2.length === 0;

  const comment1Filter = (transaction) =>
    filter.comment1?.includes(transaction?.comment1);

  const comment2Filter = (transaction) =>
    filter.comment2?.includes(transaction?.comment2);

  return transactions.filter(
    (transaction) =>
      productFilter(transaction) &&
      (commentFilterIsOff() ||
        comment1Filter(transaction) ||
        comment2Filter(transaction))
  );
};
