import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import classNames from "classnames";

import { NotMobile, Mobile } from "/src/components/responsive";
import { useTranslation } from "src/translations/translationProvider";
import TabBar from "/src/components/tabBar";
import ErrorPage from "/src/modules/error-page";

import SUBPAGES from "./subPages";
import HedgeReport from "./hedgeReport";
import TransactionList from "./transactionList";
import Outcome from "./outcome";
import IndexDetails from "./indexDetails";
import IndexPool from "./indexPool";
import GreenProducts from "./greenProducts";

import portfolioStyles from "../portfolio.module.scss";
import styles from "./financial.module.scss";

const Financial = ({
  portfolioReportConfigurations,
  indexOrders,
  organizationId,
  elcServiceStartDate,
  goServiceStartDate,
}) => {
  const { translate } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();

  const [activeSubpage, setActiveSubpage] = useState(
    (params?.subpage || SUBPAGES.HEDGE_REPORT).toUpperCase()
  );

  const onSubpageChange = (subpage) => {
    setActiveSubpage(subpage);
    navigate(`financial/${subpage.toLowerCase()}`);
  };

  const subpages = Object.keys(SUBPAGES).map((subpage) => ({
    text: translate(`SUBPAGE_${subpage}`),
    value: subpage,
  }));

  const renderSubpage = () => {
    switch (activeSubpage) {
      case SUBPAGES.HEDGE_REPORT:
        return (
          <HedgeReport
            key={portfolioReportConfigurations}
            organizationId={organizationId}
            portfolioReportConfigurations={portfolioReportConfigurations}
          />
        );
      case SUBPAGES.TRANSACTION_LIST:
        return (
          <TransactionList
            portfolioReportConfigurations={portfolioReportConfigurations}
          />
        );
      case SUBPAGES.INDEX_DETAILS:
        return (
          <IndexDetails
            portfolioReportConfigurations={portfolioReportConfigurations}
            indexOrders={indexOrders}
          />
        );
      case SUBPAGES.INDEX_POOL:
        return (
          <IndexPool
            portfolioReportConfigurations={portfolioReportConfigurations}
          />
        );
      case SUBPAGES.GREEN_PRODUCTS:
        return (
          <GreenProducts
            elcServiceStartDate={elcServiceStartDate}
            goServiceStartDate={goServiceStartDate}
            portfolioReportConfigurations={portfolioReportConfigurations}
          />
        );
      case SUBPAGES.OUTCOME:
        return (
          <Outcome
            portfolioReportConfigurations={portfolioReportConfigurations}
          />
        );
      default:
        return <ErrorPage />;
    }
  };

  return (
    <>
      <NotMobile>
        <TabBar
          className={styles.subpages}
          optionClassName={classNames(portfolioStyles.tab, styles.subpage)}
          activeOptionClassName={classNames(
            portfolioStyles.activeTab,
            styles.activeSubpage
          )}
          options={subpages}
          selectedValue={activeSubpage}
          onChange={(subpage) => onSubpageChange(subpage)}
          shouldSkipTabsClassName={true}
        />
      </NotMobile>
      <Mobile>
        <TabBar
          options={subpages}
          selectedValue={activeSubpage}
          onChange={(subpage) => onSubpageChange(subpage)}
        />
      </Mobile>
      {renderSubpage()}
    </>
  );
};

Financial.propTypes = {
  portfolioReportConfigurations: PropTypes.array,
  indexOrders: PropTypes.array,
  organizationId: PropTypes.string,
  elcServiceStartDate: PropTypes.string,
  goServiceStartDate: PropTypes.string,
};

export default Financial;
