import React from "react";

import { PlaceholderChart } from "/src/components/chart";

const chartProps = {
  data: {
    datasets: [
      {
        label: "Loading...",
        fill: false,
        borderColor: "#f08517",
        borderWidth: "2",
        backgroundColor: "#f08517",
        pointRadius: 0,
        pointHitRadius: 16,
        borderJoinStyle: "bevel",
        animations: {
          tension: {
            duration: 1500,
            easing: "linear",
            from: 1,
            to: 0,
            loop: true,
          },
          borderColor: {
            type: "color",
            duration: 1500,
            from: "black",
            to: "#f08517",
            loop: true,
          },
        },
        data: [
          {
            x: new Date("2023-04-04T22:00:00.000Z"),
            y: 12,
          },
          {
            x: new Date("2023-04-05T00:00:00.000Z"),
            y: 18,
          },
          {
            x: new Date("2023-04-06T00:00:00.000Z"),
            y: 24,
          },
          {
            x: new Date("2023-04-07T00:00:00.000Z"),
            y: 14,
          },
          {
            x: new Date("2023-04-08T00:00:00.000Z"),
            y: 17,
          },
          {
            x: new Date("2023-04-09T00:00:00.000Z"),
            y: 20,
          },
          {
            x: new Date("2023-04-10T00:00:00.000Z"),
            y: 15,
          },
          {
            x: new Date("2023-04-11T00:00:00.000Z"),
            y: 30,
          },
        ],
      },
    ],
  },
  options: {
    yLabel: "MWh",
    backgroundColor: "grey",
  },
};

const LoadingChart = () => <PlaceholderChart {...chartProps} />;

export { LoadingChart };
