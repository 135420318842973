const hasValue = (input) => input || input === 0;
const DEFAULT_DECIMALS = 2;
const SPACE_CHAR_CODE = 32;

export const formatNumber = (number, locale = "sv-SE") =>
  !hasValue(number)
    ? null
    : Number(number)
        .toLocaleString(locale, {
          minimumFractionDigits: DEFAULT_DECIMALS,
          maximumFractionDigits: DEFAULT_DECIMALS,
        })
        .replace(/\s/g, String.fromCharCode(SPACE_CHAR_CODE))
        .replace("−", "− ");

export const roundNumber = ({ value, maxDecimals = DEFAULT_DECIMALS }) => {
  // based on https://www.jacklmoore.com/notes/rounding-in-javascript/
  const exponentialNotationFormula = `${Math.round(
    `${value}e${maxDecimals}`
  )}e-${maxDecimals}`;
  return Number(exponentialNotationFormula);
};
