export const TABS = {
  TOTAL: "TOTAL",
  PHYSICAL: "PHYSICAL",
  FINANCIAL: "FINANCIAL",
};

export const tabRestrictionLookup = {
  TOTAL: [
    {
      type: "PortfolioPhysical",
      permission: "Read",
    },
    {
      type: "PortfolioFinancial",
      permission: "Read",
    },
  ],
  PHYSICAL: {
    type: "PortfolioPhysical",
    permission: "Read",
  },
  FINANCIAL: {
    type: "PortfolioFinancial",
    permission: "Read",
  },
};
