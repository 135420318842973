import React from "react";
import PropTypes from "prop-types";

import CSSTransition from "react-transition-group/cjs/CSSTransition";

import { transitionDuration } from "/src/config/config";

const Transition = ({ children, ...props }) => (
  <CSSTransition timeout={transitionDuration} {...props}>
    {children}
  </CSSTransition>
);

Transition.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default Transition;
