import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import styles from "./tableMobile.module.scss";

const TableMobile = ({ className, data, columns, tableClassName }) => (
  <div className={classNames(className)}>
    {data.map((entry, entryIndex) => (
      <div
        key={entryIndex}
        className={classNames(styles.tableMobile, tableClassName)}
      >
        {columns.map((column, columnIndex) => {
          const value = column.accessor
            ? entry[column.accessor]
            : column.accessorFunction(entry);

          const label = column.Header;

          return (
            <div className={styles.column} key={columnIndex}>
              <div className={classNames(styles.cell, styles.Header)}>
                {label}
              </div>
              <div className={classNames(styles.cell, styles.value)}>
                {value}
              </div>
            </div>
          );
        })}
      </div>
    ))}
  </div>
);

TableMobile.propTypes = {
  className: PropTypes.string,
  data: PropTypes.array.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      Header: PropTypes.string,
      accessor: PropTypes.string,
      accessorFunction: PropTypes.func,
    })
  ).isRequired,
  tableClassName: PropTypes.string,
};

export default TableMobile;
