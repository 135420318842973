import * as React from "react";
const SvgEye = (props) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <mask id="eye_svg__a" fill="#fff">
      <path
        d="M12.07 7.9c1.248 0 2.308.454 3.181 1.361.874.907 1.31 1.995 1.31 3.263 0 1.284-.436 2.376-1.31 3.275-.873.899-1.933 1.348-3.18 1.348-1.233 0-2.29-.45-3.17-1.348-.882-.9-1.322-1.99-1.322-3.275 0-1.268.44-2.356 1.322-3.263.88-.907 1.937-1.36 3.17-1.36zm0 7.706c.827 0 1.528-.305 2.105-.915s.866-1.34.866-2.191-.292-1.577-.877-2.18c-.585-.601-1.29-.902-2.117-.902s-1.532.3-2.117.903c-.585.602-.877 1.328-.877 2.179s.3 1.581.9 2.191c.6.61 1.306.915 2.117.915zm11.907-3.275c0 .033.004.065.011.097.008.032.012.064.012.096s-.004.068-.012.108-.011.077-.011.109c-.016.016-.024.032-.024.048v.072l-.023.024v.036c0 .008-.008.02-.024.037a12.83 12.83 0 0 1-2.058 3.419 13.037 13.037 0 0 1-2.83 2.516c-1.045.682-2.164 1.204-3.357 1.565S13.248 21 12 21s-2.468-.18-3.66-.542-2.313-.883-3.358-1.565-1.988-1.52-2.83-2.516a13.315 13.315 0 0 1-2.082-3.42v-.072l-.012-.012c-.007-.008-.011-.02-.011-.036v-.048a.189.189 0 0 0-.024-.072.407.407 0 0 0-.011-.097C.004 12.588 0 12.556 0 12.524s.004-.068.012-.108.011-.077.011-.109c.016-.016.024-.032.024-.048v-.072l.023-.024v-.072a13.382 13.382 0 0 1 4.936-5.972 12.731 12.731 0 0 1 3.368-1.577 12.683 12.683 0 0 1 7.334 0c1.193.361 2.304.887 3.333 1.577s1.965 1.537 2.807 2.54 1.528 2.147 2.058 3.432c.016.016.024.028.024.036v.036l.012.012c.007.008.011.02.011.036v.048c0 .016.008.04.024.072zM12 19.46c1.06 0 2.11-.145 3.146-.434a10.905 10.905 0 0 0 2.924-1.3c.912-.578 1.747-1.3 2.503-2.167.756-.867 1.384-1.878 1.883-3.034-.499-1.156-1.123-2.167-1.871-3.034a11.496 11.496 0 0 0-2.491-2.18 11.013 11.013 0 0 0-2.913-1.323 11.252 11.252 0 0 0-3.134-.446c-1.06 0-2.11.149-3.146.446A11.158 11.158 0 0 0 5.965 7.31a11.851 11.851 0 0 0-2.526 2.18 11.351 11.351 0 0 0-1.895 3.033 11.351 11.351 0 0 0 1.895 3.034c.764.867 1.602 1.59 2.514 2.167a10.942 10.942 0 0 0 2.912 1.3c1.03.29 2.075.434 3.135.434z"
        fillRule="evenodd"
      />
    </mask>
    <g fill="#f08517" mask="url(#eye_svg__a)">
      <path d="M0 0h24v24H0z" />
    </g>
  </svg>
);
export default SvgEye;
