import * as React from "react";
const SvgContact = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 39.568 39.575"
    {...props}
  >
    <g transform="translate(41 -864)">
      <path
        className="contact_svg__a"
        d="M-1.432 865.174A1.171 1.171 0 0 0-2.606 864h-18.788a1.174 1.174 0 0 0-1.174 1.174v17.155a1.176 1.176 0 0 0 1.983.851l4.471-4.26h13.508a1.174 1.174 0 0 0 1.174-1.174Zm-2.348 11.4h-12.777a1.175 1.175 0 0 0-.809.323l-2.818 2.689v-13.238h16.437Z"
      />
      <path
        className="contact_svg__a"
        d="m-27.073 879.492-4.434-7.706a3.588 3.588 0 0 0-5.635-.747l-2.3 2.288c-1.972 1.954-2.071 5.329-.328 9.519a37.449 37.449 0 0 0 8.083 11.421 37.593 37.593 0 0 0 11.414 8.068 14.011 14.011 0 0 0 5.289 1.235 5.792 5.792 0 0 0 4.227-1.559l2.284-2.288a3.586 3.586 0 0 0-.747-5.645l-7.7-4.439a3.6 3.6 0 0 0-4.326.574l-1.479 1.479a42.79 42.79 0 0 1-3.363-3.029 42.385 42.385 0 0 1-3.029-3.363l1.479-1.479a3.594 3.594 0 0 0 .569-4.329Zm-2.23 2.667-1.517 1.517a2.28 2.28 0 0 0-.169 3.053 45.177 45.177 0 0 0 3.234 3.6 45.522 45.522 0 0 0 3.6 3.236 2.284 2.284 0 0 0 3.053-.169l1.517-1.517a1.244 1.244 0 0 1 1.493-.2l7.706 4.438a1.24 1.24 0 0 1 .259 1.948l-2.288 2.288c-1.222 1.222-3.757 1.15-6.936-.178a35.362 35.362 0 0 1-10.669-7.585 34.983 34.983 0 0 1-7.575-10.684c-1.334-3.188-1.409-5.72-.178-6.936l2.282-2.269a1.211 1.211 0 0 1 .868-.361h.174a1.224 1.224 0 0 1 .912.611l4.43 7.708a1.238 1.238 0 0 1-.2 1.5Z"
      />
      <circle
        className="contact_svg__a"
        cx={1.25}
        cy={1.25}
        r={1.25}
        transform="translate(-18.081 870.092)"
      />
      <circle
        className="contact_svg__a"
        cx={1.25}
        cy={1.25}
        r={1.25}
        transform="translate(-8.081 870.092)"
      />
      <circle
        className="contact_svg__a"
        cx={1.25}
        cy={1.25}
        r={1.25}
        transform="translate(-13.081 870.092)"
      />
    </g>
  </svg>
);
export default SvgContact;
