import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import styles from "./backdrop.module.scss";

const Backdrop = ({ children, className, onClose }) => (
  <div
    onClick={() => onClose()}
    className={classNames(styles.backdrop, className)}
  >
    {children}
  </div>
);

Backdrop.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  onClose: PropTypes.func,
};

export default Backdrop;
