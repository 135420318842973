import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import DatePicker from "./datePicker";
import Checkbox from "../checkbox";

import styles from "./toggleableDatePicker.module.scss";

const ToggleableDatePicker = ({ className, toggled, date, onChange }) => {
  const [isPickerVisible, setIsPickerVisible] = useState(!!date);
  const [innerDate, setDate] = useState(date || new Date());

  const onPickerChanged = (pickerDate) => {
    setDate(pickerDate);
    onChange(pickerDate);
  };

  const togglePickerVisible = () => {
    const newVisibility = !isPickerVisible;
    setIsPickerVisible(newVisibility);
    onChange(newVisibility ? innerDate : null);
  };

  return (
    <div
      data-test-id="toggleable-datepicker"
      className={classNames(className, styles.component)}
    >
      <Checkbox
        type="toggle"
        checked={isPickerVisible}
        onClick={togglePickerVisible}
      />
      <DatePicker
        className={classNames({
          [styles.invisible]: !isPickerVisible,
        })}
        date={innerDate}
        onChange={onPickerChanged}
      />
    </div>
  );
};

ToggleableDatePicker.propTypes = {
  date: PropTypes.instanceOf(Date),
  className: PropTypes.string,
  toggled: PropTypes.bool,
  onChange: PropTypes.func,
};

export default ToggleableDatePicker;
