import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { useTranslation } from "src/translations/translationProvider";
import { Translate } from "src/translations/translate";
import Dropdown from "/src/components/dropdown";
import Checkbox from "/src/components/checkbox";
import Button from "/src/components/button";
import FilterInput from "/src/components/filterInput/filterInput";

import styles from "../select.module.scss";

const MultiSelect = ({
  wrapperClassName,
  triggerClassName,
  showSelectedCount,
  maxSelections,
  triggerNode,
  infoNode,
  value,
  options = [],
  onChange,
  isWithCaret,
  isBottomToTop,
  isDisabled,
  label,
  searchable,
  showSelectAll,
}) => {
  const { translate } = useTranslation();
  const dropdownRef = useRef(null);
  const maxNumberOfSelections = maxSelections || options?.length;
  const [selectedValue, setSelectedValue] = useState(value);
  const [filteredOptions, setFilteredOptions] = useState(options);

  const placeHolder = (
    <span>
      <Translate>Select</Translate>...
    </span>
  );
  const valueNode =
    (showSelectedCount && (
      <span>
        {selectedValue.length}{" "}
        {selectedValue.length > 1
          ? ` ${translate("MULTIPLE_SELECTED")}`
          : ` ${translate("SINGULAR_SELECTED")}`}
      </span>
    )) ||
    (value && (
      <span data-test-id="available-selections">
        {options.length} <Translate>available selections</Translate>
      </span>
    ));

  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  const selectItem = (selectedItem) => {
    const isSelected =
      selectedValue &&
      selectedValue.map((value) => value.value).includes(selectedItem.value);
    if (!isSelected && selectedValue.length < maxNumberOfSelections) {
      const newSelection = [...selectedValue, selectedItem];
      setSelectedValue(newSelection);
    } else {
      const newSelection =
        selectedValue &&
        selectedValue.filter((value) => {
          return value.value !== selectedItem.value;
        });

      setSelectedValue([...newSelection]);
    }
  };

  const outsideClick = () => {
    cancelButtonClick();
  };

  const doneButtonClick = () => {
    onChange(selectedValue);
    dropdownRef.current.forceClose();
  };

  const cancelButtonClick = () => {
    setSelectedValue(value);
    dropdownRef.current.forceClose();
  };

  const selectOrUnselectAll = () => {
    setSelectedValue(
      selectedValue.length === filteredOptions.length ? [] : filteredOptions,
    );
  };

  const getOptionLabel = (option) =>
    option.label.props && option.label.props.children
      ? option.label.props.children
      : option.label;

  const renderOptions = () => {
    const renderOption = (option, itemClass) => {
      const isSelected =
        selectedValue &&
        selectedValue.map((value) => value.value).includes(option.value);

      const isDisabled =
        option.disabled ||
        (selectedValue.length === maxNumberOfSelections && !isSelected);

      return (
        <div
          data-test-id={`select-item-option-${getOptionLabel(option)}`}
          className={classNames(itemClass, {
            [styles.disabled]: isDisabled,
          })}
          key={option.value}
          onClick={() => (isDisabled ? {} : selectItem(option))}
        >
          <Checkbox small checked={isSelected} disabled={isDisabled}></Checkbox>
          <span>{option.label}</span>
        </div>
      );
    };

    if (filteredOptions.some((o) => o.group)) {
      const groups = filteredOptions.reduce((acc, curr) => {
        const key = curr.group || "";
        (acc[key] = acc[key] || []).push(curr);
        return acc;
      }, {});

      return Object.keys(groups)
        .sort()
        .map((k) => (
          <div key={k}>
            <div className={styles.groupTitle}>{k}</div>
            {groups[k].map((option) =>
              renderOption(option, styles.dropDownItem),
            )}
          </div>
        ));
    }

    return filteredOptions.map((option) =>
      renderOption(option, classNames(styles.dropDownItem, styles.separated)),
    );
  };

  const optionsList =
    filteredOptions &&
    filteredOptions.length > 0 &&
    options.length > 0 &&
    renderOptions();

  const onSearch = (searchText) => {
    if (!searchText || searchText === "") {
      setFilteredOptions(options);
      return;
    }

    setFilteredOptions(
      options.filter((option) => {
        const name = !!option.label.props
          ? option.label.props.children
          : option.label;
        return name.toUpperCase().includes(searchText.toUpperCase());
      }),
    );
  };

  const searchBox = options && options.length > 1 && (
    <div className={classNames(styles.input)}>
      <FilterInput
        placeholder={translate("Filter by name")}
        onSearch={onSearch}
      />
    </div>
  );

  const trigger = (
    <>
      {label && <div className={styles.triggerLabel}>{label}</div>}
      <div
        className={classNames(triggerClassName, styles.trigger, {
          [styles.disabled]: isDisabled,
        })}
      >
        {triggerNode || valueNode || placeHolder}
      </div>
    </>
  );

  return (
    <Dropdown
      className={wrapperClassName}
      containerClassName={styles.container}
      ref={dropdownRef}
      trigger={trigger}
      triggerDisabled={isDisabled}
      onOutsideClickCallback={outsideClick}
      isWithCaret={isWithCaret}
      isBottomToTop={isBottomToTop}
    >
      <div data-test-id={`dropdown-${label}`} className={styles.content}>
        {showSelectAll && (
          <div
            data-test-id={`button-name-${showSelectAll}`}
            className={styles.selectAll}
          >
            <Button
              className={styles.allButton}
              small
              type={"secondary"}
              onClick={selectOrUnselectAll}
            >
              {selectedValue.length === filteredOptions.length ? (
                <Translate>Unselect all</Translate>
              ) : (
                <Translate>Select all</Translate>
              )}
            </Button>
          </div>
        )}
        {searchable && searchBox}
        {infoNode && <div className={styles.info}>{infoNode}</div>}
        {optionsList && <div>{optionsList}</div>}
      </div>
      <div className={styles.buttonRow}>
        <Button
          className={styles.cancelButton}
          small
          type={"secondary"}
          onClick={cancelButtonClick}
        >
          <Translate>Cancel</Translate>
        </Button>
        <Button
          data-test-id="select-done-button"
          small
          onClick={doneButtonClick}
        >
          <Translate>Done</Translate>
        </Button>
      </div>
    </Dropdown>
  );
};

const selectionType = PropTypes.shape({
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  value: PropTypes.string,
  group: PropTypes.string,
  disabled: PropTypes.bool,
});

const valueType = PropTypes.oneOfType([
  PropTypes.arrayOf(selectionType),
  selectionType,
]);

MultiSelect.propTypes = {
  triggerNode: PropTypes.node,
  infoNode: PropTypes.node,
  wrapperClassName: PropTypes.string,
  triggerClassName: PropTypes.string,
  triggerLabelClassName: PropTypes.string,
  value: valueType,
  showSelectedCount: PropTypes.bool,
  maxSelections: PropTypes.number,
  options: PropTypes.arrayOf(selectionType),
  onChange: PropTypes.func,
  isWithCaret: PropTypes.bool,
  isBottomToTop: PropTypes.bool,
  isDisabled: PropTypes.bool,
  label: PropTypes.string,
  searchable: PropTypes.bool,
  showSelectAll: PropTypes.bool,
};

MultiSelect.defaultProps = {
  onChange: () => {},
};

export { MultiSelect };
