import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

import { useTranslation } from "src/translations/translationProvider";
import Card from "/src/components/card";

import CardRows from "./cardRows";
import styles from "./resultCard.module.scss";

const ResultCard = ({
  className,
  isPreliminary,
  month,
  unit,
  production,
  consumption,
  year,
  standards,
  shouldShowStandards,
  shouldShowHourlyAndMonthly,
  loading,
}) => {
  const { translate } = useTranslation();
  let consumptionStandards = false;
  let productionStandards = false;
  if (shouldShowStandards && standards) {
    const keysForStandards = Object.keys(standards);
    const comsumptionKeys = keysForStandards.filter(
      (s) =>
        s === "consumptionMonthly" || s.includes("consumptionHourlyPriceArea")
    );
    consumptionStandards = comsumptionKeys.map((key) => ({
      key,
      value: standards[key],
    }));
    const productionKeys = keysForStandards.filter((s) =>
      s.includes("productionHourlyPriceArea")
    );
    productionStandards = productionKeys.map((key) => ({
      key,
      value: standards[key],
    }));
  }
  let monthlyConsumptionStandard = false;
  let hourlyConsumptionStandard = false;

  if (shouldShowHourlyAndMonthly && standards.consumptionMonthly) {
    monthlyConsumptionStandard = standards.consumptionMonthly;
    hourlyConsumptionStandard = consumption - monthlyConsumptionStandard;
  }

  return (
    <Card
      className={className}
      isPreliminary={isPreliminary}
      headerStyle={classNames(styles.title, styles.header)}
      title={
        <div>
          {month} {year}
        </div>
      }
      unit={unit}
      slotOne={
        <CardRows
          title={
            isPreliminary
              ? translate("Preliminary production")
              : translate("Production total")
          }
          unit={unit}
          total={production}
          usePrimaryColor={true}
          productionStandards={productionStandards}
          loading={loading}
        />
      }
      slotTwo={
        <CardRows
          title={
            isPreliminary
              ? translate("Preliminary consumption")
              : translate("Consumption total")
          }
          unit={unit}
          total={consumption}
          consumptionStandards={consumptionStandards}
          loading={loading}
          monthlyConsumptionStandard={monthlyConsumptionStandard}
          hourlyConsumptionStandard={hourlyConsumptionStandard}
        />
      }
    />
  );
};

ResultCard.propTypes = {
  unit: PropTypes.any,
  year: PropTypes.number,
  production: PropTypes.any,
  consumption: PropTypes.any,
  month: PropTypes.string,
  className: PropTypes.string,
  isPreliminary: PropTypes.bool,
  shouldShowStandards: PropTypes.bool,
  standards: PropTypes.shape({
    consumptionHourlyPriceArea1: PropTypes.number,
    consumptionHourlyPriceArea2: PropTypes.number,
    consumptionHourlyPriceArea3: PropTypes.number,
    consumptionHourlyPriceArea4: PropTypes.number,
    consumptionMonthly: PropTypes.number,
    productionHourlyPriceArea1: PropTypes.number,
    productionHourlyPriceArea2: PropTypes.number,
    productionHourlyPriceArea3: PropTypes.number,
    productionHourlyPriceArea4: PropTypes.number,
  }),
  loading: PropTypes.bool,
  shouldShowHourlyAndMonthly: PropTypes.bool,
};

export default ResultCard;
