import React, { ReactNode, useCallback, useContext, useState } from "react";
import { sv, enGB } from "date-fns/locale";
import { format, formatRelative } from "date-fns";
import {
  DEFAULT_LANGUAGE,
  DEFAULT_API_LOCALE,
  DEFAULT_LOCALE,
  SE,
  EN,
} from "src/config/config";
import {
  Language,
  LanguageKey,
} from "src/config/translations/translationsTypes";

const TranslationContext = React.createContext<
  | {
      language: Language;
      apiLocale: string;
      locale: string;
      translate: (value: LanguageKey) => string;
      selectLanguage: ({
        apiLocale,
        language,
        locale,
      }: {
        apiLocale: string;
        language: Language;
        locale: string;
      }) => void;
      formatDate: (date: number | Date, formatStr?: string) => string;
      formatDateRelative: (
        date: number | Date,
        compareDate: number | Date,
      ) => string;
    }
  | undefined
>(undefined);

const LOCALE = "locale";
const API_LOCALE = "apiLocale";
const LANGUAGE = "language";

export function TranslationProvider({ children }: { children: ReactNode }) {
  const [locale, setLocale] = useState(
    localStorage.getItem(LOCALE) || DEFAULT_LOCALE,
  );
  const [apiLocale, setApiLocale] = useState(
    localStorage.getItem(API_LOCALE) || DEFAULT_API_LOCALE,
  );
  const [language, setLanguage] = useState<Language>(
    (localStorage.getItem(LANGUAGE) as Language) || DEFAULT_LANGUAGE,
  );

  const translate = useCallback(
    (value: LanguageKey) => {
      // This can be removed when we have no js calling this function anymore
      if (SE[value] === undefined) {
        console.error(`${value} is not translated!`);
        return value;
      }
      if (language === "se") {
        return SE[value];
      }
      if (language === "en") {
        return EN[value];
      }
      console.error(`unsupported language ${language} is not translated!`);
      return value;
    },
    [SE, EN, language],
  );

  // TODO type up locales and api locales
  const selectLanguage = useCallback(
    ({
      apiLocale,
      language,
      locale,
    }: {
      apiLocale: string;
      language: Language;
      locale: string;
    }) => {
      localStorage.setItem(LOCALE, locale);
      localStorage.setItem(API_LOCALE, apiLocale);
      localStorage.setItem(LANGUAGE, language);

      setLocale(locale);
      setApiLocale(apiLocale);
      setLanguage(language);
    },
    [],
  );

  const formatDate = useCallback(
    (date: number | Date, formatStr = "PP") => {
      const locale = language === "en" ? enGB : sv;
      return format(date, formatStr, { locale });
    },
    [language],
  );

  const formatDateRelative = useCallback(
    (date: number | Date, compareDate: number | Date) => {
      const locale = language === "en" ? enGB : sv;
      return formatRelative(date, compareDate, { locale: locale });
    },
    [language],
  );

  const value = {
    language,
    apiLocale,
    locale,
    translate,
    selectLanguage,
    formatDate,
    formatDateRelative,
  };
  return (
    <TranslationContext.Provider value={value}>
      {children}
    </TranslationContext.Provider>
  );
}

export function useTranslation() {
  const context = useContext(TranslationContext)
  if (context === undefined) {
    throw new Error("useTranslation must be used within a TranslationProvider");
  }

  return context;
}
