import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import styles from "./controlHeader.module.scss";

const ControlHeader = ({ className, children }) => {
  return (
    <div className={classNames(styles.controlHeader, className)}>
      {children}
    </div>
  );
};

ControlHeader.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

export default ControlHeader;
