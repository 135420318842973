import {
  QueryHookOptions,
  TypedDocumentNode,
  useApolloClient,
  useQuery,
} from "@apollo/client";
import { DocumentNode, GraphQLError } from "graphql";
import { useContext, useEffect, useState } from "react";
import { useGetCurrentUser } from "src/modules/userProvider";
export * from "@apollo/client/react/hooks";

/**
 *
 * @param operation
 * @param options
 * @returns
 */
export function useOrganizationQuery<Data>(
  operation: DocumentNode | TypedDocumentNode<any, any>,
  options = {},
) {
  const [queryObservable, setQueryObservable] = useState();
  const [queryResult, setResult] = useState<{
    isLoading: boolean;
    isStale: boolean;
    error?: GraphQLError[];
    data?: Data;
  }>({
    isLoading: true,
    isStale: true,
  });

  const currentUser = useGetCurrentUser();
  let organizationId;
  if (currentUser) {
    organizationId = currentUser.selectedOrganizationConnection.organization.id;
  }
  const { variables, ...rest } = options;

  const variablesWithOrganizationId = { organizationId, ...variables };
  const optionsWithOrganizationId = {
    ...rest,
    variables: variablesWithOrganizationId,
  };

  const client = useApolloClient();

  useEffect(() => {
    const observable = client.watchQuery({
      ...optionsWithOrganizationId,
      query: operation,
    });

    setQueryObservable(observable);

    const subscription = observable.subscribe({
      next: (result) => {
        const { data, stale, errors, loading } = result;
        setResult({
          isLoading: loading,
          isStale: stale,
          error: errors,
          data,
        });
      },
      error: (error) => {
        console.error(error); // eslint-disable-line no-console
        setResult({
          isLoading: false,
          error,
        });
      },
    });

    return () => {
      subscription.unsubscribe();
    };
  }, Object.values(variablesWithOrganizationId)); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    loading: queryResult.isLoading,
    data: queryResult.data,
    error: queryResult.error,
    isStale: queryResult.isStale,
    queryObservable,
    apolloClient: client,
  };
}
