import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Image from "/src/components/image";
import { NotMobile, Mobile } from "/src/components/responsive";

import styles from "./hero.module.scss";
import globalStyles from "/src/styles/global.module.scss";

const Hero = ({
  className,
  children,
  responsiveImage,
  preamble,
  title,
  featured,
}) => {
  const captionTitle = featured
    ? styles.captionTitleFeatured
    : globalStyles.captionTitle;
  const captionText = featured
    ? styles.captionTextFeatured
    : globalStyles.captionText;

  return (
    <>
      <NotMobile>
        <Image
          responsiveImage={responsiveImage}
          className={classNames(
            styles.hero,
            featured ? styles.heroFeatured : "",
            className
          )}
        >
          <header className={classNames(styles.header)}>
            {title && <h2 className={captionTitle}>{title}</h2>}
            {preamble && <p className={captionText}>{preamble}</p>}
            {children}
          </header>
        </Image>
      </NotMobile>
      <Mobile>
        <div
          className={classNames(
            styles.heroWrapper,
            featured ? styles.heroFeatured : "",
            className
          )}
        >
          <Image responsiveImage={responsiveImage} className={styles.image} />
          <header className={classNames(styles.header)}>
            {title && (
              <h2 className={captionTitle}>
                <b>{title}</b>
              </h2>
            )}
            {preamble && <p className={captionText}>{preamble}</p>}
            {children}
          </header>
        </div>
      </Mobile>
    </>
  );
};

Hero.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  responsiveImage: PropTypes.object,
  preamble: PropTypes.string,
  title: PropTypes.string,
  featured: PropTypes.bool,
};

export default Hero;
