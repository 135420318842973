import { gql } from "@apollo/client";

export const GET_ORGANIZATION_STANDARDS = gql`
  query getOrganizationStandards(
    $organizationId: String!
    $fromDate: String!
    $toDate: String!
  ) {
    organization(id: $organizationId) {
      id
      energy {
        standard(fromDate: $fromDate, toDate: $toDate) {
          type
          unit
          readings {
            time
            value
          }
        }
      }
    }
  }
`;
