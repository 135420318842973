import React, { useState } from "react";
import propTypes from "prop-types";
import classNames from "classnames";

import config from "/src/config/config";
import { useTranslation } from "src/translations/translationProvider";
import { Translate } from "src/translations/translate";
import AuthLink from "/src/components/authLink";
import Badge from "/src/components/badge";

import { DownloadIcon } from "/src/icons";
import { DeleteIcon } from "/src/icons";

import styles from "/src/modules/files/files.module.scss";
import itemStyles from "./documentItem.module.scss";

const DocumentItem = ({
  item: { id, fileName, date, forEveryone },
  onDelete,
  showAdminControls,
}) => {
  const { formatDate } = useTranslation();
  const formattedDate = formatDate(date, "yyyy-MM-dd");
  const [downloading, setDownloading] = useState(false);

  const deleteItem = (event) => onDelete(event, { id, fileName });

  return (
    <div key={id} className={styles.link}>
      <div className={styles.file}>
        <div className={itemStyles.main}>
          <AuthLink
            url={`${config.fileProxyURL}/documents/${id}`}
            onDownloadingChanged={setDownloading}
          >
            <div className={itemStyles.linkContent}>
              <div className={styles.half}>
                <p className={styles.title}>{fileName}</p>
                <p className={styles.date}>{formattedDate}</p>
              </div>
              <div className={classNames(styles.half, itemStyles.buttons)}>
                {showAdminControls && forEveryone && (
                  <Badge className={itemStyles.badge}>
                    <Translate>Everyone</Translate>
                  </Badge>
                )}
                <DownloadIcon className={styles.downloadIcon} />
              </div>
            </div>
          </AuthLink>
        </div>
        {showAdminControls && !downloading && (
          <div className={itemStyles.delete}>
            <button onClick={deleteItem}>
              <DeleteIcon className={styles.deleteIcon} />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

DocumentItem.propTypes = {
  item: propTypes.shape({
    id: propTypes.string,
    fileName: propTypes.string,
    date: propTypes.instanceOf(Date),
    forEveryone: propTypes.bool,
    onDelete: propTypes.func,
    showAdminControls: propTypes.bool,
  }),
};

export default DocumentItem;
