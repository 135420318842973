import { ReactNode, createContext, useContext } from "react";
import {
  useOrganizationQuery,
  useQuery,
} from "src/services/apollo/hooks/useOrganizationQuery";
import { GET_CURRENT_USER } from "src/services/apollo/queries";
import styles from "src/modules/app/app.module.scss";
import LoadingSpinner from "src/components/loadingSpinner";
import {
  GetCurrentUserDocument,
  GetCurrentUserQuery,
} from "src/graphql/graphql";
import { MODITY_ORGANIZATION_NAME } from "src/config/config";

/**
 * This is only exported so tests files can get it and mock it.
 * Use the hook useGetCurrentUser() to get the context instead.
 * @deprecated
 */
export const UserContext = createContext<
  | (GetCurrentUserQuery["currentUser"] & {
      isModityAdmin: boolean | undefined;
    })
  | undefined
>(undefined);

export function UserProvider({ children }: { children: ReactNode }) {
  const { loading, error, data } = useQuery(GetCurrentUserDocument);

  if (import.meta.env.PROD) {
    console.log = () => {};
    console.error = () => {};
    console.debug = () => {};
  }

  if (loading) {
    return (
      <div className={styles.loadingWrapper}>
        <LoadingSpinner big />
      </div>
    );
  }

  if (error || !data) {
    throw error;
  }

  const { organizationConnections } = data.currentUser;
  const modityOrganization = organizationConnections.find(
    (org) => org?.organization.name === MODITY_ORGANIZATION_NAME,
  );
  const isModityAdmin =
    !!modityOrganization &&
    modityOrganization?.role?.claims?.some(
      (claim) => claim?.type === "Administration",
    );

  const user = {
    ...data.currentUser,
    isModityAdmin,
  };

  return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
}

export function useGetCurrentUser() {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error("useGetCurrentUser must be used within a UserProvider");
  }

  return context;
}
