import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useEscape } from "/src/utilities/hooks";

import { CloseIcon } from "/src/icons";
import Backdrop from "/src/components/backdrop";
import { FadeIn, SlideInFromRight } from "/src/components/transitions";

import styles from "./panel.module.scss";

const Panel = ({ className, children, isOpen, close }) => {
  useEscape(() => {
    close();
  }, [isOpen]);

  return ReactDOM.createPortal(
    <>
      <FadeIn in={isOpen} mountOnEnter unmountOnExit>
        <Backdrop onClose={close} className={styles.backdrop} />
      </FadeIn>
      <SlideInFromRight in={isOpen} mountOnEnter unmountOnExit>
        <div className={classNames(styles.panel, className)}>
          <div className={styles.closeButtonWrapper}>
            <button className={styles.close} onClick={() => close()}>
              <CloseIcon className="icon" />
            </button>
          </div>
          <div className={styles.content}>{children}</div>
        </div>
      </SlideInFromRight>
    </>,
    document.body
  );
};

Panel.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  isOpen: PropTypes.bool,
  close: PropTypes.func,
};

Panel.defaultProps = {
  close: () => {},
};

export default Panel;
