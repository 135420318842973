import React, { forwardRef } from "react";
import PropTypes from "prop-types";

import Input from "./input";
import ValidatedInput from "./validatedInput";

const InputContainer = forwardRef(({ validate, ...props }, ref) => {
  if (validate) {
    return <ValidatedInput ref={ref} validate={validate} {...props} />;
  }

  return <Input ref={ref} {...props} />;
});

InputContainer.displayName = "InputContainer";

InputContainer.propTypes = {
  ...Input.propTypes,
  validate: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        validationFunction: PropTypes.func.isRequired,
        errorMessage: PropTypes.string,
      })
    ),
    PropTypes.shape({
      validationFunction: PropTypes.func.isRequired,
      errorMessage: PropTypes.string,
    }),
  ]),
};

export default InputContainer;
