import { gql } from "@apollo/client";

export const GET_HEDGES = gql`
  query getHedges(
    $organizationId: String!
    $pointInTime: String!
    $portfolioConfigurationIds: [String]
    $productName: String!
    $currencies: [String]!
    $importedPosition: Int
    $comment1: [String]
    $comment2: [String]
  ) {
    organization(id: $organizationId) {
      id
      hedgeReport(
        portfolioConfigurationIds: $portfolioConfigurationIds
        pointInTime: $pointInTime
        productName: $productName
        currencies: $currencies
        importedPosition: $importedPosition
        comment1: $comment1
        comment2: $comment2
      ) {
        products
        resolution
        hedgeReportType
        hedges {
          period
          forecast
          hedgeVolume
          utilizedVolume
          agreementPrice
          openVolume
          marketPrice
          outcome
          portfolioPrice
          hedgePercentage
          mandateMinPercentage
          mandateMaxPercentage
          mandateMinVolume
          mandateMaxVolume
          settlementUnits
          targetVolume
          pricePerUnit
          goVolume
          goAgreementPrice
          elcVolume
          elcAgreementPrice
          totalAgreementPrice
        }
      }
    }
  }
`;
