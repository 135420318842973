import sjson from "secure-json-parse";

export function loadVisibleColumns() : { [key: string] : boolean } | null {
  const storageItem = localStorage.getItem("visibleHedgeColumns");
  return storageItem && sjson.parse(storageItem);
}

export function persistVisibleColumns(columns: { [key: string] : boolean }) {
  localStorage.setItem("visibleHedgeColumns", JSON.stringify(columns));
}
