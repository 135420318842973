import { isNumber } from "/src/utilities/validation";

const allPreliminaryFieldsFilled = (formData) =>
  formData.every(
    ({ hasFixedVolume, preliminaryVariableVolume, preliminaryFixedVolume }) =>
      hasFixedVolume
        ? isPositiveNumber(preliminaryFixedVolume) &&
          isPositiveNumber(preliminaryVariableVolume)
        : isPositiveNumber(preliminaryVariableVolume),
  );

const allFinalFieldsFilled = (formData) =>
  formData.every(({ hasFixedVolume, finalVariableVolume, finalFixedVolume }) =>
    hasFixedVolume
      ? isPositiveNumber(finalFixedVolume) &&
        isPositiveNumber(finalVariableVolume)
      : isPositiveNumber(finalVariableVolume),
  );

// Disabled if there is a field that don´t have a value
export const preliminarySaveButtonDisabled = (formData) =>
  !allPreliminaryFieldsFilled(formData);

// Disabled if there already is values saved
export const finalSaveButtonDisabled = (formData) =>
  !allFinalFieldsFilled(formData);

// Disabled if there already is values in the preliminary fields
export const preliminaryFieldDisabled = (formData) =>
  allPreliminaryFieldsFilled(formData);

// Disabled if there already is numbers in all final fields but also if the preliminary volumes is not saved
export const finalFieldDisabled = (formData) =>
  allFinalFieldsFilled(formData) || !allPreliminaryFieldsFilled(formData);

export const isPositiveNumber = (value) => {
  return isNumber(value) && value >= 0;
};

export const calculateTotalVolume = ({ fixedVolume, variableVolume }) =>
  fixedVolume ? fixedVolume + variableVolume : variableVolume;

export const calculateVariableVolume = ({ fixedVolume, totalVolume }) =>
  fixedVolume ? totalVolume - fixedVolume : totalVolume;
