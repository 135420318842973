import React from "react";
import PropTypes from "prop-types";

import classNames from "classnames";

import styles from "./placeholderChart.module.scss";

const PlaceholderChartText = ({ className, children }) => (
  <div className={classNames(styles.chartText, className)}>{children}</div>
);

PlaceholderChartText.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

export { PlaceholderChartText };
