import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

import { useTranslation } from "src/translations/translationProvider";
import { Translate } from "src/translations/translate";
import Table from "/src/components/table";
import { FormatOrEmpty } from "/src/components/tableCellFormats";

import styles from "./physicalTable.module.scss";
import portfolioStyles from "../portfolio.module.scss";

const PhysicalTable = ({
  className,
  title,
  unit,
  currency: originalCurrency,
  data,
}) => {
  const { translate } = useTranslation();
  const currency = originalCurrency.toUpperCase();

  const columns = [
    {
      accessor: "rowLabel",
      name: "",
      Cell: ({ value, row }) => {
        const { shouldBeBold } = row.original;
        return (
          <div
            className={classNames(
              {
                [styles.bold]: shouldBeBold,
              },
              styles.physicalTableCell
            )}
          >
            <Translate>{value}</Translate>
          </div>
        );
      },
    },
    {
      Header: `${translate("Volume")} ${unit}`,
      name: `${translate("Volume")} ${unit}`,
      accessor: "volume",
      Cell: ({ value, row }) => {
        const { shouldBeBold } = row.original;
        return (
          <FormatOrEmpty
            value={value}
            className={classNames(
              {
                [styles.bold]: shouldBeBold,
              },
              styles.physicalTableCell
            )}
          />
        );
      },
    },
    {
      Header: `${translate("Price per unit")} ${currency}/${unit}`,
      name: `${translate("Price per unit")} ${currency}/${unit}`,
      accessor: "price",
      Cell: ({ value, row }) => {
        const { shouldBeBold } = row.original;
        return (
          <FormatOrEmpty
            value={value}
            className={classNames(
              {
                [styles.bold]: shouldBeBold,
              },
              styles.physicalTableCell
            )}
          />
        );
      },
    },
    {
      Header: `${translate("Cost(-)/Revenue(+)")} ${currency}`,
      name: `${translate("Cost(-)/Revenue(+)")} ${currency}`,
      accessor: "amount",
      Cell: ({ value, row }) => {
        const { shouldBeBold } = row.original;
        return (
          <FormatOrEmpty
            value={value}
            className={classNames(
              {
                [styles.bold]: shouldBeBold,
              },
              styles.physicalTableCell
            )}
          />
        );
      },
    },
  ];
  /* eslint-enable react/prop-types */

  return (
    <div
      className={classNames(
        className,
        styles.physicalTable,
        portfolioStyles.table
      )}
    >
      <div
        data-test-id={`portfolio-table-month-${title}`}
        className={portfolioStyles.title}
      >
        {title}
      </div>
      <Table
        headerClassName={classNames(
          portfolioStyles.cell,
          styles.cell,
          portfolioStyles.header
        )}
        cellClassName={classNames(portfolioStyles.cell, styles.cell)}
        data={data}
        columns={columns}
        exportable
      />
    </div>
  );
};

PhysicalTable.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  unit: PropTypes.string,
  currency: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      volume: PropTypes.number,
      price: PropTypes.number,
      amount: PropTypes.number,
    })
  ),
};

export default PhysicalTable;
