import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { addHours, endOfDay, startOfDay, subHours } from "date-fns";

import { useTranslation } from "src/translations/translationProvider";
import { Translate } from "src/translations/translate";
import Calendar from "/src/components/calendar";
import Dropdown from "/src/components/dropdown";
import Button from "/src/components/button";

import styles from "/src/components/calendar/calendar.module.scss";

const UTC_HOUR_DIFF = 2;

const HedgeRangePicker = (initialProps) => {
  const { triggerClassName, from, to, onChange, isUTC, ...props } =
    initialProps;
  const { formatDate, translate } = useTranslation();
  const dropdownRef = useRef(null);
  const [singleDate, setSingleDate] = useState(to);
  const [selectedRange, setSelectedRange] = useState([from, to]);
  const [key, setKey] = useState(new Date().getTime());
  const [isPicking, setIsPicking] = useState(false);
  const toggleIsPicking = (date) => {
    setIsPicking(!isPicking);
    setSingleDate(date);
  };

  const decoratedOnChange = () => {
    if (isPicking) {
      const from = isUTC
        ? addHours(startOfDay(new Date(singleDate)), UTC_HOUR_DIFF)
        : startOfDay(new Date(singleDate));
      const to = isUTC
        ? subHours(endOfDay(new Date(singleDate)), UTC_HOUR_DIFF)
        : endOfDay(new Date(singleDate));

      setSelectedRange([from, to]);
      onChange([from, to]);
    } else {
      onChange(selectedRange);
    }
  };

  const resetState = () => {
    setKey(new Date().getTime());
    setIsPicking(false);
  };

  const outsideClick = () => {
    resetState();
  };

  const doneClick = () => {
    decoratedOnChange();
    dropdownRef.current.forceClose();
  };

  const cancelClick = () => {
    setSelectedRange([from, to]);
    dropdownRef.current.forceClose();
    resetState();
  };

  const format = (date) => formatDate(date, "yyyy");

  return (
    <Dropdown
      ref={dropdownRef}
      onOutsideClickCallback={() => outsideClick()}
      isWithCaret={true}
      className={styles.dropdown}
      trigger={
        <span className={classNames(triggerClassName, styles.selectedDates)}>
          {`${format(from)} - ${to ? format(to) : translate("Continues")}`}
        </span>
      }
    >
      <Calendar
        key={key}
        tileClassName={classNames({
          [styles.showHover]: isPicking,
        })}
        value={selectedRange}
        onClickDay={toggleIsPicking}
        selectRange
        onChange={setSelectedRange}
        returnValue={"range"}
        allowPartialRange
        {...props}
      />
      <div className={styles.buttonRow}>
        <Button
          className={styles.cancelButton}
          small
          type={"secondary"}
          onClick={() => cancelClick()}
        >
          <Translate>Cancel</Translate>
        </Button>
        <Button small onClick={() => doneClick()}>
          <Translate>Done</Translate>
        </Button>
      </div>
    </Dropdown>
  );
};

HedgeRangePicker.propTypes = {
  from: PropTypes.object,
  triggerClassName: PropTypes.string,
  to: PropTypes.object,
  onChange: PropTypes.func,
  isUTC: PropTypes.bool,
  minDetail: PropTypes.string,
  maxDetail: PropTypes.string,
};

HedgeRangePicker.defaultProps = {
  onChange: () => {},
};

export default HedgeRangePicker;
