import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import styles from "./badge.module.scss";

const Badge = ({ className, children, color }) => (
  <div
    className={classNames(
      styles.badge,
      {
        [styles.blue]: color === "blue",
        [styles.green]: color === "green",
      },
      className
    )}
  >
    {children}
  </div>
);

Badge.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  color: PropTypes.oneOf(["default", "blue", "green"]),
};

Badge.defaultProps = {
  color: "default",
};

export default Badge;
