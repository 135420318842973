import { useMediaQuery } from "react-responsive";
import { RESPONSIVE_TABLET, RESPONSIVE_MOBILE } from "/src/config/config";

export const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: RESPONSIVE_TABLET + 1 });
  return isDesktop ? children : null;
};

export const Tablet = ({ children }) => {
  const isTablet = useMediaQuery({
    minWidth: RESPONSIVE_MOBILE + 1,
    maxWidth: RESPONSIVE_TABLET,
  });
  return isTablet ? children : null;
};
export const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: RESPONSIVE_MOBILE });
  return isMobile ? children : null;
};
export const NotMobile = ({ children }) => {
  const isNotMobile = useMediaQuery({ minWidth: RESPONSIVE_MOBILE + 1 });
  return isNotMobile ? children : null;
};

export const isMobile = () =>
  window.matchMedia(`(max-width: ${RESPONSIVE_MOBILE}px)`).matches;
