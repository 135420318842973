import React from "react";
import PropTypes from "prop-types";

import auth from "/src/services/customAuth";

const CustomAuthenticationRequired = ({ children }) => {
  const isAuth = auth.isUserAuthenticated();
  if (!isAuth) {
    auth.redirectToLogin();
  }
  return <React.Fragment>{isAuth ? children : null}</React.Fragment>;
};

export default CustomAuthenticationRequired;

CustomAuthenticationRequired.propTypes = {
  children: PropTypes.node,
};
