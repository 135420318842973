import React, { useMemo } from "react";
import PropTypes from "prop-types";

import { useTranslation } from "src/translations/translationProvider";
import { MultiSelect } from "/src/components/select";
import classNames from "classnames";
import { getText } from "/src/constants/portfolioConfigurationTypes";

import adminStyles from "/src/styles/admin.module.scss";
import physicalStyles from "../physical/physical.module.scss";

const PortfolioConfigurationSelect = ({
  availableConfigurations,
  selectedConfigurationIds,
  setSelectedConfigurationIds,
  showGroups,
}) => {
  const { translate } = useTranslation();

  const options = useMemo(
    () =>
      availableConfigurations.map((option, i) => ({
        value: option.id.toString(),
        label: <span key={i}>{option.name}</span>,
        group: showGroups ? translate(getText(option.type)) : undefined,
      })),
    [availableConfigurations, showGroups, translate],
  );

  const selectedConfigurations = useMemo(
    () => options.filter((c) => selectedConfigurationIds.includes(c.value)),
    [options, selectedConfigurationIds],
  );

  return (
    <MultiSelect
      showSelectAll
      showSelectedCount={selectedConfigurations.length > 0}
      triggerClassName={classNames(adminStyles.trigger, physicalStyles.trigger)}
      isWithCaret
      options={options}
      value={selectedConfigurations}
      onChange={(selected) =>
        setSelectedConfigurationIds(selected.map((x) => x.value))
      }
    />
  );
};

PortfolioConfigurationSelect.propTypes = {
  availableConfigurations: PropTypes.array,
  selectedConfigurationIds: PropTypes.array,
  setSelectedConfigurationIds: PropTypes.func,
  showGroups: PropTypes.bool,
};

export default PortfolioConfigurationSelect;
