import React, { useState } from "react";
import PropTypes from "prop-types";

import IndexPoolHedgeReport from "./indexPoolHedgeReport";
import LoadingSpinner from "/src/components/loadingSpinner";
import { useOrganizationQuery } from "/src/services/apollo/hooks/useOrganizationQuery";
import { Translate } from "src/translations/translate";
import { GET_PRODUCTS_AND_INDEX_POOL_IDS } from "/src/services/apollo/queries";
import { INDEXPOOL_TYPE } from "/src/constants/portfolioConfigurationTypes";

const IndexPoolHedgeReportContainer = ({
  organizationId,
  portfolioReportConfigurations,
}) => {
  const validConfigurations = portfolioReportConfigurations.filter(
    (c) => c.type === INDEXPOOL_TYPE
  );

  const [selectedConfigurationIds, setSelectedConfigurationIds] = useState(
    validConfigurations.map((config) => config.id)
  );

  const { loading, error, data } = useOrganizationQuery(
    GET_PRODUCTS_AND_INDEX_POOL_IDS,
    {
      variables: {
        portfolioConfigurationIds: selectedConfigurationIds,
        fromDate: "2000-01-01",
      },
    }
  );

  if (loading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return (
      <div>
        <Translate>An error occurred.</Translate>
      </div>
    );
  }

  const { products, indexPools } = data.organization;

  return (
    <IndexPoolHedgeReport
      organizationId={organizationId}
      products={products}
      indexPools={indexPools}
      portfolioReportConfigurations={validConfigurations}
      selectedConfigurationIds={selectedConfigurationIds}
      setSelectedConfigurationIds={setSelectedConfigurationIds}
    ></IndexPoolHedgeReport>
  );
};

IndexPoolHedgeReportContainer.propTypes = {
  organizationId: PropTypes.string,
  portfolioReportConfigurations: PropTypes.array,
};

export default IndexPoolHedgeReportContainer;
