const SUBPAGES = {
  HEDGE_REPORT: "HEDGE_REPORT",
  TRANSACTION_LIST: "TRANSACTION_LIST",
  INDEX_DETAILS: "INDEX_DETAILS",
  INDEX_POOL: "INDEX_POOL",
  GREEN_PRODUCTS: "GREEN_PRODUCTS",
  OUTCOME: "OUTCOME",
};

export default SUBPAGES;
