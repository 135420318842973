import { useSpring, a, easings } from "@react-spring/web";

const ModityLogo = ({ className }) => {
  const anim = useSpring({
    loop: { reverse: true },
    from: {
      strokeDasharray: 47,
      strokeDashoffset: 47,
    },
    to: { strokeDashoffset: 0 },
    config: { duration: 1200, easing: easings.easeInOutSine },
  });

  return (
    <svg
      className={className}
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M15.4 15.05L15.4 7.53387L15.4 7.53334C15.4019 3.96566 12.3827 1.00397 8.57015 1H1.99259L1.99259 7.28095V7.28148C1.9907 10.849 5.00958 13.8106 8.82193 13.8148C8.82225 13.8148 8.82258 13.8148 8.8229 13.8148L14.15 13.8148"
          stroke="#ffe79f"
          strokeWidth="2.5"
        />
        <path
          d="M18.5926 15.05L18.5926 7.53387L18.5926 7.53334C18.5907 3.96566 21.6098 1.00397 25.4224 1H32L32 7.28095V7.28148C32.0019 10.849 28.983 13.8106 25.1707 13.8148C25.1703 13.8148 25.17 13.8148 25.1697 13.8148L19.84 13.8148"
          stroke="#94e9ff"
          strokeWidth="2.5"
        />
        <path
          d="M16.64 18.1852H8.83522C5.02805 18.1852 2.00609 21.1425 2 24.7142L2 31H8.57219C12.3794 31 15.4014 28.0426 15.4074 24.4708C15.4074 24.4706 15.4074 24.4704 15.4074 24.4702L15.4074 19.43"
          stroke="#86d6ff"
          strokeWidth="2.5"
        />
        <path
          d="M17.35 18.1852H25.1648C28.9719 18.1852 31.9939 21.1425 32 24.7142V31H25.4278C21.6206 31 18.5986 28.0426 18.5926 24.4708C18.5926 24.4706 18.5926 24.4704 18.5926 24.4702L18.5926 19.43"
          stroke="#d1e8b2"
          strokeWidth="2.5"
        />
      </g>
      <g>
        <a.path
          style={anim}
          id="Shape"
          d="M15.4 15.05L15.4 7.53387L15.4 7.53334C15.4019 3.96566 12.3827 1.00397 8.57015 1H1.99259L1.99259 7.28095V7.28148C1.9907 10.849 5.00958 13.8106 8.82193 13.8148C8.82225 13.8148 8.82258 13.8148 8.8229 13.8148L14.15 13.8148"
          stroke="#FFC20E"
          strokeWidth="2.5"
        />
        <a.path
          style={anim}
          id="Shape_2"
          d="M18.5926 15.05L18.5926 7.53387L18.5926 7.53334C18.5907 3.96566 21.6098 1.00397 25.4224 1H32L32 7.28095V7.28148C32.0019 10.849 28.983 13.8106 25.1707 13.8148C25.1703 13.8148 25.17 13.8148 25.1697 13.8148L19.84 13.8148"
          stroke="#00C0F3"
          strokeWidth="2.5"
        />
        <a.path
          style={anim}
          id="Shape_3"
          d="M16.64 18.1852H8.83522C5.02805 18.1852 2.00609 21.1425 2 24.7142L2 31H8.57219C12.3794 31 15.4014 28.0426 15.4074 24.4708C15.4074 24.4706 15.4074 24.4704 15.4074 24.4702L15.4074 19.43"
          stroke="#0089CF"
          strokeWidth="2.5"
        />
        <a.path
          style={anim}
          id="Shape_4"
          d="M17.35 18.1852H25.1648C28.9719 18.1852 31.9939 21.1425 32 24.7142V31H25.4278C21.6206 31 18.5986 28.0426 18.5926 24.4708C18.5926 24.4706 18.5926 24.4704 18.5926 24.4702L18.5926 19.43"
          stroke="#8DC63F"
          strokeWidth="2.5"
        />
      </g>
    </svg>
  );
};

export default ModityLogo;
