import React, { ReactNode, useContext, useState } from "react";
import PropTypes from "prop-types";

import { transitionDuration } from "src/config/config";
import Snackbar from "src/components/snackbar";
import { INotification } from "src/modules/notifications/notificationTypes";

const NotificationContext = React.createContext<
  { showNotification: (notificationMessage: string) => void } | undefined
>(undefined);

const SNACKBAR_MESSAGE_DURATION = 3800;
let notificationIdCounter = 0;

// without localNotifications we always get empty notifications state
// and get weird re-renders when removing notifications
let localNotifications: INotification[] = [];

export function NotificationProvider({ children }: { children: ReactNode }) {
  const [notifications, setNotifications] = useState<INotification[]>([]);

  const hideNotification = (id: number) => {
    const newNotifications = localNotifications.map((notification) =>
      notification.id === id
        ? {
            ...notification,
            open: false,
          }
        : notification,
    );
    localNotifications = newNotifications;
    setNotifications(newNotifications);
  };

  const removeNotification = (id: number) => {
    const newNotifications = localNotifications.filter(
      (notification) => notification.id !== id,
    );
    localNotifications = newNotifications;
    setNotifications(newNotifications);
  };

  const hideThenRemoveNotification = (id: number) => {
    hideNotification(id);
    setTimeout(() => {
      removeNotification(id);
    }, transitionDuration);
  };

  const showNotification = (notificationMessage: string) => {
    const id = notificationIdCounter++;
    const newNotification: INotification = {
      message: notificationMessage,
      id,
      open: true,
    };

    localNotifications = [newNotification, ...localNotifications];

    setNotifications(localNotifications);

    setTimeout(() => {
      hideThenRemoveNotification(id);
    }, SNACKBAR_MESSAGE_DURATION);
  };

  return (
    <NotificationContext.Provider value={{ showNotification }}>
      {children}

      {notifications.map((notification, index) => {
        const { message, id, open } = notification;
        return (
          <Snackbar key={id} message={message} open={open} index={index} />
        );
      })}
    </NotificationContext.Provider>
  );
}

export function useNotifications() {
  const context = useContext(NotificationContext)
  if (context === undefined) {
    throw new Error("useNotifications must be used within a  NotificationsProvider");
  }

  return context;
}
