import React from "react";
import PropTypes from "prop-types";

import LoadingSpinner from "/src/components/loadingSpinner";
import { ErrorBoundary } from "/src/components/error";
import { useQuery } from "/src/services/apollo/hooks/useOrganizationQuery";
import { useTranslation } from "src/translations/translationProvider";
import { Translate } from "src/translations/translate";

const AppQuery = ({
  children,
  renderError,
  renderLoading,
  renderNoContent,
  variables,
  query,
  ...queryProps
}) => {
  const { apiLocale } = useTranslation();
  const queryVariables = {
    locale: apiLocale,
    ...variables,
  };
  const {
    loading: isLoading,
    error,
    data,
  } = useQuery(query, {
    variables: queryVariables,
    ...queryProps,
  });

  const networkError = error && error.networkError;
  const customError = networkError && networkError.result;

  const renderContent = () => {
    if (isLoading) {
      return renderLoading();
    }

    if (customError) {
      if (renderError) {
        return renderError(customError);
      }
      throw customError;
    }

    if (error) {
      if (renderError) {
        return renderError(error);
      }
      throw error;
    }

    if (!data || (Array.isArray(data) && !data.length)) {
      return renderNoContent();
    }

    return children(data);
  };

  return <ErrorBoundary>{renderContent()}</ErrorBoundary>;
};

AppQuery.propTypes = {
  children: PropTypes.any.isRequired,
  renderError: PropTypes.func,
  renderLoading: PropTypes.func,
  renderNoContent: PropTypes.func,
  variables: PropTypes.object,
  query: PropTypes.any.isRequired,
};

AppQuery.defaultProps = {
  renderLoading: function renderLoading() {
    return <LoadingSpinner />;
  },
  renderNoContent: function renderNoContent() {
    return <Translate>No data found</Translate>;
  },
  variables: {},
};

export default AppQuery;
