import React, { useMemo } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

import { formatNumber } from "/src/utilities";
import { useTranslation } from "src/translations/translationProvider";
import { Translate } from "src/translations/translate";
import Table from "/src/components/table";

import portfolioStyles from "/src/modules/portfolio/portfolio.module.scss";
import financialStyles from "../../financial.module.scss";
import greenProductStyles from "../greenProducts.module.scss";

const ROW_FIELDS_ORDERED = [
  "consumptionVolume",
  "quotaObligationExemptedVolume",
  "quotaObligationVolume",
  "buyQuantity",
  "quotaPercent",
  "quotaQuantity",
  "buyPriceAvg",
];

const ElcWithService = ({ elcData }) => {
  const { translate, formatDate } = useTranslation();
  const months = elcData.map((dataRow) =>
    formatDate(new Date(2020, dataRow.month, 2), "MMMM")
  );
  const columns = useMemo(
    () => getColumnsData({ translate, months }),
    [translate, months]
  );

  const restructuredData = ROW_FIELDS_ORDERED.map((field) => {
    const result = {
      title: { name: translate(field) },
    };
    elcData.forEach((m) => {
      result[formatDate(new Date(2020, m.month, 2), "MMMM")] = formatNumber(
        m[field]
      );
    });
    return result;
  });

  return (
    <div data-test-id="elc-with-service-wrapper">
      <header className={greenProductStyles.sectionHeader}>
        <div
          className={classNames(portfolioStyles.title, financialStyles.title)}
        >
          <Translate>Electricity Certificates</Translate>
          {` - ${elcData[0]?.year || ""}`}
        </div>
      </header>
      {elcData.length > 0 && (
        <section
          className={greenProductStyles.tableWrapper}
          data-test-id="elcWithServiceWrapper"
        >
          <Table
            className={greenProductStyles.innerTable}
            data={restructuredData}
            columns={columns}
            cellClassName={portfolioStyles.cell}
            headerClassName={portfolioStyles.cell}
          />
        </section>
      )}
      {elcData.length < 1 && <Translate>No data found</Translate>}
    </div>
  );
};

ElcWithService.propTypes = {
  elcData: PropTypes.array,
};

export default ElcWithService;

function getColumnsData({ translate, months }) {
  const renderCell = ({ name }) => {
    return (
      <div
        className={classNames(
          portfolioStyles.header,
          portfolioStyles.cell,
          greenProductStyles.columnHeaderLabel
        )}
      >
        {name}
      </div>
    );
  };

  const renderMonth = {
    accessor: "title",
    Cell: ({ value }) => renderCell(value),
    name: translate("Month"),
  };
  return [
    renderMonth,
    ...months.map((month) => {
      return {
        Header: () => (
          <div className={greenProductStyles.columnHeaderLabel}>{month}</div>
        ),
        accessor: `${month}`,
        Cell: ({ value }) => value,
        name: month,
      };
    }),
  ];
}
