import { gql } from "@apollo/client";

export const GET_INDEX_POOLS = gql`
  query getIndexPools(
    $organizationId: String!
    $fromDate: String!
    $portfolioConfigurationIds: [String]
  ) {
    organization(id: $organizationId) {
      id
      indexPools(
        fromDate: $fromDate
        portfolioConfigurationIds: $portfolioConfigurationIds
      ) {
        id
        type
        key
        hedgePeriodStart
        hedgePeriodEnd
        deliveryStart
        deliveryEnd
        currency
        priceArea
      }
    }
  }
`;

export const GET_PRODUCTS_AND_INDEX_POOL_IDS = gql`
  query getProductsAndIndexPoolIds(
    $organizationId: String!
    $portfolioConfigurationIds: [String]
    $fromDate: String!
  ) {
    organization(id: $organizationId) {
      id
      products(portfolioConfigurationIds: $portfolioConfigurationIds) {
        name
        currencies
      }
      indexPools(
        fromDate: $fromDate
        portfolioConfigurationIds: $portfolioConfigurationIds
      ) {
        key
        id
        currency
        priceArea
      }
    }
  }
`;

export const GET_INDEXPOOL_INVOICE_MATERIAL = gql`
  query getIndexPoolInvoiceMaterial(
    $organizationId: String!
    $deliveryMonth: String!
    $portfolioConfigurationIds: [String]
  ) {
    organization(id: $organizationId) {
      id
      indexPoolInvoiceMaterial(
        deliveryMonth: $deliveryMonth
        portfolioConfigurationIds: $portfolioConfigurationIds
      ) {
        deliveryMonth
        indexPoolType
        firstDeliveryMonth
        currency
        priceArea
        hedgedPrice
        hedgedVolume
        spotPrice
        outcome
        targetVolume
      }
    }
  }
`;

export const GET_INDEXPOOL_HEDGEREPORT = gql`
  query getIndexPoolHedgeReport(
    $organizationId: String!
    $pointInTime: String
    $productName: String!
    $currencies: [String]!
    $indexPoolIds: [Int]
    $portfolioConfigurationIds: [String]
  ) {
    organization(id: $organizationId) {
      id
      indexPoolHedgeReport(
        pointInTime: $pointInTime
        productName: $productName
        currencies: $currencies
        indexPoolIds: $indexPoolIds
        portfolioConfigurationIds: $portfolioConfigurationIds
      ) {
        resolution
        products
        hedges {
          period
          forecast
          hedgeVolume
          targetVolume
          utilizedVolume
          agreementPrice
          openVolume
          marketPrice
          outcome
          portfolioPrice
          hedgePercentage
          mandateMinPercentage
          mandateMaxPercentage
          mandateMinVolume
          mandateMaxVolume
          pricePerUnit
          settlementUnits
          currency
        }
      }
    }
  }
`;
