import React from "react";
import PropTypes from "prop-types";

import Chart, {
  LoadingChart,
  PlaceholderChart,
  PlaceholderChartText,
  NoDataChart,
} from "/src/components/chart";
import { useTranslation } from "src/translations/translationProvider";
import { Translate } from "src/translations/translate";

const FinancialOutcomeChart = ({ loading, error, data, className }) => {
  const { translate } = useTranslation();

  if (!data) {
    return (
      <NoDataChart
        className={className}
        key="portfolio financial outcome chart empty"
      />
    );
  }

  const { outcome, summary } = data;

  if (loading) {
    return (
      <LoadingChart
        className={className}
        key="portfolio financial outcome chart loading"
      />
    );
  }

  if (error) {
    return (
      <PlaceholderChart
        className={className}
        key="portfolio financial outcome chart error"
      >
        <PlaceholderChartText>
          <Translate>UnexpectedError</Translate>
        </PlaceholderChartText>
      </PlaceholderChart>
    );
  }

  const chartData = [...outcome.map((p) => p.outcome), summary.outcome];
  const allProducts = [...outcome.map((o) => o.product), "TOTAL"];

  const datasetOptions = {
    fill: false,
    borderWidth: 0,
    pointRadius: 0,
    pointHitRadius: 16,
    borderJoinStyle: "bevel",
  };

  const datasets = [
    {
      ...datasetOptions,
      backgroundColor: "#15c2f3",
      label: `${translate("Calculated result for")} ${data.currency}`,
      data: chartData,
    },
  ];

  return (
    <Chart
      type="Bar"
      className={className}
      data={{
        labels: allProducts,
        datasets,
      }}
      options={{
        yLabel: data.currency,
        xAxisType: "category",
      }}
    />
  );
};

FinancialOutcomeChart.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.bool,
  className: PropTypes.string,
  data: PropTypes.object,
};

export default FinancialOutcomeChart;
