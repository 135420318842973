import React from "react";
import PropTypes from "prop-types";
import { Link, useLocation } from "react-router-dom";
import classNames from "classnames";

const HashLink = ({
  className,
  activeClassName,
  hash,
  children,
  ...restProps
}) => {
  const location = useLocation();
  const isActive = location.hash === hash;

  return (
    <Link
      to={{
        pathname: location.pathname,
        hash: location.hash === hash ? "" : hash,
        search: location.search,
      }}
      className={classNames(className, { [activeClassName]: isActive })}
      {...restProps}
    >
      {children}
    </Link>
  );
};

HashLink.propTypes = {
  activeClassName: PropTypes.string,
  className: PropTypes.string,
  hash: PropTypes.string,
  children: PropTypes.node,
};

export default HashLink;
