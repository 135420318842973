import React from "react";
import { Helmet } from "react-helmet";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "src/translations/translationProvider";

import Knowledge from "./knowledge";

const KnowledgeContainer = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const filter = params.get("filter") || "all";
  const searchInput = params.get("si") || "";

  const onSearch = (newSearchInput) => {
    if (searchInput === newSearchInput) {
      return;
    }

    params.set("si", newSearchInput);
    location.search = params.toString();

    navigate(location);
  };

  const onFilter = (newTypeFilter, newSearchInput) => {
    params.set("filter", newTypeFilter);
    params.set("si", newSearchInput);
    location.search = params.toString();

    navigate(location);
  };

  const { translate } = useTranslation();
  const filterOptions = [
    { text: translate("All"), value: "all" },
    { text: translate("Videos"), value: "videoarticle" },
    { text: translate("Webinars"), value: "webinararticle" },
    { text: translate("Articles"), value: "article" },
  ];

  return (
    <>
      <Helmet>
        <title>{`Modity - ${translate("Menu_Knowledge")}`}</title>
      </Helmet>
      <Knowledge
        key={searchInput}
        filterOptions={filterOptions}
        searchInput={searchInput}
        typeFilter={filter}
        onFilter={onFilter}
        onSearch={onSearch}
      />
    </>
  );
};

export default KnowledgeContainer;
