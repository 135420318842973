import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import Card from "/src/components/card";
import ValueUnit from "/src/components/valueUnit";

import styles from "./portfolioCard.module.scss";

const PortfolioCard = ({
  valueClassName,
  active,
  text,
  value,
  unit,
  vertical,
  onClick,
}) => {
  return (
    <Card
      onClick={onClick}
      slotOne={
        <div
          data-test-id={`portfolio-card-${text}`}
          className={styles.portfolioCard}
        >
          <h2>{text}</h2>
          <ValueUnit
            valueClassName={classNames(styles.value, valueClassName)}
            unitClassName={styles.unit}
            value={value}
            unit={unit}
            vertical={vertical}
          />
        </div>
      }
      active={active}
    />
  );
};

PortfolioCard.propTypes = {
  valueClassName: PropTypes.string,
  active: PropTypes.bool,
  text: PropTypes.node,
  value: PropTypes.node,
  unit: PropTypes.string,
  vertical: PropTypes.bool,
  onClick: PropTypes.func,
};

export default PortfolioCard;
