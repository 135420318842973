import { gql } from "@apollo/client";

import { Article } from "/src/services/apollo/fragments";

export const GET_FEATURED_ARTICLE = gql`
  query getFeaturedArticle($organizationId: String!, $locale: String = "en") {
    organization(id: $organizationId) {
      id
      featuredArticle(locale: $locale) {
        ...Article
      }
    }
  }
  ${Article}
`;
