import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { CopyIcon } from "/src/icons";
import styles from "./copyButton.module.scss";

const CopyButton = ({ className, onClick }) => (
  <button
    className={classNames(styles.copyButton, className)}
    onClick={onClick}
  >
    <CopyIcon className={styles.copyIcon} />
  </button>
);

CopyButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};

export default CopyButton;
