import React from "react";
import classNames from "classnames";
import { isBefore, startOfDay } from "date-fns";

import EnergyAggregateChart from "/src/components/chart/energyAggregateChart";
import {
  LoadingChart,
  PlaceholderChart,
  PlaceholderChartText,
} from "/src/components/chart";
import ValueUnit from "/src/components/valueUnit";
import { roundNumber, formatNumber } from "/src/utilities";
import { isMobile, NotMobile } from "/src/components/responsive";

import styles from "./energySummary.module.scss";
import globalStyles from "/src/styles/global.module.scss";
import { useTranslation } from "src/translations/translationProvider";
import { Translate } from "src/translations/translate";

const EnergySummaryCard = ({
  isLoading,
  error,
  dataType,
  date,
  monthTotal,
  chartData,
  unitForTotal,
  chartFromDate,
  chartToDate,
}) => {
  const { translate } = useTranslation();
  const now = new Date();
  const { readings, unit } = chartData;

  const chartProps = {
    type: dataType,
    readings,
    unit,
    from: chartFromDate,
    to: chartToDate,
    filterReadings: (reading) =>
      isBefore(new Date(reading.time), startOfDay(now)),
    options: {
      xMin: chartFromDate,
      xMax: chartToDate,
      yLabel: unit,
    },
  };

  return (
    <div className={styles.summaryCard}>
      <div className={styles.dateAndTotal}>
        <div className={styles.titleAndDate}>
          <div
            data-test-id={`production-card-${dataType}`}
            className={classNames(styles.header, {
              [globalStyles.sectionTitleLarge]: isMobile(),
            })}
          >
            <Translate>{dataType}</Translate>
          </div>
          <div className={styles.date}>{date}</div>
        </div>
        <div className={styles.total}>
          {`Total ${translate(dataType)}`}

          <ValueUnit
            valueClassName={classNames({
              [styles.primaryColor]: dataType === "Production",
            })}
            value={
              monthTotal || monthTotal === 0
                ? formatNumber(roundNumber({ value: monthTotal }))
                : "..."
            }
            unit={unitForTotal}
            vertical
          />
        </div>
      </div>
      <NotMobile>
        {isLoading && <LoadingChart />}
        {error && (
          <PlaceholderChart>
            <PlaceholderChartText>
              <Translate>UnexpectedError</Translate>
            </PlaceholderChartText>
          </PlaceholderChart>
        )}
        {!isLoading && !error && (
          <EnergyAggregateChart className={styles.chart} {...chartProps} />
        )}
      </NotMobile>
    </div>
  );
};

EnergySummaryCard.defaultProps = {
  isLoading: false,
  error: false,
  dataType: "Production",
  monthTotal: null,
  chartData: {
    readings: [],
    unit: "MWH",
  },
  unitForTotal: "MWH",
};

export default EnergySummaryCard;
