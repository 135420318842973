import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { isSameDay, isSameMonth } from "date-fns";

import { default as ReactCalendar } from "react-calendar";
import { useTranslation } from "src/translations/translationProvider";
import { CaretRightIcon as ArrowIcon } from "/src/icons";

import styles from "./calendar.module.scss";

const Calendar = ({ className, tileClassName, value, onChange, ...props }) => {
  const { locale } = useTranslation();
  const today = new Date();

  return (
    <ReactCalendar
      className={classNames(className, styles.calendar)}
      tileClassName={(tile) => {
        const tileIsToday =
          tile.view === "month" && isSameDay(today, tile.date);
        const tileIsThisMonth =
          tile.view === "year" && isSameMonth(today, tile.date);

        return classNames(tileClassName, styles.tile, {
          [styles.today]: tileIsToday || tileIsThisMonth,
        });
      }}
      prevLabel={<ArrowIcon className={styles.prev} />}
      nextLabel={<ArrowIcon className={styles.next} />}
      prev2Label={null}
      next2Label={null}
      onChange={onChange}
      value={value}
      locale={locale}
      {...props}
    />
  );
};

Calendar.propTypes = {
  className: PropTypes.string,
  tileClassName: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.object, // Date
    PropTypes.array, // [Date, Date]
  ]),
  onChange: PropTypes.func,
};

Calendar.defaultProps = {
  value: new Date(),
};

export default Calendar;
