import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import styles from "../tableMobile.module.scss";

const fakeDataSize = 3;
const fakeData = new Array(fakeDataSize).fill();

export const LoadingTableMobile = ({ className }) => (
  <div className={classNames(styles.loadingTableMobile, className)}>
    {fakeData.map((entry, i) => (
      <div key={i} className={classNames(styles.tableMobile)}>
        {fakeData.map((value, index) => {
          return (
            <div className={styles.column} key={i + index}>
              <div className={classNames(styles.cell, styles.header)}>
                &nbsp;
              </div>
              <div className={classNames(styles.cell, styles.value)}>
                &nbsp;
              </div>
            </div>
          );
        })}
      </div>
    ))}
  </div>
);

LoadingTableMobile.propTypes = {
  className: PropTypes.string,
};
