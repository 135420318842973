import React from "react";
import PropTypes from "prop-types";

import config from "/src/config/config";
import { Translate } from "src/translations/translate";
import AuthLink from "/src/components/authLink";
import Button from "/src/components/button";

const GoConsumptionExcelExport = ({ organizationId }) => {
  const downloadUrl = `${config.fileProxyURL}/greenproducts/consumption/${organizationId}/excel`;

  return (
    <AuthLink url={downloadUrl}>
      <Button small>
        <Translate>Download report</Translate>
      </Button>
    </AuthLink>
  );
};

GoConsumptionExcelExport.prototypes = {
  organizationId: PropTypes.string,
};

export default GoConsumptionExcelExport;
