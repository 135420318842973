import classNames from "classnames";
import { ReactNode } from "react";

import Chart from "src/components/chart/chart";

import styles from "./placeholderChart.module.scss";

export function PlaceholderChart({
  className,
  children,
  ...chartProps
}: {
  className?: string;
  children: ReactNode; //TODO fix chart props when converting chart.jsx to ts
}) {
  return (
    <div className={styles.wrapper}>
      <Chart className={className} {...chartProps} />
      <div className={classNames(styles.fade, styles.center)}>{children}</div>
    </div>
  );
}
