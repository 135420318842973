import { gql } from "@apollo/client";

export const GET_TOKEN_INFORMATION = gql`
  query getTokenInformation {
    apiTokens {
      expires
      issueDate
      revokedDate
      jti
      name
    }
  }
`;
