import React, { ErrorInfo, ReactNode } from "react";
import { SEVERITY_LEVELS, appInsights } from "src/analytics.js";
import { AuthError } from "@azure/msal-browser";

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
  error?: AuthError;
}

export class MsalErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    const severityLevel =
      error?.message && error.message.includes("user has cancelled")
        ? SEVERITY_LEVELS.WARNING
        : SEVERITY_LEVELS.ERROR;

    appInsights.trackException({
      exception: error,
      properties: { errorInfo, hasReloadedApp: true },
      severityLevel: severityLevel,
    });
  }

  static getDerivedStateFromError(error: Error) {
    if (error?.name === "AuthError" || error?.name == "ServerError") {
      const authError = error as AuthError;
      return {
        error: authError,
        hasError: true,
      };
    }

    return { hasError: false };
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      window.location.reload();
      return <></>;
    }

    return children;
  }
}
