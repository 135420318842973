import { ReactNode } from "react";
import classnames from "classnames";
import {
  LoadingChart,
  NoDataChart,
  PlaceholderChart,
  PlaceholderChartText,
} from "src/components/chart";
import { Desktop } from "src/components/responsive";
import { Translate } from "src/translations/translate";
import { PriceTrendsChart } from "src/modules/dashboard/priceTrends/priceTrendsChart";

import { useQuery } from "@apollo/client";
import { GetPriceTrendsDocument } from "src/graphql/graphql";
import { useGetCurrentUser } from "src/modules/userProvider";

import dashboardStyles from "../dashboard.module.scss";
import globalStyles from "/src/styles/global.module.scss";
import styles from "./priceTrends.module.scss";

function TrendContainer({ children }: { children: ReactNode }) {
  return (
    <div className={styles.wrapper}>
      <h2
        className={classnames(
          globalStyles.sectionTitle,
          dashboardStyles.sectionTitle,
        )}
      >
        &nbsp;
      </h2>
      <div className={styles.chartContainer}>
        <Desktop>{children}</Desktop>
      </div>
    </div>
  );
}

export function PriceTrends() {
  const user = useGetCurrentUser();
  const { data, loading, error } = useQuery(GetPriceTrendsDocument, {
    variables: {
      organizationId: user.selectedOrganizationConnection.organization.id,
    },
  });

  if (loading) {
    return (
      <TrendContainer>
        <LoadingChart />
      </TrendContainer>
    );
  }

  if (error) {
    return (
      <TrendContainer>
        <PlaceholderChart>
          <PlaceholderChartText>
            <Translate>UnexpectedError</Translate>
          </PlaceholderChartText>
        </PlaceholderChart>
      </TrendContainer>
    );
  }

  if (!data || (Array.isArray(data) && !data.length)) {
    return (
      <TrendContainer>
        <NoDataChart />
      </TrendContainer>
    );
  }

  const { spotPriceTrends, forwards } = data.organization;

  return (
    <TrendContainer>
      <PriceTrendsChart spotPrices={spotPriceTrends} forwards={forwards} />
    </TrendContainer>
  );
}
