import React from "react";

import ElcWithService from "./elcWithService";
import LoadingSpinner from "/src/components/loadingSpinner";
import { useOrganizationQuery } from "/src/services/apollo/hooks/useOrganizationQuery";
import { Translate } from "src/translations/translate";
import { GET_ELC_WITH_SERVICE } from "/src/services/apollo/queries";

const ElcWithServiceContainer = () => {
  const { loading, error, data } = useOrganizationQuery(GET_ELC_WITH_SERVICE);

  if (loading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return (
      <div>
        <Translate>UnexpectedError</Translate>
      </div>
    );
  }

  const { elcWithService } = data.organization;

  return <ElcWithService elcData={elcWithService} />;
};

export default ElcWithServiceContainer;
