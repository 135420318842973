import React from "react";
import PropTypes from "prop-types";

import config from "/src/config/config";
import { useTranslation } from "src/translations/translationProvider";
import { Translate } from "src/translations/translate";
import Dialog from "/src/components/dialog";
import { getToken } from "/src/auth/authenticationStrategies";
import { useNotifications } from "src/modules/notifications/notificationsProvider";

const DocumentDelete = ({ file: { id, fileName }, onClose, onSuccess }) => {
  const { translate } = useTranslation();
  const { showNotification } = useNotifications();

  const handleConfirm = async () => {
    const token = await getToken();
    const deleteUrl = `${config.fileProxyURL}/documents/${id}`;

    try {
      await fetch(deleteUrl, {
        method: "DELETE",
        mode: "cors",
        headers: { Authorization: `Bearer ${token}` },
      });

      onSuccess();

      showNotification("Document deleted");
    } catch (error) {
      console.log(error);
      showNotification("Failed to delete document");
    } finally {
      onClose();
    }
  };

  return (
    <Dialog
      forceClose={onClose}
      title={`${translate("Are you sure you want to remove")} ${fileName}?`}
      dialogSupport={<Translate>You can't undo this choice</Translate>}
    >
      <button onClick={onClose}>
        <Translate>Cancel</Translate>
      </button>
      <button onClick={handleConfirm}>
        <Translate>Delete</Translate>
      </button>
    </Dialog>
  );
};

DocumentDelete.propTypes = {
  file: PropTypes.shape({ id: PropTypes.string, fileName: PropTypes.string }),
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
};

export default DocumentDelete;
