import React from "react";
import PropTypes from "prop-types";

import SplitLayout from "/src/components/splitLayout";
import { Translate } from "src/translations/translate";
import { Mobile, NotMobile } from "/src/components/responsive";

import styles from "./userAgreement.module.scss";
import globalStyles from "/src/styles/global.module.scss";

import { Remarkable } from "remarkable";
import RemarkableReactRenderer from "remarkable-react";
const md = new Remarkable();
md.renderer = new RemarkableReactRenderer();

const UserAgreement = ({ content }) => (
  <SplitLayout
    mainContent={
      <>
        <NotMobile>
          <div
            data-test-id="user-agreement"
            className={globalStyles.pageContentWrapper}
          >
            <h1 className={globalStyles.pageTitle}>
              <Translate>User Agreement</Translate>
            </h1>
            <div className={styles.userAgreement}>{md.render(content)}</div>
          </div>
        </NotMobile>
        <Mobile>
          <div className={styles.mobileUserAgreement}>{md.render(content)}</div>
        </Mobile>
      </>
    }
  />
);

UserAgreement.propTypes = {
  content: PropTypes.string,
};

export default UserAgreement;
