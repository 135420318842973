import { gql } from "@apollo/client";

export const GET_FINANCIAL_OUTCOME_DATA = gql`
  query getPortfolioFinancialOutcome(
    $organizationId: String!
    $portfolioConfigurationIds: [String]
    $month: String!
  ) {
    organization(id: $organizationId) {
      id
      outcomes(
        portfolioConfigurationIds: $portfolioConfigurationIds
        month: $month
      ) {
        currencyResults {
          currency
          outcome {
            product
            agreementPrice
            settlementPrice
            spotPrice
            totalVolume
            outcome
          }
          summary {
            totalVolume
            outcome
          }
        }
        totalResult {
          outcomeSummary {
            currency
            value
          }
          outcome {
            currency
            outcome
          }
          exchangeRates {
            name
            value
          }
        }
      }
    }
  }
`;
