import React from "react";
import PropTypes from "prop-types";

import { Translate } from "src/translations/translate";
import { useTranslation } from "src/translations/translationProvider";
import { OrganizationAppQuery } from "/src/components/appQuery";
import Chart, {
  LoadingChart,
  PlaceholderChart,
  PlaceholderChartText,
} from "/src/components/chart";
import {
  distinctColors,
  getRandomGraphColors,
} from "/src/components/chart/utils";

import { GET_PLANT_MEASURAND_SERIES } from "/src/services/apollo/queries";

const PlantMeasurandChart = ({ className, from, to, plantId }) => {
  const { formatDate } = useTranslation();
  return (
    <OrganizationAppQuery
      query={GET_PLANT_MEASURAND_SERIES}
      variables={{
        plantId,
        fromDate: formatDate(new Date(from), "yyyy-MM-dd"),
        toDate: formatDate(new Date(to), "yyyy-MM-dd"),
      }}
      renderLoading={() => (
        <>
          <LoadingChart className={className} key="plant meas chart loading" />
        </>
      )}
      renderError={() => (
        <PlaceholderChart
          className={className}
          key="plant meas chart error" // this should be an error response once backend ticket 1198 is solved
        >
          <PlaceholderChartText>
            <Translate>No data found</Translate>
          </PlaceholderChartText>
        </PlaceholderChart>
      )}
      renderNoContent={() => (
        <PlaceholderChart className={className} key="plant meas chart empty">
          <PlaceholderChartText>
            <Translate>No data found</Translate>
          </PlaceholderChartText>
        </PlaceholderChart>
      )}
    >
      {({ organization: { plant } }) => {
        const { measurands } = plant.energy;
        if (!measurands || measurands.length < 1) {
          return (
            <PlaceholderChart
              className={className}
              key="plant meas chart empty manual"
            >
              <PlaceholderChartText>
                <Translate>No data found</Translate>
              </PlaceholderChartText>
            </PlaceholderChart>
          );
        }

        const graphColors =
          measurands.length > distinctColors.length
            ? getRandomGraphColors(measurands.length)
            : distinctColors.map((color) => color.hex);

        const measurandsByType = [
          {
            measurands: measurands.filter((measurand) =>
              measurand.series.type.toLowerCase().includes("consumption")
            ),
            type: "Consumption",
          },
          {
            measurands: measurands.filter((measurand) =>
              measurand.series.type.toLowerCase().includes("production")
            ),
            type: "Production",
          },
        ].filter(({ measurands }) => measurands.length > 0);

        return measurandsByType.map(({ measurands, type }, typeIndex) => {
          const datasets = measurands.map((measurand, measurandIndex) => {
            const index = typeIndex + measurandIndex;
            const color = graphColors[index];

            return {
              label: measurand.name,
              fill: false,
              borderColor: color,
              borderWidth: "2",
              backgroundColor: color,
              pointRadius: 0,
              pointHitRadius: 16,
              borderJoinStyle: "bevel",
              data: measurand.series.readings.map((reading) => ({
                x: new Date(reading.time),
                y: parseFloat(reading.value),
              })),
            };
          });

          return (
            <Chart
              key={`plant meas chart ${type}`}
              className={className}
              data={{ datasets }}
              options={{
                xMin: from,
                xMax: to,
                yLabel: `${measurands[0].series.unit}`,
              }}
            />
          );
        });
      }}
    </OrganizationAppQuery>
  );
};

PlantMeasurandChart.propTypes = {
  className: PropTypes.string,
  from: PropTypes.object,
  to: PropTypes.object,
  plantId: PropTypes.string,
};

export default PlantMeasurandChart;
