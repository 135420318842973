import React from "react";

import { useOrganizationQuery } from "/src/services/apollo/hooks/useOrganizationQuery";
import { Translate } from "src/translations/translate";
import { GET_INVOICE_MATERIALS } from "/src/services/apollo/queries";

import LoadingSpinner from "/src/components/loadingSpinner";
import InvoiceMaterials from "./invoiceMaterials";

const InvoiceMaterialsContainer = () => {
  const { loading, error, data } = useOrganizationQuery(GET_INVOICE_MATERIALS);

  if (loading) {
    return (
      <div>
        <LoadingSpinner />
      </div>
    );
  }

  if (error) {
    return (
      <div>
        <Translate>UnexpectedError</Translate>
      </div>
    );
  }

  const {
    invoiceMaterials: {
      financial: financialMaterials,
      physical: physicalMaterials,
    },
    id,
  } = data.organization;

  if (financialMaterials.length + physicalMaterials.length === 0) {
    return (
      <div data-test-id="invoiceMaterial">
        <Translate>No invoice material found</Translate>
      </div>
    );
  }
  return (
    <InvoiceMaterials
      financialMaterials={financialMaterials}
      physicalMaterials={physicalMaterials}
      organizationId={id}
    />
  );
};

export default InvoiceMaterialsContainer;
