import * as React from "react";
const SvgAdminKey = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      d="M47.92 28.36v-8.72c-4.28-1.527-5.433-1.598-6.033-3.048-.604-1.453.165-2.33 2.11-6.424l-6.165-6.166c-4.038 1.92-4.96 2.72-6.426 2.111-1.45-.602-1.531-1.772-3.044-6.034H19.64c-1.523 4.274-1.594 5.432-3.048 6.034-1.499.624-2.398-.2-6.424-2.11l-6.166 6.165c1.948 4.096 2.715 4.97 2.111 6.426-.602 1.45-1.756 1.523-6.034 3.046v8.722c4.264 1.515 5.432 1.594 6.034 3.046.606 1.463-.161 2.326-2.11 6.424l6.165 6.166c3.985-1.894 4.923-2.737 6.424-2.111 1.452.602 1.523 1.754 3.048 6.034h8.72c1.51-4.248 1.592-5.428 3.063-6.042 1.486-.614 2.365.197 6.41 2.117l6.165-6.166c-1.944-4.086-2.715-4.967-2.111-6.424.598-1.448 1.754-1.521 6.034-3.046zm-9.717 1.523c-1.15 2.778-.136 4.9.973 7.132l-2.161 2.16c-2.179-1.082-4.308-2.144-7.122-.976-2.783 1.158-3.569 3.375-4.362 5.735h-3.058c-.793-2.362-1.577-4.579-4.352-5.731-2.828-1.172-4.997-.09-7.134.973l-2.159-2.161c1.11-2.229 2.125-4.346.971-7.136-1.154-2.773-3.37-3.557-5.733-4.35v-3.056c2.362-.793 4.579-1.577 5.731-4.354 1.152-2.778.136-4.901-.973-7.134l2.161-2.16c2.157 1.072 4.31 2.146 7.136.972 2.775-1.15 3.559-3.369 4.352-5.731h3.058c.793 2.362 1.579 4.579 4.354 5.731 2.828 1.172 4.995.09 7.134-.973l2.16 2.161c-1.108 2.235-2.122 4.36-.972 7.136 1.15 2.773 3.367 3.557 5.731 4.352v3.058c-2.368.793-4.589 1.577-5.735 4.352z"
      style={{
        strokeWidth: 1.99341,
      }}
    />
    <path fill="none" d="M8.979 8.979H39.02V39.02H8.98Z" />
    <path d="m22.445 23.785 9.825-9.825 1.77 1.77-1.77 1.77 3.097 3.098-1.77 1.771-3.098-3.098-1.77 1.77 2.655 2.655-1.77 1.771-2.655-2.656-2.744 2.744a6.261 6.261 0 0 1-9.648 7.878 6.259 6.259 0 0 1 7.878-9.648zm-.797 7.877a3.755 3.755 0 1 0-5.309-5.309 3.755 3.755 0 0 0 5.31 5.31z" />
  </svg>
);
export default SvgAdminKey;
