import React from "react";
import PropTypes from "prop-types";

import { useOrganizationQuery } from "/src/services/apollo/hooks/useOrganizationQuery";
import { GET_PLANTS_LISTING } from "/src/services/apollo/queries";

import { Translate } from "src/translations/translate";
import { useTranslation } from "src/translations/translationProvider";
import OverviewTable from "./overviewTable";
import LoadingSpinner from "/src/components/loadingSpinner";

const PlantsListingTable = ({ from, to }) => {
  const { formatDate } = useTranslation();
  const { loading, error, data } = useOrganizationQuery(GET_PLANTS_LISTING, {
    variables: {
      fromDate: formatDate(new Date(from), "yyyy-MM-dd"),
      toDate: formatDate(new Date(to), "yyyy-MM-dd"),
    },
  });

  if (loading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return (
      <OverviewTable data={[]} key="org plants listing loading error">
        <div>
          <span>
            <Translate>UnexpectedError</Translate>
          </span>
        </div>
      </OverviewTable>
    );
  }

  const { plants } = data.organization.energy;

  if (!plants || plants.length === 0) {
    return null;
  }

  return <OverviewTable data={plants} key="org plants listing table" />;
};

PlantsListingTable.propTypes = {
  className: PropTypes.string,
  from: PropTypes.object,
  to: PropTypes.object,
};

export default PlantsListingTable;
