import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";

import { ROUTES } from "/src/config/config";

import { HamburgerMenuIcon } from "/src/icons";
import { HomeIcon } from "/src/icons";
import { InvoicesIcon } from "/src/icons";
import { KnowledgeIcon } from "/src/icons";
import { AdminIcon } from "/src/icons";
import { EnergyIcon } from "/src/icons";
import { DeveloperIcon } from "/src/icons";

import Menu from "/src/components/menu";
import { ReadAccessControl } from "/src/components/accessControl";
import { useTranslation } from "src/translations/translationProvider";
import { Translate } from "src/translations/translate";
import IconLabel from "../iconLabel";

import styles from "./navBar.module.scss";
import { useGetCurrentUser } from "src/modules/userProvider";

const getPageName = (pathname) => {
  const currentRoute = Object.values(ROUTES).find((route) => {
    return pathname.includes(route.path);
  });
  return currentRoute && currentRoute.pageName;
};

const NavBarMobile = () => {
  const currentUser = useGetCurrentUser();
  const { translate } = useTranslation();
  const { pathname } = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [pageName, setPageName] = useState(getPageName(pathname));

  useEffect(() => {
    setIsMenuOpen(false);
    setPageName(getPageName(pathname));
  }, [pathname]);

  function closeMenu() {
    setIsMenuOpen(false);
  }

  return (
    <div className={styles.navMobile}>
      <HamburgerMenuIcon
        className={styles.menuIcon}
        onClick={() => setIsMenuOpen(true)}
      />
      <Translate>{pageName}</Translate>
      <Menu user={currentUser} isOpen={isMenuOpen} close={closeMenu}>
        <div className={styles.menuContent}>
          <IconLabel icon={HomeIcon} label={translate("Home")} to="/" />

          <ReadAccessControl
            restrictionTypes={["Invoices", "Documents"]}
            operator={"or"}
          >
            <IconLabel
              icon={InvoicesIcon}
              label={translate("Files")}
              to="/files"
            />
          </ReadAccessControl>

          <ReadAccessControl restrictionTypes={["Knowledge"]}>
            <IconLabel
              icon={KnowledgeIcon}
              label={translate("Knowledge Center")}
              to="/knowledge"
            />
          </ReadAccessControl>

          <ReadAccessControl restrictionTypes={["Energy"]}>
            <IconLabel
              icon={EnergyIcon}
              label={translate("Consumption and Production")}
              to="/energy"
            />
          </ReadAccessControl>

          <ReadAccessControl restrictionTypes={["Administration"]}>
            <IconLabel
              icon={AdminIcon}
              label={translate("Users & Roles")}
              to="/admin"
            />
          </ReadAccessControl>

          <ReadAccessControl restrictionTypes={["Developer"]}>
            <IconLabel
              icon={DeveloperIcon}
              label={translate("Developer")}
              to="/developer"
            />
          </ReadAccessControl>
        </div>
      </Menu>
    </div>
  );
};

NavBarMobile.propTypes = {
  children: PropTypes.node,
  mutate: PropTypes.func,
};

export default NavBarMobile;
