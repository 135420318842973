import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import ValueUnit from "/src/components/valueUnit";
import { formatNumber } from "/src/utilities";
import { useTranslation } from "src/translations/translationProvider";

import config from "/src/config/config";
import AuthLink from "/src/components/authLink";

import styles from "/src/modules/files/files.module.scss";
import invoiceStyles from "./invoicePreview.module.scss";
import { DownloadIcon } from "/src/icons";
import { useGetCurrentUser } from "src/modules/userProvider";

const InvoicePreview = React.memo(
  ({
    invoice: { date, invoiceNumber, amount, currencyCode, id, type } = {},
    locale,
  }) => {
    const currentUser = useGetCurrentUser();
    const amountIsValidNumber = !Number.isNaN(Number(amount));
    const value = formatNumber(amount, locale);
    const { translate } = useTranslation();

    const { id: organizationId } =
      currentUser.selectedOrganizationConnection.organization;

    return (
      <div className={styles.link}>
        <AuthLink
          url={`${config.fileProxyURL}/invoices/${organizationId}/${id}`}
        >
          <div className={styles.file}>
            <div className={styles.half}>
              <p className={styles.title} title={invoiceNumber}>
                {invoiceNumber && `${translate(type)} #${invoiceNumber}`}
              </p>
              <p className={styles.date} title={date}>
                {date &&
                  new Date(date.split("T")[0]).toISOString().split("T")[0]}
              </p>
            </div>
            <div
              className={classNames(styles.half, invoiceStyles.valueAndIcon)}
            >
              {amountIsValidNumber && (
                <ValueUnit
                  value={value}
                  unit={String(currencyCode).toUpperCase()}
                />
              )}
              <DownloadIcon className={styles.downloadIcon} />
            </div>
          </div>
        </AuthLink>
      </div>
    );
  },
);

InvoicePreview.propTypes = {
  invoice: PropTypes.shape({
    date: PropTypes.string,
    id: PropTypes.string,
    amount: PropTypes.number,
    currencyCode: PropTypes.string,
    type: PropTypes.string,
  }),
  locale: PropTypes.string,
};

InvoicePreview.defaultProps = {
  locale: "sv-SE",
};

export default InvoicePreview;
