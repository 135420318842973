import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import classNames from "classnames";

import { phoneNumberFormat } from "/src/utilities";
import { useTranslation } from "src/translations/translationProvider";
import { Translate } from "src/translations/translate";
import Contact from "/src/components/contact";
import Avatar from "/src/components/avatar";
import Panel from "/src/components/panel";

import styles from "./contactPanel.module.scss";
import globalStyles from "/src/styles/global.module.scss";

import { supportContact } from "./data.mock";

const ContactPanel = ({ className, contacts }) => {
  const { translate } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  function closeUserProfile() {
    location.hash = location.hash.replace("contact", "");
    navigate(location, { replace: true });
  }

  return (
    <Panel
      isOpen={window.location.hash.includes("contact")}
      close={closeUserProfile}
    >
      <div
        data-test-id="contacts"
        className={classNames(styles.ContactPanel, className)}
      >
        <h1 className={globalStyles.pageTitle}>
          <Translate>Contact us</Translate>
        </h1>
        <div className={styles.contacts}>
          {contacts.map((contact, i) => {
            return (
              <Contact
                key={i}
                avatar={
                  <Avatar
                    imageUrl={contact.fileUrl}
                    className={styles.contactAvatar}
                  />
                }
                firstName={contact.firstName}
                lastName={contact.lastName}
                title={translate(contact.function)}
                email={contact.email}
                phoneNumber={phoneNumberFormat(contact.phoneNumber)}
              />
            );
          })}

          <Contact
            className={classNames(styles.contact, styles.noImage)}
            firstName={translate("Other matters")}
            lastName={supportContact.lastName}
            email={supportContact.email}
          />
        </div>
      </div>
    </Panel>
  );
};

ContactPanel.propTypes = {
  className: PropTypes.string,
  contacts: PropTypes.arrayOf(
    PropTypes.shape({
      userId: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      fileUrl: PropTypes.string,
      email: PropTypes.string,
      phoneNumber: PropTypes.string,
      function: PropTypes.string,
    })
  ),
};

export default ContactPanel;
