import React from "react";
import classnames from "classnames";
import { Link } from "react-router-dom";

import ArticlePreview from "/src/components/articlePreview";
import ArticleListLoader from "/src/modules/knowledge/articleListLoader";
import { Translate } from "src/translations/translate";
import { ErrorBoundary } from "/src/components/error";
import { OrganizationAppQuery } from "/src/components/appQuery";
import { GET_ARTICLES } from "/src/services/apollo/queries";
import { isMobile } from "/src/components/responsive";

import { ArrowIcon } from "/src/icons";

import styles from "./dashboardNews.module.scss";
import dashboardStyles from "../dashboard.module.scss";
import globalStyles from "/src/styles/global.module.scss";

const numberOfArticles = 3;

const DashboardNews = () => {
  return (
    <div className={styles.previewList}>
      <h2
        className={classnames(
          globalStyles.sectionTitle,
          dashboardStyles.sectionTitle
        )}
      >
        <Translate>Latest news</Translate>
      </h2>
      <ErrorBoundary>
        <OrganizationAppQuery
          query={GET_ARTICLES}
          variables={{
            startCount: 0,
            maxResult: numberOfArticles,
            typeFilter: "article",
            searchInput: "",
          }}
          renderLoading={() => (
            <ArticleListLoader
              fetchSize={numberOfArticles}
              itemClassName={classnames(styles.article, styles.loading)}
            />
          )}
        >
          {({ organization: { articles } }) => {
            if (!articles || !articles.length) {
              return <Translate>There are no news yet</Translate>;
            }
            return (
              <>
                {articles.map(({ id, preamble, ...rest }) => (
                  <Link
                    className={classnames(styles.articleLink, styles.article)}
                    key={id}
                    to={`/article/${id}`}
                  >
                    <ArticlePreview
                      {...rest}
                      preamble={preamble}
                      compact={!isMobile()}
                    />
                  </Link>
                ))}
                <div className={styles.sectionLink}>
                  <Link to="/knowledge">
                    <Translate>See all news</Translate>
                    <ArrowIcon className={styles.arrowIcon} />
                  </Link>
                </div>
              </>
            );
          }}
        </OrganizationAppQuery>
      </ErrorBoundary>
    </div>
  );
};

export default DashboardNews;
