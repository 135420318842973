import React from "react";
import PropTypes from "prop-types";
import config from "/src/config/config";
import { useTranslation } from "src/translations/translationProvider";
import { Translate } from "src/translations/translate";
import AuthLink from "/src/components/authLink";
import Button from "/src/components/button";

const GoSummaryExcelExport = ({ organizationId, year }) => {
  const { locale } = useTranslation();

  const downloadUrl = `${
    config.fileProxyURL
  }/gosummary/${organizationId}/summary/excel?year=${encodeURIComponent(
    year
  )}&language=${locale === "sv-SE" ? 0 : 1}`;

  return (
    <AuthLink url={downloadUrl}>
      <Button small>
        <Translate>Download report</Translate>
      </Button>
    </AuthLink>
  );
};

GoSummaryExcelExport.prototypes = {
  organizationId: PropTypes.string,
  year: PropTypes.number,
};

export default GoSummaryExcelExport;
