import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { CheckIcon } from "/src/icons";

import styles from "./checkbox.module.scss";

const isSpace = ({ key, keyCode }) => {
  return key === " " || keyCode === 32; // eslint-disable-line no-magic-numbers
};

const Checkbox = ({
  className,
  type,
  checked,
  small,
  text,
  onClick,
  disabled,
}) => {
  const preventDefault = (e) => {
    e.preventDefault();
    return false;
  };

  const selectOnSpace = (event) => {
    if (isSpace(event)) {
      event.preventDefault();
      onClick();
    }
  };

  return (
    <div
      className={classNames(styles.wrapper, className)}
      tabIndex={0}
      onClick={disabled ? () => {} : onClick}
      onKeyDown={selectOnSpace}
    >
      <input type="checkbox" defaultChecked={checked} disabled={disabled} />
      {type === "checkbox" && (
        <div
          className={classNames(
            styles.checkbox,
            { [styles.checked]: checked },
            { [styles.small]: small },
            { [styles.disabled]: disabled }
          )}
          onMouseDown={preventDefault}
          data-test-id="checkbox-input"
        >
          {checked && <CheckIcon className={styles.icon} />}
        </div>
      )}
      {type === "toggle" && (
        <div
          className={classNames(styles.toggle, { [styles.checked]: checked })}
        >
          <div className={styles.indicator} />
        </div>
      )}
      <span className={styles.text}>{text}</span>
    </div>
  );
};

Checkbox.propTypes = {
  className: PropTypes.string,
  type: PropTypes.oneOf(["checkbox", "toggle"]),
  checked: PropTypes.bool,
  small: PropTypes.bool,
  text: PropTypes.any,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

Checkbox.defaultProps = {
  type: "checkbox",
  checked: false,
  small: false,
  onClick: () => {},
};

export default Checkbox;
