export default function getMaxTwelveMonthsBackwards({ data, currentYear }) {
  const NUMBER_OF_MONTHS_TO_SHOW = 12;

  const { currentYearSummaries, previousYearSummaries } = data;
  const numberOfMonthsToKeepFromPreviousYear =
    NUMBER_OF_MONTHS_TO_SHOW - currentYearSummaries.length;

  return previousYearSummaries
    .slice(-numberOfMonthsToKeepFromPreviousYear)
    .map((previousYearSummary) => ({
      ...previousYearSummary,
      year: currentYear - 1,
    }))
    .concat(
      currentYearSummaries.map((currentYearSummary) => ({
        ...currentYearSummary,
        year: currentYear,
      }))
    );
}
