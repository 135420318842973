import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import config from "/src/config/config";

const noop = () => {};
const EXCEPTION_LIMIT = 100;
let exceptionCounter = 0;

let realAppInsights = {
  trackException: () => noop,
};

export const appInsights = {
  trackException: ({
    exception,
    severityLevel = SEVERITY_LEVELS.ERROR,
    properties,
  }) => {
    if (exceptionCounter < EXCEPTION_LIMIT) {
      exceptionCounter++;
      realAppInsights.trackException({
        error: exception,
        severityLevel,
        properties,
      });
    } else if (exceptionCounter === EXCEPTION_LIMIT) {
      throw Error("Exception limit exceeded");
    }
  },
  trackEvent: (customEvent) => {
    realAppInsights.trackEvent(customEvent);
    realAppInsights.flush();
  },
  enableCookies: () => realAppInsights.core.getCookieMgr().setEnabled(true),
};

export const SEVERITY_LEVELS = {
  VERBOSE: 0,
  INFORMATION: 1,
  WARNING: 2,
  ERROR: 3,
  CRITICAL: 4,
};

function initializeAnalytics() {
  realAppInsights = new ApplicationInsights({
    config: {
      connectionString: config.appInsightsConnectionString,
      enableAutoRouteTracking: true,
      disableCookiesUsage: true,
    },
  });
  realAppInsights.loadAppInsights();
  realAppInsights.addTelemetryInitializer((envelope) => {
    envelope.tags["ai.cloud.role"] = "SmileWebPortal";
  });
}

export default initializeAnalytics;
