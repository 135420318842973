import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { createRoot } from "react-dom/client";

import config from "/src/config/config";
import { formatNumber } from "/src/utilities";

import styles from "./weatherMap.module.scss";

import("leaflet/dist/leaflet");

const isValidPlant = (plant) => {
  const { coordinates, name } = plant;
  if (
    !name ||
    !coordinates ||
    typeof coordinates.latitude !== "number" ||
    typeof coordinates.longitude !== "number"
  ) {
    return false;
  }
  return true;
};

const popupHTML = (name, energy) => {
  const currentDate = new Date();
  const firstDateOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1,
  );
  const component = (
    <div className={styles.popup}>
      <h3>{name}</h3>
      <div>
        {`${new Intl.DateTimeFormat("sv").format(
          firstDateOfMonth,
        )} - ${new Intl.DateTimeFormat("sv").format(currentDate)}`}
      </div>
      <div className={styles.popupEnergy}>
        <span>Production: </span>
        <span>{formatNumber(energy.production)} MWh</span>
      </div>
      <div className={styles.popupEnergy}>
        <span>Consumption: </span>
        <span>{formatNumber(energy.consumption)} MWh</span>
      </div>
    </div>
  );
  const element = document.createElement("div");
  const root = createRoot(element);
  root.render(component);

  return element;
};

const initMap = (validPlants, options) => {
  window.windyInit(options, (windyAPI) => {
    const { map } = windyAPI;
    map.setView([55.8234, 13.5456], 7);

    if (validPlants) {
      validPlants.forEach((plant) => {
        const { coordinates, name, energy } = plant;
        window.L.marker([coordinates.latitude, coordinates.longitude])
          .addTo(map)
          .bindPopup(popupHTML(name, energy))
          .closePopup();
      });
    }
  });
};

const WeatherMap = ({ plants }) => {
  useEffect(() => {
    const runningTests = /HeadlessChrome/.test(window.navigator.userAgent);
    if (runningTests) return;

    /* Remove the global windy object so that we can run init again without warning. We can't remove it in the unmount stage because then Windys lib-something scripts will throw errors */
    if (window.W) {
      delete window.W;
    }

    /* Create and add windy script that fetches and injects files for it to work */
    const validPlants = plants.length > 0 ? plants.filter(isValidPlant) : [];
    const OPTIONS = {
      key: config.windyApiKey,
      lat: 50.4,
      lon: 14.3,
      zoom: 5,
    };
    const script = document.createElement("script");
    script.src = "https://api4.windy.com/assets/libBoot.js";
    script.async = true;
    script.onload = () => initMap(validPlants, OPTIONS);
    document.body.appendChild(script);

    return () => {
      /* Remove all windy files so that we don't get copies on every mount */
      const windyScripts = [...document.getElementsByTagName("script")].filter(
        (s) => s.src.includes("windy"),
      );
      const windyStyles = [...document.getElementsByTagName("link")].filter(
        (s) => s.href.includes("windy"),
      );

      windyScripts.forEach((script) => script.remove());
      windyStyles.forEach((style) => style.remove());
    };
  }, [plants]);

  return <div className={styles.windy} id="windy" />;
};

WeatherMap.propTypes = {
  plants: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      coordinates: PropTypes.shape({
        latitude: PropTypes.number,
        longitude: PropTypes.number,
      }),
      energy: PropTypes.shape({
        production: PropTypes.number,
        consumption: PropTypes.number,
      }),
    }),
  ),
};

WeatherMap.defaultProps = {
  plants: [],
};

export default WeatherMap;
