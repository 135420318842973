import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import { useTranslation } from "src/translations/translationProvider";
import Table from "/src/components/table";

import styles from "./overviewTable.module.scss";
import { formatNumber } from "/src/utilities";

const PlantCell = (id, name) => (
  <Link to={`/energy/plant?id=${id}`} className={styles.plant}>
    {name}
  </Link>
);
PlantCell.propTypes = {
  value: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};

function sortStrings(a, b, locale) {
  const sortOptions = { sensitivity: "variant", numeric: "true" };
  return a.localeCompare(b, locale, sortOptions);
}

const FormattedNumberCell =
  (locale) =>
  ({ value }) =>
    formatNumber(value, locale);

const OverviewTable = ({ data }) => {
  const { translate, locale } = useTranslation();

  return (
    <Table
      className={styles.table}
      data={data}
      columns={[
        {
          Header: translate("Plant"),
          accessor: "name",
          Cell: (value) => {
            const { row } = value;
            const { name } = row.values;
            const plantId = row.original.id.split(":")[0];
            return PlantCell(plantId, name);
          },
          sortMethod: (a, b) => sortStrings(a, b, locale),
        },
        {
          Header: `${translate("Production")} (MWh)`,
          accessor: "production",
          Cell: FormattedNumberCell(locale),
        },
        {
          Header: `${translate("Consumption")} (MWh)`,
          accessor: "consumption",
          Cell: FormattedNumberCell(locale),
        },
      ]}
      sortable={true}
      tableOptions={{
        initialState: {
          sortBy: [
            {
              id: "name",
              desc: false,
            },
          ],
        },
      }}
    />
  );
};

OverviewTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      production: PropTypes.number,
      consumption: PropTypes.number,
    })
  ),
};

export default OverviewTable;
