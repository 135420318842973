import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { isSameYear } from "date-fns";

import { useTranslation } from "src/translations/translationProvider";
import { Translate } from "src/translations/translate";
import Dropdown from "/src/components/dropdown";
import Button from "/src/components/button";
import Calendar from "./calendar";

import styles from "./calendar.module.scss";

const YearPicker = ({
  triggerClassName,
  year,
  onChange,
  isWithCaret,
  className,
  ...props
}) => {
  const { formatDate } = useTranslation();
  const dropdownRef = useRef(null);
  const [date, setDate] = useState(year);

  useEffect(() => {
    setDate(year);
  }, [year]);

  const decoratedOnChange = () => {
    if (!isSameYear(date, year)) {
      onChange(date);
    }
  };

  const outsideClick = () => {
    decoratedOnChange();
  };

  const doneClick = () => {
    decoratedOnChange();
    dropdownRef.current.forceClose();
  };

  const cancelClick = () => {
    setDate(year);
    dropdownRef.current.forceClose();
  };

  return (
    <Dropdown
      ref={dropdownRef}
      onOutsideClickCallback={outsideClick}
      isWithCaret={isWithCaret}
      className={classNames(className, styles.dropdown, styles.monthPicker)}
      trigger={
        <span
          className={classNames(
            triggerClassName,
            styles.selectedDates,
            styles.lowerCase,
          )}
        >
          {formatDate(year, "yyyy")}
        </span>
      }
    >
      <Calendar
        value={date}
        onChange={setDate}
        minDetail={"decade"}
        maxDetail={"decade"}
        {...props}
      />
      <div className={styles.buttonRow}>
        <Button
          className={styles.cancelButton}
          small
          type={"secondary"}
          onClick={() => cancelClick()}
        >
          <Translate>Cancel</Translate>
        </Button>
        <Button
          data-test-id="yearPicker-done-button"
          small
          onClick={() => doneClick()}
        >
          <Translate>Done</Translate>
        </Button>
      </div>
    </Dropdown>
  );
};

YearPicker.propTypes = {
  className: PropTypes.string,
  triggerClassName: PropTypes.string,
  year: PropTypes.instanceOf(Date),
  onChange: PropTypes.func,
  isWithCaret: PropTypes.bool,
};

YearPicker.defaultProps = {
  year: new Date(),
  onChange: () => {},
  isWithCaret: true,
};

export default YearPicker;
