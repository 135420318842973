import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import ReactGA from "react-ga4";

import { useTranslation } from "src/translations/translationProvider";
import { Translate } from "src/translations/translate";
import Button from "/src/components/button";
import config from "/src/config/config";
import { appInsights } from "/src/analytics.js";

import styles from "./cookieConsent.module.scss";

const CookieConsent = ({ cookieLink }) => {
  const { translate } = useTranslation();
  const cookieConsentAccepted = localStorage.getItem("cookieConsentAccepted");
  const { googleAnalyticsKey } = config;
  const [isAccepted, setIsAccepted] = useState(
    cookieConsentAccepted === "true"
  );
  const { pathname, search } = useLocation();

  useEffect(() => {
    if (isAccepted && googleAnalyticsKey) {
      ReactGA.initialize(googleAnalyticsKey, { gaOptions: config.gaOptions });
    }
  }, [googleAnalyticsKey, isAccepted]);

  useEffect(() => {
    if (isAccepted) {
      ReactGA.send({
        hitType: "pageview",
        page: pathname + search,
      });
    }
  }, [isAccepted, pathname, search]);

  useEffect(() => {
    if (isAccepted) {
      appInsights.enableCookies();
    }
  }, [isAccepted]);

  const handleAccept = () => {
    localStorage.setItem("cookieConsentAccepted", "true");
    setIsAccepted(true);
  };

  return (
    <>
      {!isAccepted && (
        <div data-test-id="cookie-consent" className={styles.cookieConsent}>
          <header className={styles.consentHeader}>
            <Translate>This website uses cookies</Translate>
          </header>
          <section className={styles.consentText}>
            {`${translate(
              "The information is used to improve our services. Read more in our"
            )} `}
            <a
              href={cookieLink}
              className={styles.link}
              target="_blank"
              rel="noreferrer"
            >
              {translate("Cookie Policy")}
            </a>
          </section>
          <Button
            data-test-id="cookie-consent-accept"
            small
            onClick={handleAccept}
            className={styles.button}
          >
            <Translate>Accept</Translate>
          </Button>
        </div>
      )}
    </>
  );
};

CookieConsent.propTypes = {
  cookieLink: PropTypes.string,
};

export default CookieConsent;
