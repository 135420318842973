import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import styles from "./videoPlayer.module.scss";

const VideoPlayer = ({ className, videoId }) => (
  <iframe
    title={"Video Player"}
    className={classNames(styles.videoPlayer, className)}
    src={`https://www.youtube.com/embed/${videoId}?rel=0`}
    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen
  />
);

VideoPlayer.propTypes = {
  className: PropTypes.string,
  videoId: PropTypes.string.isRequired,
};

export default VideoPlayer;
