import { PlanInput } from "src/graphql/graphql";
import { LanguageKey } from "src/config/translations/translationsTypes";

export function createChartDataset({
  availabilityPlans,
  translate,
}: {
  availabilityPlans: PlanInput[];
  translate: (value: LanguageKey) => string;
}) {
  return {
    datasets: [
      {
        label: translate("Power") + " (MW)",
        fill: false,
        borderColor: "#25a772",
        borderWidth: "2",
        backgroundColor: "#25a772",
        pointRadius: 0,
        pointHitRadius: 16,
        yAxisID: "y",
        spanGaps: true,
        data: availabilityPlans.flatMap((plan) => [
          {
            x: new Date(plan.fromTimestamp),
            y: plan.power,
          },
          {
            x: new Date(plan.toTimestamp),
            y: plan.power,
          },
        ]),
      },
      {
        label: translate("Curtailment") + " (MW)",
        fill: false,
        borderColor: "#f08517",
        borderWidth: "2",
        backgroundColor: "#f08517",
        pointRadius: 0,
        pointHitRadius: 16,
        yAxisID: "y",
        spanGaps: true,
        data: availabilityPlans.flatMap((plan) => [
          {
            x: new Date(plan.fromTimestamp),
            y: plan.curtailment,
          },
          {
            x: new Date(plan.toTimestamp),
            y: plan.curtailment,
          },
        ]),
      },
      {
        label: translate("Ice") + " (%)",
        fill: false,
        borderColor: "#2589f1",
        borderWidth: "2",
        backgroundColor: "#2589f1",
        pointRadius: 0,
        pointHitRadius: 16,
        yAxisID: "y1",
        spanGaps: true,
        data: availabilityPlans.flatMap((plan) => [
          {
            x: new Date(plan.fromTimestamp),
            y: plan.ice,
          },
          {
            x: new Date(plan.toTimestamp),
            y: plan.ice,
          },
        ]),
      },
    ],
  };
}
