import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";

import { Translate } from "src/translations/translate";
import { useTranslation } from "src/translations/translationProvider";
import Dropdown from "/src/components/dropdown";
import FilterInput from "/src/components/filterInput/filterInput";

import styles from "./select.module.scss";

const Select = ({
  wrapperClassName,
  triggerClassName,
  disabledClassName = styles.disabled,
  triggerNode,
  infoNode,
  value,
  options = [],
  onChange,
  isWithCaret,
  isBottomToTop,
  isDisabled,
  label,
  searchable,
}) => {
  const { translate } = useTranslation();
  const dropdownRef = useRef(null);
  const [selectedValue, setSelectedValue] = useState(value);
  const [filteredOptions, setFilteredOptions] = useState(options);

  const placeHolder = (
    <span>
      <Translate>Select</Translate>...
    </span>
  );
  const valueNode = value && <span>{value.label}</span>;

  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  const selectItem = (selectedItem) => {
    onChange(selectedItem);
    setSelectedValue(selectedItem);
    dropdownRef.current.forceClose();
  };

  const outsideClick = () => {
    cancelButtonClick();
  };

  const cancelButtonClick = () => {
    setSelectedValue(value);
    dropdownRef.current.forceClose();
  };

  const getOptionLabel = (option) =>
    option.label.props && option.label.props.children
      ? option.label.props.children
      : option.label;

  const getGroupedOptions = (options) => {
    if (options.some((o) => o.group)) {
      const groups = options.reduce((acc, curr) => {
        const key = curr.group || "";
        (acc[key] = acc[key] || []).push(curr);
        return acc;
      }, {});

      return Object.keys(groups)
        .sort()
        .map((k) => (
          <div key={k}>
            <div className={styles.groupTitle}>{k}</div>
            {groups[k].map((option) => {
              const isSelected =
                selectedValue && selectedValue.value === option.value;
              return (
                <div
                  data-test-id={`select-item-option-${getOptionLabel(option)}`}
                  className={classNames(styles.dropDownItem, {
                    [styles.selectedDropdownItem]: isSelected,
                  })}
                  key={option.value}
                  onClick={() => selectItem(option)}
                >
                  {option.label}
                </div>
              );
            })}
          </div>
        ));
    }
    return null;
  };

  const getOptions = (options) =>
    options &&
    options.length > 0 &&
    options.map((option) => {
      const isSelected = selectedValue && selectedValue.value === option.value;
      return (
        <div
          data-test-id={`select-item-option-${getOptionLabel(option)}`}
          className={classNames(styles.dropDownItem, {
            [styles.selectedDropdownItem]: isSelected,
          })}
          key={option.value}
          onClick={() => selectItem(option)}
        >
          {option.label}
        </div>
      );
    });

  const optionsList = filteredOptions.some((o) => o.group)
    ? getGroupedOptions(filteredOptions)
    : getOptions(filteredOptions);

  const onSearch = (searchText) => {
    if (!searchText || searchText === "") {
      setFilteredOptions(options);
      return;
    }

    setFilteredOptions(
      options.filter((option) => {
        const name = !!option.label.props
          ? option.label.props.children
          : option.label;
        return name.toUpperCase().includes(searchText.toUpperCase());
      }),
    );
  };

  const searchBox = options && options.length > 1 && (
    <div className={classNames(styles.input)}>
      <FilterInput
        placeholder={translate("Filter by name")}
        onSearch={onSearch}
      />
    </div>
  );

  const trigger = (
    <>
      {label && <div className={styles.triggerLabel}>{label}</div>}
      <div
        className={classNames(triggerClassName, styles.trigger, {
          [disabledClassName]: isDisabled,
        })}
      >
        {triggerNode || valueNode || placeHolder}
      </div>
    </>
  );

  return (
    <Dropdown
      className={wrapperClassName}
      containerClassName={styles.container}
      ref={dropdownRef}
      trigger={trigger}
      triggerDisabled={isDisabled}
      onOutsideClickCallback={outsideClick}
      isWithCaret={isWithCaret}
      isBottomToTop={isBottomToTop}
    >
      <div data-test-id={`dropdown-${label}`} className={styles.content}>
        {searchable && searchBox}
        {infoNode && <div className={styles.info}>{infoNode}</div>}
        {optionsList ? (
          <div>{optionsList}</div>
        ) : (
          <div className={classNames(styles.dropDownItem, styles.disabled)}>
            <Translate>No available options</Translate>
          </div>
        )}
      </div>
    </Dropdown>
  );
};

const selectionType = PropTypes.shape({
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  value: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
});

const valueType = PropTypes.oneOfType([
  PropTypes.arrayOf(selectionType),
  selectionType,
]);

Select.propTypes = {
  triggerNode: PropTypes.node,
  infoNode: PropTypes.node,
  wrapperClassName: PropTypes.string,
  triggerClassName: PropTypes.string,
  disabledClassName: PropTypes.string,
  triggerLabelClassName: PropTypes.string,
  value: valueType,
  options: PropTypes.arrayOf(selectionType),
  onChange: PropTypes.func,
  isWithCaret: PropTypes.bool,
  isBottomToTop: PropTypes.bool,
  isDisabled: PropTypes.bool,
  label: PropTypes.string,
  searchable: PropTypes.bool,
};

Select.defaultProps = {
  onChange: () => {},
};

export default Select;
