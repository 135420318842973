import xlsx from "xlsx";

export function parseExcel(
  excelFile: File,
  setParsedExcel: (arg: ParsedExcel) => void,
) {
  if (excelFile) {
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = e.target?.result;
      const workbook = xlsx.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0] || "";
      const worksheet = workbook.Sheets[sheetName];
      if (!worksheet) {
        // TODO proper error here
        console.log("No worksheet found");
        return;
      }
      const json: Data[] = xlsx.utils.sheet_to_json(worksheet, {
        defval: null,
        header: "A",
      });
      const jsonHeaders = json.shift();
      setParsedExcel({ data: json, header: jsonHeaders });
    };

    reader.readAsArrayBuffer(excelFile);
  }
}

type Data = {
  A: string | null;
  B: string | null;
  C: number | string | null;
  D: number | string | null;
  E: number | string | null;
  F: string | null;
  __RowNum__: number;
};

export interface ParsedExcel {
  data: Data[];
  header: Data | undefined;
}
