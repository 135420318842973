import React from "react";
import classnames from "classnames";
import { addDays, isToday, isFuture } from "date-fns";

import { GET_LATEST_SPOT_PRICES } from "/src/services/apollo/queries";
import { Translate } from "src/translations/translate";
import { OrganizationAppQuery } from "/src/components/appQuery";
import { isMobile, Desktop } from "/src/components/responsive";
import Card from "/src/components/card";
import ValueUnit from "/src/components/valueUnit";
import { LoadingChart } from "/src/components/chart";
import SpotPriceCard from "./spotPriceCard";
import SpotPriceChart from "./spotPriceChart";

import styles from "./latestSpotPrices.module.scss";
import dashboardStyles from "../dashboard.module.scss";
import globalStyles from "/src/styles/global.module.scss";

const DEFAULT_SPOTPRICE_AREAS = ["SYS", "SE1", "SE2", "SE3", "SE4"];

const sortPriceAreas = ({ spotPriceAreas }) => {
  const sorted = spotPriceAreas.slice();
  sorted.sort((a, b) => {
    if (a.area < b.area) {
      return -1;
    }
    if (a.area > b.area) {
      return 1;
    }

    return 0;
  });

  // Make sure "SYS" comes first
  if (sorted.length > 1) sorted.unshift(sorted.pop());

  return sorted;
};

const renderSpotpriceDataCard = ({ spotPrice, weather, isLoading, index }) => {
  const { area, areaDescription, currency, spotPrices } = spotPrice;
  const filteredSpotPrices = spotPrices.filter(({ time }) => {
    const date = new Date(time);
    return isToday(date) || isFuture(date);
  });

  return (
    (Boolean(spotPrice) || isLoading) && (
      <SpotPriceCard
        isLoading={isLoading}
        key={index}
        titleClass={classnames({
          [styles.sysPriceColor]: area === "SYS",
        })}
        area={`${area} ${areaDescription || ""}`}
        unit={currency}
        spotPrices={filteredSpotPrices}
        testDataId={`spotprice-${area}`}
        weather={weather}
      />
    )
  );
};

const renderSummaryCardWithError = (cardType, headerText, errorText) => {
  return (
    <Card
      key={cardType}
      title={
        <div
          className={classnames(styles.header, {
            [globalStyles.sectionTitleLarge]: isMobile(),
          })}
        >
          <Translate>{headerText}</Translate>
        </div>
      }
      slotOne={
        <div className={styles.cardContent}>
          <p className={styles.title}>
            <Translate>{errorText}</Translate>
          </p>
          <ValueUnit value={""} unit={""} vertical />
        </div>
      }
    />
  );
};

const LatestSpotPrices = () => {
  const now = new Date();

  return (
    <div className={styles.wrapper}>
      <h2
        className={classnames(
          globalStyles.sectionTitle,
          dashboardStyles.sectionTitle
        )}
      >
        <Translate>Market</Translate>
      </h2>
      <OrganizationAppQuery
        query={GET_LATEST_SPOT_PRICES}
        renderNoContent={() => null}
        renderError={() => {
          return renderSummaryCardWithError(
            "Market",
            "Market data",
            "Cannot get Market data"
          );
        }}
        renderLoading={() => {
          return (
            <div className={styles.spotpriceContainer}>
              <Desktop>
                <LoadingChart />
              </Desktop>
              <div className={styles.spotPriceCards}>
                {DEFAULT_SPOTPRICE_AREAS.map((_area, i) => {
                  const placeholder = {
                    area: "...",
                    areaDescription: "",
                    currency: "",
                    spotPrices: [
                      {
                        price: null,
                        day: addDays(now, 1).toString(),
                      },
                      {
                        price: null,
                        day: now.toString(),
                      },
                    ],
                  };
                  return renderSpotpriceDataCard({
                    spotPrice: placeholder,
                    weather: null,
                    isLoading: true,
                    index: i,
                  });
                })}
              </div>
            </div>
          );
        }}
      >
        {(data) => {
          const spotPriceAreas = sortPriceAreas(data.organization);
          const { priceAreasWeatherToday } = data;

          if (spotPriceAreas.length < 1) {
            return renderSummaryCardWithError(
              "Market",
              "Market data",
              "Cannot get Market data"
            );
          }

          return (
            <div>
              <div className={styles.spotPriceContainer}>
                <Desktop>
                  <SpotPriceChart spotPriceAreas={spotPriceAreas} />
                </Desktop>
              </div>
              <h2
                className={classnames(
                  globalStyles.sectionTitle,
                  dashboardStyles.sectionTitle
                )}
              >
                <Translate>Prices</Translate>
              </h2>
              <div>
                <div className={styles.spotPriceCards}>
                  {spotPriceAreas.map((spotPrice, i) =>
                    renderSpotpriceDataCard({
                      spotPrice: spotPrice,
                      weather: priceAreasWeatherToday.find(
                        (pa) => pa.code === spotPrice.area
                      ),
                      isLoading: false,
                      index: i,
                    })
                  )}
                </div>
              </div>
            </div>
          );
        }}
      </OrganizationAppQuery>
    </div>
  );
};

export default LatestSpotPrices;
