import { parseISO } from "date-fns";
import { LanguageKey } from "src/config/translations/translationsTypes";
import { PlanInput } from "src/graphql/graphql";
import { ParsedExcel } from "src/modules/energy/availability/parseExcel";

export function ValidateParsedExcel(parsedExcel: ParsedExcel): {
  errors: ParsedExcelError[];
  values: PlanInput[];
} {
  return parsedExcel.data.reduce(
    ({ errors, values }, row) => {
      const value = validateRowAndCreateErrors(row);
      if (isParsedExcelErrors(value)) {
        return {
          errors: [...errors, ...value],
          values,
        };
      }
      return {
        errors,
        values: [...values, value],
      };
    },
    {
      errors: [],
      values: [],
    } as {
      errors: ParsedExcelError[];
      values: PlanInput[];
    },
  );
}

function validateRowAndCreateErrors(
  row: ParsedExcel["data"][0],
): PlanInput | ParsedExcelError[] {
  const errors: ParsedExcelError[] = [];
  if (!isValidDate(row["A"])) {
    errors.push({
      column: "A",
      row: row.__RowNum__,
      value: row["A"],
      message: "invalid date format",
    });
  }
  if (!isValidDate(row["B"])) {
    errors.push({
      column: "B",
      row: row.__RowNum__,
      value: row["B"],
      message: "invalid date format",
    });
  }
  if (row.C != null && !isNumber(row.C)) {
    errors.push({
      column: "C",
      row: row.__RowNum__,
      value: row.C.toString(),
      message: "invalid number value",
    });
  }
  if (row["D"] != null && !isNumber(row["D"])) {
    errors.push({
      column: "D",
      row: row.__RowNum__,
      value: row["D"].toString(),
      message: "invalid number value",
    });
  }
  if (row["E"] != null && !isNumber(row["E"])) {
    errors.push({
      column: "E",
      row: row.__RowNum__,
      value: row["E"].toString(),
      message: "invalid number value",
    });
  }

  if (errors.length === 0) {
    return {
      fromTimestamp: row.A as PlanInput["fromTimestamp"],
      toTimestamp: row.B as PlanInput["toTimestamp"],
      power: row.C === null ? null : Number(row.C),
      curtailment: row.D === null ? null : Number(row.D),
      ice: row.E === null ? null : Number(row.E),
      comment: row.F,
    };
  }

  return errors;
}

function isParsedExcelErrors(
  input: PlanInput | ParsedExcelError[],
): input is ParsedExcelError[] {
  return Array.isArray(input);
}

function isNumber(value: string | number) {
  if (typeof value === "number") {
    return true;
  }
  return !isNaN(Number(value)) && value.trim() !== "";
}

function isValidDate(dateString: string | null) {
  if (dateString === null) {
    return false;
  }
  const date = parseISO(dateString);
  return !isNaN(date.getTime());
}

export interface ParsedExcelError {
  column: string;
  row: number;
  value: string | null;
  message: LanguageKey;
}
