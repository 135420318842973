import { gql } from "@apollo/client";

export const CREATE_EMAIL_WHITELIST_ENTRY = gql`
  mutation createEmailWhitelistEntry(
    $organizationId: String!
    $value: String!
  ) {
    createEmailWhitelistEntry(organizationId: $organizationId, value: $value)
  }
`;

export const DELETE_EMAIL_WHITELIST_ENTRY = gql`
  mutation deleteEmailWhitelistEntry(
    $organizationId: String!
    $value: String!
  ) {
    deleteEmailWhitelistEntry(organizationId: $organizationId, value: $value)
  }
`;
