import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import styles from "./input.module.scss";

const decoratedOnChange = (onChange) => (event) => onChange(event.target.value);

const Input = forwardRef(
  ({ className, inputClassName, onChange, label, ...restProps }, ref) => (
    <div className={classNames(styles.input, className)}>
      <input
        data-test-id={`first-name-${label}`}
        className={classNames(styles.inputElement, inputClassName, {
          [styles.withValue]:
            restProps.value !== undefined && restProps.value !== null,
        })}
        onChange={decoratedOnChange(onChange)}
        ref={ref}
        {...restProps}
      />
      <span className={styles.label}>{label}</span>
    </div>
  ),
);

Input.displayName = "Input";

Input.propTypes = {
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  children: PropTypes.node,
  label: PropTypes.string,
  onChange: PropTypes.func,
  type: PropTypes.oneOf(["text", "password", "datetime-local", "number"]),
  value: PropTypes.string,
};

Input.defaultProps = {
  onChange: () => {},
  type: "text",
};

export default Input;
