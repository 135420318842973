export const fakeContacts = [
  {
    firstName: "Per",
    lastName: "Svenonius",
    title: "Kundansvarig",
    phoneNumber: "0768 - 29 319 32",
    email: "per.svenonius@modity.se",
    fileUrl: "/images/author-example.png",
  },
  {
    firstName: "Per",
    lastName: "Svenonius",
    title: "Kundansvarig",
    phoneNumber: "0768 - 29 319 32",
    email: "per.svenonius@modity.se",
    fileUrl: "/images/author-example.png",
  },
  {
    firstName: "Per",
    lastName: "Svenonius",
    title: "Kundansvarig",
    phoneNumber: "0768 - 29 319 32",
    email: "per.svenonius@modity.se",
    fileUrl: "/images/author-example.png",
  },
  {
    firstName: "Per",
    lastName: "Svenonius",
    title: "Kundansvarig",
    phoneNumber: "0768 - 29 319 32",
    email: "per.svenonius@modity.se",
    fileUrl: "/images/author-example.png",
  },
];

export const supportContact = {
  phoneNumber: "(+46) 46 - 325 900",
  email: "support@modity.se",
  fileUrl: "/images/author-example.png",
};
