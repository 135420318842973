import * as React from "react";
const SvgNetmwLogo = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={40} height={20} {...props}>
    <g fill="none">
      <path
        fill="#F57A86"
        d="m21.293 2.672-2.232 13.18 3.314-7.072-1.082-6.108"
      />
      <path
        fill="#1C4052"
        d="M2.56 3.13.073 16.845a2.54 2.54 0 0 0 2.063 2.932 2.55 2.55 0 0 0 2.946-2.053l1.175-6.317-3.473-7.41a2.493 2.493 0 0 1-.226-.865m29.81 13.477 1.411-7.965-3.3-7.044c-.015-.03-.033-.055-.048-.084.157.328.248.693.248 1.08a2.5 2.5 0 0 1-.029.358c-.03.265-.1.53-.22.785l-2.28 4.868 4.284 9.142a2.49 2.49 0 0 1-.066-1.14M16.188 2.924c-.006.17.006.344.037.518l1.379 7.782-3.508 7.362c.254-.534.267-.696.308-1.027.01-.09.008-.213-.001-.301-.015-.32-.09-.64-.233-.947l-2.282-4.868 4.3-9.173s.192-.412.51-.78c0 0-.507.614-.51 1.434"
      />
      <path
        fill="#6BCDDA"
        d="M25.869 3.738a2.484 2.484 0 0 1-.194-.625l-.004-.017a2.564 2.564 0 0 1-.016-.106 2.523 2.523 0 0 1-.034-.395c0-.397.095-.77.259-1.104-.02.036-.041.07-.059.108l-3.214 6.86-.151.321-1.16 2.475-1.056 2.253-1.099 2.345-.29 1.71a2.533 2.533 0 0 0 1.427 1.854 2.556 2.556 0 0 0 3.38-1.224l.045-.097 4.447-9.49-2.281-4.868m-4.874.211v.002l-3.391 7.273 1.106 6.244 2.285-13.519M37.898.211a2.55 2.55 0 0 0-2.946 2.053l-1.175 6.389 3.473 7.41c.131.28.203.573.226.865L39.96 3.143A2.54 2.54 0 0 0 37.898.21M6.258 11.406l3.432 7.18c-.07-.23-.33-.807-.33-1.06 0-.09.005-.18.014-.27.015-.318.09-.639.233-.945l2.281-4.868-4.284-9.142c.106.36.135.748.066 1.14l-1.412 7.965"
      />
      <path
        fill="#45AFD2"
        d="m20.991 3.976.223-1.321a2.588 2.588 0 0 0-.324-1.022A2.56 2.56 0 0 0 18.276.456a2.564 2.564 0 0 0-2.072 2.962l1.385 7.86 3.401-7.3.001-.002M7.609 2.3 7.4 1.857a2.51 2.51 0 0 0-.392-.594A2.529 2.529 0 0 0 5.611.51a2.55 2.55 0 0 0-2.946 2.053l-.101.568c.023.292.095.585.226.865l3.473 7.41 1.411-7.964c.07-.393.04-.78-.065-1.141M33.76 8.589l-1.425 8.043c-.07.397-.041.789.066 1.153l.21.449c.105.223.24.422.395.6.366.384.851.661 1.412.76a2.575 2.575 0 0 0 2.976-2.074l.102-.573a2.512 2.512 0 0 0-.228-.874L33.76 8.589M29.212.335A2.553 2.553 0 0 0 28.25.1a2.717 2.717 0 0 0-.1-.002 2.735 2.735 0 0 0-.102.002 2.554 2.554 0 0 0-2.203 3.63L28.15 8.65l2.304-4.917A2.558 2.558 0 0 0 29.212.335M12.955 19.702a2.558 2.558 0 0 0 1.242-3.397l-2.304-4.917-2.304 4.917a2.558 2.558 0 0 0 2.405 3.631c.324-.014.65-.09.96-.234"
      />
    </g>
  </svg>
);
export default SvgNetmwLogo;
