import React, { useState, useEffect } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import sjson from "secure-json-parse";

import { useTranslation } from "src/translations/translationProvider";
import Table from "/src/components/table";
import ToggleColumns from "/src/components/toggleColumns";
import { FormatOrEmpty } from "/src/components/tableCellFormats";

import portfolioStyles from "/src/modules/portfolio/portfolio.module.scss";
import financialStyles from "../financial.module.scss";
import styles from "./transactionList.module.scss";

const TransactionListTable = ({
  className,
  data,
  selectedProduct,
  selectedPortfolioReportConfigurationNames,
}) => {
  const { translate, formatDate } = useTranslation();
  const [visibleColumns, setVisibleColumns] = useState(
    getColumnsData({ formatDate, translate })
  );

  useEffect(() => {
    setVisibleColumns(getColumnsData({ formatDate, translate }));
  }, [formatDate, translate]);

  const renderHeader = () => (
    <>
      {selectedPortfolioReportConfigurationNames.join(", ")}
      {", "}
      {selectedProduct.label}
    </>
  );

  const decoratedSetVisibleColumns = (columns) => {
    const storeMe = columns.reduce(
      (prev, { accessor, show }) => ({ ...prev, [accessor]: show }),
      {}
    );
    localStorage.setItem("visibleTransactionColumns", JSON.stringify(storeMe));
    setVisibleColumns(columns);
  };

  return (
    <div
      data-test-id="transaction-table"
      className={classNames(className, portfolioStyles.physicalTable)}
    >
      <div className={financialStyles.titleRow}>
        <div
          className={classNames(
            portfolioStyles.title,
            styles.title,
            financialStyles.title
          )}
        >
          {renderHeader()}
        </div>
        <ToggleColumns
          columns={visibleColumns}
          setColumns={decoratedSetVisibleColumns}
        />
      </div>
      <Table
        headerClassName={classNames(
          portfolioStyles.cell,
          styles.cell,
          portfolioStyles.header,
          styles.hedgeReportTableHeader
        )}
        cellClassName={classNames(portfolioStyles.cell, styles.cell)}
        data={data}
        columns={visibleColumns.filter((column) => column.show)}
        sortable
        exportable
        tableOptions={{
          initialState: {
            sortBy: [
              {
                id: "tradeDate",
                desc: false,
              },
            ],
          },
          disableSortRemove: true,
        }}
      />
    </div>
  );
};

TransactionListTable.propTypes = {
  className: PropTypes.string,
  selectedPortfolioReportConfigurationNames: PropTypes.array,
  data: PropTypes.array,
  selectedProduct: PropTypes.shape({
    name: PropTypes.string,
    label: PropTypes.node,
  }),
};

export default TransactionListTable;

function getColumnsData({ formatDate, translate }) {
  const DEFAULT_VISIBLE_COLUMNS = {
    id: true,
    tradeDate: true,
    status: true,
    product: true,
    mWh: true,
    mw: true,
    price: true,
    currency: true,
    startDate: true,
    endDate: true,
    outcome: true,
    comment1: false,
    comment2: false,
  };

  const savedColumnState = localStorage.getItem("visibleTransactionColumns");

  const visibleColumns = {
    ...DEFAULT_VISIBLE_COLUMNS,
    ...sjson.parse(savedColumnState),
  };

  const LabelAndUnit = (
    { label } // eslint-disable-line react/prop-types
  ) => (
    <div className={styles.columnHeader}>
      <div className={styles.columnHeaderLabel}>{label}</div>
    </div>
  );

  return [
    {
      Header: () => (
        <div className={styles.columnHeaderLabel}>{translate("ID")}</div>
      ),
      accessor: "id",
      Cell: ({ value }) => value,
      name: translate("ID"),
      show: visibleColumns["id"],
    },
    {
      Header: () => (
        <div className={styles.columnHeaderLabel}>
          {translate("Trade date")}
        </div>
      ),
      accessor: "tradeDate",
      Cell: ({ value }) => formatDate(new Date(value), "yyyy-MM-dd"),
      name: translate("Trade date"),
      show: visibleColumns["tradeDate"],
    },
    {
      Header: () => (
        <div className={styles.columnHeaderLabel}>{translate("Status")}</div>
      ),
      accessor: "status",
      Cell: ({ value }) => translate(value),
      name: translate("Status"),
      show: visibleColumns["status"],
    },
    {
      Header: () => (
        <div className={styles.columnHeaderLabel}>{translate("Product")}</div>
      ),
      accessor: "product",
      Cell: ({ value }) => value,
      name: translate("Product"),
      show: visibleColumns["product"],
    },
    {
      Header: () => <div className={styles.columnHeaderLabel}>MWh</div>,
      accessor: "mWh",
      Cell: ({ value }) => <FormatOrEmpty value={value} />,
      name: "MWh",
      show: visibleColumns["mWh"],
    },
    {
      Header: () => <div className={styles.columnHeaderLabel}>MW</div>,
      accessor: "mw",
      Cell: ({ value }) => <FormatOrEmpty value={value} />,
      name: "MW",
      show: visibleColumns["mw"],
    },
    {
      Header: () => (
        <div className={styles.columnHeaderLabel}>
          {translate("Hedged price")}
        </div>
      ),
      accessor: "price",
      Cell: ({ value }) => <FormatOrEmpty value={value} />,
      name: translate("Hedged price"),
      show: visibleColumns["price"],
    },
    {
      Header: () => (
        <div className={styles.columnHeaderLabel}>{translate("Currency")}</div>
      ),
      accessor: "currency",
      Cell: ({ value }) => value,
      name: translate("Currency"),
      show: visibleColumns["currency"],
    },
    {
      Header: () => (
        <div className={styles.columnHeaderLabel}>
          {translate("Start date")}
        </div>
      ),
      accessor: "startDate",
      Cell: ({ value }) => formatDate(new Date(value), "yyyy-MM-dd"),
      name: translate("Start date"),
      show: visibleColumns["startDate"],
    },
    {
      Header: () => (
        <div className={styles.columnHeaderLabel}>{translate("End date")}</div>
      ),
      accessor: "endDate",
      Cell: ({ value }) => formatDate(new Date(value), "yyyy-MM-dd"),
      name: translate("End date"),
      show: visibleColumns["endDate"],
    },
    {
      Header: () => LabelAndUnit({ label: translate("Settlement") }),
      accessor: "outcome",
      Cell: ({ value }) => <FormatOrEmpty value={value} />,
      name: translate("Settlement"),
      show: visibleColumns["outcome"],
    },
    {
      Header: () => (
        <div className={styles.columnHeaderLabel}>{translate("Comment1")}</div>
      ),
      accessor: "comment1",
      Cell: ({ value }) => value,
      name: translate("Comment1"),
      show: visibleColumns["comment1"],
    },
    {
      Header: () => (
        <div className={styles.columnHeaderLabel}>{translate("Comment2")}</div>
      ),
      accessor: "comment2",
      Cell: ({ value }) => value,
      name: translate("Comment2"),
      show: visibleColumns["comment2"],
    },
  ];
}
