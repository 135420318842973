import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "src/translations/translationProvider";

import Chart from "/src/components/chart";

import { addMissingHours } from "/src/components/chart/utils";

const TEMPERATURE_COLOR = "#d70046";
const WIND_COLOR = "#888888";

const defaultMinAndMaxValues = {
  temperature: {
    min: 0,
    max: 0,
  },
  wind: {
    min: 0,
    max: 0,
  },
};

const defaultGraphPaddingStepSize = 1;

const WeatherChart = ({ className, from, to, temperature, wind }) => {
  const { translate, locale } = useTranslation();
  const [balancedTemperature, setBalancedTemperature] = useState([]);
  const [balancedWind, setBalancedWind] = useState([]);
  const [minAndMaxValues, setMinAndMaxValues] = useState(
    defaultMinAndMaxValues
  );

  useEffect(() => {
    if (temperature.length && wind.length) {
      const balancedTemperature = addMissingHours(temperature, from, to);
      const balancedWind = addMissingHours(wind, from, to);

      setBalancedTemperature(balancedTemperature);
      setBalancedWind(balancedWind);
      setMinAndMaxValues(getMinAndMaxValues(temperature, wind));
    }
  }, [from, to, temperature, wind]);

  return (
    <Chart
      key={locale}
      className={className}
      data={{
        datasets: [
          {
            label: `${translate("Temperature")} (°C)`,
            backgroundColor: TEMPERATURE_COLOR,
            borderColor: TEMPERATURE_COLOR,
            borderWidth: "2",
            data: balancedTemperature,
            fill: false,
            pointRadius: 0,
            pointHitRadius: 10,
            yAxisID: "y",
            spanGaps: true,
          },
          {
            label: `${translate("Wind")} (m/s)`,
            backgroundColor: WIND_COLOR,
            borderColor: WIND_COLOR,
            borderWidth: "2",
            data: balancedWind,
            fill: false,
            pointRadius: 0,
            pointHitRadius: 10,
            yAxisID: "y1",
            spanGaps: true,
          },
        ],
      }}
      options={{
        xMin: from,
        xMax: to,
        y: {
          position: "left",
          beginAtZero: true,
          suggestedMin:
            minAndMaxValues.temperature.min - defaultGraphPaddingStepSize,
          suggestedMax:
            minAndMaxValues.temperature.max + defaultGraphPaddingStepSize,
          ticks: {
            font: {
              size: 14,
              family: "AcuminPro",
              padding: 12,
            },
          },
          title: {
            display: true,
            text: `${translate("Temperature")} (°C)`,
          },
          grid: {
            tickLength: 0,
          },
        },
        y1: {
          position: "right",
          beginAtZero: true,
          suggestedMin: minAndMaxValues.wind.min - defaultGraphPaddingStepSize,
          suggestedMax: minAndMaxValues.wind.max + defaultGraphPaddingStepSize,
          ticks: {
            font: {
              size: 14,
              family: "AcuminPro",
              padding: 12,
            },
          },
          title: {
            display: true,
            text: `${translate("Wind")} (m/s)`,
          },
          grid: {
            display: false,
          },
        },
      }}
    />
  );
};

WeatherChart.propTypes = {
  className: PropTypes.string,
  from: PropTypes.instanceOf(Date),
  to: PropTypes.instanceOf(Date),
  temperature: PropTypes.arrayOf(
    PropTypes.shape({
      x: PropTypes.instanceOf(Date),
      y: PropTypes.number,
    })
  ),
  wind: PropTypes.arrayOf(
    PropTypes.shape({
      x: PropTypes.instanceOf(Date),
      y: PropTypes.number,
    })
  ),
};

WeatherChart.defaultProps = {
  from: new Date(),
  to: new Date(),
  temperature: [],
  wind: [],
};

export default WeatherChart;

export function getMinAndMaxValues(temperature, wind) {
  const temperatureValues = temperature.map((temperatureCoordinate) =>
    Number(temperatureCoordinate.y)
  );
  const windValues = wind.map((windCoordinate) => Number(windCoordinate.y));
  return {
    temperature: {
      min: Math.min(...temperatureValues),
      max: Math.max(...temperatureValues),
    },
    wind: {
      min: Math.min(...windValues),
      max: Math.max(...windValues),
    },
  };
}
