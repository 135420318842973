import React from "react";
import PropTypes from "prop-types";

import { useTranslation } from "src/translations/translationProvider";
import { Translate } from "src/translations/translate";
import ControlHeader from "/src/components/controlHeader";
import { DatePicker } from "/src/components/calendar";
import PortfolioConfigurationSelect from "../../portfolioConfigurationSelect";
import OrdersTable from "./ordersTable";

import financialStyles from "../../financial.module.scss";
import styles from "./orders.module.scss";

const Orders = ({
  indexPools,
  fromDate,
  setFromDate,
  portfolioReportConfigurations,
  selectedConfigurationIds,
  setSelectedConfigurationIds,
}) => {
  const { translate } = useTranslation();

  return (
    <div>
      <ControlHeader>
        <div className={financialStyles.dropdown}>
          <Translate>Portfolios</Translate>:
          <PortfolioConfigurationSelect
            availableConfigurations={portfolioReportConfigurations}
            selectedConfigurationIds={selectedConfigurationIds}
            setSelectedConfigurationIds={setSelectedConfigurationIds}
          />
        </div>
        <div className={styles.datePicker}>
          <div>
            {`${translate("Orders")} ${translate("From").toLowerCase()}:`}
          </div>
          <DatePicker date={fromDate} onChange={setFromDate} isWithCaret />
        </div>
      </ControlHeader>
      <OrdersTable indexPools={indexPools} />
    </div>
  );
};

Orders.propTypes = {
  indexPools: PropTypes.array,
  fromDate: PropTypes.instanceOf(Date),
  setFromDate: PropTypes.func,
  portfolioReportConfigurations: PropTypes.array,
  selectedConfigurationIds: PropTypes.array,
  setSelectedConfigurationIds: PropTypes.func,
};

export default Orders;
