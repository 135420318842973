import sjson from "secure-json-parse";

import config from "/src/config/config";
const authConfig = {
  ...config.auth,
  url: `${config.auth.authority}oauth2/v2.0/`,
  scope: config.auth.scopes.join(" "),
};

function extractAndStoreToken(responseString) {
  const response = responseString || "";

  const processed = response
    .replace("#", "")
    .split("&")
    .reduce((res, keyValue) => {
      const [key, value] = keyValue.split("=");
      return { ...res, [key]: value };
    }, {});

  processed.receive_time = Date.now(); // eslint-disable-line camelcase

  if (response && !processed.error && processed.access_token) {
    window.localStorage.setItem("token", JSON.stringify(processed));

    window.sessionStorage.setItem("isCustomAuth", true);

    if (processed.is_iframe) {
      window.sessionStorage.setItem("isIframe", true);
    }
  }
}

function redirectToLogin() {
  let authRequest = `${authConfig.url}${authConfig.endpointAuth}?`;
  authRequest += `client_id=${authConfig.clientId}`;
  authRequest += `&response_type=${authConfig.responseType}`;
  authRequest += `&response_mode=${authConfig.responseMode}`;
  authRequest += `&scope=${encodeURIComponent(authConfig.scope)}`;
  authRequest += `&p=${authConfig.authPolicy}`;
  const url = new URL(authRequest);
  url.searchParams.append("state", window.location.pathname);
  url.searchParams.append("redirect_uri", `${window.location.origin}/auth`);
  window.location.replace(url);
}

function logout() {
  let logoutRequest = `${authConfig.url}${authConfig.endpointLogout}?`;
  logoutRequest += `&p=${authConfig.authPolicy}`;

  localStorage.removeItem("token");

  const url = new URL(logoutRequest);
  url.searchParams.append("post_logout_redirect_uri", window.location.origin);
  window.location.replace(url);
}

function isUserAuthenticated() {
  const token = sjson.parse(window.localStorage.getItem("token"));
  if (!token || isTokenExpired(token)) {
    return false;
  }
  return true;
}

function isTokenExpired(token) {
  if (!token || !token.expires_in || !token.receive_time) {
    return true;
  }
  const msPerSecond = 1000;
  const tokenExpireDuration = token.expires_in * msPerSecond;
  const msSinceTokenFetch = Date.now() - token.receive_time;

  const isExpired = msSinceTokenFetch > tokenExpireDuration;

  return isExpired;
}

export default {
  extractAndStoreToken,
  redirectToLogin,
  logout,
  isUserAuthenticated,
  isTokenExpired,
};
