import { gql } from "@apollo/client";

export const GET_ORGANIZATION_AGGREGATES = gql`
  query getOrganizationAggregates(
    $organizationId: String!
    $fromDate: String!
    $toDate: String!
  ) {
    organization(id: $organizationId) {
      id
      energy {
        aggregate(fromDate: $fromDate, toDate: $toDate) {
          type
          unit
          readings {
            time
            value
          }
        }
      }
    }
  }
`;
