import React from "react";
import PropTypes from "prop-types";

import AccessControl from "./accessControl";

const mapRestriction = (types) => {
  if (!types || types.length === 0) return undefined;

  if (types.length === 1) return { type: types[0], permission: "Read" };

  return types.map((t) => ({ type: t, permission: "Read" }));
};

const ReadAccessControl = ({ restrictionTypes, children, ...props }) => {
  const restriction = mapRestriction(restrictionTypes);

  return (
    <AccessControl restriction={restriction} {...props}>
      {children}
    </AccessControl>
  );
};

ReadAccessControl.propTypes = {
  restrictionTypes: PropTypes.array,
  children: PropTypes.node,
};

export default ReadAccessControl;
