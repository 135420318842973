import React, { useState } from "react";
import PropTypes from "prop-types";
import { addYears } from "date-fns";

import { useOrganizationQuery } from "/src/services/apollo/hooks/useOrganizationQuery";
import { GET_INDEX_POOLS } from "/src/services/apollo/queries";
import { Translate } from "src/translations/translate";

import { INDEXPOOL_TYPE } from "/src/constants/portfolioConfigurationTypes";
import LoadingSpinner from "/src/components/loadingSpinner";
import Orders from "./orders";

const OrdersContainer = ({ portfolioReportConfigurations }) => {
  const validConfigurations = portfolioReportConfigurations.filter(
    (c) => c.type === INDEXPOOL_TYPE
  );

  const [selectedConfigurationIds, setSelectedConfigurationIds] = useState(
    validConfigurations.map((config) => config.id)
  );
  const [fromDate, setFromDate] = useState(addYears(new Date(), -1));

  const { loading, error, data } = useOrganizationQuery(GET_INDEX_POOLS, {
    variables: {
      portfolioConfigurationIds: selectedConfigurationIds,
      fromDate,
    },
  });

  if (loading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return (
      <div>
        <Translate>UnexpectedError</Translate>
      </div>
    );
  }

  const { indexPools } = data.organization;

  return (
    <Orders
      indexPools={indexPools}
      fromDate={fromDate}
      setFromDate={setFromDate}
      portfolioReportConfigurations={validConfigurations}
      selectedConfigurationIds={selectedConfigurationIds}
      setSelectedConfigurationIds={setSelectedConfigurationIds}
    ></Orders>
  );
};

OrdersContainer.propTypes = {
  organizationId: PropTypes.string,
  portfolioReportConfigurations: PropTypes.array,
};

export default OrdersContainer;
