import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { Translate } from "src/translations/translate"
import AuthImage from "/src/components/authImage";
import config from "/src/config/config";

import styles from "./avatar.module.scss";

const buildProxyUrl = (img) => `${config.fileProxyURL}${img}`;

const defaultUrl = "/images/default-user.svg";

const Avatar = ({
  className,
  imageUrl,
  type,
  showButton = false,
  onChange,
  author = false,
}) => {
  const decoratedOnChange = (onChange) => (event) => {
    const reader = new FileReader();
    reader.onload = () => {
      onChange(reader.result);
    };
    reader.readAsDataURL(event.target.files[0]);
  };

  imageUrl = imageUrl || defaultUrl;

  return (
    <div className={classNames(className, styles.avatar, styles[type])}>
      {imageUrl === defaultUrl || author ? (
        <img src={imageUrl} alt={""} />
      ) : (
        <AuthImage src={buildProxyUrl(imageUrl)} alt={""} />
      )}
      {showButton && (
        <label className={styles.upload}>
          <input
            type="file"
            accept="
              image/png, .png,
              image/jpeg, .jpeg, .jpg,
            "
            onChange={decoratedOnChange(onChange)}
          />
          <div className={styles.labelWrapper}>
            <span className={styles.label}>
              <Translate>select image</Translate>
            </span>
          </div>
        </label>
      )}
    </div>
  );
};

Avatar.propTypes = {
  className: PropTypes.string,
  type: PropTypes.oneOf(["", "large", "x-large"]),
  imageUrl: PropTypes.string,
  showButton: PropTypes.bool,
  onChange: PropTypes.func,
  author: PropTypes.bool,
};

export default Avatar;
