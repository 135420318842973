import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { parseISO } from "date-fns";
import { useTranslation } from "src/translations/translationProvider";

import Avatar from "/src/components/avatar";

import styles from "./author.module.scss";

const Author = ({
  className,
  firstName,
  lastName,
  publishedDate,
  avatar,
  large,
}) => {
  const { formatDate } = useTranslation();

  const format = (date) =>
    date ? formatDate(parseISO(date), "d MMMM yyyy") : "";

  return (
    <div className={classNames(styles.author, className)}>
      <Avatar
        author
        className={classNames(styles.authorAvatar, {
          [styles.authorAvatarLarge]: large,
        })}
        imageUrl={avatar ? avatar.fileUrl : undefined}
      />
      <div className={styles.authorDetails}>
        <span
          className={classNames(styles.authorName, {
            [styles.authorNameLarge]: large,
          })}
        >
          {firstName}&nbsp;{lastName}
        </span>
        {publishedDate && (
          <span className={styles.date}>{format(publishedDate)}</span>
        )}
      </div>
    </div>
  );
};

Author.propTypes = {
  avatar: PropTypes.any,
  className: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  publishedDate: PropTypes.string,
  large: PropTypes.bool,
};

export default Author;
