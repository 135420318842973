import { gql } from "@apollo/client";

import { Article } from "/src/services/apollo/fragments";

export const GET_ARTICLES = gql`
  query getArticles(
    $organizationId: String!
    $searchInput: String! = ""
    $typeFilter: String! = "All"
    $startCount: Int! = 0
    $maxResult: Int! = 20
    $locale: String!
  ) {
    organization(id: $organizationId) {
      id
      articles(
        search: $searchInput
        filterType: $typeFilter
        skip: $startCount
        limit: $maxResult
        locale: $locale
      ) {
        ...Article
      }
    }
  }
  ${Article}
`;
