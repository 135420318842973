import { gql } from "@apollo/client";

export const GET_DOCUMENTS = gql`
  query getDocuments($organizationId: String!) {
    organization(id: $organizationId) {
      id
      documents {
        id
        createdDate
        createdBy
        modifiedDate
        modifiedBy
        organization
        fileName
      }
    }
  }
`;
