import { gql } from "@apollo/client";

export const Image = gql`
  fragment Image on Image {
    id
    title
    description
    fileUrl
    imageHeight
    imageWidth
  }
`;
