import { gql } from "@apollo/client";

export const GET_PLANT_AGGREGATES = gql`
  query getPlantAggregate(
    $organizationId: String!
    $plantId: String!
    $fromDate: String!
    $toDate: String!
  ) {
    organization(id: $organizationId) {
      id
      plant(id: $plantId) {
        id
        energy {
          aggregate(fromDate: $fromDate, toDate: $toDate) {
            type
            unit
            readings {
              time
              value
            }
          }
        }
      }
    }
  }
`;
