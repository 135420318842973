import React from "react";
import PropTypes from "prop-types";
import config from "/src/config/config";

const EnvironmentGuard = ({ allowed, children }) => {
  const { environment } = config;

  if (allowed && allowed.includes(environment)) return <>{children}</>;

  return null;
};

EnvironmentGuard.propTypes = {
  allowed: PropTypes.array,
};

export default EnvironmentGuard;
