import { gql } from "@apollo/client";

export const GET_PHYSICAL_FEES = gql`
  query getPhysicalFees($organizationId: String!) {
    organization(id: $organizationId) {
      name
      physicalFees {
        id
        rules {
          factor
          id
          type
        }
        validFrom
        validTo
      }
    }
  }
`;
