import React from "react";
import config, { environments as env } from "/src/config/config";
import { EnvironmentGuard } from "/src/components/guards";
import styles from "./environmentWarning.module.scss";

const EnvironmentWarning = () => {
  return (
    <EnvironmentGuard allowed={[env.LOCAL, env.TEST, env.STAGE]}>
      <div className={styles.environmentWarning}>
        <div className={styles.content}>
          <p>{config.environment}</p>
        </div>
      </div>
    </EnvironmentGuard>
  );
};

export default EnvironmentWarning;
