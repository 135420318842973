import { gql } from "@apollo/client";

export const GET_ROLES_AND_CLAIMS = gql`
  query getRolesAndClaims($organizationId: String!) {
    organization(id: $organizationId) {
      id
      roles {
        id
        name
        claims {
          type
          value
        }
      }
      availableClaims {
        type
        value
      }
    }
  }
`;
