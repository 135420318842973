import React, { useState } from "react";
import classNames from "classnames";
import { subMonths } from "date-fns";

import { useTranslation } from "src/translations/translationProvider";
import { Translate } from "src/translations/translate";
import { formatNumber } from "/src/utilities";
import { useOrganizationQuery } from "/src/services/apollo/hooks/useOrganizationQuery";
import { GET_PHYSICAL_DATA } from "/src/services/apollo/queries";
import PortfolioCard from "/src/modules/portfolio/portfolioCard";
import ControlHeader from "/src/components/controlHeader";
import { MonthPicker } from "/src/components/calendar";
import PhysicalTable from "./physicalTable";
import CARDS from "./cards";
import LoadingSpinner from "/src/components/loadingSpinner";

import styles from "./physical.module.scss";
import adminStyles from "/src/styles/admin.module.scss";

const Physical = () => {
  const { translate, formatDate } = useTranslation();
  const [activeCard, setActiveCard] = useState(CARDS.TOTAL);
  const [month, setMonth] = useState(subMonths(new Date(), 1));

  const { loading, error, data } = useOrganizationQuery(GET_PHYSICAL_DATA, {
    variables: {
      year: month.getFullYear(),
      month: month.getMonth() + 1,
    },
  });

  if (loading) {
    return (
      <div>
        <LoadingSpinner />
      </div>
    );
  }

  if (error) {
    console.log(error); // eslint-disable-line no-console
    return (
      <div>
        <Translate>UnexpectedError</Translate>
      </div>
    );
  }

  const { physical } = data.organization.portfolio;

  const {
    productionTotalAmount,
    consumptionTotalAmount,
    productionRows,
    consumptionRows,
    currency,
  } = physical;

  return (
    <div className={styles.physical}>
      <ControlHeader>
        <MonthPicker
          dataTestId="physical-month-picker"
          month={month}
          onChange={setMonth}
          triggerClassName={classNames(adminStyles.trigger, styles.trigger)}
        />
      </ControlHeader>

      <div className={styles.cardRow}>
        <PortfolioCard
          onClick={() => setActiveCard(CARDS.PRODUCTION)}
          active={activeCard === CARDS.PRODUCTION}
          text={translate("Production")}
          value={formatNumber(productionTotalAmount)}
          valueClassName={styles.production}
          unit={currency.toUpperCase()}
          vertical
        />
        <PortfolioCard
          onClick={() => setActiveCard(CARDS.CONSUMPTION)}
          active={activeCard === CARDS.CONSUMPTION}
          text={translate("Consumption")}
          value={formatNumber(consumptionTotalAmount)}
          unit={currency.toUpperCase()}
          vertical
        />
        <PortfolioCard
          onClick={() => setActiveCard(CARDS.TOTAL)}
          active={activeCard === CARDS.TOTAL}
          text={translate("Total")}
          value={formatNumber(productionTotalAmount + consumptionTotalAmount)}
          valueClassName={styles.total}
          unit={currency.toUpperCase()}
          vertical
        />
      </div>

      {activeCard === CARDS.PRODUCTION && (
        <PhysicalTable
          className={styles.table}
          title={`${translate("Production")} ${formatDate(
            month,
            "MMMM yyyy"
          ).toLowerCase()}`}
          unit={"MWh"}
          currency={currency}
          data={[...productionRows].sort((a, b) => a.sortOrder - b.sortOrder)}
        />
      )}
      {activeCard === CARDS.CONSUMPTION && (
        <PhysicalTable
          className={styles.table}
          title={`${translate("Consumption")} ${formatDate(
            month,
            "MMMM yyyy"
          ).toLowerCase()}`}
          unit={"MWh"}
          currency={currency}
          data={[...consumptionRows].sort((a, b) => a.sortOrder - b.sortOrder)}
        />
      )}
    </div>
  );
};

export default Physical;
