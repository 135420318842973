import React, { useRef } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useMutation } from "/src/services/apollo/hooks/useOrganizationQuery";

import { useMsal } from "@azure/msal-react";
import { isCustomAuth } from "/src/auth/authenticationStrategies";
import customAuth from "/src/services/customAuth";

import LanguageSwitcher from "/src/components/languageSwitcher";
import ProfileInfo from "/src/components/profileInfo";
import HashLink from "/src/components/hashLink";
import { Translate } from "src/translations/translate";
import { Mobile } from "/src/components/responsive";
import { SELECT_ORGANIZATION } from "/src/services/apollo/mutations";
import { GET_CURRENT_USER } from "/src/services/apollo/queries";

import { useEscape } from "/src/utilities/hooks";
import { SlideInFromLeft } from "/src/components/transitions";

import { LogoutIcon } from "/src/icons";
import { ProfileIcon } from "/src/icons";
import { ContactIcon } from "/src/icons";
import { InformationIcon } from "/src/icons";

import styles from "./menu.module.scss";
import { GET_FINANCIAL_DATA } from "src/services/apollo/queries";

const Menu = ({
  isOpen,
  close,
  user: {
    avatar,
    firstName,
    lastName,
    selectedOrganizationConnection = {},
    organizationConnections,
  },
  children,
}) => {
  let msal = null;

  /* eslint-disable react-hooks/rules-of-hooks */
  if (!isCustomAuth()) {
    msal = useMsal();
  }
  /* eslint-enable react-hooks/rules-of-hooks */

  const ref = useRef();

  useEscape(() => {
    close();
  });

  const [selectOrganization] = useMutation(SELECT_ORGANIZATION);

  const handleOrganizationChange = (value) => {
    selectOrganization({
      refetchQueries: [
        {
          query: GET_CURRENT_USER,
        },
        {
          query: GET_FINANCIAL_DATA,
          variables: {
            organizationId: value,
          },
        },
      ],
      variables: {
        organizationId: value,
      },
    });
  };

  return (
    <div
      ref={ref}
      className={classNames(styles.overlay, { [styles.open]: isOpen })}
      onClick={(e) => {
        if (e.target === ref.current) {
          close();
        }
      }}
    >
      <SlideInFromLeft in={isOpen} mountOnEnter unmountOnExit>
        <div className={styles.menu}>
          <div className={styles.header}>
            <ProfileInfo
              avatar={avatar}
              firstName={firstName}
              lastName={lastName}
              selectedOrganization={selectedOrganizationConnection.organization}
              organizationConnections={organizationConnections}
              onChangeOrganization={handleOrganizationChange}
            />
          </div>
          <div className={styles.content}>{children}</div>
          <div className={styles.footer}>
            <Mobile>
              <HashLink
                className={classNames(
                  styles.footerItem,
                  styles.footerItemSpacing,
                )}
                hash={`${window.location.hash}contact`}
                data-test-id="user-menu-update-profile"
              >
                <ContactIcon className="icon" />
                <Translate>Contact us</Translate>
              </HashLink>
            </Mobile>
            <HashLink
              className={classNames(
                styles.footerItem,
                styles.footerItemSpacing,
              )}
              hash={`${window.location.hash},editprofile`}
              data-test-id="user-menu-update-profile"
            >
              <ProfileIcon className="icon" />
              <Translate>Edit Profile</Translate>
            </HashLink>
            <Link
              className={classNames(
                styles.footerItem,
                styles.footerItemSpacing,
              )}
              to={"/agreement"}
              data-test-id="user-menu-agreement"
            >
              <InformationIcon className="icon" />
              <Translate>User Agreement</Translate>
            </Link>
            <LanguageSwitcher
              className={classNames(
                styles.footerItem,
                styles.footerItemSpacing,
                styles.languageSwitcher,
              )}
            />
            <div
              data-test-id="log-out-button"
              className={classNames(styles.footerItem)}
              onClick={() =>
                msal && msal.instance
                  ? msal.instance.logoutRedirect({
                      account: msal.instance.getActiveAccount(),
                    })
                  : customAuth.logout()
              }
            >
              <LogoutIcon className="icon" />
              <Translate>Logout</Translate>
            </div>
          </div>
        </div>
      </SlideInFromLeft>
    </div>
  );
};

Menu.propTypes = {
  isOpen: PropTypes.bool,
  close: PropTypes.func,
  user: PropTypes.shape({
    avatar: PropTypes.object,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    selectedOrganizationConnection: PropTypes.shape({
      organization: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      }),
    }),
    organizationConnections: PropTypes.arrayOf(
      PropTypes.shape({
        organization: PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string,
        }),
      }),
    ),
  }),
  children: PropTypes.node,
  selectOrganization: PropTypes.func,
};

Menu.defaultProps = {
  close: () => {},
  user: {
    firstName: "Unknown",
    lastName: "User",
    currentOrganization: "Organization",
  },
};

export default Menu;
