import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { addMonths, isFuture, isToday } from "date-fns";
import sjson from "secure-json-parse";

import { useOrganizationQuery } from "/src/services/apollo/hooks/useOrganizationQuery";
import { useTranslation } from "src/translations/translationProvider";
import { GET_DAILY_COMMENTS_AND_NOTIFICATIONS } from "/src/services/apollo/queries";
import {
  DailyNotification,
  ExtraNotification,
} from "/src/components/notifications";

import styles from "./notification.module.scss";
import { useGetCurrentUser } from "src/modules/userProvider";

const DISMISSED_DAILY_COMMENTS = "dismissedDailyComments";
const DISMISSED_NOTIFICATIONS = "dismissedNotifications";

export function removeOldDailyComments(notifications) {
  return notifications.filter((n) => n.dateRead && isToday(n.dateRead));
}

export function removeOldNotifications(notifications) {
  return notifications.filter((n) => {
    const dayToExpire = addMonths(n.dateRead, 1);
    return (n.dateRead && isToday(dayToExpire)) || isFuture(dayToExpire);
  });
}

function findUndismissedNotification(notifications, dismissedNotifications) {
  if (notifications && notifications.length) {
    // nested find feels bad but these lists should contain only a few items
    return notifications.find(
      (notification) =>
        !dismissedNotifications.find((n) => n.id === notification.id)
    );
  }

  return null;
}

function getFromLocalStorage(key, defaultValue) {
  const result = localStorage.getItem(key);

  if (!result) {
    return defaultValue;
  }

  try {
    return sjson.parse(result);
  } catch (e) {
    localStorage.removeItem(key);
    return defaultValue;
  }
}

function saveDismissedToLocalStorage(key, dismissedNotifications) {
  const cleanedData = dismissedNotifications.map(({ id }) => ({
    id,
    dateRead: new Date().toString(),
  }));

  localStorage.setItem(key, JSON.stringify(cleanedData));
}

const NotificationContainer = () => {
  const currentUser = useGetCurrentUser();
  const { apiLocale } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const [dismissedDailyComments, setDismissedDailyComments] = useState([]);
  const [dismissedNotifications, setDismissedNotifications] = useState([]);

  useEffect(() => {
    setDismissedDailyComments(
      getFromLocalStorage(DISMISSED_DAILY_COMMENTS, [])
    );
    setDismissedNotifications(getFromLocalStorage(DISMISSED_NOTIFICATIONS, []));
  }, []);

  function onDisable() {
    navigate(location, {
      hash: ",editprofile",
    });
  }

  function onDismissDaily(notification) {
    const newDismissed = [
      ...removeOldDailyComments(dismissedDailyComments),
      notification,
    ];
    setDismissedDailyComments(newDismissed);
    saveDismissedToLocalStorage(DISMISSED_DAILY_COMMENTS, newDismissed);
  }

  function onDismissNotification(notification) {
    let notificationToAdd = notification;

    if (!notificationToAdd.dateRead) {
      notificationToAdd = {
        ...notification,
        dateRead: new Date(),
      };
    }
    const newDismissed = [
      ...removeOldNotifications(dismissedNotifications),
      notificationToAdd,
    ];

    setDismissedNotifications(newDismissed);
    saveDismissedToLocalStorage(DISMISSED_NOTIFICATIONS, newDismissed);
  }

  const { loading, error, data } = useOrganizationQuery(
    GET_DAILY_COMMENTS_AND_NOTIFICATIONS,
    {
      variables: {
        locale: apiLocale,
        includeDailyComment: currentUser.showDailyComment ?? true,
      },
    }
  );

  if (loading) {
    return null;
  }

  if (error) {
    console.error(error); // eslint-disable-line no-console
    return null;
  }

  const { dailyComments, notifications } = data;

  const filteredDailyComment = findUndismissedNotification(
    dailyComments,
    dismissedDailyComments
  );

  const filteredNotification = findUndismissedNotification(
    notifications,
    dismissedNotifications
  );

  if (!filteredDailyComment && !filteredNotification) {
    return null;
  }

  return (
    <div className={styles.notificationContainer}>
      {filteredNotification && (
        <ExtraNotification
          key={filteredNotification.id}
          notification={filteredNotification}
          onDismiss={onDismissNotification}
        />
      )}
      {filteredDailyComment && (
        <DailyNotification
          key={filteredDailyComment.id}
          notification={filteredDailyComment}
          onDismiss={onDismissDaily}
          onDisable={onDisable}
        />
      )}
    </div>
  );
};

export default NotificationContainer;
