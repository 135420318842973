import { gql } from "@apollo/client";

export const GET_DAILY_COMMENTS_AND_NOTIFICATIONS = gql`
  query DailyCommentsAndNotifications(
    $locale: String!
    $includeDailyComment: Boolean!
  ) {
    dailyComments(locale: $locale) @include(if: $includeDailyComment) {
      id
      title
      content
    }
    notifications(locale: $locale) {
      id
      title
      content
      url
    }
  }
`;
