import { gql } from "@apollo/client";

export const GET_GO_SUMMARY = gql`
  query getGoSummary($organizationId: String!, $year: Int!) {
    organization(id: $organizationId) {
      id
      goSummary(year: $year) {
        year
        totalPrice
        totalQuantity
        totalUnitPrice
        marketPrices {
          productionType
          value
        }
        marketQuantities {
          productionType
          value
        }
      }
    }
  }
`;

export const GET_GO_SUMMARY_EXCEL = gql`
  query getGoSummaryExcel(
    $organizationId: String!
    $year: Int!
    $language: Int!
  ) {
    organization(id: $organizationId) {
      id
      goSummaryExcel(year: $year, language: $language) {
        content
        filename
      }
    }
  }
`;

export const GET_GO_WITHOUT_SERVICE = gql`
  query getGoWithoutService(
    $organizationId: String!
    $productionTypes: [String]
  ) {
    organization(id: $organizationId) {
      id
      goWithoutService(productionTypes: $productionTypes) {
        year
        forecast
        buyQuantity
        buyPriceAvg
        buyForecastPercent
        sellQuantity
        sellPriceAvg
        sellForecastPercent
        importedQuantity
        importedPriceAvg
        totalQuantity
        totalPriceAvg
        totalPrice
        remainingQuantity
        remainingPercent
        importedBuyQuantity
        importedBuyPriceAvg
        importedSellQuantity
        importedSellPriceAvg
      }
    }
  }
`;
