export const TABS = {
  OVERVIEW: "OVERVIEW",
  PLANT: "PLANT",
  AVAILABILITY: "AVAILABILITY",
};

export const SUPPLIER_TABS = {
  ...TABS,
};

export const BALANCE_TABS = [TABS["OVERVIEW"]];
