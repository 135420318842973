import React from "react";
import PropTypes from "prop-types";

import styles from "./slideInFromLeft.module.scss";

import Transition from "./transition";

const SlideInFromLeft = ({ children, ...props }) => (
  <Transition classNames={{ ...styles }} {...props}>
    {children}
  </Transition>
);

SlideInFromLeft.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default SlideInFromLeft;
