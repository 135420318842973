import { gql } from "@apollo/client";

export const GET_CURRENT_USER = gql`
  query Me {
    currentUser {
      id
      firstName
      lastName
      email
      showDailyComment
      phoneNumber
      avatar {
        id
        fileUrl
      }
      impersonatedRole {
        roleName
        organizationName
      }
      active
      selectedOrganizationConnection {
        organization {
          id
          name
          category
        }
        role {
          id
          name
          claims {
            type
            value
          }
        }
      }
      organizationConnections {
        organization {
          id
          name
        }
        role {
          id
          name
          claims {
            type
            value
          }
        }
      }
    }
  }
`;
