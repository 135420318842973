import React, { useEffect, useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import sjson from "secure-json-parse";

import { useTranslation } from "src/translations/translationProvider";
import Table from "/src/components/table";
import ToggleColumns from "/src/components/toggleColumns";
import { FormatOrEmpty } from "/src/components/tableCellFormats";
import { formatNumber } from "/src/utilities";

import portfolioStyles from "/src/modules/portfolio/portfolio.module.scss";
import financialStyles from "../financial.module.scss";
import styles from "./indexDetails.module.scss";

const IndexDetailsTable = ({ className, indexOrders, heading }) => {
  const { translate, locale } = useTranslation();
  const [visibleColumns, setVisibleColumns] = useState(
    getColumns({ translate, locale })
  );

  useEffect(() => {
    setVisibleColumns(getColumns({ translate, locale }));
  }, [translate, locale]);

  const decoratedSetVisibleColumns = (columns) => {
    const storeMe = columns.reduce(
      (prev, { accessor, show }) => ({ ...prev, [accessor]: show }),
      {}
    );
    localStorage.setItem("visibleIndexColumns", JSON.stringify(storeMe));
    setVisibleColumns(columns);
  };

  return (
    <div
      data-test-id="index-details-table"
      className={classNames(className, portfolioStyles.physicalTable)}
    >
      <div
        data-test-id={`index-table-header-${heading}`}
        className={financialStyles.titleRow}
      >
        <div
          className={classNames(portfolioStyles.title, financialStyles.title)}
        >
          {heading}
        </div>
        <ToggleColumns
          columns={visibleColumns}
          setColumns={decoratedSetVisibleColumns}
        />
      </div>
      <Table
        headerClassName={classNames(
          portfolioStyles.cell,
          styles.header,
          portfolioStyles.header,
          styles.hedgeReportTableHeader
        )}
        cellClassName={portfolioStyles.cell}
        data={indexOrders}
        columns={visibleColumns.filter((column) => column.show)}
        sortable
        exportable
        tableOptions={{
          initialState: {
            sortBy: [
              {
                id: "createdDate",
                desc: true,
              },
            ],
          },
          disableSortRemove: true,
        }}
      />
    </div>
  );
};

IndexDetailsTable.protoTypes = {
  className: PropTypes.string,
  indexOrders: PropTypes.array,
  heading: PropTypes.node,
};

export default IndexDetailsTable;

const getColumns = ({ translate, locale }) => {
  const DEFAULT_VISIBLE_COLUMNS = {
    portfolioName: true,
    positionType: true,
    product: true,
    deliveryPeriod: true,
    forecastVolume: true,
    targetVolume: true,
    hedgedVolume: true,
    hedgedPercentageOfTarget: true,
    hedgedPercentageOfForecast: true,
    price: true,
    status: true,
    tradingStartDate: true,
    tradingEndDate: true,
    createdDate: false,
  };

  const savedColumnState = localStorage.getItem("visibleIndexColumns");

  const visibleColumns = {
    ...DEFAULT_VISIBLE_COLUMNS,
    ...sjson.parse(savedColumnState),
  };

  const LabelAndUnit = (
    { label, unit } // eslint-disable-line react/prop-types
  ) => (
    <div>
      <div className={styles.columnHeaderLabel}>{label}</div>
      {unit && <div className={styles.columnHeaderUnit}>{unit}</div>}
    </div>
  );

  const formatPercentage = (value) =>
    value === undefined || value === null
      ? ""
      : `${formatNumber(value * 100, locale)}%`;

  return [
    {
      Header: () => translate("Portfolio"),
      accessor: "portfolioName",
      Cell: ({ value }) => value,
      name: translate("Portfolio"),
      show: visibleColumns["portfolioName"],
    },
    {
      Header: () => translate("Order"),
      accessor: "positionType",
      Cell: ({ value }) => translate(value),
      name: translate("Order"),
      show: visibleColumns["positionType"],
    },
    {
      Header: () => translate("Product"),
      accessor: "product",
      Cell: ({ value }) => value,
      name: translate("Product"),
      show: visibleColumns["product"],
    },
    {
      Header: () => translate("Delivery"),
      accessor: ({ hedgingStartDate, hedgingEndDate }) => ({
        hedgingStartDate,
        hedgingEndDate,
      }),
      id: ({ portfolioId }) => portfolioId,
      Cell: ({ value }) => {
        const { hedgingStartDate, hedgingEndDate } = value;
        const endDate =
          hedgingEndDate === null
            ? translate("Until further notice")
            : new Intl.DateTimeFormat(locale).format(new Date(hedgingEndDate));
        return `${new Intl.DateTimeFormat(locale).format(
          new Date(hedgingStartDate),
          {
            year: "numeric",
            month: "numeric",
          }
        )} - ${endDate}`;
      },
      name: translate("Delivery period"),
      show: visibleColumns["deliveryPeriod"],
    },
    {
      Header: () => LabelAndUnit({ label: translate("Forecast"), unit: "MWh" }),
      accessor: "forecastVolume",
      Cell: ({ value }) => <FormatOrEmpty value={value} />,
      name: `${translate("Forecast")} MWh`,
      show: visibleColumns["forecastVolume"],
    },
    {
      Header: () => LabelAndUnit({ label: translate("Target"), unit: "MWh" }),
      accessor: "targetVolume",
      Cell: ({ value }) => <FormatOrEmpty value={value} />,
      name: `${translate("Target")} MWh`,
      show: visibleColumns["targetVolume"],
    },
    {
      Header: () => LabelAndUnit({ label: translate("Hedged"), unit: "MWh" }),
      accessor: "hedgedVolume",
      Cell: ({ value }) => <FormatOrEmpty value={value} />,
      name: `${translate("Hedged")} MWh`,
      show: visibleColumns["hedgedVolume"],
    },
    {
      Header: () => LabelAndUnit({ label: translate("Target"), unit: "%" }),
      accessor: "hedgedPercentageOfTarget",
      Cell: ({ value }) => formatPercentage(value),
      name: translate("Target"),
      show: visibleColumns["hedgedPercentageOfTarget"],
    },
    {
      Header: () => LabelAndUnit({ label: translate("Forecast"), unit: "%" }),
      accessor: "hedgedPercentageOfForecast",
      Cell: ({ value }) => formatPercentage(value),
      name: translate("Forecast"),
      show: visibleColumns["hedgedPercentageOfForecast"],
    },
    {
      Header: () =>
        LabelAndUnit({ label: translate("Price"), unit: "SEK/MWh" }),
      accessor: "price",
      Cell: ({ value }) => <FormatOrEmpty value={value} />,
      name: translate("Price"),
      show: visibleColumns["price"],
    },
    {
      Header: () => translate("Status"),
      accessor: "status",
      Cell: ({ value }) => translate(value),
      name: translate("Status"),
      show: visibleColumns["status"],
    },
    {
      Header: () => translate("Start"),
      accessor: "tradingStartDate",
      Cell: ({ value }) =>
        new Intl.DateTimeFormat(locale).format(new Date(value), {
          year: "numeric",
          month: "numeric",
        }),
      name: translate("Start"),
      show: visibleColumns["tradingStartDate"],
    },
    {
      Header: () => translate("End"),
      accessor: "tradingEndDate",
      Cell: ({ value }) =>
        value === null
          ? translate("Until further notice")
          : new Intl.DateTimeFormat(locale).format(new Date(value), {
              year: "numeric",
              month: "numeric",
            }),
      name: translate("End"),
      show: visibleColumns["tradingEndDate"],
    },
    {
      Header: () => translate("Created"),
      accessor: "createdDate",
      Cell: ({ value }) =>
        new Intl.DateTimeFormat(locale).format(new Date(value), {
          year: "numeric",
          month: "numeric",
        }),
      name: translate("Created"),
      show: visibleColumns["createdDate"],
    },
  ];
};
