import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { isSameMonth } from "date-fns";

import { useTranslation } from "src/translations/translationProvider";
import { Translate } from "src/translations/translate";
import Dropdown from "/src/components/dropdown";
import Button from "/src/components/button";
import Calendar from "./calendar";

import styles from "./calendar.module.scss";

const MonthPicker = (initialProps) => {
  const {
    triggerClassName,
    month,
    onChange,
    isWithCaret,
    className,
    dataTestId,
    ...props
  } = initialProps;
  const { formatDate } = useTranslation();
  const dropdownRef = useRef(null);
  const [date, setDate] = useState(month);

  useEffect(() => {
    setDate(month);
  }, [month]);

  const decoratedOnChange = () => {
    if (!isSameMonth(date, month)) {
      onChange(date);
    }
  };

  const outsideClick = () => {
    decoratedOnChange();
  };

  const doneClick = () => {
    decoratedOnChange();
    dropdownRef.current.forceClose();
  };

  const cancelClick = () => {
    setDate(month);
    dropdownRef.current.forceClose();
  };

  return (
    <Dropdown
      dataTestId={dataTestId}
      ref={dropdownRef}
      onOutsideClickCallback={outsideClick}
      isWithCaret={isWithCaret}
      className={classNames(className, styles.dropdown, styles.monthPicker)}
      trigger={
        <span
          className={classNames(
            triggerClassName,
            styles.selectedDates,
            styles.lowerCase,
          )}
        >
          {formatDate(month, "MMMM yyyy")}
        </span>
      }
    >
      <Calendar
        value={date}
        onChange={setDate}
        minDetail={"year"}
        maxDetail={"year"}
        {...props}
      />
      <div className={styles.buttonRow}>
        <Button
          className={styles.cancelButton}
          small
          type={"secondary"}
          onClick={() => cancelClick()}
        >
          <Translate>Cancel</Translate>
        </Button>
        <Button
          data-test-id="monthPicker-done-button"
          small
          onClick={() => doneClick()}
        >
          <Translate>Done</Translate>
        </Button>
      </div>
    </Dropdown>
  );
};

MonthPicker.propTypes = {
  className: PropTypes.string,
  triggerClassName: PropTypes.string,
  month: PropTypes.instanceOf(Date),
  onChange: PropTypes.func,
  isWithCaret: PropTypes.bool,
  dataTestId: PropTypes.string,
  maxDate: PropTypes.date,
};

MonthPicker.defaultProps = {
  month: new Date(),
  onChange: () => {},
  isWithCaret: true,
};

export default MonthPicker;
