import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import Image from "/src/components/image";
import { PlayIcon } from "/src/icons";

import styles from "./videoThumbnail.module.scss";

const VideoThumbnail = ({ children, className, onClick, videoId }) => (
  <Image
    className={classNames(styles.videoThumbnail, className)}
    onClick={onClick}
    responsiveImage={{
      desktop: `https://i3.ytimg.com/vi/${videoId}/hqdefault.jpg`,
      tablet: `https://i3.ytimg.com/vi/${videoId}/mqdefault.jpg`,
      mobile: `https://i3.ytimg.com/vi/${videoId}/mqdefault.jpg`,
    }}
  >
    {children && <div className={styles.contentWrapper}>{children}</div>}
    <div className={styles.iconWrapper}>
      {videoId && <PlayIcon className="icon" />}
    </div>
  </Image>
);

VideoThumbnail.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  onClick: PropTypes.func,
  videoId: PropTypes.string,
};

export default VideoThumbnail;
