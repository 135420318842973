import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import styles from "./splitLayout.module.scss";

const SplitLayout = ({ className, mainContent, sideContent }) => (
  <div className={classNames(styles.splitLayout, className)}>
    <main className={styles.content}>{mainContent}</main>
    <aside className={styles.side}>{sideContent}</aside>
  </div>
);

SplitLayout.propTypes = {
  className: PropTypes.string,
  mainContent: PropTypes.node,
  sideContent: PropTypes.node,
};

export default SplitLayout;
