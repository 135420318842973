import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { useTranslation } from "src/translations/translationProvider";
import { Translate } from "src/translations/translate";
import ControlHeader from "/src/components/controlHeader";
import { MonthPicker } from "/src/components/calendar";
import Select from "/src/components/select";
import PortfolioConfigurationSelect from "../../portfolioConfigurationSelect";
import IndexPoolInvoiceMaterialTable from "./indexPoolinvoiceMaterialTable";

import financialStyles from "../../financial.module.scss";
import adminStyles from "/src/styles/admin.module.scss";
import styles from "./indexPoolinvoiceMaterial.module.scss";

const IndexPoolInvoiceMaterial = ({
  portfolioReportConfigurations,
  selectedConfigurationIds,
  setSelectedConfigurationIds,
  deliveryMonth,
  setDeliveryMonth,
  invoiceMaterial,
  selectedProduct,
  setSelectedProduct,
  availableProducts,
}) => {
  const { formatDate } = useTranslation();

  const makeDropdownOptionItems = (products) => {
    return products.map((p) => ({ label: p, value: p }));
  };

  const handleProductChange = (selection) => {
    setSelectedProduct(selection.value);
  };

  return (
    <div>
      <ControlHeader>
        <div className={financialStyles.dropdown}>
          <Translate>Portfolios</Translate>:
          <PortfolioConfigurationSelect
            availableConfigurations={portfolioReportConfigurations}
            selectedConfigurationIds={selectedConfigurationIds}
            setSelectedConfigurationIds={setSelectedConfigurationIds}
          />
        </div>
        <div className={financialStyles.dropdown}>
          <Translate>Product</Translate>:
          <Select
            key={availableProducts}
            isWithCaret
            triggerClassName={classNames(
              adminStyles.trigger,
              financialStyles.trigger
            )}
            wrapperClassName={financialStyles.selectWrapper}
            options={makeDropdownOptionItems(availableProducts)}
            value={
              selectedProduct
                ? { label: selectedProduct, value: selectedProduct }
                : null
            }
            onChange={handleProductChange}
          />
        </div>
        <div className={styles.datePicker}>
          <div>
            <Translate>Delivery month</Translate>:
          </div>
          <MonthPicker
            month={new Date(deliveryMonth)}
            onChange={(month) => {
              setDeliveryMonth(formatDate(month, "yyyy-MM-dd"));
            }}
            isWithCaret
          />
        </div>
      </ControlHeader>
      <IndexPoolInvoiceMaterialTable invoiceMaterial={invoiceMaterial} />
    </div>
  );
};

IndexPoolInvoiceMaterial.propTypes = {
  portfolioReportConfigurations: PropTypes.array,
  selectedConfigurationIds: PropTypes.array,
  setSelectedConfigurationIds: PropTypes.func,
  deliveryMonth: PropTypes.string,
  setDeliveryMonth: PropTypes.func,
  invoiceMaterial: PropTypes.array,
  selectedProduct: PropTypes.string,
  setSelectedProduct: PropTypes.func,
};

export default IndexPoolInvoiceMaterial;
