import React from "react";
import PropTypes from "prop-types";

import config from "/src/config/config";
import { useTranslation } from "src/translations/translationProvider";
import AuthLink from "/src/components/authLink";
import Button from "/src/components/button";

const HedgeReportExcelExport = ({
  organizationId,
  className,
  args,
  getColumnFilter,
}) => {
  const { locale, translate } = useTranslation();

  const getPostBody = () => ({ ...args, locale, columns: getColumnFilter() });

  const downloadUrl = `${config.fileProxyURL}/hedgereports/${organizationId}/excel`;

  return (
    <AuthLink url={downloadUrl} className={className} getPostBody={getPostBody}>
      <Button small>{translate("Download report")}</Button>
    </AuthLink>
  );
};

HedgeReportExcelExport.propTypes = {
  organizationId: PropTypes.string,
  portfolioConfigurationIds: PropTypes.array,
  importedPosition: PropTypes.number,
  pointInTime: PropTypes.instanceOf(Date),
  className: PropTypes.string,
  args: PropTypes.object,
  getColumnFilter: PropTypes.func,
};

export default HedgeReportExcelExport;
