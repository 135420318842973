import React from "react";
import { useOrganizationQuery } from "/src/services/apollo/hooks/useOrganizationQuery";
import { useTranslation } from "src/translations/translationProvider";
import CookieConsent from "./cookieConsent";
import { GET_COOKIE_CONSENT } from "/src/services/apollo/queries";

const CookieConsentContainer = (props) => {
  const { locale } = useTranslation();
  const { loading, error, data } = useOrganizationQuery(GET_COOKIE_CONSENT, {
    variables: {
      locale: locale,
    },
  });

  if (loading) {
    return null;
  }

  if (error) {
    console.error(`Can't get cookie policy: ${JSON.stringify(error)}`);
  }

  const url = data?.cookieConsent?.url;

  return <CookieConsent {...props} cookieLink={url} />;
};

export default CookieConsentContainer;
