import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { RESPONSIVE_TABLET, RESPONSIVE_MOBILE } from "/src/config/config";

import styles from "./image.module.scss";

const Image = ({ children, className, responsiveImage, ...restProps }) => (
  <picture className={classNames(styles.image, className)} {...restProps}>
    <source
      srcSet={responsiveImage.desktop}
      media={`(min-width: ${RESPONSIVE_TABLET}px)`}
    />
    <source
      srcSet={responsiveImage.tablet}
      media={`(min-width: ${RESPONSIVE_MOBILE}px)`}
    />
    <img
      className={styles.imageElement}
      src={responsiveImage.mobile}
      alt={""}
    />
    {children}
  </picture>
);

Image.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  responsiveImage: PropTypes.shape({
    desktop: PropTypes.string,
    tablet: PropTypes.string,
    mobile: PropTypes.string,
  }),
};

Image.defaultProps = {
  responsiveImage: {},
};

export default Image;
