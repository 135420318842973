import { gql } from "@apollo/client";

export const GET_PORTFOLIO_REPORT_CONFIGURATIONS = gql`
  query getPortfolioReportConfigurations($organizationId: String!) {
    clientAdministration {
      organization(id: $organizationId) {
        id
        name
        clientType
        category
        portfolioReportConfigurations {
          id
          name
        }
        availableConfigurationOptions {
          availablePortfolios {
            id
            name
          }
          availableMeasurands {
            plantName
            measurandId
            measurandName
            measurandType
            priceArea
            belongsToThirdParty
          }
          availableThirdPartyPortfolios
          availableAllegroCounterparties
          availableIndexProductPortfolios {
            id
            name
            usedByPortfolioConfiguration
          }
        }
      }
    }
  }
`;

export const GET_PORTFOLIO_REPORT_CONFIGURATION = gql`
  query getPortfolioReportConfiguration(
    $organizationId: String!
    $configurationId: String!
  ) {
    clientAdministration {
      organization(id: $organizationId) {
        id
        name
        portfolioReportConfiguration(id: $configurationId) {
          id
          name
          type
          portfolios {
            id
            name
            type
          }
          measurandIds
          thirdPartyPortfolios
          allegroCounterparties
          indexProductPortfolios {
            id
            name
          }
        }
      }
    }
  }
`;

export const GET_PORTFOLIO_IMPORTED_POSITIONS = gql`
  query getPortfolioImportedPositions(
    $organizationId: String!
    $configurationId: String!
  ) {
    priceAreas {
      financial
    }
    clientAdministration {
      organization(id: $organizationId) {
        id
        portfolioReportConfiguration(id: $configurationId) {
          id
          name
          importedPositions {
            tradeDate
            productName
            contract
            contact
            counterpart
            volume
            unit
            currency
            price
            hedgeFee
            fromDate
            toDate
            comment
            comment2
            createdBy
            created
          }
        }
      }
    }
  }
`;

export const GET_PORTFOLIO_IMPORTED_ELC_POSITIONS = gql`
  query getPortfolioImportedElcPositions(
    $organizationId: String!
    $configurationId: String!
  ) {
    clientAdministration {
      organization(id: $organizationId) {
        id
        portfolioReportConfiguration(id: $configurationId) {
          id
          name
          importedElcPositions {
            id
            createdBy
            created
            tradeDate
            productName
            currency
            contract
            counterpart
            quantity
            unit
            price
            fee
            fromDate
            toDate
            comment
            contact
            deliveryDate
          }
        }
      }
    }
  }
`;

export const GET_PORTFOLIO_IMPORTED_GO_POSITIONS = gql`
  query getPortfolioImportedGoPositions(
    $organizationId: String!
    $configurationId: String!
  ) {
    clientAdministration {
      organization(id: $organizationId) {
        id
        portfolioReportConfiguration(id: $configurationId) {
          id
          name
          importedGoPositions {
            id
            createdBy
            created
            tradeDate
            productName
            currency
            contract
            counterpart
            quantity
            unit
            price
            fee
            fromDate
            toDate
            comment
            contact
            deliveryDate
          }
        }
      }
    }
  }
`;

export const GET_FORECAST_UPDATE_HISTORY = gql`
  query getForecastUpdateHistory(
    $organizationId: String!
    $configurationId: String!
    $portfolioConfigurationIds: [String]
  ) {
    priceAreas {
      financial
    }
    organization(id: $organizationId) {
      id
      indexPools(portfolioConfigurationIds: $portfolioConfigurationIds) {
        id
        type
        key
        hedgePeriodStart
        hedgePeriodEnd
        deliveryStart
        deliveryEnd
        currency
        priceArea
        indexPool
      }
      portfolioReportConfiguration(id: $configurationId) {
        id
        name
        forecastUpdateHistory {
          forecastUpdateHistory {
            id
            createdBy
            created
            indexPoolId
          }
          latestUpdate {
            year
            month
            volume
            priceAreas {
              priceArea
              value
            }
          }
        }
      }
    }
  }
`;

export const GET_FORECAST = gql`
  query getForecastById(
    $organizationId: String!
    $configurationId: String!
    $forecastId: String!
  ) {
    organization(id: $organizationId) {
      id
      portfolioReportConfiguration(id: $configurationId) {
        id
        forecast(forecastId: $forecastId) {
          year
          month
          volume
          priceAreas {
            priceArea
            value
          }
        }
      }
    }
  }
`;

export const GET_MANDATE_UPDATE_HISTORY = gql`
  query getMandateUpdateHistory(
    $organizationId: String!
    $configurationId: String!
  ) {
    priceAreas {
      financial
    }
    organization(id: $organizationId) {
      id
      mandateUpdateHistory(configurationId: $configurationId) {
        id
        createdBy
        created
      }
    }
  }
`;

export const GET_MANDATE = gql`
  query getMandateById(
    $organizationId: String!
    $configurationId: String!
    $mandateId: String!
  ) {
    organization(id: $organizationId) {
      id
      portfolioReportConfiguration(id: $configurationId) {
        id
        mandate(mandateId: $mandateId) {
          priceAreas
          tradePeriodsMin {
            year
            month
            hedgePeriods {
              year
              month
              value
            }
          }
          tradePeriodsMax {
            year
            month
            hedgePeriods {
              year
              month
              value
            }
          }
          tradePeriodsIndex {
            year
            month
            hedgePeriods {
              year
              month
              value
            }
          }
        }
      }
    }
  }
`;
