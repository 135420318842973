import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { useTranslation } from "src/translations/translationProvider";
import { Translate } from "src/translations/translate";
import { isPhoneNumber } from "/src/utilities/validation";
import { phoneNumberFormat } from "/src/utilities";
import Input from "/src/components/input";
import Avatar from "/src/components/avatar";
import Checkbox from "/src/components/checkbox";

import styles from "./userProfile.module.scss";

const UserProfile = ({ user, onCancel, onChange, shouldPreventUpdate }) => {
  const [tempUser, setTempUser] = useState(user);
  const { translate } = useTranslation();

  const decoratedOnCancel = (onCancel) => {
    setTempUser({ ...user });
    onCancel();
  };

  return (
    <div className={styles.userProfile}>
      <h1 className={styles.title}>
        <Translate>Edit profile</Translate>
      </h1>
      <div className={classNames(styles.section, styles.personal)}>
        <div className={styles.contact}>
          <Input
            className={classNames(styles.field, styles.email)}
            value={user.email}
            label={translate("Email")}
            aria-label={"Email"}
            disabled
          />
          <Input
            className={classNames(styles.field, styles.role)}
            value={user.selectedOrganizationConnection.role.name}
            label={translate("Role")}
            aria-label={"Role"}
            disabled
          />
          <Input
            className={styles.field}
            value={tempUser.firstName}
            label={translate("First name")}
            aria-label={"First name"}
            onChange={(firstName) => setTempUser({ ...tempUser, firstName })}
            validate={{
              validationFunction: (value) => value && value.length < 65, // eslint-disable-line no-magic-numbers
              errorMessage: "Maximum 64 characters",
            }}
          />
          <Input
            className={styles.field}
            value={tempUser.lastName}
            label={translate("Last name")}
            aria-label={"Last name"}
            onChange={(lastName) => setTempUser({ ...tempUser, lastName })}
            validate={{
              validationFunction: (value) => value && value.length < 65, // eslint-disable-line no-magic-numbers
              errorMessage: "Maximum 64 characters",
            }}
          />

          <Input
            className={styles.field}
            value={phoneNumberFormat(tempUser.phoneNumber) || ""}
            label={translate("Phone number")}
            aria-label={"Phone number"}
            onChange={(phoneNumber) => {
              const sanitizedNumber = phoneNumber.replace(/\D/g, "");
              setTempUser({ ...tempUser, phoneNumber: sanitizedNumber });
            }}
            validate={{
              validationFunction: (value) => {
                if (value === null || value === "") {
                  return true;
                }
                return isPhoneNumber(value);
              },
              errorMessage: translate("Numbers only"),
            }}
          />
        </div>

        <Avatar
          key={tempUser}
          imageUrl={user.avatar && user.avatar.fileUrl}
          className={styles.avatar}
          showButton={true}
          type="x-large"
          onChange={(image) => {
            const newTempUser = { ...tempUser, avatar: { fileUrl: image } };
            setTempUser(newTempUser);
            onChange(newTempUser, { preventClosingSidePanel: true });
          }}
        />
      </div>

      <div className={styles.section}>
        <p className={styles.heading}>
          <Translate>Daily comments</Translate>
        </p>
        <Checkbox
          checked={tempUser.showDailyComment}
          text={translate("Subscribed to daily comments")}
          type="toggle"
          aria-label={"Subscribed to daily comments"}
          onClick={() =>
            setTempUser({
              ...tempUser,
              showDailyComment: !tempUser.showDailyComment,
            })
          }
        />
      </div>

      <div className={styles.buttons}>
        <button
          id="test-cancel"
          className={classNames(styles.button, styles.cancel, {
            [styles.disabled]: shouldPreventUpdate,
          })}
          onClick={() =>
            shouldPreventUpdate ? () => {} : decoratedOnCancel(onCancel)
          }
        >
          <Translate>Cancel</Translate>
        </button>
        <button
          id="test-confirm"
          data-test-id="confirm-button"
          className={classNames(styles.button, styles.confirm, {
            [styles.disabled]: shouldPreventUpdate,
          })}
          onClick={() => (shouldPreventUpdate ? () => {} : onChange(tempUser))}
        >
          <Translate>Save Changes</Translate>
        </button>
      </div>
    </div>
  );
};

UserProfile.propTypes = {
  user: PropTypes.shape({
    email: PropTypes.string,
    selectedOrganizationConnection: PropTypes.shape({
      role: PropTypes.object,
      organization: PropTypes.shape({
        name: PropTypes.string,
      }),
    }),
    organizationConnections: PropTypes.arrayOf(
      PropTypes.shape({
        organization: PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string,
        }),
      })
    ),
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    avatar: PropTypes.object,
    showDailyComment: PropTypes.bool,
    phoneNumber: PropTypes.string,
  }),
  onCancel: PropTypes.func,
  onChange: PropTypes.func,
  shouldPreventUpdate: PropTypes.bool,
};

UserProfile.defaultProps = {
  user: {},
  onChange: () => {},
  shouldPreventUpdate: false,
};

export default UserProfile;
