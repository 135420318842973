import { gql } from "@apollo/client";

export const GET_INVOICE = gql`
  query getInvoices($organizationId: String!, $invoiceId: String!) {
    organization(id: $organizationId) {
      id
      invoice(id: $invoiceId) {
        id
        type
        pdfRedirectUrl
      }
    }
  }
`;
