import { gql } from "@apollo/client";

import { Article } from "/src/services/apollo/fragments";

export const GET_ARTICLE = gql`
  query getArticle($organizationId: String!, $id: String!, $locale: String!) {
    organization(id: $organizationId) {
      id
      article(id: $id, locale: $locale) {
        ...Article
        content
      }
    }
  }
  ${Article}
`;
