import React from "react";

import { ApolloClient, ApolloLink, ApolloProvider } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import config from "/src/config/config";
import cache from "./cache";
import { errorLink, httpLink, retryLink } from "./links";
import defaultOptions from "./defaultOptions";
import { getToken } from "/src/auth/authenticationStrategies";
import { useAccount, useMsal } from "@azure/msal-react";

// Inspired by https://github.com/othpwn/apollo-client-msal-boilerplate
const ApolloWithMsal = ({ children }) => {
  const { instance, inProgress } = useMsal();
  const account = useAccount();

  if (!account && inProgress !== "none") {
    return null;
  }

  const withToken = setContext(async (_, { headers }) => {
    const token = await getToken(account);

    return {
      headers: {
        ...headers,
        Authorization: token ? `Bearer ${token}` : null,
        Accept: "application/json",
      },
    };
  });

  const client = new ApolloClient({
    link: ApolloLink.from([
      withToken,
      errorLink(instance),
      retryLink,
      httpLink(config.graphqlURL),
    ]),
    cache,
    defaultOptions,
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default ApolloWithMsal;
