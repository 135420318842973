import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { format } from "date-fns";

import { useTranslation } from "src/translations/translationProvider";
import { Translate } from "src/translations/translate";
import Table from "/src/components/table";
import { FormatOrEmpty } from "/src/components/tableCellFormats";

import styles from "./totalTable.module.scss";
import portfolioStyles from "../portfolio.module.scss";

const HeaderMonthCell = ({ value }) => (
  <span data-test-id={`header-value-${value}`} className={styles.month}>
    {value}
  </span>
);

HeaderMonthCell.propTypes = {
  value: PropTypes.string,
};

const MonthCell = ({ value }) => (
  <span data-test-id={`month-cell-${value}`} className={styles.month}>
    <Translate>{format(new Date(value), "MMMM")}</Translate>
  </span>
);
MonthCell.propTypes = {
  value: PropTypes.string,
};

const ColumnHeader = ({ label, unit, title }) => (
  <div title={title}>
    <div data-test-id={`header-value-${label}`}>{label}</div>
    {unit && (
      <div data-test-id={`value-${unit}`} className={styles.columnHeaderUnit}>
        {unit}
      </div>
    )}
  </div>
);

ColumnHeader.propTypes = {
  label: PropTypes.string.isRequired,
  unit: PropTypes.string,
  title: PropTypes.string,
  className: PropTypes.any,
};

const TotalTable = ({ className, data, currencyCode }) => {
  const { translate } = useTranslation();
  const unit = "MWh";
  const currency = currencyCode.toUpperCase();

  const TotalCell = ({ value }) => (
    <span data-test-id={`total-cell-${currency}`} className={styles.totalCell}>
      <FormatOrEmpty value={value} />
    </span>
  );

  const columns = [
    {
      Header: "",
      id: "MONTH",
      headerClassName: styles.topHeader,
      columns: [
        {
          Header: HeaderMonthCell({ value: translate("Month") }),
          accessor: "month",
          name: translate("Month"),
          Cell: MonthCell,
        },
      ],
    },
    {
      Header: translate("TOTAL_TABLE_TITLE_PHYSICAL"),
      headerClassName: styles.topHeader,
      columns: [
        {
          Header: ColumnHeader({
            label: translate("Volume"),
            unit,
            title: translate("Actual produced or consumed volume"),
          }),
          accessor: "volume",
          name: `${translate("Volume")} ${unit}`,
          Cell: ({ value }) => <FormatOrEmpty value={value} />,
        },
        {
          Header: ColumnHeader({
            label: translate("Cost(-)/Revenue(+)"),
            unit: `${currency}`,
            title: translate("With imbalance price"),
          }),
          accessor: "amount",
          name: `${translate("Cost(-)/Revenue(+)")} ${currency}`,
          Cell: ({ value }) => <FormatOrEmpty value={value} />,
        },
        {
          Header: ColumnHeader({
            label: translate("Fees"),
            unit: `${currency}`,
            title: translate("Trading fees and government fees"),
          }),
          accessor: "fee",
          name: `${translate("Fees")} ${currency}`,
          Cell: ({ value }) => <FormatOrEmpty value={value} />,
        },
      ],
    },
    {
      Header: translate("TOTAL_TABLE_TITLE_FINANCIAL"),
      headerClassName: styles.topHeader,
      columns: [
        {
          Header: ColumnHeader({
            label: translate("Hedged volume"),
            unit,
            title: translate("Actual produced or consumed volume"),
          }),
          name: `${translate("Hedged volume")} ${unit}`,
          accessor: "financialVolume",
          Cell: ({ value }) => <FormatOrEmpty value={value} />,
        },
        {
          Header: ColumnHeader({
            label: translate("Calculated result for"),
            unit: `${currency}`,
            title: translate("With imbalance price"),
          }),
          accessor: "financialOutcome",
          name: `${translate("Calculated result for")} ${currency}`,
          Cell: ({ value }) => <FormatOrEmpty value={value} />,
        },
      ],
    },
    {
      Header: "",
      id: "TOTAL",
      headerClassName: styles.topHeader,
      columns: [
        {
          Header: ColumnHeader({
            label: translate("TOTAL_TABLE_TOTAL"),
            unit: `${currency}`,
          }),
          accessor: "totalAmount",
          name: `${translate("TOTAL_TABLE_TOTAL")} ${currency}`,
          Cell: TotalCell,
        },
        {
          Header: ColumnHeader({
            label: translate("Price per unit"),
            unit: `${currency}/${unit}`,
          }),
          accessor: "unitPrice",
          name: `${translate("Price per unit")} ${currency}/${unit}`,
          Cell: ({ value }) => <FormatOrEmpty value={value} />,
        },
      ],
    },
  ];

  return (
    <Table
      className={classNames(
        portfolioStyles.table,
        styles.table,
        styles.withoutBorder,
        className
      )}
      headerClassName={classNames(
        portfolioStyles.cell,
        portfolioStyles.header,
        styles.cell,
        styles.header
      )}
      exportable
      cellClassName={classNames(portfolioStyles.cell, styles.cell)}
      data={data}
      columns={columns}
      sortable={false}
      tableOptions={{ disableSortBy: true }}
    />
  );
};

TotalTable.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  currencyCode: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      month: PropTypes.string,
      volume: PropTypes.number,
      amount: PropTypes.number,
      fee: PropTypes.number,
      totalAmount: PropTypes.number,
      unitPrice: PropTypes.number,
    })
  ),
};

export default TotalTable;
