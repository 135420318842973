import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";


import { useTranslation } from "src/translations/translationProvider";
import { Translate } from "src/translations/translate";


import styles from "./calendar.module.scss";

const RangePickerMobile = ({
  from,
  to,
  onChangeFrom,
  onChangeTo,
  className,
  ...props
}) => {
  const { formatDate } = useTranslation();

  const formatToISODateFormat = (date) => {
    if (!date || date.constructor.name !== "Date") {
      return "";
    }
    return formatDate(date, "yyyy-MM-dd");
  };

  return (
    <div className={classNames(className, styles.rangePickerMobile)} {...props}>
      <div>
        <label className={styles.dateLabel}>
          <div className={styles.dateLabelText}>
            <Translate>From</Translate>:{" "}
          </div>
          <input
            className={styles.dateLabelInput}
            type="date"
            value={formatToISODateFormat(from)}
            onChange={(event) => onChangeFrom(event.target.value)}
          ></input>
        </label>
      </div>
      <div>
        <label className={styles.dateLabel}>
          <div className={styles.dateLabelText}>
            <Translate>To</Translate>:{" "}
          </div>
          <input
            className={styles.dateLabelInput}
            type="date"
            value={formatToISODateFormat(to)}
            onChange={(event) => onChangeTo(event.target.value)}
          ></input>
        </label>
      </div>
    </div>
  );
};

RangePickerMobile.propTypes = {
  from: PropTypes.object,
  to: PropTypes.object,
  onChangeFrom: PropTypes.func,
  onChangeTo: PropTypes.func,
  className: PropTypes.string,
};

RangePickerMobile.defaultProps = {
  from: new Date(),
  to: new Date(),
  onChangeFrom: () => {},
  onChangeTo: () => {},
};

export default RangePickerMobile;
