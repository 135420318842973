const accessLevels = ["Read", "ReadWrite"];

export const hasAccess = (restriction, userClaims) => {
  const indexedUserClaims = userClaims.reduce((result, currentValue) => {
    result[currentValue.type] = currentValue;
    return result;
  }, {});

  const restrictedUserClaim = indexedUserClaims[restriction.type];
  const hasClaim = !!restrictedUserClaim;
  if (hasClaim) {
    const restrictionLevel = accessLevels.indexOf(restriction.permission);
    const accessLevel = accessLevels.indexOf(restrictedUserClaim.value);
    return accessLevel >= restrictionLevel;
  }

  return false;
};
