import React from "react";

import Chart from "/src/components/chart";
import { modityColors } from "/src/components/chart/utils";

import styles from "../latestSpotPrices.module.scss";
import { useTranslation } from "src/translations/translationProvider";

const colors = Object.values(modityColors);

const SpotPriceChart = ({ spotPriceAreas }) => {
  const { translate } = useTranslation();

  const sharedOptions = {
    borderWidth: "2",
    pointRadius: 0,
    pointHitRadius: 16,
    borderJoinStyle: "bevel",
  };

  const datasets = spotPriceAreas.map((spotPriceArea, i) => ({
    ...sharedOptions,
    label: `${spotPriceArea.area} ${spotPriceArea.areaDescription}`,
    borderColor: colors[i],
    backgroundColor: colors[i],
    data: spotPriceArea.spotPrices.map((spotPrice) => ({
      x: new Date(spotPrice.time),
      y: parseFloat(spotPrice.price),
    })),
  }));

  return (
    <Chart
      enableCopy={true}
      data={{ datasets: datasets }}
      options={{
        title: translate("Spot prices"),
        yLabel: spotPriceAreas[0].currency,
        classNameForChartCanvas: styles.chartCanvas,
      }}
    />
  );
};

export default SpotPriceChart;
