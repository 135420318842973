import React from "react";

import { useTranslation } from "src/translations/translationProvider";
import { mapTypeToColor } from "/src/components/chart/utils";
import Chart from "./chart";

const EnergyAggregateChart = ({
  className,
  type,
  readings,
  filterReadings,
  from,
  to,
  unit,
  options,
}) => {
  const { translate } = useTranslation();
  const color = mapTypeToColor(type);
  const chartData = {
    label: translate(type),
    fill: false,
    borderColor: color,
    borderWidth: "2",
    backgroundColor: color,
    pointRadius: 0,
    pointHitRadius: 16,
    borderJoinStyle: "bevel",
    data: readings.filter(filterReadings).map((reading) => ({
      x: new Date(reading.time),
      y: parseFloat(reading.value),
    })),
  };
  return (
    <Chart
      className={className}
      data={{ datasets: [chartData] }}
      options={{
        xMin: from,
        xMax: to,
        yLabel: `${unit}`,
        ...options,
      }}
    />
  );
};

export default EnergyAggregateChart;
