import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import Avatar from "/src/components/avatar";
import Select from "/src/components/select";

import styles from "./profileInfo.module.scss";

const ProfileInfo = ({
  avatar,
  className,
  firstName,
  lastName,
  selectedOrganization,
  onChangeOrganization,
  organizationConnections,
}) => {
  return (
    <div
      data-test-id="profile-info"
      className={classNames(styles.profileInfo, className)}
    >
      <Avatar
        className={styles.avatar}
        imageUrl={avatar && avatar.fileUrl}
        type="large"
      />
      <span className={styles.name} title={`${firstName} ${lastName}`}>
        {firstName} {lastName}
      </span>
      <Select
        searchable={true}
        wrapperClassName={styles.organization}
        triggerClassName={styles.organizationTrigger}
        value={{
          label: selectedOrganization.name,
          value: selectedOrganization.id,
        }}
        options={organizationConnections.map((oc) => ({
          label: oc.organization.name,
          value: oc.organization.id,
        }))}
        isWithCaret={true}
        onChange={({ value }) => {
          if (value) {
            onChangeOrganization(value);
          }
        }}
      />
    </div>
  );
};

ProfileInfo.propTypes = {
  avatar: PropTypes.shape({
    fileUrl: PropTypes.string,
  }),
  className: PropTypes.string,
  selectedOrganization: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  onChangeOrganization: PropTypes.func,
  organizationConnections: PropTypes.arrayOf(
    PropTypes.shape({
      organization: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      }),
    }),
  ).isRequired,
};

ProfileInfo.defaultProps = {
  onChangeOrganization: () => {},
  selectedOrganizationConnection: {},
};

export default ProfileInfo;
