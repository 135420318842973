import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import ModityLogo from "./logo.jsx";

import styles from "./loadingSpinner.module.scss";

const DEFAULT_DELAY = 400; // ms

const LoadingSpinner = ({ className, delay, useDelay, small, big }) => {
  const [hidden, setHidden] = useState(true);
  useEffect(() => {
    let timer;
    if (useDelay) {
      timer = setTimeout(() => {
        setHidden(false);
      }, delay || DEFAULT_DELAY);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [delay, useDelay]);

  return (
    <div
      data-test-id="loadingSpinner"
      className={classNames(styles.loaderWrapper, className)}
    >
      {!hidden && (
        <ModityLogo
          className={classNames(styles.svg, {
            [styles.small]: small,
            [styles.big]: big,
          })}
        />
      )}
    </div>
  );
};

LoadingSpinner.defaultProps = {
  useDelay: true,
};

LoadingSpinner.propTypes = {
  className: PropTypes.string,
  delay: PropTypes.number,
  useDelay: PropTypes.bool,
  big: PropTypes.bool,
  small: PropTypes.bool,
};

export default LoadingSpinner;
