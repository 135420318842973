import * as React from "react";
const SvgArrow = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20.345}
    height={16.27}
    {...props}
  >
    <path
      className="arrow_svg__a"
      d="M20.08 7.359 14.38.441a1.246 1.246 0 0 0-1.723-.172 1.272 1.272 0 0 0-.169 1.721l4.057 4.921H1.221a1.221 1.221 0 0 0 0 2.442h15.324l-4.057 4.921a1.283 1.283 0 0 0 .172 1.723 1.247 1.247 0 0 0 1.723-.172l5.7-6.918a1.265 1.265 0 0 0 0-1.551Z"
    />
  </svg>
);
export default SvgArrow;
