import React, { useEffect, useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import sjson from "secure-json-parse";

import { useTranslation } from "src/translations/translationProvider";
import Table from "/src/components/table";
import ToggleColumns from "/src/components/toggleColumns";
import { FormatOrEmpty } from "/src/components/tableCellFormats";

import portfolioStyles from "/src/modules/portfolio/portfolio.module.scss";
import financialStyles from "../../financial.module.scss";
import styles from "./indexPoolinvoiceMaterial.module.scss";

const storageKey = "visibleIndexPoolInvoiceColumns";

const IndexPoolInvoiceMaterialTable = ({ invoiceMaterial }) => {
  const { translate, formatDate } = useTranslation();
  const [visibleColumns, setVisibleColumns] = useState(
    getColumns({ translate, formatDate })
  );

  useEffect(() => {
    setVisibleColumns(getColumns({ translate, formatDate }));
  }, [translate, formatDate]);

  const decoratedSetVisibleColumns = (columns) => {
    const storeMe = columns.reduce(
      (prev, { accessor, show }) => ({ ...prev, [accessor]: show }),
      {}
    );
    localStorage.setItem(storageKey, JSON.stringify(storeMe));
    setVisibleColumns(columns);
  };

  return (
    <div
      data-test-id="index-pools-invoice-material-table"
      className={classNames(styles.table, portfolioStyles.physicalTable)}
    >
      <div className={financialStyles.titleRow}>
        <div
          className={classNames(portfolioStyles.title, financialStyles.title)}
        ></div>
        <ToggleColumns
          columns={visibleColumns}
          setColumns={decoratedSetVisibleColumns}
        />
      </div>
      <Table
        headerClassName={classNames(
          portfolioStyles.cell,
          styles.header,
          portfolioStyles.header,
          styles.hedgeReportTableHeader
        )}
        cellClassName={portfolioStyles.cell}
        data={invoiceMaterial}
        columns={visibleColumns.filter((column) => column.show)}
        sortable
        exportable
        tableOptions={{
          initialState: {
            sortBy: [
              {
                id: "firstDeliveryMonth",
                desc: true,
              },
            ],
          },
          disableSortRemove: true,
        }}
      />
    </div>
  );
};

IndexPoolInvoiceMaterialTable.protoTypes = {
  invoiceMaterial: PropTypes.array,
};

export default IndexPoolInvoiceMaterialTable;

const getColumns = ({ translate, formatDate }) => {
  const DEFAULT_VISIBLE_COLUMNS = {
    indexPoolType: true,
    firstDeliveryMonth: true,
    currency: true,
    priceArea: true,
    hedgedPrice: true,
    hedgedVolume: true,
    spotPrice: true,
    outcome: true,
    targetVolume: true,
  };

  const UNITS = {
    mwh: "MWh",
    sek: "SEK",
    sek_mwh: "SEK/MWh",
  };

  const savedColumnState = localStorage.getItem(storageKey);

  const visibleColumns = {
    ...DEFAULT_VISIBLE_COLUMNS,
    ...sjson.parse(savedColumnState),
  };

  const LabelAndUnit = ({ label, unit }) => (
    <div className={styles.columnHeader}>
      <div className={styles.columnHeaderLabel}>{label}</div>
      {unit && <div className={styles.columnHeaderUnit}>{unit}</div>}
    </div>
  );

  return [
    {
      Header: () => translate("Type"),
      accessor: "indexPoolType",
      Cell: ({ value }) => translate(value),
      name: translate("Type"),
      show: visibleColumns["indexPoolType"],
    },
    {
      Header: () => translate("First delivery month"),
      accessor: "firstDeliveryMonth",
      Cell: ({ value }) => (
        <div className={styles.capitalize}>
          {formatDate(new Date(value), "MMMM-yyyy")}
        </div>
      ),
      name: translate("First delivery month"),
      show: visibleColumns["firstDeliveryMonth"],
    },
    {
      Header: () => translate("Product"),
      accessor: "priceArea",
      Cell: ({ value }) => value,
      name: translate("Product"),
      show: visibleColumns["priceArea"],
    },
    {
      Header: () => translate("Currency"),
      accessor: "currency",
      Cell: ({ value }) => value,
      name: translate("Currency"),
      show: visibleColumns["currency"],
    },
    {
      Header: () =>
        LabelAndUnit({ label: translate("Target volume"), unit: UNITS.mwh }),
      accessor: "targetVolume",
      Cell: ({ value }) => <FormatOrEmpty value={value} />,
      name: `${translate("Target volume")} ${UNITS.mwh}`,
      show: visibleColumns["targetVolume"],
    },
    {
      Header: () =>
        LabelAndUnit({ label: translate("Hedged volume"), unit: UNITS.mwh }),
      accessor: "hedgedVolume",
      Cell: ({ value }) => <FormatOrEmpty value={value} />,
      name: `${translate("Hedged volume")} ${UNITS.mwh}`,
      show: visibleColumns["hedgedVolume"],
    },
    {
      Header: () =>
        LabelAndUnit({ label: translate("Hedged price"), unit: UNITS.sek_mwh }),
      accessor: "hedgedPrice",
      Cell: ({ value }) => <FormatOrEmpty value={value} />,
      name: `${translate("Hedged price")} ${UNITS.sek_mwh}`,
      show: visibleColumns["hedgedPrice"],
    },
    {
      Header: () =>
        LabelAndUnit({
          label: translate("Heading_SpotPrice_invoice_material"),
          unit: UNITS.sek_mwh,
        }),
      accessor: "spotPrice",
      Cell: ({ value }) => <FormatOrEmpty value={value} />,
      name: `${translate("Heading_SpotPrice_invoice_material")} ${
        UNITS.sek_mwh
      }`,
      show: visibleColumns["spotPrice"],
    },
    {
      Header: () =>
        LabelAndUnit({ label: translate("Outcome"), unit: UNITS.sek }),
      accessor: "outcome",
      Cell: ({ value }) => <FormatOrEmpty value={value} />,
      name: `${translate("Outcome")} ${UNITS.sek}`,
      show: visibleColumns["outcome"],
    },
  ];
};
