import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";

import { useOrganizationQuery } from "/src/services/apollo/hooks/useOrganizationQuery";
import { GET_PLANTS_LISTING } from "/src/services/apollo/queries";
import { formatNumber } from "/src/utilities";

import TableMobile, { LoadingTableMobile } from "/src/components/tableMobile";

import { Translate } from "src/translations/translate";
import { useTranslation } from "src/translations/translationProvider";
import styles from "./overviewTable.module.scss";

const getFormattedNumberCell = (locale, value) => formatNumber(value, locale);

const PlantCell = (id, name) => (
  <Link to={`/energy/plant?id=${id}`} className={styles.plant}>
    {name}
  </Link>
);
PlantCell.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
};

const PlantsListingTableMobile = ({ className, from, to }) => {
  const { translate, locale, formatDate } = useTranslation();
  const columns = [
    {
      Header: translate("Plant"),
      accessorFunction: ({ id, name }) => PlantCell(id, name),
    },
    {
      Header: translate("Production"),
      accessorFunction: ({ production }) => {
        return getFormattedNumberCell(locale, production);
      },
    },
    {
      Header: translate("Consumption"),
      accessorFunction: ({ consumption }) =>
        getFormattedNumberCell(locale, consumption),
    },
  ];

  const { loading, error, data } = useOrganizationQuery(GET_PLANTS_LISTING, {
    variables: {
      fromDate: formatDate(new Date(from), "yyyy-MM-dd"),
      toDate: formatDate(new Date(to), "yyyy-MM-dd"),
    },
  });

  if (loading) {
    return (
      <LoadingTableMobile
        className={className}
        key="plants listing table mobile loading"
      />
    );
  }

  if (error) {
    return (
      <TableMobile data={[]} key="org plants listing loading error">
        <div>
          <span>
            <Translate>UnexpectedError</Translate>
          </span>
        </div>
      </TableMobile>
    );
  }

  const { plants } = data.organization.energy;

  if (!plants || plants.length === 0) {
    return null;
  }

  return (
    <TableMobile
      data={plants}
      columns={columns}
      key="org plants listing table"
    />
  );
};

PlantsListingTableMobile.propTypes = {
  className: PropTypes.string,
  from: PropTypes.object,
  to: PropTypes.object,
};

export default PlantsListingTableMobile;
