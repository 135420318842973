import React, { useState } from "react";
import PropTypes from "prop-types";
import { startOfMonth } from "date-fns";

import { GET_INDEXPOOL_INVOICE_MATERIAL } from "/src/services/apollo/queries";
import { useOrganizationQuery } from "/src/services/apollo/hooks/useOrganizationQuery";
import { useTranslation } from "src/translations/translationProvider";
import { Translate } from "src/translations/translate";

import { INDEXPOOL_TYPE } from "/src/constants/portfolioConfigurationTypes";
import LoadingSpinner from "/src/components/loadingSpinner";
import IndexPoolInvoiceMaterial from "./indexPoolInvoiceMaterial";

const IndexPoolInvoiceMaterialContainer = ({
  portfolioReportConfigurations,
}) => {
  const { formatDate, translate } = useTranslation();
  const now = formatDate(startOfMonth(new Date()), "yyyy-MM-dd");
  const ALL_PRODUCTS = translate("All products");
  const validConfigurations = portfolioReportConfigurations.filter(
    (c) => c.type === INDEXPOOL_TYPE
  );

  const [deliveryMonth, setDeliveryMonth] = useState(now);
  const [selectedProduct, setSelectedProduct] = useState(ALL_PRODUCTS);
  const [selectedConfigurationIds, setSelectedConfigurationIds] = useState(
    validConfigurations.map((c) => c.id)
  );

  const { loading, error, data } = useOrganizationQuery(
    GET_INDEXPOOL_INVOICE_MATERIAL,
    {
      variables: {
        portfolioConfigurationIds: selectedConfigurationIds,
        deliveryMonth: deliveryMonth,
      },
    }
  );

  if (loading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return <Translate>UnexpectedError</Translate>;
  }

  const { indexPoolInvoiceMaterial: invoiceMaterial } = data.organization;
  const availableProducts = [
    ALL_PRODUCTS,
    ...new Set(invoiceMaterial.map((im) => im.priceArea)),
  ];
  const filteredInvoiceMaterial =
    selectedProduct !== ALL_PRODUCTS
      ? invoiceMaterial.filter((im) => im.priceArea === selectedProduct)
      : invoiceMaterial;

  return (
    <IndexPoolInvoiceMaterial
      key={filteredInvoiceMaterial}
      portfolioReportConfigurations={validConfigurations}
      selectedConfigurationIds={selectedConfigurationIds}
      setSelectedConfigurationIds={setSelectedConfigurationIds}
      setDeliveryMonth={setDeliveryMonth}
      deliveryMonth={deliveryMonth}
      invoiceMaterial={filteredInvoiceMaterial}
      selectedProduct={selectedProduct}
      setSelectedProduct={setSelectedProduct}
      availableProducts={availableProducts}
    />
  );
};

IndexPoolInvoiceMaterialContainer.propTypes = {
  portfolioReportConfigurations: PropTypes.array,
};

export default IndexPoolInvoiceMaterialContainer;
