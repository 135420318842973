import { gql } from "@apollo/client";

export const UPDATE_ROLE = gql`
  mutation updateRole(
    $organizationId: String!
    $roleId: Int!
    $role: RoleInput!
  ) {
    updateRole(organizationId: $organizationId, roleId: $roleId, role: $role)
  }
`;
