import { gql } from "@apollo/client";
import { format, eachMonthOfInterval, startOfYear, subYears } from "date-fns";
import { enGB } from "date-fns/locale";
import { capitalize } from "/src/utilities";

export const getMonthlyFragmentsForYear = ({
  organizationId,
  year,
  endDateMoment,
  apolloClient,
}) => {
  const now = new Date();
  const endDate = endDateMoment || now;
  const startDate = endDateMoment
    ? subYears(startOfYear(now), 1)
    : startOfYear(now);
  const months = eachMonthOfInterval({
    start: new Date(2020, 0),
    end: new Date(2020, 11),
  }).map((month) => capitalize(format(month, "MMMM", { locale: enGB })));
  const allMonthsThisYearZeroBased = eachMonthOfInterval({
    start: startDate,
    end: endDate,
  }).map((month) => format(month, "M") - 1);

  const cachedDataForThisYear = allMonthsThisYearZeroBased
    .map((monthIdx) => {
      const id = `EnergyMonthlySummary:organization:${organizationId}:${year}:${months[monthIdx]}`;
      return apolloClient.readFragment({
        id,
        fragment: gql`
          fragment monthlySummary on EnergyMonthlySummary {
            id
            consumption
            production
            month
            monthIndex
            unit
          }
        `,
      });
    })
    .filter((value) => value);
  return cachedDataForThisYear.length ? cachedDataForThisYear : null;
};

export const GET_ORGANIZATION_TWO_YEAR_MONTHLY_SUMMARIES = gql`
  query getOrganizationTwoYearMonthlySummaries(
    $organizationId: String!
    $year: Int!
    $previousYear: Int!
    $canSkipCurrentYear: Boolean!
    $canSkipPreviousYear: Boolean!
  ) {
    organization(id: $organizationId) {
      id
      energy {
        selectedYearSummaries: monthlySummary(year: $year)
          @skip(if: $canSkipCurrentYear) {
          id
          month
          monthIndex
          unit
          consumption
          production
        }
        previousYearSummaries: monthlySummary(year: $previousYear)
          @skip(if: $canSkipPreviousYear) {
          id
          month
          monthIndex
          unit
          consumption
          production
        }
      }
    }
  }
`;
