import React, { useMemo } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

import { formatNumber } from "utilities";
import Table from "components/table";

import portfolioStyles from "modules/portfolio/portfolio.module.scss";
import financialStyles from "../../financial.module.scss";
import greenProductStyles from "../greenProducts.module.scss";
import { useTranslation } from "src/translations/translationProvider";

import { Translate } from "src/translations/translate";

const ROW_FIELDS_ORDERED = [
  "forecast",
  "buyQuantity",
  "buyPriceAvg",
  "buyForecastPercent",
  "sellQuantity",
  "sellPriceAvg",
  "sellForecastPercent",
  "importedBuyQuantity",
  "importedBuyPriceAvg",
  "importedSellQuantity",
  "importedSellPriceAvg",
  "importedQuantity",
  "importedPriceAvg",
  "totalQuantity",
  "totalPriceAvg",
  "totalPrice",
  "remainingQuantity",
  "remainingPercent",
];

const GoWithoutService = ({ tableData }) => {
  const { translate } = useTranslation();
  const years = tableData.map((dataRow) => dataRow.year);
  const columns = useMemo(
    () => getColumnsData({ translate, years }),
    [translate, years],
  );

  const restructuredData = ROW_FIELDS_ORDERED.map((field) => {
    const result = {
      title: { name: field !== "" ? translate(field) : "" },
    };
    tableData.forEach((y) => {
      result[y.year] = formatNumber(y[field]);
    });
    return result;
  });

  return (
    <div>
      <header className={greenProductStyles.sectionHeader}>
        <div
          className={classNames(portfolioStyles.title, financialStyles.title)}
        >
          <Translate>Guarantee of origin</Translate>
        </div>
      </header>
      {tableData.length > 0 && (
        <section
          className={greenProductStyles.tableWrapper}
          data-test-id="goTable"
        >
          <Table
            data={restructuredData}
            columns={columns}
            cellClassName={portfolioStyles.cell}
            headerClassName={portfolioStyles.cell}
          />
        </section>
      )}
      {tableData.length < 1 && <Translate>No data found</Translate>}
    </div>
  );
};

GoWithoutService.propTypes = {
  selectedProductionTypes: PropTypes.array,
  setSelectedProductionTypes: PropTypes.func,
  typeCount: PropTypes.number,
  tableData: PropTypes.arrayOf(
    PropTypes.shape({
      buyForecastPercent: PropTypes.number,
      buyPriceAvg: PropTypes.number,
      buyQuantity: PropTypes.number,
      forecast: PropTypes.number,
      importedBuyPriceAvg: PropTypes.number,
      importedBuyQuantity: PropTypes.number,
      importedPriceAvg: PropTypes.number,
      importedQuantity: PropTypes.number,
      importedSellPriceAvg: PropTypes.number,
      importedSellQuantity: PropTypes.number,
      remainingPercent: PropTypes.number,
      remainingQuantity: PropTypes.number,
      sellForecastPercent: PropTypes.number,
      sellPriceAvg: PropTypes.number,
      sellQuantity: PropTypes.number,
      totalPrice: PropTypes.number,
      totalPriceAvg: PropTypes.number,
      totalQuantity: PropTypes.number,
      year: PropTypes.number,
      __typename: PropTypes.string,
    })
  ),
};

export default GoWithoutService;

function getColumnsData({ translate, years }) {
  const renderCell = ({ name }) => (
    <div
      className={classNames(
        portfolioStyles.header,
        portfolioStyles.cell,
        greenProductStyles.columnHeaderLabel
      )}
    >
      {name}
    </div>
  );

  const renderYear = {
    accessor: "title",
    Cell: ({ value }) => renderCell(value),
    name: translate("Year"),
  };
  return [
    renderYear,
    ...years.map((y) => {
      return {
        Header: () => (
          <div className={greenProductStyles.columnHeaderLabel}>{y}</div>
        ),
        accessor: `${y}`,
        Cell: ({ value }) => value,
        name: y,
      };
    }),
  ];
}
