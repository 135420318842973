import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { getToken } from "/src/auth/authenticationStrategies";

const AuthImage = ({ src, alt, children, ...props }) => {
  const [objectUrl, setObjectUrl] = useState(null);

  useEffect(() => {
    async function loadImage() {
      const token = await getToken();
      const response = await fetch(src, {
        mode: "cors",
        headers: { Authorization: `Bearer ${token}` },
      });

      const blob = await response.blob();
      setObjectUrl(URL.createObjectURL(blob));
    }

    loadImage();
  }, [src]);

  const cleanUp = () => {
    if (objectUrl) {
      URL.revokeObjectURL(objectUrl);
    }
  };

  return (
    objectUrl && (
      <img src={objectUrl} alt={alt} onLoad={cleanUp} {...props}></img>
    )
  );
};

AuthImage.propTypes = {
  url: PropTypes.string,
  alt: PropTypes.string,
};

export default AuthImage;
