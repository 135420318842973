import React, { useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import sjson from "secure-json-parse";

import { isBefore } from "date-fns";
import { useTranslation } from "src/translations/translationProvider";
import Table from "/src/components/table";
import ToggleColumns from "/src/components/toggleColumns";
import { FormatOrEmpty, RawValue } from "/src/components/tableCellFormats";

import portfolioStyles from "/src/modules/portfolio/portfolio.module.scss";
import financialStyles from "/src/modules/portfolio/financial/financial.module.scss";
import styles from "./indexPoolHedgeReport.module.scss";

const DEFAULT_CURRENCY = "SEK";
const storageKey = "visibleIndexPoolHedgeColumns";

const IndexPoolHedgeReportTable = ({
  className,
  unit,
  data,
  header,
  dataResolution,
}) => {
  const { translate, formatDate } = useTranslation();
  const [visibleColumns, setVisibleColumns] = useState(
    getColumnsData({
      formatDate,
      translate,
      unit,
      dataResolution,
    })
  );

  const decoratedSetVisibleColumns = (columns) => {
    const storeMe = columns.reduce(
      (prev, { accessor, show }) => ({ ...prev, [accessor]: show }),
      {}
    );
    localStorage.setItem(storageKey, JSON.stringify(storeMe));
    setVisibleColumns(columns);
  };

  const columns = getColumnsData({
    formatDate,
    translate,
    unit,
    dataResolution,
  }).map((column) => {
    return {
      ...column,
      show: visibleColumns.find((c) => column.accessor === c.accessor).show,
    };
  });

  return (
    <div
      data-test-id="index-pools-overview-table"
      className={classNames(className, portfolioStyles.table)}
    >
      <div className={financialStyles.titleRow}>
        <div
          className={classNames(
            portfolioStyles.title,
            styles.title,
            financialStyles.title
          )}
        >
          {header}
        </div>
        <ToggleColumns
          key={unit}
          columns={columns}
          setColumns={decoratedSetVisibleColumns}
        />
      </div>
      <Table
        headerClassName={classNames(
          portfolioStyles.cell,
          styles.cell,
          portfolioStyles.header,
          styles.hedgeReportTableHeader
        )}
        cellClassName={classNames(portfolioStyles.cell, styles.cell)}
        data={data}
        columns={columns.filter((column) => column.show)}
        sortable
        tableOptions={{
          initialState: {
            sortBy: [
              {
                id: "period",
                desc: false,
              },
            ],
          },
          disableSortRemove: true,
        }}
      />
    </div>
  );
};

IndexPoolHedgeReportTable.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  unit: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.object),
  header: PropTypes.string,
};

export default IndexPoolHedgeReportTable;

function getColumnsData({ formatDate, translate, unit, dataResolution }) {
  const DEFAULT_VISIBLE_COLUMNS = {
    forecast: true,
    period: true,
    hedgeVolume: true,
    targetVolume: true,
    agreementPrice: true,
    openVolume: true,
    marketPrice: true,
    outcome: true,
    portfolioPrice: true,
    hedgePercentage: true,
  };

  const dateFormatMap = {
    Monthly: "MMM - yy",
    Quarterly: "QQQ - yyyy",
    Yearly: "yyyy",
  };

  const savedColumnState = localStorage.getItem(storageKey);

  const visibleColumns = {
    ...DEFAULT_VISIBLE_COLUMNS,
    ...sjson.parse(savedColumnState),
  };

  const LabelAndUnit = (
    { label, unit } // eslint-disable-line react/prop-types
  ) => (
    <div className={styles.columnHeader}>
      <div className={styles.columnHeaderLabel}>{label}</div>
      {unit && <div className={styles.columnHeaderUnit}>{unit}</div>}
    </div>
  );

  const financialOutcomeClass = ({ row }) => {
    const { period } = row.values;
    const now = new Date();

    return isBefore(now, new Date(period))
      ? styles.highlightFinancialOutcome
      : "";
  };

  /* eslint-disable react/prop-types */
  return [
    {
      Header: () => (
        <div className={styles.columnHeaderLabel}>{translate("Period")}</div>
      ),
      accessor: "period",
      Cell: ({ value }) => (
        <div className={styles.monthColumnHeader}>
          {formatDate(new Date(value), dateFormatMap[dataResolution]).replace(
            ".",
            ""
          )}
        </div>
      ),
      name: translate("Period"),
      show: visibleColumns["period"],
    },
    {
      Header: () => LabelAndUnit({ label: translate("Forecast"), unit }),
      accessor: "forecast",
      Cell: ({ value }) => <FormatOrEmpty value={value} />,
      name: `${translate("Forecast")} ${unit}`,
      show: visibleColumns["forecast"],
    },
    {
      Header: () => LabelAndUnit({ label: translate("Target volume"), unit }),
      accessor: "targetVolume",
      Cell: ({ value }) => <FormatOrEmpty value={value} />,
      name: `${translate("Target volume")} ${unit}`,
      show: visibleColumns["targetVolume"],
    },
    {
      Header: () => LabelAndUnit({ label: translate("Hedged volume"), unit }),
      accessor: "hedgeVolume",
      Cell: ({ value }) => <FormatOrEmpty value={value} />,
      name: `${translate("Hedged volume")} ${unit}`,
      show: visibleColumns["hedgeVolume"],
    },
    {
      Header: () =>
        LabelAndUnit({
          label: translate("Hedged price"),
          unit: `${DEFAULT_CURRENCY}/MWh`,
        }),
      accessor: "agreementPrice",
      Cell: ({ value }) => <FormatOrEmpty value={value} />,
      name: `${translate("Hedged price")} ${DEFAULT_CURRENCY}/${unit}`,
      show: visibleColumns["agreementPrice"],
    },
    {
      Header: () => LabelAndUnit({ label: translate("Open volume"), unit }),
      accessor: "openVolume",
      Cell: ({ value }) => <FormatOrEmpty value={value} />,
      name: `${translate("Open volume")} ${unit}`,
      show: visibleColumns["openVolume"],
    },
    {
      Header: () =>
        LabelAndUnit({
          label: translate("Market price"),
          unit: `${DEFAULT_CURRENCY}/${unit}`,
        }),
      accessor: "marketPrice",
      Cell: ({ value }) => <FormatOrEmpty value={value} />,
      name: `${translate("Market price")} ${DEFAULT_CURRENCY}/${unit}`,
      show: visibleColumns["marketPrice"],
    },
    {
      Header: () =>
        LabelAndUnit({
          label: translate("Settlement"),
          unit: DEFAULT_CURRENCY,
        }),
      accessor: "outcome",
      Cell: ({ value, row }) => (
        <FormatOrEmpty
          value={value}
          className={financialOutcomeClass({ row })}
        />
      ),
      name: `${translate("Settlement")} ${DEFAULT_CURRENCY}`,
      show: visibleColumns["outcome"],
    },
    {
      Header: () =>
        LabelAndUnit({
          label: translate("Forecast price"),
          unit: `${DEFAULT_CURRENCY}/${unit}`,
        }),
      accessor: "portfolioPrice",
      Cell: ({ value }) => <FormatOrEmpty value={value} />,
      name: `${translate("Forecast price")} ${DEFAULT_CURRENCY}/${unit}`,
      show: visibleColumns["portfolioPrice"],
    },
    {
      Header: () =>
        LabelAndUnit({
          label: translate("% Hedged"),
        }),
      accessor: "hedgePercentage",
      Cell: ({ value }) => <RawValue value={value} />,
      name: translate("% Hedged"),
      show: visibleColumns["hedgePercentage"],
    },
  ];
  /* eslint-enable react/prop-types */
}
