import { gql } from "@apollo/client";

export const GET_PLANT_MONTHLY_SUMMARIES = gql`
  query getPlantMonthlySummaries(
    $organizationId: String!
    $plantId: String!
    $year: Int!
    $previousYear: Int!
  ) {
    organization(id: $organizationId) {
      id
      plant(id: $plantId) {
        id
        energy {
          currentYearSummaries: monthlySummary(year: $year) {
            id
            month
            unit
            consumption
            production
            standards {
              consumptionMonthly
            }
          }
          previousYearSummaries: monthlySummary(year: $previousYear) {
            id
            month
            unit
            consumption
            production
            standards {
              consumptionMonthly
            }
          }
        }
      }
    }
  }
`;
