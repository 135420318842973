import { differenceBy } from "lodash-es";
import { eachHourOfInterval, endOfDay, startOfDay, format } from "date-fns";

export const modityColors = {
  orange: "#f08517",
  black: "#04101c",
  green: "#25a772",
  lightBlue: "#0289f1",
  purple: "#6d00c1",
  red: "#d80047",
  darkGrey: "#8c8e91",
};

export const distinctColors = [
  {
    name: "Maroon",
    hex: "#800000",
  },
  {
    name: "Teal",
    hex: "#469990",
  },
  {
    name: "Olive",
    hex: "#808000",
  },
  {
    name: "Red",
    hex: "#e6194B",
  },
  {
    name: "Lavender",
    hex: "#e6beff",
  },
  {
    name: "Lime",
    hex: "#bfef45",
  },
  {
    name: "Brown",
    hex: "#9A6324",
  },
  {
    name: "Magenta",
    hex: "#f032e6",
  },
  {
    name: "Pink",
    hex: "#fabebe",
  },
  {
    name: "Yellow",
    hex: "#ffe119",
  },
  {
    name: "Grey",
    hex: "#999999",
  },
  {
    name: "Purple",
    hex: "#911eb4",
  },
];

export function randomColorFactor() {
  return Math.round(Math.random() * 255); // eslint-disable-line no-magic-numbers
}

export function randomColor(opacity) {
  return `rgba(${randomColorFactor()},${randomColorFactor()},${randomColorFactor()},${
    opacity || ".3"
  })`;
}

export function randomDistinctColor() {
  const num = Math.round(Math.random() * distinctColors.length - 1);
  return distinctColors[Math.max(0, num)].hex;
}

export function mapTypeToColor(type) {
  switch (type) {
    case "HourlyStandardsProduction":
    case "Production":
    case "Portfolio_production":
    case "Portfolio_Deducted result":
      return modityColors.orange;
    case "HourlyStandardsConsumption":
    case "Consumption":
    case "Portfolio_consumption":
      return modityColors.black;
    case "SpotPrice":
    case "Portfolio_total":
    case "Portfolio_Total":
      return modityColors.lightBlue;
    case "PlanProduction":
      // eslint-disable-next-line no-magic-numbers
      return hexToRGBA(modityColors.orange, 0.5);
    case "PlanConsumption":
      // eslint-disable-next-line no-magic-numbers
      return hexToRGBA(modityColors.black, 0.5);
    case "Portfolio_Trading fees":
      return modityColors.purple;
    default:
      return randomDistinctColor(1);
  }
}

/* eslint-disable no-magic-numbers */
export function hexToRGBA(hex, alpha = 1) {
  if (typeof hex !== "string") {
    return hex;
  }

  const parsedHex = hex.replace("#", "");

  if (parsedHex.length === 3) {
    const r = parseInt(parsedHex[0], 16);
    const g = parseInt(parsedHex[1], 16);
    const b = parseInt(parsedHex[2], 16);
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  } else if (parsedHex.length === 6) {
    const r = parseInt(parsedHex.slice(0, 2), 16);
    const g = parseInt(parsedHex.slice(2, 4), 16);
    const b = parseInt(parsedHex.slice(4, 6), 16);
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }
  return hex;
}
/* eslint-enable no-magic-numbers */

export function getRandomGraphColors(count) {
  const result = new Array(count);
  let colorIndex = Math.floor(Math.random() * distinctColors.length);

  for (let i = 0; i < count; i++) {
    result[i] = distinctColors[colorIndex].hex;
    colorIndex = ++colorIndex >= distinctColors.length ? 0 : colorIndex;
  }

  return result;
}

export function fillGapsInDatasetWithNull(dataset) {
  if (!(dataset && dataset.length)) {
    return dataset;
  }
  const startOfDataset = new Date(dataset[0].time);
  const endOfDataset = new Date(dataset[dataset.length - 1].time);

  const emptyDataset = generateEmptyDataset(startOfDataset, endOfDataset);

  if (dataset.length === emptyDataset.length) {
    return dataset;
  }

  const difference = differenceBy(emptyDataset, dataset, "time");

  return [...dataset, ...difference].sort(
    (a, b) => new Date(a.time) - new Date(b.time)
  );
}

function generateEmptyDataset(from, to) {
  return eachHourOfInterval({
    start: from,
    end: to,
  }).map((date) => ({
    time: format(date, `yyyy-MM-dd'T'HH:mm:ss`),
    value: null,
  }));
}

export function addMissingHours(dataset, from, to) {
  if (!(dataset && from && to)) {
    return dataset;
  }
  const emptyDataset = generateEmptyHours(
    startOfDay(new Date(from)),
    endOfDay(new Date(to))
  );

  if (dataset.length === emptyDataset.length) {
    return dataset;
  }

  const difference = differenceBy(emptyDataset, dataset, "x");

  return [...dataset, ...difference].sort((a, b) => a.x - b.x);
}

export function generateEmptyHours(from, to) {
  return eachHourOfInterval({
    start: new Date(from),
    end: new Date(to),
  }).map((date) => ({
    x: date.getTime(),
    y: null,
  }));
}
