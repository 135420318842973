import { gql } from "@apollo/client";

import { Image } from "./image";

export const Article = gql`
  fragment Article on Article {
    id
    title
    preamble
    updatedAt
    createdAt
    publishedDate: publicationStartDate
    author {
      id
      firstName
      lastName
      avatar {
        id
        fileUrl
      }
    }
    contentType
    featured
    ... on VideoArticle {
      videoId
    }
    ... on WebinarArticle {
      videoId
    }
    ... on TextArticle {
      heroImage {
        id
        description
        desktop {
          ...Image
        }
        mobile {
          ...Image
        }
        tablet {
          ...Image
        }
      }
      heroThumbnail {
        ...Image
      }
    }
  }
  ${Image}
`;
