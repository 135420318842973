import { useEffect } from "react";

const ENTER_KEY_CODE = 13;

export const useEnter = (handler, deps) => {
  useEffect(() => {
    const keyListener = (event) => {
      const key = event.key || event.keyCode;
      const isEnter = key === "Enter" || key === ENTER_KEY_CODE;
      if (isEnter) {
        handler(event);
      }
    };

    document.addEventListener("keyup", keyListener);
    return () => {
      document.removeEventListener("keyup", keyListener);
    };
  }, deps); // eslint-disable-line react-hooks/exhaustive-deps
};
