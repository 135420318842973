import React, { useState } from "react";
import PropTypes from "prop-types";

import config from "/src/config/config";
import Checkbox from "/src/components/checkbox";
import Button from "/src/components/button";
import { useTranslation } from "src/translations/translationProvider";
import { Translate } from "src/translations/translate";
import { getToken } from "/src/auth/authenticationStrategies";

import styles from "./documentUpload.module.scss";
import { useNotifications } from "src/modules/notifications/notificationsProvider";

const DocumentUpload = ({ organizationId, onSuccess }) => {
  const [selectedFile, setSelectedFile] = useState();
  const [uploadForEveryone, setUploadForEveryone] = useState(false);
  const { showNotification } = useNotifications();
  const { translate } = useTranslation();

  const toggleUploadForEveryone = () => {
    setUploadForEveryone(!uploadForEveryone);
  };

  const handleChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    const token = await getToken();

    const formData = new FormData();
    formData.append("file", selectedFile);

    const uploadUrl = `${config.fileProxyURL}/documents${
      uploadForEveryone ? "" : `?organization=${organizationId}`
    }`;

    try {
      await fetch(uploadUrl, {
        method: "POST",
        body: formData,
        mode: "cors",
        headers: { Authorization: `Bearer ${token}` },
      });

      onSuccess();

      showNotification("Upload succeeded");
    } catch (error) {
      console.log(error);
      showNotification("Upload failed");
    }
  };

  return (
    <div className={styles.documentUpload}>
      <input type="file" name="file" onChange={handleChange}></input>
      <Checkbox
        checked={uploadForEveryone}
        onClick={toggleUploadForEveryone}
        text={translate("Accessible to everyone")}
      />
      <div className={styles.uploadButton}>
        <Button small onClick={handleUpload} disabled={!selectedFile}>
          <Translate>Upload</Translate>
        </Button>
      </div>
    </div>
  );
};

DocumentUpload.propTypes = {
  organizationId: PropTypes.string,
  onSuccess: PropTypes.func,
};

export default DocumentUpload;
