import React from "react";
import PropTypes from "prop-types";

import { Translate } from "src/translations/translate";
import { ORGANIZATION_CATEGORY } from "/src/constants/organizationCategories";

const RangePickerText = ({ organizationCategory }) => {
  const organizationIsSupplier =
    organizationCategory === ORGANIZATION_CATEGORY.SUPPLIER;
  return organizationIsSupplier ? (
    <Translate>ALL PLANTS DURING PERIOD</Translate>
  ) : (
    <Translate>ALL REGIONS DURING PERIOD</Translate>
  );
};

export default RangePickerText;

RangePickerText.propTypes = {
  organizationCategory: PropTypes.string.isRequired,
};
