import React from "react";
import PropTypes from "prop-types";

import { format } from "date-fns";
import { Translate } from "src/translations/translate";
import { useTranslation } from "src/translations/translationProvider";
import AuthLink from "/src/components/authLink";
import Button from "/src/components/button";
import config from "/src/config/config";

const IndexPoolExcelExport = ({
  organizationId,
  portfolioConfigurationIds,
  indexPoolIds,
  pointInTime,
  className,
}) => {
  const { locale } = useTranslation();

  const configIdsParam = portfolioConfigurationIds
    .map((id) => `portfolioConfigurationIds=${encodeURIComponent(id)}`)
    .join("&");

  const indexPoolIdsParam = indexPoolIds
    .map((id) => `indexPoolIds=${encodeURIComponent(id)}`)
    .join("&");

  const downloadUrl = `${
    config.fileProxyURL
  }/indexpool/${organizationId}/excel?${configIdsParam}&${indexPoolIdsParam}&pointInTime=${encodeURIComponent(
    format(pointInTime, "yyyy-MM-dd")
  )}&language=${locale === "sv-SE" ? 0 : 1}`;

  return (
    <AuthLink url={downloadUrl} className={className}>
      <Button small>
        <Translate>Download report</Translate>
      </Button>
    </AuthLink>
  );
};

IndexPoolExcelExport.prototypes = {
  organizationId: PropTypes.string,
  portfolioConfigurationIds: PropTypes.array,
  indexPoolIds: PropTypes.array,
  pointInTime: PropTypes.instanceOf(Date),
  className: PropTypes.string,
};

export default IndexPoolExcelExport;
