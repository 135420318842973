import { gql } from "@apollo/client";

export const GET_USERS_AND_ROLES = gql`
  query getUsers($organizationId: String!) {
    organization(id: $organizationId) {
      id
      userConnections {
        user {
          id
          firstName
          lastName
          showDailyComment
          avatar {
            fileUrl
          }
          active
          organizationCount
        }
        role {
          name
        }
        hasApiToken
      }
      roles {
        id
        name
      }
      emailWhitelist
    }
  }
`;
