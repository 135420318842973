import { useEffect } from "react";

const ESC_KEY_CODE = 27;

export const useEscape = (handler, deps) => {
  useEffect(() => {
    const keyListener = (event) => {
      const key = event.key || event.keyCode;
      const isEscape =
        key === "Escape" || key === "Esc" || key === ESC_KEY_CODE;
      if (isEscape) {
        handler(event);
      }
    };

    document.addEventListener("keyup", keyListener);
    return () => {
      document.removeEventListener("keyup", keyListener);
    };
  }, deps); // eslint-disable-line react-hooks/exhaustive-deps
};
