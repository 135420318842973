import React, { useState } from "react";

import { useTranslation } from "src/translations/translationProvider";

import ControlHeader from "src/components/controlHeader/controlHeader";
import DownloadTemplate from "./downloadTemplate";
import Button, { BUTTON_STATE } from "src/components/button";

import globalStyles from "src/styles/global.module.scss";
import styles from "./availability.module.scss";
import { useNotifications } from "src/modules/notifications/notificationsProvider";

import PlantSelector from "../plant/plantSelector";
import { parseExcel } from "./parseExcel";
import { useMutation, useQuery } from "@apollo/client";
import {
  GetPlantsDocument,
  PlanInput,
  SaveAvailabilityDocument,
} from "src/graphql/graphql";
import LoadingSpinner from "src/components/loadingSpinner";
import { ValidationErrors } from "src/modules/energy/availability/validationErrors";
import {
  ParsedExcelError,
  ValidateParsedExcel,
} from "src/modules/energy/availability/validateAvailability";
import classNames from "classnames";
import { AvailabilityChart } from "./availabilityChart";

export function Availability({
  organizationId,
  plantId,
  setPlantId,
}: {
  organizationId: string;
  plantId: string;
  setPlantId: React.Dispatch<React.SetStateAction<string>>;
}) {
  const { showNotification } = useNotifications();
  const { translate } = useTranslation();

  const [buttonState, setButtonState] = useState<
    (typeof BUTTON_STATE)[keyof typeof BUTTON_STATE]
  >(BUTTON_STATE.DEFAULT);
  const [plans, setPlans] = useState<PlanInput[]>();
  const [errors, setErrors] = useState<ParsedExcelError[]>();
  const [canSave, setCanSave] = useState(false);

  const [saveAvailabilityMutation] = useMutation(SaveAvailabilityDocument);
  const { loading, error, data } = useQuery(GetPlantsDocument, {
    variables: {
      organizationId,
    },
  });

  if (loading) {
    return (
      <div>
        <LoadingSpinner />
      </div>
    );
  }

  if (error || !data) {
    console.log(error); // eslint-disable-line no-console
    return <div>{translate("UnexpectedError")}</div>;
  }

  const plants = data.organization.plants;

  if (plants.length === 0) {
    return (
      <h3 className={classNames(styles.heading, styles.section)}>
        {translate("No plants found")}
      </h3>
    );
  }

  const handleSave = () => {
    setButtonState(BUTTON_STATE.LOADING);

    if (!plans) {
      return;
    }
    saveAvailabilityMutation({
      variables: {
        organizationId,
        plantId,
        plans: plans,
      },
    })
      .then(({ errors }) => {
        if (errors && errors.length) {
          throw errors[0];
        }
        setButtonState(BUTTON_STATE.SUCCESS);
        setTimeout(() => {
          setButtonState(BUTTON_STATE.DEFAULT);
        }, 2000);
        showNotification(translate("Successfully saved Availability plan"));
      })
      .catch((error) => {
        console.error("Saving availability failed: ", error);
        setButtonState(BUTTON_STATE.FAIL);
        setTimeout(() => {
          setButtonState(BUTTON_STATE.DEFAULT);
        }, 2000);
        showNotification(translate("Saving availability plan failed"));
      });
  };

  const handleFileChange = (file: File) => {
    parseExcel(file, (result) => {
      const { errors, values } = ValidateParsedExcel(result);
      if (errors.length > 0) {
        setErrors(errors);
        setCanSave(false);
      } else {
        setPlans(values);
        setCanSave(true);
      }
    });
  };

  return (
    <div>
      <PlantSelector
        plantId={plantId}
        setPlantId={setPlantId}
        plants={plants}
        loading={loading}
        error={error}
      />
      <header className={globalStyles.subTitle}>
        {translate("AvailabilityPlanDownloadInstructions")}
      </header>
      <ControlHeader className={styles.controlHeader}>
        <div className={styles.fileUpload}>
          <input
            type="file"
            name="availabilityPlan"
            onChange={(e) => {
              const file = e.target.files?.[0];
              if (!file) {
                return;
              }
              handleFileChange(file);
            }}
          ></input>
        </div>
        <DownloadTemplate
          className={styles.downloadTemplate}
          organizationId={organizationId}
          plantId={plantId}
        />
      </ControlHeader>
      <main className={styles.verification}>
        <div className={globalStyles.sectionTitleLarge}>
          <AvailabilityChart
          availabilityPlans={plans} 
          className={styles.chart}/>
        </div>
        <ValidationErrors errors={errors || []} />
      </main>
      <Button
        state={buttonState}
        disabled={!canSave}
        onClick={handleSave}
        small
      >
        {translate("Save")}
      </Button>
    </div>
  );
}
