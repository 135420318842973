import React from "react";
import PropTypes from "prop-types";

import {
  PlaceholderChart,
  PlaceholderChartText,
} from "/src/components/chart/placeholderChart";
import { Translate } from "src/translations/translate";

const NoDataChart = ({ className }) => (
  <PlaceholderChart className={className}>
    <PlaceholderChartText>
      <Translate>No data found</Translate>
    </PlaceholderChartText>
  </PlaceholderChart>
);

NoDataChart.propTypes = {
  className: PropTypes.string,
};

export { NoDataChart };
