import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { useTranslation } from "src/translations/translationProvider";
import { Translate } from "src/translations/translate";
import Dropdown from "/src/components/dropdown";
import Checkbox from "/src/components/checkbox";
import Button from "/src/components/button";

import selectStyles from "../select.module.scss";
import styles from "./nestedSelect.module.scss";

const NestedSelect = ({
  wrapperClassName,
  triggerClassName,
  showSelectedCount,
  triggerNode,
  infoNode,
  values,
  secondValues,
  options = [],
  onChange,
  isWithCaret,
  isBottomToTop,
  label,
  showSelectAll,
}) => {
  const { translate } = useTranslation();
  const dropdownRef = useRef(null);
  const [selectedValue, setSelectedValue] = useState(values);
  const [selectedSubValues, setSelectedSubValues] = useState(secondValues);

  const placeHolder = (
    <span>
      <Translate>Select</Translate>...
    </span>
  );
  const valueNode =
    (showSelectedCount && (
      <span>
        {selectedSubValues.length}{" "}
        {selectedSubValues.length > 1
          ? ` ${translate("MULTIPLE_SELECTED")}`
          : ` ${translate("SINGULAR_SELECTED")}`}
      </span>
    )) ||
    (values && (
      <span data-test-id="available-selections">
        {options.map((x) => x.subList).flat().length}{" "}
        <Translate>available selections</Translate>
      </span>
    ));

  useEffect(() => {
    setSelectedValue(values);
  }, [values]);

  const selectItem = (selectedItem) => {
    const isSelected =
      selectedValue &&
      selectedValue.map((value) => value.value).includes(selectedItem.value);
    if (!isSelected) {
      const newSelection = [...selectedValue, selectedItem];
      setSelectedValue(newSelection);
      setSelectedSubValues([...selectedSubValues, ...selectedItem.subList]);
    } else {
      const newSelection =
        selectedValue &&
        selectedValue.filter((value) => {
          return value.value !== selectedItem.value;
        });
      setSelectedValue([...newSelection]);

      const newSubValues = selectedSubValues.filter(
        (val) => !selectedItem.subList.find((i) => i.value === val.value)
      );
      setSelectedSubValues(newSubValues);
    }
  };

  const selectSubItem = (subItem) => {
    const isSelected =
      selectedSubValues &&
      selectedSubValues.map((value) => value.value).includes(subItem.value);
    if (!isSelected) {
      const newSelection = [...selectedSubValues, subItem];
      setSelectedSubValues(newSelection);
    } else {
      const newSelection =
        selectedSubValues &&
        selectedSubValues.filter((value) => {
          return value.value !== subItem.value;
        });

      setSelectedSubValues([...newSelection]);
    }
  };

  const outsideClick = () => {
    cancelButtonClick();
  };

  const doneButtonClick = () => {
    onChange(selectedSubValues);
    dropdownRef.current.forceClose();
  };

  const cancelButtonClick = () => {
    dropdownRef.current.forceClose();
  };

  const selectOrUnselectAll = () => {
    setSelectedValue(selectedValue.length === options.length ? [] : options);
  };

  const optionsList =
    options.length > 0 &&
    options.map((option) => {
      let isSelected =
        selectedValue &&
        selectedValue.map((value) => value.value).includes(option.value);
      return (
        <div
          data-test-id={"select-item-option"}
          className={selectStyles.dropDownItem}
          key={option.value}
          onClick={() => selectItem(option)}
        >
          <Checkbox small checked={isSelected}></Checkbox>
          <span>{option.label}</span>
        </div>
      );
    });

  const subOptionsList = selectedValue.map((value) => {
    const { subList } = value;

    return subList.map((item) => {
      let isSelected =
        selectedSubValues &&
        selectedSubValues.some((v) => v.value.includes(item.value));

      return (
        <div
          data-test-id={"select-item-nested-option"}
          className={selectStyles.dropDownItem}
          key={item.value}
          onClick={() => selectSubItem(item)}
        >
          <Checkbox small checked={isSelected}></Checkbox>
          <span>{item.label}</span>
        </div>
      );
    });
  });

  const trigger = (
    <>
      {label && <div className={selectStyles.triggerLabel}>{label}</div>}
      <div className={classNames(triggerClassName, selectStyles.trigger)}>
        {triggerNode || valueNode || placeHolder}
      </div>
    </>
  );

  return (
    <Dropdown
      className={wrapperClassName}
      ref={dropdownRef}
      trigger={trigger}
      onOutsideClickCallback={outsideClick}
      isWithCaret={isWithCaret}
      isBottomToTop={isBottomToTop}
    >
      <div data-test-id={`dropdown-${label}`} className={selectStyles.content}>
        {showSelectAll && (
          <div
            data-test-id={`button-name-${showSelectAll}`}
            className={selectStyles.selectAll}
          >
            <Button
              className={selectStyles.allButton}
              small
              type={"secondary"}
              onClick={selectOrUnselectAll}
            >
              {selectedValue.length === options.length ? (
                <Translate>Unselect all</Translate>
              ) : (
                <Translate>Select all</Translate>
              )}
            </Button>
          </div>
        )}
        {infoNode && <div className={selectStyles.info}>{infoNode}</div>}
        <div className={styles.lists}>
          {optionsList && <div>{optionsList}</div>}
          {subOptionsList && <div>{subOptionsList}</div>}
        </div>
      </div>
      <div className={selectStyles.buttonRow}>
        <Button
          className={selectStyles.cancelButton}
          small
          type={"secondary"}
          onClick={cancelButtonClick}
        >
          <Translate>Cancel</Translate>
        </Button>
        <Button
          data-test-id="select-done-button"
          small
          onClick={doneButtonClick}
        >
          <Translate>Done</Translate>
        </Button>
      </div>
    </Dropdown>
  );
};

const selectionType = PropTypes.shape({
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  value: PropTypes.string,
});

const valueType = PropTypes.oneOfType([
  PropTypes.arrayOf(selectionType),
  selectionType,
]);

NestedSelect.propTypes = {
  triggerNode: PropTypes.node,
  infoNode: PropTypes.node,
  wrapperClassName: PropTypes.string,
  triggerClassName: PropTypes.string,
  triggerLabelClassName: PropTypes.string,
  values: valueType,
  secondValues: valueType,
  showSelectedCount: PropTypes.bool,
  maxSelections: PropTypes.number,
  options: PropTypes.arrayOf(selectionType),
  onChange: PropTypes.func,
  isWithCaret: PropTypes.bool,
  isBottomToTop: PropTypes.bool,
  label: PropTypes.string,
  showSelectAll: PropTypes.bool,
};

NestedSelect.defaultProps = {
  onChange: () => {},
};

export { NestedSelect };
