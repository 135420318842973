import { gql } from "@apollo/client";

export const GET_PLANTS_LISTING = gql`
  query getPlantsListing(
    $organizationId: String!
    $fromDate: String!
    $toDate: String!
  ) {
    organization(id: $organizationId) {
      id
      energy {
        plants(fromDate: $fromDate, toDate: $toDate) {
          id
          name
          consumption
          production
        }
      }
    }
  }
`;
