import { gql } from "@apollo/client";

export const GET_SPOT_PRICES = gql`
  query getSpotPrices(
    $organizationId: String!
    $plantId: String!
    $fromDate: String!
    $toDate: String!
    $currency: String = "Eur"
  ) {
    organization(id: $organizationId) {
      id
      plant(id: $plantId) {
        id
        spotPrices(currency: $currency, fromDate: $fromDate, toDate: $toDate) {
          id
          area
          currency
          hourlyPrices {
            id
            date
            price
          }
        }
      }
    }
  }
`;
