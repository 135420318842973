import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { startOfMonth, subMonths } from "date-fns";

import { useOrganizationQuery } from "/src/services/apollo/hooks/useOrganizationQuery";
import { useTranslation } from "src/translations/translationProvider";
import { Translate } from "src/translations/translate";
import { GET_FINANCIAL_OUTCOME_DATA } from "/src/services/apollo/queries";
import PortfolioCard from "/src/modules/portfolio/portfolioCard";
import ControlHeader from "/src/components/controlHeader";
import Select from "/src/components/select";
import { MonthPicker } from "/src/components/calendar";
import { formatNumber, roundNumber } from "/src/utilities";
import ProductResultTable from "./productResultTable";
import PortfolioConfigurationSelect from "../portfolioConfigurationSelect";
import FinancialOutcomeChart from "./financialOutcomeChart";
import LoadingSpinner from "/src/components/loadingSpinner";
import { ELC_TYPE, GO_TYPE } from "/src/constants/portfolioConfigurationTypes";

import styles from "./outcome.module.scss";
import financialStyles from "../financial.module.scss";
import adminStyles from "/src/styles/admin.module.scss";

const DEFAULT_CURRENCY = "SEK";
const TOTAL = "TOTAL";

const Outcome = ({ portfolioReportConfigurations }) => {
  const { translate, locale, formatDate } = useTranslation();

  const excludedTypes = [ELC_TYPE, GO_TYPE];
  const validConfigurations = portfolioReportConfigurations.filter(
    (c) => !excludedTypes.includes(c.type)
  );

  const [selectedConfigurationIds, setSelectedConfigurationIds] = useState(
    validConfigurations.map((config) => config.id)
  );

  const [activeCurrency, setActiveCurrency] = useState(DEFAULT_CURRENCY);
  const [activeCard, setActiveCard] = useState();
  const previousMonth = startOfMonth(subMonths(new Date(), 1));
  const [date, setDate] = useState(previousMonth);

  const { loading, error, data } = useOrganizationQuery(
    GET_FINANCIAL_OUTCOME_DATA,
    {
      variables: {
        portfolioConfigurationIds: selectedConfigurationIds,
        month: formatDate(date, "yyyy-MM-dd"),
      },
    }
  );

  if (loading) {
    return (
      <div>
        <LoadingSpinner />
      </div>
    );
  }

  if (error) {
    return (
      <div>
        <Translate>UnexpectedError</Translate>
      </div>
    );
  }

  const { outcomes } = data.organization;
  const { currencyResults, totalResult } = outcomes;
  const { exchangeRates, outcomeSummary } = totalResult;
  const currencies = outcomeSummary.map((summary) => summary.currency);

  if (!activeCard) {
    setActiveCard(TOTAL);
  }

  const currenciesTextAndValues = currencies.map((currency) => ({
    label: currency,
    value: currency,
  }));

  return (
    <div className={financialStyles.tabContent}>
      <ControlHeader>
        <div className={financialStyles.dropdown}>
          <Translate>Portfolios</Translate>:
          <PortfolioConfigurationSelect
            availableConfigurations={validConfigurations}
            selectedConfigurationIds={selectedConfigurationIds}
            setSelectedConfigurationIds={setSelectedConfigurationIds}
            showGroups
          />
        </div>
        <div className={financialStyles.dateWrapper}>
          <MonthPicker
            className={styles.monthPicker}
            month={date}
            onChange={setDate}
            triggerClassName={classNames(
              adminStyles.trigger,
              financialStyles.trigger
            )}
          />
        </div>
        <div className={financialStyles.dropdown}>
          {currencies.length > 1 && (
            <Select
              isWithCaret
              triggerClassName={classNames(
                adminStyles.trigger,
                financialStyles.trigger
              )}
              wrapperClassName={financialStyles.selectWrapper}
              options={currenciesTextAndValues}
              value={currenciesTextAndValues.find(
                (currency) => currency.value === activeCurrency
              )}
              onChange={(currency) => setActiveCurrency(currency.value)}
            />
          )}
        </div>
      </ControlHeader>

      {exchangeRates.length > 0 && (
        <div className={styles.exchangeRate}>
          {`${translate("Exchange Rate")}: ${
            exchangeRates[0].name
          } ${roundNumber({
            value: exchangeRates[0].value,
            maxDecimals: 4,
          })}`}
        </div>
      )}

      <div className={financialStyles.cardRow}>
        {currencyResults.map((currencyData, i) => (
          <PortfolioCard
            key={i}
            onClick={() => {
              setActiveCard(currencyData.currency);
            }}
            active={activeCard === currencyData.currency}
            text={translate("Settlement")}
            value={formatNumber(currencyData.summary.outcome, locale)}
            valueClassName={styles.fees}
            unit={currencyData.currency.toUpperCase()}
            vertical
          />
        ))}
        <PortfolioCard
          active={activeCard === TOTAL}
          onClick={() => {
            setActiveCard(TOTAL);
          }}
          text={translate("Total")}
          value={formatNumber(
            outcomeSummary.find(
              (summary) => summary.currency === activeCurrency
            ).value,
            locale
          )}
          valueClassName={financialStyles.total}
          unit={activeCurrency.toUpperCase()}
          vertical
        />
      </div>

      {activeCard && activeCard !== TOTAL && (
        <>
          <ProductResultTable
            className={financialStyles.table}
            title={`${translate("Calculated result for")} ${formatDate(
              date,
              "MMMM yyyy"
            )}`}
            data={
              activeCard === TOTAL
                ? totalResult
                : currencyResults.find(
                    (currencyResult) => currencyResult.currency === activeCard
                  )
            }
          />
          {data && (
            <FinancialOutcomeChart
              className={styles.barChart}
              loading={loading}
              error={error}
              data={
                activeCard === TOTAL
                  ? totalResult
                  : currencyResults.find(
                      (currencyResult) => currencyResult.currency === activeCard
                    )
              }
              selectedDate={date}
            />
          )}
        </>
      )}
    </div>
  );
};

Outcome.propTypes = {
  portfolioReportConfigurations: PropTypes.array.isRequired,
};

export default Outcome;
