// in [invoiceMaterials]
// out [ [year, [ [month, [invoiceMaterials] ] ]] ]
export const invoiceMaterialFormatter = (invoiceMaterials, formatDate) => {
  if (!invoiceMaterials) return [];

  invoiceMaterials.sort((a, b) => {
    if (a.insertionDate < b.insertionDate) return 1;
    if (a.insertionDate > b.insertionDate) return -1;
    return 0;
  });

  return invoiceMaterials.reduce((list, invoice) => {
    // We need to use insertionDate to match these to invoices
    const date = new Date(invoice.insertionDate);
    const year = formatDate(date, "yyyy");
    const month = formatDate(date, "MMMM");

    const years = list.find((y) => y[0] === year);
    if (!years) {
      list.push([year, [[month, [invoice]]]]);
    } else {
      const months = years[1].find((m) => m[0] === month);
      if (!months) {
        years[1].push([month, [invoice]]);
      } else {
        months[1].push(invoice);
      }
    }

    return list;
  }, []);
};
