import { gql } from "@apollo/client";

export const GET_TRANSACTIONS_AND_PRODUCTS = gql`
  query getTransactionsById(
    $organizationId: String!
    $portfolioConfigurationIds: [String]
    $fromDate: String
    $toDate: String
  ) {
    organization(id: $organizationId) {
      id
      transactions(
        portfolioConfigurationIds: $portfolioConfigurationIds
        fromDate: $fromDate
        toDate: $toDate
      ) {
        products
        transactions {
          id
          tradeDate
          status
          product
          mWh
          mw
          price
          outcome
          startDate
          endDate
          currency
          comment1
          comment2
        }
      }
    }
  }
`;
