export default [
  { text: "BM sol", value: "GecSolar" },
  { text: "BM vind", value: "GecWind" },
  { text: "BM vatten", value: "GecHydro" },
  { text: "BM bio", value: "GecBio" },
  { text: "Sol", value: "Solar" },
  { text: "Vind", value: "Wind" },
  { text: "Vatten", value: "Hydro" },
  { text: "Bio", value: "Bio" },
  { text: "Kärnkraft", value: "Nuclear" },
];
